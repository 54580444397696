import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { UserBadgeField } from 'shared/react-admin/Fields/UserBadgeField/UserBadgeField';
import { User } from 'types';

export const UserActionUserField: React.FC = () => {
  return (
    <FunctionField
      link={false}
      render={(record: User) => {
        const { firstName, lastName } = record;
        return (
          <Box display="flex" gap={1}>
            <UserBadgeField firstName={firstName} lastName={lastName} />
            <div>
              <Typography gutterBottom variant="body2">{`${firstName} ${lastName}`}</Typography>
              <Typography color="#00000061" variant="body2">
                {record?.corporateEmail || record?.email}
              </Typography>
            </div>
          </Box>
        );
      }}
    />
  );
};
