import { LinkProps, useLocation } from 'react-router-dom';

import { useHistoryContext } from '../../../../../hooks/useHistoryContext';
import { DatagridActionsEditButton } from '../DatagridActionsEditButton/DatagridActionsEditButton';

export type DatagridActionsEditDialogPageButtonProps = Partial<LinkProps>;

export const DatagridActionsEditDialogPageButton: React.FC<
  DatagridActionsEditDialogPageButtonProps
> = (props) => {
  const { baseRoute } = useHistoryContext();
  const location = useLocation();

  return (
    <DatagridActionsEditButton state={{ backgroundLocation: baseRoute || location }} {...props} />
  );
};
