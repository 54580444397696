import { useState } from 'react';

import { useListContext } from 'react-admin';
import { Loading } from 'shared/react-admin/Loading';

import { CumulativeReportDatagrid } from './CumulativeReportDatagrid';
import { BoxStyled } from './CumulativeReportDatagrid.styled';
import { CumulativeReportDatagridPeriod } from './CumulativeReportDatagridPeriod';
import { CumulativeReportFilterValuesNew } from '../../CumulativeReportFilterNew';
import { CumulativeReportEmptyAlert } from '../CumulativeReportAlerts/CumulativeReportEmptyAlert';

export type Props = {
  filters: CumulativeReportFilterValuesNew;
  isLoading: boolean;
};

export const CumulativeReportDatagridCollapsible: React.FC<Props> = ({ filters, isLoading }) => {
  const { data: cumulativeReport } = useListContext();
  const [isOpen, setIsOpen] = useState(true);

  if (isLoading) {
    return <Loading />;
  }

  if (!cumulativeReport.length && !Object.keys(filters).length) {
    return <CumulativeReportEmptyAlert />;
  }

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  if (isOpen) {
    return (
      <BoxStyled>
        <CumulativeReportDatagridPeriod
          filters={filters}
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
        />
        <CumulativeReportDatagrid filters={filters} />
      </BoxStyled>
    );
  }

  return (
    <BoxStyled>
      <CumulativeReportDatagridPeriod
        filters={filters}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
      />
    </BoxStyled>
  );
};
