import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useListContext } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { Currency } from 'types';
import {
  RefundChargebackReportSummaryMerchantAccount,
  RefundChargebackReportSummaryMerchantAccountConversionValues,
  RefundChargebackReportSummaryMerchantAccountOriginalValues,
} from 'types/refundChagrebackReport';
import { convertReactAdminIdToHydraId, formatCurrency } from 'utils';

type Props = {
  conversionValueKey: keyof RefundChargebackReportSummaryMerchantAccountConversionValues;
  originalValueKey: keyof RefundChargebackReportSummaryMerchantAccountOriginalValues;
  source?:
    | keyof RefundChargebackReportSummaryMerchantAccountConversionValues
    | keyof RefundChargebackReportSummaryMerchantAccountOriginalValues;
} & Omit<FunctionFieldProps, 'render'>;

export const RefundChargebackReportSummaryAmountField: React.FC<Props> = ({
  label,
  conversionValueKey,
  originalValueKey,
  source,
  ...rest
}) => {
  const { filterValues } = useListContext();
  const { data: conversionCurrency } = useGetOne<Currency>(
    'currencies',
    {
      id: convertReactAdminIdToHydraId(filterValues?.conversionCurrency, 'currencies'),
    },
    { enabled: !!filterValues?.conversionCurrency },
  );

  return (
    <FunctionField
      label={label}
      render={(record: RefundChargebackReportSummaryMerchantAccount) => {
        if (Number(record?.[originalValueKey]) === 0) {
          return EMPTY_FIELD;
        }
        const isOriginalCurrency = record?.currency.alphaCode === conversionCurrency?.alphaCode;

        return (
          <Stack direction="column">
            <Typography variant="body2">
              {formatCurrency(Number(record?.[originalValueKey]))}{' '}
              <Typography component="span" fontWeight={500} variant="body2">
                {record?.currency.alphaCode}
              </Typography>
            </Typography>
            {!isOriginalCurrency && (
              <Typography color="#00000099" variant="body2">
                {formatCurrency(Number(record?.[conversionValueKey]))}{' '}
                <Typography component="span" fontWeight={500} variant="body2">
                  {conversionCurrency?.alphaCode}
                </Typography>
              </Typography>
            )}
          </Stack>
        );
      }}
      source={source}
      {...rest}
    />
  );
};
