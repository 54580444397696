import { RaRecord } from 'react-admin';

import { formatCurrency } from '../../../../utils';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type SimpleCurrencyFieldProps<T extends RaRecord = RaRecord> = {
  onClick?: (record?: T) => void;
  source: keyof T;
} & Omit<FunctionFieldProps, 'render'>;

export const SimpleCurrencyField = <T extends RaRecord = RaRecord>({
  source,
  ...rest
}: SimpleCurrencyFieldProps<T>) => (
  <FunctionField
    render={(record: T) => {
      if (!record?.[source]) {
        return rest?.emptyText;
      }

      return formatCurrency(record?.[source] ? Number(record?.[source]) : 0);
    }}
    {...rest}
  />
);
