import { fromUnixTime } from 'date-fns';
import { Alert } from 'shared/mui/Alert/Alert';
import { DEFAULT_DATE_TIME_MASK } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';
import { fromTimestampRangeToString } from 'shared/mui/DatePicker/utils/fromTimestampRangeToString';

import { OrdersReportFilterFormValues } from '../../OrdersReportFilterNew';

type Props = {
  filterValues: OrdersReportFilterFormValues;
};

export const OrdersReportPeriodSuccessAlert: React.FC<Props> = ({ filterValues }) => {
  return (
    <Alert>
      {`Отчет сформирован за период ${fromTimestampRangeToString(
        [
          Number(fromUnixTime(filterValues.createdAt.gte)),
          Number(fromUnixTime(filterValues.createdAt.lte)),
        ],
        DEFAULT_DATE_TIME_MASK,
        filterValues.timezone,
      )} (${TIMEZONES.find((tz) => tz?.name === filterValues.timezone)?.offset || '+3 UTC'})`}
    </Alert>
  );
};
