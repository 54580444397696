import { SxProps } from '@mui/material';
import { TableCell, TableRow } from 'shared/mui/Table';

import { Typography } from '../../Typography';

export type TableRowUnitedWrapperProps = {
  title: string;
  children?: React.ReactNode;
  headerStyle?: SxProps;
  contentStyle?: SxProps;
};

export const TableRowUnitedWrapper: React.FC<TableRowUnitedWrapperProps> = ({
  title,
  children,
  headerStyle,
  contentStyle,
}) => (
  <TableRow>
    <TableCell
      colSpan={2}
      component="th"
      scope="row"
      sx={{ textWrap: 'wrap', wordBreak: 'break-all', paddingLeft: '24px', ...contentStyle }}
    >
      <Typography fontSize="14px" fontWeight={500} sx={{ marginBottom: '8px', ...headerStyle }}>
        {title}
      </Typography>
      {children}
    </TableCell>
  </TableRow>
);
