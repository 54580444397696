import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { Limit, Permission } from 'types';

import { LimitsShowTable } from './LimitsShowTable';
import { usePermissions } from '../../../hooks/usePermissions';
import { ShowGuesserDialog } from '../../../shared/react-admin/Dialogs/ShowGuesserDialog/ShowGuesserDialog';
import { LimitsDeleteDialog } from '../components/LimitsDeleteDialog';

export const LimitsShow = (): JSX.Element => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Limit>();

  return (
    <>
      <ShowGuesserDialog open={!openRecordModalDelete} title="Просмотр лимита">
        <LimitsShowTable />
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('limits', [Permission.Full, Permission.Update]) ? (
              <EditButton />
            ) : undefined
          }
          rightActionsSlot={
            hasResourcePermissions('limits', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
            ) : undefined
          }
        />
      </ShowGuesserDialog>
      {openRecordModalDelete && (
        <LimitsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={record}
        />
      )}
    </>
  );
};
