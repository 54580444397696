import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { Filter, FilterRow } from 'shared/mui/FilterNew';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { FilterFieldGroupStyled } from './GatewaysFilters.styled';
import { GatewaysFiltersStatusSelectInput } from './GatewaysFiltersStatusSelectInput/GatewaysFiltersStatusSelectInput';
import { BOOLEAN_CHOICES } from '../../../constants/booleanChoices';
import { useFilters } from '../../../hooks/useFilters';

type GatewayFilterValues = {
  name: string;
  provider: string;
};

export const GatewaysFilters: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<GatewayFilterValues>({
    name: '',
    provider: '',
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow>
            <FilterFieldGroupStyled>
              <TextInputShrink
                alwaysOn
                helperText={false}
                key="name"
                label="Поиск по названию"
                resettable
                source="name"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
              <GatewaysFiltersStatusSelectInput />
              <ReferenceInput name="provider" perPage={100} reference="providers" source="provider">
                <AutocompleteInputShrink
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  helperText={false}
                  label="Провайдер"
                  optionText="name"
                  sx={{ minWidth: 0 }}
                  variant="outlined"
                />
              </ReferenceInput>
              <AutocompleteInputShrink
                choices={BOOLEAN_CHOICES}
                fullWidth
                helperText={false}
                label="PCI DSS"
                name="pcidss"
                source="pcidss"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
              <AutocompleteInputShrink
                choices={BOOLEAN_CHOICES}
                fullWidth
                helperText={false}
                label="Push API"
                name="pushApi"
                source="pushApi"
                sx={{ minWidth: 0 }}
                variant="outlined"
              />
            </FilterFieldGroupStyled>
            <FilterActions sx={{ mt: 0 }}>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
