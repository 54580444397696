import { CreateButton } from 'shared/mui/Buttons';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  BooleanField,
  DateTimeField,
  FunctionField,
  ImageField,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { TooltipTextLinkField } from 'shared/react-admin/Fields/TooltipTextLinkField/TooltipTextLinkField';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MethodLogosShowDialog } from 'shared/widgets/MethodLogosShowDialog/MethodLogosShowDialog';

import { StyledDatagrid } from './MethodsList.styled';
import { usePermissions } from '../../../hooks/usePermissions';
import { useRecordModal } from '../../../hooks/useRecordModal';
import { Method, MethodLogo, Permission } from '../../../types';
import { MethodsDeleteDialog } from '../components/MethodsDeleteDialog/MethodsDeleteDialog';
import { MethodsFilter } from '../MethodsFilter';

export const MethodsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<Method>();
  const {
    record: logo,
    handleCloseRecordModal: handleCloseRecordModalLogo,
    openRecordModal: openRecordModalLogo,
    handleOpenRecordModal: handleOpenRecordModalLogo,
  } = useRecordModal<MethodLogo>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('methods', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-comissions" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Методы',
        tooltipText:
          'Способ проведения платежа в Интернет, обладающий определенными характеристиками. Например, банковская карта, ваучер, криптовалютный кошелек.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <MethodsFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextLinkField
          label="Название метода"
          resource="methods"
          source="name"
          type="show"
        />
        <TooltipTextField label="Алиас" source="alias" />
        {hasResourceAccess('method_types') && (
          <ReferenceOneField
            label="Тип метода"
            reference="method_types"
            sortBy="methodType.name"
            source="methodType"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        <BooleanField label="Активен" source="active" />
        {hasResourceAccess('method_logos') && (
          <ReferenceOneField
            label="Логотип"
            reference="method_logos"
            sortable={false}
            source="logo"
            target="id"
          >
            <FunctionField
              render={(record: MethodLogo | undefined) => (
                <ImageField
                  onClick={() => handleOpenRecordModalLogo(record)}
                  source="filePath"
                  sx={{
                    cursor: 'pointer',
                    '& .RaImageField-image': {
                      maxWidth: 60,
                      maxHeight: 60,
                      minWidth: 60,
                      minHeight: 60,
                      objectFit: 'contain',
                    },
                  }}
                />
              )}
            />
          </ReferenceOneField>
        )}
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <DatagridActions
          render={(record) => [
            hasResourceAccess('user_actions') && (
              <DatagridActionsUserActionsButton entityName="method" key="user-action-button" />
            ),
            <DatagridActionsShowButton key="show-button" />,
            hasResourcePermissions('methods', [Permission.Full, Permission.Update]) && (
              <DatagridActionsEditButton key="edit-button" />
            ),
            hasResourcePermissions('methods', [Permission.Full, Permission.Delete]) && (
              <DatagridActionsDeleteButton
                key="delete-button"
                onClick={() => handleOpenRecordModal(record as Method)}
              />
            ),
          ]}
        />
      </StyledDatagrid>
      {openRecordModal && (
        <MethodsDeleteDialog
          onClose={handleCloseRecordModal}
          open={openRecordModal}
          record={record}
        />
      )}
      {logo && (
        <MethodLogosShowDialog
          methodLogo={logo}
          onClose={handleCloseRecordModalLogo}
          open={openRecordModalLogo}
        />
      )}
    </ListPage>
  );
};
