import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { grey } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';

import { PlaceholderWrapperStyled } from './TableFileViewerUploader.styled';
import { Link } from '../../../../mui/Link/Link';

type FileUploaderPlaceholderProps = {
  onClick: () => void;
};

export const TableFileViewerUploaderPlaceholder: React.FC<FileUploaderPlaceholderProps> = ({
  onClick,
}) => {
  return (
    <PlaceholderWrapperStyled onClick={onClick}>
      <UploadFileOutlinedIcon sx={{ fontSize: '28px', color: grey[600], marginRight: '4px' }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link sx={{ marginTop: '1px' }}>Нажмите, чтобы загрузить</Link>
        <Typography sx={{ lineHeight: '28px', marginLeft: '4px' }} variant="subtitle1">
          или перетащите файл
        </Typography>
      </Box>
    </PlaceholderWrapperStyled>
  );
};
