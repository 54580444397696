import { Table, TableBody } from '@mui/material';
import { Stack } from 'shared/mui/Stack/Stack';
import { TableRow } from 'shared/mui/Table';
import { TransactionsReportSummary } from 'types/transactionsReportSummary';

import { StyledTableCell } from './OrderListSummaryDialog.styled';

type Props = {
  summary?: TransactionsReportSummary;
};

export const OrderListSummaryDialogTable: React.FC<Props> = ({ summary }): JSX.Element => {
  return (
    <Stack direction="row" gap="80px" sx={{ marginY: '24px' }}>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>Создан:</StyledTableCell>
            <StyledTableCell>{summary?.created}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Подготовлен:</StyledTableCell>
            <StyledTableCell>{summary?.prepared}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Ожидание:</StyledTableCell>
            <StyledTableCell>{summary?.pending}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Успех:</StyledTableCell>
            <StyledTableCell>{summary?.success}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Провал:</StyledTableCell>
            <StyledTableCell>{summary?.failure}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>Отменен:</StyledTableCell>
            <StyledTableCell>{summary?.cancelled}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Просрочен:</StyledTableCell>
            <StyledTableCell>{summary?.expired}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Рефанд:</StyledTableCell>
            <StyledTableCell>{summary?.refund}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Чарджбек:</StyledTableCell>
            <StyledTableCell>{summary?.chargeback}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>На проверке:</StyledTableCell>
            <StyledTableCell>{summary?.waitingConfirmation}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};
