import { useHistoryContext } from 'hooks/useHistoryContext';
import { LinkProps, useLocation } from 'react-router-dom';

import { DatagridActionsShowButton } from '../DatagridActionsShowButton/DatagridActionsShowButton';

export type DatagridActionsShowDialogPageButtonProps = Partial<LinkProps>;

export const DatagridActionsShowDialogPageButton: React.FC<
  DatagridActionsShowDialogPageButtonProps
> = (props) => {
  const { baseRoute } = useHistoryContext();
  const location = useLocation();

  return (
    <DatagridActionsShowButton state={{ backgroundLocation: baseRoute || location }} {...props} />
  );
};
