import { OrderAdjustmentsTypes } from 'constants/orderAdjustmentTypes';

import { useShowController, useGetManyReference } from 'react-admin';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';
import { Order } from 'types';
import { OrderAdjustments } from 'types/orderAdjustments';

export const OrderShowCommonInfoStateAdjustmentsField = (): JSX.Element => {
  const { record: order } = useShowController<Order>();

  const { data: orderAdjustments, isLoading } = useGetManyReference<OrderAdjustments>(
    'order_adjustments',
    {
      target: 'order',
      id: order?.id,
      filter: {
        adjustmentType: OrderAdjustmentsTypes.OrderAmount,
      },
    },
  );

  if (isLoading) {
    return <LinearProgress />;
  }

  const lastAdjustment = orderAdjustments?.at(-1);
  if (!lastAdjustment) {
    return <Typography variant="body2">Нет</Typography>;
  }

  return (
    <Stack alignItems="center" direction="row" gap="8px">
      <Typography
        sx={{ textDecoration: 'line-through', color: appColors.text.secondary }}
        variant="body2"
      >
        {lastAdjustment.oldAmount}
      </Typography>
      &rarr;
      <Typography variant="body2">{lastAdjustment.newAmount}</Typography>
    </Stack>
  );
};
