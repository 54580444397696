import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { Table } from 'shared/mui/Table';
import { appColors } from 'themes/variables';

export const StyledBox = styled(Box)({
  height: '36px',
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
});

export const StyledTable = styled(Table)({
  borderTop: `1px solid ${appColors.divider}`,
  '& thead': { display: 'table-header-group' },
  '& .MuiTableCell-body': {
    borderRight: `1px solid ${appColors.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
    width: '50%',
    verticalAlign: 'top',
    borderBottom: 'none',
    backgroundColor: appColors.background,
  },
  '& .MuiTableCell-head': {
    borderRight: `1px solid ${appColors.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
    width: '50%',
    padding: '8px 16px',
    backgroundColor: appColors.background,
  },
});

export const StyledJsonBox = styled(Box)({
  padding: '0 32px',
  backgroundColor: appColors.background,
});
