import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { CONDITION_VALUES } from './constants/conditionValues';

export const MerchantsListFilterForm: React.FC = () => {
  return (
    <>
      <TextInputShrink
        helperText={false}
        id="name"
        key="name"
        label="Название"
        name="name"
        source="name"
        variant="outlined"
      />
      <AutocompleteInputShrink
        choices={CONDITION_VALUES}
        helperText={false}
        label="Состояние"
        name="archive"
        source="archive"
        variant="outlined"
      />
      <ReferenceArrayInput
        isFilter={true}
        name="companies"
        perPage={200}
        reference="companies"
        source="companies"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Компания"
          name="companies"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        isFilter={true}
        name="platform"
        perPage={200}
        reference="merchant_platforms"
        source="platform"
      >
        <SelectInputShrink
          fullWidth
          helperText={false}
          label="Платформа"
          name="platform"
          optionText="name"
          optionValue="value"
          size="small"
          source="platform"
          variant="outlined"
        />
      </ReferenceArrayInput>

      <AutocompleteInputShrink
        choices={BOOLEAN_CHOICES}
        fullWidth
        helperText={false}
        label="Партнерский"
        name="partner"
        source="partner"
        variant="outlined"
      />
    </>
  );
};
