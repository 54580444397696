import React, { useEffect, useState } from 'react';

import { Button, Popover, TextFieldProps } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { DateObject } from 'react-multi-date-picker';

import { Calendar } from '../../Calendar/Calendar';
import { DatePickerActions } from '../components/DatePickerActions/DatePickerActions';
import { DatePickerMaskInput } from '../components/DatePickerMaskInput/DatePickerMaskInput';
import { DEFAULT_SHORTCUTS } from '../components/DatePickerShortcuts/constants/shortcuts';
import { DatePickerShortcuts } from '../components/DatePickerShortcuts/DatePickerShortcuts';
import { DatePickerToolbar } from '../components/DatePickerToolbar/DatePickerToolbar';
import {
  DEFAULT_DATE_MASK,
  DEFAULT_DATE_RANGE_MASK,
  DEFAULT_DATE_RANGE_PLACEHOLDER,
  DEFAULT_SEPARATOR,
  DEFAULT_TIMEZONE,
} from '../config/defaultValues';
import {
  PopoverCalendarContainerStyled,
  PopoverContentStyled,
  PopoverDatesStyled,
  PopoverLayoutStyled,
  PopoverListStyled,
} from '../DatePicker.styled';
import { useAnchor } from '../hooks/useAnchor';
import { formatStringToTimestamp } from '../utils/formatStringToTimestamp';
import { fromTimestampRangeToString } from '../utils/fromTimestampRangeToString';
import { isValidDate } from '../utils/isValidDate';

export type DateRangePickerValue = number | null;

export type DateRangePickerProps = Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue'> & {
  value: [DateRangePickerValue, DateRangePickerValue];
  numberOfMonths?: 1 | 2;
  displayShortcuts?: boolean;
  onChange?: (newValue: [DateRangePickerValue, DateRangePickerValue]) => void;
  maxDate?: Date;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  label,
  variant = 'outlined',
  numberOfMonths = 2,
  displayShortcuts = true,
  maxDate,
  disabled,
  onChange: onChangeProp,
  value,
  ...restProps
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const [error, setError] = useState(false);

  const [resetValue, setResetValue] = useState<[DateRangePickerValue, DateRangePickerValue]>(value);

  const [inputValue, setInputValue] = useState<string | undefined>();

  const [calendarValue, setCalendarValue] =
    useState<[DateRangePickerValue, DateRangePickerValue]>(value);

  const open = Boolean(anchorEl);
  const id = open ? 'date-picker-popover' : undefined;

  const debouncedOnChangeInputHandler = useDebounce((target: string) => {
    const [start, end] = target.split(DEFAULT_SEPARATOR);

    setError(true);
    const validStart = isValidDate(start) ? formatStringToTimestamp(start) : null;
    const validEnd = isValidDate(end) ? formatStringToTimestamp(end) : null;

    if (isValidDate(start)) {
      setError(false);
      setCalendarValue((prev) => [validStart, prev[1]]);
    } else {
      setCalendarValue((prev) => [null, prev[1]]);
    }

    if (isValidDate(end)) {
      setError(false);
      setCalendarValue((prev) => [prev[0], validEnd]);
    } else {
      setCalendarValue((prev) => [prev[0], null]);
    }

    setInputValue(target);
  }, 300);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedOnChangeInputHandler(event?.target?.value);
  }

  function onChangeCalendar(value: (DateObject | null)[]) {
    const [start, end] = value;

    const validStart = start ? Number(startOfDay(start.valueOf())) : null;
    const validEnd = end ? Number(endOfDay(end.valueOf())) : null;

    const validValue: [DateRangePickerValue, DateRangePickerValue] = [validStart, validEnd];

    setInputValue(fromTimestampRangeToString(validValue));
    setCalendarValue(validValue);
  }

  const onClickShortcut = (value: [DateRangePickerValue, DateRangePickerValue]) => {
    setInputValue(fromTimestampRangeToString(value));
    setCalendarValue(value);
  };

  function onClickApply() {
    if (onChangeProp) {
      const validValue: [DateRangePickerValue, DateRangePickerValue] = [
        calendarValue[0],
        calendarValue[1] || Number(endOfDay(new Date())),
      ];
      setCalendarValue(validValue);
      onChangeProp(validValue);
      setResetValue(validValue);
      handleClose();
    }
  }

  function onClickReset() {
    if (onChangeProp) {
      onChangeProp(resetValue);
      setInputValue(fromTimestampRangeToString(resetValue));
      setCalendarValue(resetValue);
      handleClose();
    }
  }

  const onResetInput = () => {
    if (onChangeProp) {
      setInputValue('');
      setCalendarValue([null, null]);
      onChangeProp([null, null]);
      setResetValue([null, null]);
      setError(false);
    }
  };

  useEffect(() => {
    setResetValue(value);

    if (value[0] || value[1]) {
      setCalendarValue(value);
      setInputValue(
        fromTimestampRangeToString([value[0], value[1]], DEFAULT_DATE_MASK, DEFAULT_TIMEZONE),
      );
      return;
    }
    setCalendarValue([null, null]);
    setInputValue('');
  }, [value]);

  return (
    <>
      <DatePickerMaskInput
        {...restProps}
        disabled={disabled}
        error={error}
        label={label}
        mask={DEFAULT_DATE_RANGE_MASK}
        onChange={onChangeInput}
        onClick={(e) => {
          if (disabled) {
            return;
          }

          handleClick(e);
        }}
        onResetInput={onResetInput}
        placeholder={DEFAULT_DATE_RANGE_PLACEHOLDER}
        value={inputValue}
        variant={variant}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableAutoFocus={true}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverLayoutStyled
          sx={{
            width: '713px',
          }}
        >
          <PopoverCalendarContainerStyled>
            {displayShortcuts && (
              <PopoverListStyled>
                <DatePickerShortcuts
                  onClick={onClickShortcut}
                  shortcuts={(timezone) => [DEFAULT_SHORTCUTS(timezone)[0]]}
                />
              </PopoverListStyled>
            )}
            <PopoverContentStyled>
              <DatePickerToolbar end={calendarValue[1]} start={calendarValue[0]} />
              <PopoverDatesStyled>
                <PopoverCalendarContainerStyled>
                  <Calendar
                    maxDate={maxDate}
                    numberOfMonths={numberOfMonths}
                    onChange={onChangeCalendar}
                    range
                    value={calendarValue}
                  />
                </PopoverCalendarContainerStyled>
              </PopoverDatesStyled>
            </PopoverContentStyled>
          </PopoverCalendarContainerStyled>
          <DatePickerActions>
            <Button onClick={onClickReset}>Отменить</Button>
            <Button onClick={onClickApply} variant="contained">
              Подтвердить
            </Button>
          </DatePickerActions>
        </PopoverLayoutStyled>
      </Popover>
    </>
  );
};
