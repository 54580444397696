import { useMemo } from 'react';

import { formatInTimeZone } from 'date-fns-tz';
import { DefaultValues } from 'react-hook-form';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';

import { RefundChargebackReportListFilterValues } from '../RefundChargebackReportFilter/RefundChargebackReportFilter';

type Props = Pick<
  DefaultValues<RefundChargebackReportListFilterValues>,
  'appliedDate' | 'updatedDate'
>;

export function useGetReportPeriodDateTimeString({ appliedDate, updatedDate }: Props): string {
  const reportPeriod = useMemo(() => {
    const dateFilterValue = appliedDate?.[0] ? appliedDate : updatedDate?.[0] ? updatedDate : null;
    return `${dateFilterValue?.[0] ? formatInTimeZone(dateFilterValue?.[0], dateFilterValue?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy HH:mm') : ''} -
      ${dateFilterValue?.[1] ? formatInTimeZone(dateFilterValue?.[1], dateFilterValue?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy HH:mm') : ''}`;
  }, [appliedDate, updatedDate]);

  return reportPeriod;
}
