import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { formatInTimeZone } from 'date-fns-tz';
import { useGetList } from 'react-admin';
import { DefaultValues } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

type Props = {
  onClose: () => void;
  filters: CumulativeReportFilterValuesNew | DefaultValues<CumulativeReportFilterValuesNew>;
};

export const CumulativeReportSuccessAlertNew: React.FC<Props> = ({ onClose, filters }) => {
  const { appliedDate } = filters;
  const { data: currencyData } = useGetList<Currency>('currencies', {
    filter: { alphaCode: filters?.currency },
    sort: { field: 'id', order: 'ASC' },
  });
  const currencyName = currencyData?.[0]
    ? `${currencyData?.[0].alphaCode}, ${currencyData?.[0].name}`
    : '';

  return (
    <Alert onClose={onClose} severity="success" sx={{ margin: '16px 0' }}>
      <Typography fontWeight={500}>Актуальный курс</Typography>
      <Typography fontSize={14}>
        {`Отчет сформирован за период ${appliedDate?.[0] ? formatInTimeZone(appliedDate?.[0], appliedDate?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy HH:mm') : ''} -
          ${appliedDate?.[1] ? formatInTimeZone(appliedDate?.[1], appliedDate?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy HH:mm') : ''} (${TIMEZONES.find((tz) => tz?.name === appliedDate?.[2])?.offset || 'UTC +3'}) и сконвертирован в валюте
          "${currencyName}" на текущую дату запроса.`}
      </Typography>
    </Alert>
  );
};
