import { OrderStatuses } from 'constants/orderStatuses';

import { usePermissions } from 'hooks/usePermissions';
import { useGetOne, useShowController } from 'react-admin';
import { Box } from 'shared/mui/Box';

import { StyledLeftColumnBox, StyledRightColumnBox } from './OrderShowCommonInfo.styled';
import { OrderShowCommonInfoAdjustments } from './OrderShowCommonInfoAdjustments/OrderShowCommonInfoAdjustments';
import { OrderShowCommonInfoConfirmation } from './OrderShowCommonInfoConfirmation/OrderShowCommonInfoConfirmation';
import { OrderShowCommonInfoMain } from './OrderShowCommonInfoMain/OrderShowCommonInfoMain';
import { OrderShowCommonInfoMerchant } from './OrderShowCommonInfoMerchant/OrderShowCommonInfoMerchant';
import { OrderShowCommonInfoProvider } from './OrderShowCommonInfoProvider/OrderShowCommonInfoProvider';
import { OrderShowCommonInfoResources } from './OrderShowCommonInfoResources/OrderShowCommonInfoResources';
import { OrderShowCommonInfoState } from './OrderShowCommonInfoState/OrderShowCommonInfoState';
import { OrderShowCommonInfoStatuses } from './OrderShowCommonInfoStatuses/OrderShowCommonInfoStatuses';
import { OrderShowCommonInfoTransactions } from './OrderShowCommonInfoTransactions/OrderShowCommonInfoTransactions';

export const OrderShowCommonInfo = (): JSX.Element => {
  const { hasResourceAccess } = usePermissions();
  const { record } = useShowController();

  const { data: orderStatus } = useGetOne(
    'order_statuses',
    {
      id: record?.status || '',
    },
    {
      enabled: !!record,
    },
  );

  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <StyledLeftColumnBox>
        <OrderShowCommonInfoState />
        <OrderShowCommonInfoMain />
        {hasResourceAccess('merchants') && <OrderShowCommonInfoMerchant />}
        {hasResourceAccess('providers') && <OrderShowCommonInfoProvider />}
        {(hasResourceAccess('order_action_urls') ||
          hasResourceAccess('order_notification_urls')) && <OrderShowCommonInfoResources />}
      </StyledLeftColumnBox>
      <StyledRightColumnBox>
        {orderStatus?.value === OrderStatuses.WaitingConfirmation && (
          <OrderShowCommonInfoConfirmation />
        )}
        {hasResourceAccess('transactions') && <OrderShowCommonInfoTransactions />}
        {hasResourceAccess('order_status_histories') && <OrderShowCommonInfoStatuses />}
        {hasResourceAccess('order_adjustments') && <OrderShowCommonInfoAdjustments />}
      </StyledRightColumnBox>
    </Box>
  );
};
