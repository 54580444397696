import { CreateButton } from 'shared/mui/Buttons';
import { EditDialogIconButton, ShowDialogIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { DateTimeField, SimpleCurrencyField, TextField } from 'shared/react-admin/Fields';
import { CurrencyField } from 'shared/react-admin/Fields/CurrencyField/CurrencyField';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { DatagridStyled } from './LimitsList.styled';
import { EMPTY_FIELD } from '../../../constants';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';
import { LimitsFilters } from '../LimitsFilters';

export const LimitsList: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('limits', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create-limits" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Лимиты',
        tooltipText:
          'Данные о минимальных и максимальных значениях сумм пополнений и выводов шлюза в некоторой валюте.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <LimitsFilters />
      <DatagridStyled bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        {hasResourceAccess('merchants') && (
          <ReferenceOneField
            emptyText={EMPTY_FIELD}
            label="Мерчант"
            link="show"
            reference="merchants"
            resource="orders"
            sortable={false}
            source="merchant"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('gateways') && (
          <ReferenceOneField
            label="Шлюз"
            link="show"
            reference="gateways"
            sortBy="gateway.name"
            source="gateway"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.name"
            source="currency"
            target="id"
          >
            <CurrencyField alphaCodeSource="alphaCode" nameSource="name" />
          </ReferenceOneField>
        )}
        <SimpleCurrencyField
          emptyText={EMPTY_FIELD}
          label="Мин. сумма пополнения"
          source="paymentMin"
        />
        <SimpleCurrencyField
          emptyText={EMPTY_FIELD}
          label="Макс. сумма пополнения"
          source="paymentMax"
        />
        <SimpleCurrencyField emptyText={EMPTY_FIELD} label="Мин. сумма вывода" source="payoutMin" />
        <SimpleCurrencyField
          emptyText={EMPTY_FIELD}
          label="Макс. сумма вывода"
          source="payoutMax"
        />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <ActionsSlots
          render={() => (
            <>
              {hasResourceAccess('user_actions') && <UserActionsIconButton entityName="limit" />}
              <ShowDialogIconButton />
              {hasResourcePermissions('limits', [Permission.Full, Permission.Update]) && (
                <EditDialogIconButton />
              )}
            </>
          )}
        />
      </DatagridStyled>
    </ListPage>
  );
};
