import { EMPTY_FIELD } from 'constants/emptyField';

import { Chip } from 'shared/mui/Chips';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { OrderLog } from 'types';

type Props = Omit<FunctionFieldProps, 'render' | 'source'>;

export const OrderShowLogsDelayField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      render={(record: OrderLog) => {
        const delay = Number(record?.delay);
        if (isNaN(delay)) {
          return EMPTY_FIELD;
        }

        return <Chip label={`${delay} Ms`} />;
      }}
      {...props}
    />
  );
};
