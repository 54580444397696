import { Typography } from '@mui/material';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { Divider } from 'shared/mui/Divider/Divider';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { CardContentStyled, OlStyled, SpanStyled } from './WorkTeamsListInfoDialog.styled';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const WorkTeamsListInfoDialog: React.FC<Props> = ({ open, onClose }) => (
  <Dialog onClose={onClose} open={open} transitionDuration={0}>
    <Box sx={{ width: 600 }}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto', gap: '30px' }}
        title="Справка по работе с подразделом “Рабочие группы”"
      />
      <CardContentStyled>
        <Typography sx={{ marginBottom: '16px' }} variant="body2">
          Функциональность <SpanStyled>&quot;Рабочие группы&quot;</SpanStyled> в системе позволяет
          эффективно распределять доступ к данным для формирования финансовой отчетности среди
          менеджеров финансового подразделения компании.
        </Typography>
        <Typography sx={{ marginBottom: '16px' }} variant="body2">
          В этом разделе <SpanStyled>старший финансовый менеджер</SpanStyled> или{' '}
          <SpanStyled>администратор системы</SpanStyled> может создавать группы сотрудников, а затем
          настраивать их доступ через <SpanStyled>&quot;Личные кабинеты&quot;</SpanStyled>.
        </Typography>
        <Divider orientation="horizontal" sx={{ marginBottom: '16px' }} />
        <OlStyled>
          <li>
            <Typography sx={{ marginBottom: '16px', fontWeight: 500 }} variant="body1">
              Процесс создания рабочей группы
            </Typography>
          </li>
          <Typography sx={{ marginBottom: '16px' }} variant="body2">
            Старший финансовый менеджер или администратор может создать{' '}
            <SpanStyled>одну рабочую группу</SpanStyled>, в которой необходимо указать:
          </Typography>
          <OlStyled>
            <li>
              <Typography variant="body2">
                <SpanStyled>Название группы</SpanStyled> — уникальное имя для группы.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <SpanStyled>Руководитель группы</SpanStyled> — выбирается только один пользователь с
                ролью <SpanStyled>&quot;Старший финансовый менеджер&quot;</SpanStyled>. Обратите
                внимание, что данный пользователь может быть назначен руководителем только в одной
                рабочей группе.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                <SpanStyled>Сотрудники группы</SpanStyled> — выбираются пользователи с ролью{' '}
                <SpanStyled>&quot;Финансовый менеджер&quot;</SpanStyled>. В случае, если нужных
                сотрудников нет в списке, необходимо обратиться к администратору системы.
              </Typography>
            </li>
          </OlStyled>
          <Typography sx={{ marginBottom: '16px' }} variant="body2">
            Если комиссия настраивается для партнёра, менеджер платежного отдела может выполнить
            весь процесс согласования самостоятельно, без участия финансового менеджера.
          </Typography>
          <Divider orientation="horizontal" sx={{ marginBottom: '16px' }} />
          <li>
            <Typography sx={{ marginBottom: '16px', fontWeight: 500 }} variant="body1">
              Процесс настройки доступа в личном кабинете
            </Typography>
          </li>
          <Typography variant="body2">
            После создания и настройки <SpanStyled>Рабочей группы</SpanStyled>, старший финансовый
            менеджер или администратор может перейти в подраздел{' '}
            <SpanStyled>&quot;Личные кабинеты&quot;</SpanStyled>, где можно персонализировать доступ
            каждого сотрудника или участника группы.
          </Typography>
        </OlStyled>
        <Button
          label="Вернуться"
          onClick={onClose}
          sx={{ marginLeft: 'auto' }}
          variant="outlined"
        />
      </CardContentStyled>
    </Box>
  </Dialog>
);
