import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';

import { List } from '../../List/List';

export const StyledList = styled(List)({
  marginTop: '24px',
  marginBottom: 0,
  backgroundColor: '#FAFAFA',
});

export const StyledListBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});
