import { styled } from '@mui/material/styles';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(136);

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-name': getColumnStyle(200),
  '& .column-undefined': { width: '100%' },
  '& .column-archive': columnStyle,
  '& .column-merchantCount': {
    ...getColumnStyle(110),
    textAlign: 'center',
  },
  '& .column-createdAt': columnStyle,
  '& .column-updatedAt': columnStyle,
});
