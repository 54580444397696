import * as React from 'react';
import { cloneElement, FC } from 'react';

import { TableFooter } from '@mui/material';
import clsx from 'clsx';
import { DatagridClasses } from 'react-admin';
import { DatagridBodyProps } from 'shared/react-admin/DatagridBodies/DatagridBody/DatagridBody';
import { DatagridRow } from 'shared/react-admin/DatagridRows/DatagridRow/DatagridRow';

export type DatagridFooterProps = DatagridBodyProps;

const defaultChildren = <DatagridRow />;

export const DatagridFooter: FC<DatagridFooterProps> = React.forwardRef(
  (
    {
      children,
      className,
      data = [],
      hover,
      resource,
      row = defaultChildren,
      rowClick,
      rowSx,
      rowStyle,
      ...rest
    },
    ref,
  ) => (
    <TableFooter
      className={clsx('datagrid-footer', className, 'RaDatagrid-tfooter')}
      ref={ref}
      {...rest}
    >
      {data.map((record, rowIndex) =>
        cloneElement(
          row,
          {
            className: clsx(DatagridClasses.row, {
              [DatagridClasses.rowEven]: rowIndex % 2 === 0,
              [DatagridClasses.rowOdd]: rowIndex % 2 !== 0,
            }),
            hover,
            id: record.id ?? `row${rowIndex}`,
            key: record.id ?? `row${rowIndex}`,
            record,
            resource,
            rowClick,
            sx: rowSx?.(record, rowIndex),
            style: rowStyle?.(record, rowIndex),
          },
          children,
        ),
      )}
    </TableFooter>
  ),
);

DatagridFooter.displayName = 'DatagridFooter';
