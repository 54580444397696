import { styled } from '@mui/material';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';

import { getColumnStyle } from '../../../../utils';

export const DatagridStyled = styled(DatagridWithStickyActions)({
  '& .column-originId': getColumnStyle(72),
  '& .column-gateway': getColumnStyle(200),
  '& .column-currency': getColumnStyle(200),
  '& .column-paymentMin': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-paymentMax': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-payoutMin': {
    width: '158px',
    textAlign: 'right',
  },
  '& .column-payoutMax': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-createdAt': getColumnStyle(136),
  '& .column-updatedAt': getColumnStyle(136),
});
