import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { CreateStyled } from './MerchantsShowLimitsCreateDialog.styled';
import { MerchantsShowLimitsCreateDialogForm } from './MerchantsShowLimitsCreateDialogForm/MerchantsShowLimitsCreateDialogForm';
import { LIMITS_SCHEMA } from '../schemas/schema';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
};

interface FormValues extends FieldValues {
  currency: string;
  gateway: string;
  paymentMax: number;
  paymentMin: number;
  payoutMax: number;
  payoutMin: number;
}

const transform = (data: FormValues) => {
  return {
    ...data,
    paymentMax: data.paymentMax ? data.paymentMax + '' : null,
    paymentMin: data.paymentMin ? data.paymentMin + '' : null,
    payoutMax: data.payoutMax ? data.payoutMax + '' : null,
    payoutMin: data.payoutMin ? data.payoutMin + '' : null,
  };
};

export const MerchantsShowLimitsCreateDialog: React.FC<Props> = ({ open, onClose, merchantId }) => {
  const [create] = useCreate();
  const notify = useNotify();

  const onSubmit = (formValues: FormValues) => {
    create(
      'limits',
      {
        data: {
          ...transform(formValues),
          merchant: merchantId,
        },
      },
      {
        onSuccess: () => {
          notify('Лимит успешно создан!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => notify(`Ошибка: ${(error as Error).message}`, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} scroll="body" transitionDuration={0}>
      <CreateStyled resource="merchant_credentials">
        <Form onSubmit={onSubmit as any} resolver={yupResolver<FieldValues>(LIMITS_SCHEMA)}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание лимита"
          />
          <MerchantsShowLimitsCreateDialogForm merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="create" label="Создать" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </CreateStyled>
    </Dialog>
  );
};
