import { useEffect, useState } from 'react';

import _ from 'lodash';
import { FieldValues, useForm, DefaultValues, UseFormReturn } from 'react-hook-form';
import { Mode } from 'react-hook-form/dist/types/form';
import { Resolver } from 'react-hook-form/dist/types/resolvers';

export type UseFiltersReturnValues<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues, any, any>;
  openDrawer: boolean;
  toggleDrawer: (open: boolean) => () => void;
  onReset: () => void;
  onSubmit: (values: TFieldValues) => void;
  appliedFilters: TFieldValues | DefaultValues<TFieldValues>;
  setAppliedFilters: React.Dispatch<
    React.SetStateAction<TFieldValues | DefaultValues<TFieldValues>>
  >;
  setAppliedFilter: (name: string, value: any) => void;
};

type Props<TFieldValues extends FieldValues, TContext> = {
  defaultValues: DefaultValues<TFieldValues>;
  resetValues?: DefaultValues<TFieldValues>;
  resolver?: Resolver<TFieldValues, TContext>;
  mode?: Mode;
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
};

export function useFilters<TFieldValues extends FieldValues, TContext = any>(
  props: Props<TFieldValues, TContext>,
): UseFiltersReturnValues<TFieldValues> {
  const form = useForm<TFieldValues, TContext>({
    shouldUnregister: false,
    mode: props?.mode,
    reValidateMode: props?.reValidateMode,
    resolver: props?.resolver,
    defaultValues: props?.defaultValues || ({} as DefaultValues<TFieldValues>),
  });

  const [appliedFilters, setAppliedFilters] = useState<TFieldValues | DefaultValues<TFieldValues>>(
    props?.defaultValues ? _.cloneDeep(props?.defaultValues) : ({} as TFieldValues),
  );

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setAppliedFilters(
      props?.defaultValues ? _.cloneDeep(props?.defaultValues) : ({} as TFieldValues),
    );
  }, []);

  const toggleDrawer = (open: boolean) => () => {
    setOpenDrawer(open);
  };

  const onSubmit = (values: TFieldValues) => {
    const newValues = _.cloneDeep(values);
    setAppliedFilters({ ...newValues });
  };

  const setAppliedFilter = (name: string, value: any) => {
    setAppliedFilters((prev) => {
      return { ..._.set(prev, name, value) };
    });
  };

  const onReset = () => {
    form.reset(_.cloneDeep(props?.resetValues || props?.defaultValues || ({} as TFieldValues)));
    setAppliedFilters(_.cloneDeep(props?.resetValues || ({} as TFieldValues)));
  };

  return {
    form,
    openDrawer,
    toggleDrawer,
    onSubmit,
    onReset,
    appliedFilters,
    setAppliedFilters,
    setAppliedFilter,
  };
}
