import { AlertProps } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import { StyledAlert } from './OrderListExportAlert.styled';

type Props = {
  onClick: VoidFunction;
  onClose: VoidFunction;
} & AlertProps;

export const OrderListExportAlert: React.FC<Props> = ({ onClick, onClose }) => {
  return (
    <StyledAlert severity="warning">
      <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
        <Typography sx={{ color: appColors.text.secondary }} variant="body2">
          Применённый фильтр слишком широкий, поэтому показано только 1000 записей. Чтобы получить
          все результаты, сделайте экспорт.
        </Typography>
        <Stack alignItems="center" direction="row" gap={2}>
          <Button
            label="экспорт"
            onClick={onClick}
            size="small"
            sx={{ color: appColors.text.secondary }}
            variant="text"
          />
          <CloseIconButton onClose={onClose} sx={{ color: appColors.text.secondary }} />
        </Stack>
      </Stack>
    </StyledAlert>
  );
};
