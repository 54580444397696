import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { CumulativeReport } from 'types';
import { createFilterQueryString } from 'utils/createFilterQueryString';

import { ReactComponent as CompletedReport } from '../../../../../../images/completedReport.svg';
import { LinkStyled, ListItemTextStyled } from '../CumulativeReportDatagrid.styled';

type Props = {
  record: CumulativeReport;
  filters: CumulativeReportFilterValuesNew;
};

export const CumulativeReportDatagridTransactionsButton: React.FC<Props> = ({
  record,
  filters,
}) => {
  return (
    <LinkStyled
      target="_blank"
      to={{
        pathname: '/orders_report',
        search: createFilterQueryString({
          merchantAccount: filters.merchantAccounts,
          merchant: filters.merchant,
          provider: filters.provider,
          company: filters.company,
          timezone: filters.timezone,
          transactions: {
            createdAt: {
              gte: record.periodFrom,
              lte: record.periodTo,
            },
          },
        }),
      }}
    >
      <MenuItem sx={{ width: '274px' }}>
        <ListItemIcon>
          <CompletedReport />
        </ListItemIcon>
        <ListItemTextStyled primary="Исполенные транзакции за выбранный период" />
      </MenuItem>
    </LinkStyled>
  );
};
