import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useRecordContext } from 'react-admin';
import { Chip } from 'shared/mui/Chips';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Commission, Currency } from 'types';

type Props = {
  onClick: (record?: Commission | undefined) => void;
  label?: string;
  source?: string;
};

export const RefundChargebackReportRuleField = ({ onClick }: Props): JSX.Element => {
  const commission = useRecordContext<Commission>();
  const { data: currency, isLoading: isLoadingCurrency } = useGetOne<Currency>(
    'currencies',
    { id: commission?.currency || '' },
    {
      enabled: Boolean(commission?.currency),
    },
  );

  if (!commission) {
    return <>{EMPTY_FIELD}</>;
  }

  if (isLoadingCurrency) {
    return <LinearProgress />;
  }

  const getLabel = () => {
    const fix = commission?.fix ? ` / FIX: ${commission?.fix}` : '';
    const percent = commission?.percent ? ` / ${commission?.percent}%` : '';
    return `${currency?.alphaCode || ''}${fix}${percent}`;
  };

  return (
    <Chip color="primary" label={getLabel()} onClick={() => onClick(commission)} size="small" />
  );
};
