import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { blue } from '@mui/material/colors';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useFormContext } from 'react-hook-form';
import { Link } from 'shared/mui/Link/Link';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { DatagridActionsEditDialogButton } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActionsEditDialogButton/DatagridActionsEditDialogButton';
import { DatagridActionsShowDialogButton } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActionsShowDialogButton/DatagridActionsShowDialogButton';
import {
  ChipField,
  CurrencyField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { CommissionStatusChipField } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField';
import { IconButtonStyled } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField.styled';
import { PeriodActivityField } from 'shared/react-admin/Fields/PeriodActivityField/PeriodActivityField';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { CommissionsCommentDialog } from 'shared/widgets/CommissionsCommentDialog/CommissionsCommentDialog';

import { DatagridStyled } from './MerchantShowCommissionsListDatagrid.styled';
import { EMPTY_FIELD } from '../../../../../../constants';
import { CommissionStatuses } from '../../../../../../constants/commissionStatuses';
import { Commission, Permission } from '../../../../../../types';
import { getId, getLastUrlSegment } from '../../../../../../utils';

type Props = {
  handleOpenShowDialog: (record?: Commission) => void;
  handleOpenEditDialog: (record?: Commission) => void;
  handleOpenDeleteDialog: (record?: Commission) => void;
};

export const MerchantShowCommissionsListDatagrid: React.FC<Props> = ({
  handleOpenEditDialog,
  handleOpenDeleteDialog,
  handleOpenShowDialog,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { setValue } = useFormContext();
  const { setAppliedFilter } = useFilterContext();

  const {
    record: recordComment,
    handleOpenRecordModal: handleOpenRecordModalComment,
    openRecordModal: openRecordModalComment,
    handleCloseRecordModal: handleCloseRecordModalComment,
  } = useRecordModal<Commission>();

  return (
    <>
      <DatagridStyled bulkActionButtons={false}>
        <FunctionField
          label="ID"
          render={(record: Commission) => (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleOpenShowDialog(record);
              }}
            >
              {getId(record?.originId)}
            </Link>
          )}
          sortBy="id"
          source="originId"
        />
        <FunctionField
          label="Версий"
          render={(record: Commission) => {
            if (!record?.children?.length) {
              return EMPTY_FIELD;
            }

            return (
              <>
                <Link
                  onClick={() => {
                    setValue('parent.id', getId(record?.id), { shouldDirty: true });
                    setAppliedFilter('parent.id', getId(record?.id));
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {record?.children?.length}
                </Link>
              </>
            );
          }}
          sortable={false}
          source="parent"
        />
        <PeriodActivityField
          label="Период активности"
          sortable={false}
          source="startDateMilliseconds"
        />
        <TooltipTextField
          label="Название"
          onClick={handleOpenShowDialog}
          source="name"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: blue[700],
          }}
        />
        <FunctionField
          label="Статус"
          render={(record: Commission) => {
            return (
              <ReferenceField
                emptyText={EMPTY_FIELD}
                label="Статус"
                link={false}
                reference="commission_statuses"
                source="status"
              >
                <CommissionStatusChipField source="description">
                  {Boolean(record?.comments.length) && (
                    <IconButtonStyled
                      onClick={() => {
                        handleOpenRecordModalComment(record);
                      }}
                    >
                      <ModeCommentOutlinedIcon />
                    </IconButtonStyled>
                  )}
                </CommissionStatusChipField>
              </ReferenceField>
            );
          }}
          sortable={false}
          source="status"
        />
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.alphaCode"
            source="currency"
            target="id"
          >
            <CurrencyField alphaCodeSource="alphaCode" nameSource="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('transaction_types') && (
          <ReferenceArrayField
            label="Типы транзакций"
            reference="transaction_types"
            sortable={false}
            source="transactionTypes"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <FunctionField
          label="Процент"
          render={(record: Commission) => {
            if (!record?.percent) {
              return EMPTY_FIELD;
            }

            return `${record?.percent}%`;
          }}
          source="percent"
        />
        <NumberField emptyText={EMPTY_FIELD} label="Фикс." sortable={false} source="fix" />
        <NumberField emptyText={EMPTY_FIELD} label="Макс." sortable={false} source="max" />
        <NumberField emptyText={EMPTY_FIELD} label="Мин." sortable={false} source="min" />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <DatagridActions
          render={(record: Commission) => {
            const isShowButton = record?.status
              ? [CommissionStatuses.Created, CommissionStatuses.Rejected].includes(
                  getLastUrlSegment(record?.status) as CommissionStatuses,
                )
              : false;

            return [
              hasResourceAccess('user_actions') && (
                <DatagridActionsUserActionsButton entityName="commission" key="user-action-link" />
              ),
              <DatagridActionsShowDialogButton
                key="show-button"
                onClick={() => handleOpenShowDialog(record)}
              />,
              isShowButton &&
                hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
                  <DatagridActionsEditDialogButton
                    key="edit-button"
                    onClick={() => handleOpenEditDialog(record)}
                  />
                ),
              isShowButton &&
                hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                  <DatagridActionsDeleteButton
                    key="delete-button"
                    onClick={() => handleOpenDeleteDialog(record)}
                  />
                ),
            ];
          }}
        />
      </DatagridStyled>
      <CommissionsCommentDialog
        onClose={handleCloseRecordModalComment}
        open={openRecordModalComment}
        record={recordComment}
      />
    </>
  );
};
