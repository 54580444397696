import { Alert } from 'shared/mui/Alert/Alert';

type Props = {
  userFirstName: string;
  userLastName: string;
};

export const FinanceUserAlert: React.FC<Props> = ({ userFirstName, userLastName }) => (
  <Alert severity="warning" sx={{ marginBottom: '8px' }}>
    {`Не удалось сформировать отчет по указанным критериям. 
      Возможно, для пользователя ${userFirstName} ${userLastName} не настроена "Рабочая группа" и "Личные кабинеты", обратитесь к руководителю вашего подразделения для настроек доступа.`}
  </Alert>
);
