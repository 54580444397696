export const enum CommissionStatuses {
  Created = 'created',
  OnApproval = 'on_approval',
  Rejected = 'rejected',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Implemented = 'implemented',
  Archived = 'archived',
  CreateChildAvailable = 'create_child_available',
}
