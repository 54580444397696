import { Role } from 'constants/roles';

import { useFormContext } from 'react-hook-form';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterAutocompleteChip,
  FilterMultiselectChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const ProviderAccountsFilterChips: React.FC = () => {
  const { watch, setValue, resetField } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const [company, merchant, provider] = watch(['company', 'merchant', 'provider']);
  return (
    <>
      <FilterAutocompleteChip label="Рабочая группа" name="workTeam" resource="work_teams">
        <ReferenceInput perPage={50} reference="work_teams" source="workTeam">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Рабочая группа"
            name="workTeam"
            optionText="name"
            size="small"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Участники"
        limitTags={1}
        name="members"
        renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
        resource="users"
      >
        <ReferenceArrayInput
          filter={{
            'roleEntities.alias': [Role.Finance],
          }}
          perPage={200}
          reference="users"
          source="members"
        >
          <AutocompleteMultiselectInputShrink
            filterToQuery={(searchText: string) => ({
              fullName: searchText,
            })}
            helperText={false}
            label="Участники"
            limitTags={1}
            name="members"
            optionText="name"
            renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterAutocompleteChip
        label="Компания"
        name="company"
        onApply={() => {
          setAppliedFilter('merchant', '');
          setAppliedFilter('provider', '');
          setAppliedFilter('merchantAccounts', []);

          resetField('merchant', { keepDirty: true });
          resetField('provider', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('merchant', { keepDirty: true });
          resetField('provider', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });

          setAppliedFilter('merchant', '');
          setAppliedFilter('provider', '');
          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('merchant', appliedFilters?.['merchant']);
          setValue('provider', appliedFilters?.['provider']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);

          setAppliedFilter('merchant', appliedFilters?.['merchant']);
          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
        }}
        resource="companies"
      >
        <ReferenceInput
          filter={{ exists: { 'merchants.merchantAccounts': true }, archive: false }}
          isFilter={true}
          name="company"
          perPage={200}
          reference="companies"
          source="company"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Компания"
            name="company"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Мерчант"
        name="merchant"
        onApply={() => {
          setAppliedFilter('provider', '');
          setAppliedFilter('merchantAccounts', []);

          resetField('provider', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('provider', { keepDirty: true });
          resetField('merchantAccounts', { keepDirty: true });

          setAppliedFilter('provider', '');
          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
        }}
        resource="merchants"
      >
        <ReferenceInput
          enableGetChoices={() => !!company}
          filter={{
            companies: company,
            exists: { merchantAccounts: true },
            archive: false,
          }}
          isFilter={true}
          name="merchant"
          perPage={200}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Мерчант"
            name="merchant"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Провайдер"
        name="provider"
        onApply={() => {
          setAppliedFilter('merchantAccounts', []);

          resetField('merchantAccounts', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('merchantAccounts', { keepDirty: true });

          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);

          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
        }}
        resource="providers"
      >
        <ReferenceInput
          enableGetChoices={() => !!merchant}
          filter={{ merchantAccounts: { merchants: merchant } }}
          isFilter={true}
          name="provider"
          perPage={200}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Провайдер"
            name="provider"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Счета мерчанта"
        limitTags={1}
        name="merchantAccounts"
        resource="merchant_accounts"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!merchant && !!provider}
          filter={{
            merchants: merchant,
            provider: provider,
          }}
          isFilter={true}
          name="merchantAccounts"
          perPage={1000}
          reference="merchant_accounts"
          resource="merchant_accounts"
          source="merchantAccounts"
        >
          <AutocompleteMultiselectInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Счета мерчанта"
            limitTags={1}
            name="merchantAccounts"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};
