import { EMPTY_FIELD } from 'constants/emptyField';

import { useState } from 'react';

import { useShowLinkByPermission } from 'components/Orders/hooks/useShowLinkByPermission';
import { RecordContextProvider, useShowController } from 'react-admin';
import { Collapse } from 'shared/mui/Collapse/Collapse';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { Table, TableBody } from 'shared/mui/Table';
import { TextField } from 'shared/react-admin/Fields';
import {
  ReferenceField,
  ReferenceLink,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { Order } from 'types';

import { OrderShowCommonInfoMerchantClientField } from './OrderShowCommonInfoMerchantClientField';
import { OrderShowCommonInfoTableRowWrapper } from '../components/OrderShowCommonInfoTableRowWrapper';
import { StyledCard, StyledCardHeader } from '../OrderShowCommonInfo.styled';

export const OrderShowCommonInfoMerchant = (): JSX.Element => {
  const { record: order } = useShowController<Order>();
  const editLinkByPermission = useShowLinkByPermission();

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <RecordContextProvider value={order}>
      <StyledCard>
        <StyledCardHeader
          actions={
            <ExpandIconButton expanded={expanded} onClick={handleExpandClick} size="small" />
          }
          title="Данные мерчанта"
          titleSize="small"
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              <OrderShowCommonInfoTableRowWrapper title="Мерчант:">
                <ReferenceField link={false} reference="merchants" source="merchant">
                  {editLinkByPermission.merchants ? (
                    <ReferenceLink type={editLinkByPermission.merchants} underline="always">
                      <TextField source="name" />
                    </ReferenceLink>
                  ) : (
                    <TextField source="name" />
                  )}
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="Счет мерчанта:">
                <ReferenceOneField
                  label="Счёт"
                  reference="merchant_accounts"
                  source="merchantAccount"
                  target="id"
                >
                  <TextField source="name" />
                </ReferenceOneField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="Клиент:">
                <ReferenceField
                  emptyText="Нет"
                  link={false}
                  reference="customers"
                  source="customer"
                >
                  <OrderShowCommonInfoMerchantClientField />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
              <OrderShowCommonInfoTableRowWrapper title="Данные клиента:">
                <ReferenceField
                  emptyText={EMPTY_FIELD}
                  link={false}
                  reference=".well-known/genid"
                  source="account"
                >
                  <TextField source="account" />
                </ReferenceField>
              </OrderShowCommonInfoTableRowWrapper>
            </TableBody>
          </Table>
        </Collapse>
      </StyledCard>
    </RecordContextProvider>
  );
};
