import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { NUMBER_BOOLEAN_CHOICES } from 'constants/numberBooleanChoices';

import { RaRecord, required } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DateRangePickerValue,
  DateRangeTimezoneValue,
  DateTimeRangeTimezonePicker,
} from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { Stack } from 'shared/mui/Stack/Stack';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

export type RefundChargebackReportListFilterValues = {
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  updatedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  createdAt: {
    gte: number;
    lte: number;
  };
  updatedAt: {
    gte: number;
    lte: number;
  };
  company: string | null;
  merchant: string | null;
  provider: string | null;
  merchantAccount: string[];
  gateway: string[];
  method: string[];
  status: string[];
  impactToBalance: string | null;
  currency: string[];
  conversionCurrency: string | null;
  summary: boolean | string | null;
  id: string;
  merchantReference: string;
  providerReferenceId: string;
  transaction: string;
  timezone: string;
};

export const RefundChargebackReportFilter: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const { company, merchant, provider, gateway, appliedDate, updatedDate, summary } = watch();
  const isAppliedDateDisabled = !!updatedDate[0] || !!updatedDate[1];
  const isUpdatedDateDisabled = !!appliedDate[0] || !!appliedDate[1];

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
          <DateTimeRangeTimezonePicker
            disabled={isAppliedDateDisabled}
            error={!!errors.appliedDate && !isAppliedDateDisabled}
            helperText={!isAppliedDateDisabled ? fieldState?.error?.message : false}
            label="Время создания / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            required
            sx={{ marginBottom: 0 }}
            value={value}
          />
        )}
        rules={{
          validate: {
            required: (value) => {
              if (isAppliedDateDisabled) {
                return true;
              }
              return (!!value[0] && !!value[1]) || 'Обязательно для заполнения';
            },
          },
        }}
      />
      <Controller
        control={control}
        name="updatedDate"
        render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
          <DateTimeRangeTimezonePicker
            disabled={isUpdatedDateDisabled}
            error={!!errors.updatedDate && !isUpdatedDateDisabled}
            helperText={!isUpdatedDateDisabled ? fieldState?.error?.message : false}
            label="Помечен возвратом / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            required
            sx={{ margin: 0 }}
            value={value}
          />
        )}
        rules={{
          validate: {
            required: (value) => {
              if (isUpdatedDateDisabled) {
                return true;
              }
              return (!!value[0] && !!value[1]) || 'Обязательно для заполнения';
            },
          },
        }}
      />
      <ReferenceInput
        filter={{ exists: { merchants: true }, archive: false }}
        perPage={100}
        reference="companies"
        source="company"
      >
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Компания"
          onChange={() => {
            setValue('merchant', null);
            setValue('provider', null);
            setValue('gateway', []);
            setValue('merchantAccount', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!company}
        filter={{
          companies: company,
          exists: { merchantAccounts: true },
          archive: false,
        }}
        perPage={100}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteInputShrink
          disabled={!company}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Мерчант"
          onChange={() => {
            setValue('provider', null);
            setValue('gateway', []);
            setValue('merchantAccount', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!merchant}
        filter={{ merchantAccounts: { merchants: merchant } }}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          disabled={!merchant}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          onChange={() => {
            setValue('merchantAccount', []);
            setValue('gateway', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant?.length && !!provider?.length}
        filter={{
          merchants: merchant,
          provider: provider,
        }}
        isFilter={true}
        name="merchantAccount"
        perPage={1000}
        reference="merchant_accounts"
        resource="orders"
        source="merchantAccount"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!provider}
          helperText={false}
          label="Счет мерчанта"
          limitTags={1}
          name="merchantAccount"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant && !!provider}
        filter={{
          merchantAccounts: { merchants: merchant },
          provider,
        }}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!provider}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          onChange={() => {
            setValue('method', []);
          }}
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{
          gateway,
        }}
        name="method"
        perPage={100}
        reference="methods"
        source="method"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!gateway?.length}
          helperText={false}
          label="Методы"
          limitTags={1}
          name="method"
          optionText="name"
        />
      </ReferenceArrayInput>
      <AutocompleteMultiselectInputShrink
        helperText={false}
        label="Рефанд/Чарджбек"
        name="status"
        optionText="name"
        optionValue="value"
        options={[
          { name: 'Рефанд', value: 'refund' },
          { name: 'Чарджбек', value: 'chargeback' },
        ]}
      />
      <Stack direction="row" gap="16px" justifyContent="space-between">
        <SelectInputShrink
          choices={NUMBER_BOOLEAN_CHOICES}
          defaultValue={null}
          fullWidth
          helperText={false}
          label="Движение по балансу"
          name="impactToBalance"
          optionText="label"
          source="impactToBalance"
          sx={{ minWidth: 0 }}
          variant="outlined"
        />
        <ReferenceArrayInput
          filter={{ active: true }}
          name="currency"
          perPage={50}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteMultiselectInputShrink
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            helperText={false}
            label="Валюта"
            limitTags={1}
            name="currency"
            optionText="name"
            renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
            sx={{
              '.MuiTextField-root': {
                marginTop: 0,
              },
            }}
          />
        </ReferenceArrayInput>
      </Stack>
      <SelectInputShrink
        choices={BOOLEAN_CHOICES}
        defaultValue={false}
        fullWidth
        helperText={false}
        id="summary"
        label="Запросить сводку"
        name="summary"
        onChange={() => {
          setValue('conversionCurrency', null);
        }}
        source="summary"
        validate={required()}
        variant="outlined"
      />
      <ReferenceInput
        filter={{ active: true }}
        name="conversionCurrency"
        perPage={50}
        reference="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="conversionCurrency"
      >
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          fullWidth
          helperText={false}
          label="Валюта конвертации"
          name="conversionCurrency"
          optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
          source="conversionCurrency"
          validate={summary ? required() : undefined}
          variant="outlined"
        />
      </ReferenceInput>
    </>
  );
};
