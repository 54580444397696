import { sendFile } from 'config/requests';
import { useMutation } from 'react-query';

import { UPLOAD_FILES } from './useGetFiles';
import { queryClient } from '../../../../App';

export function useSendFile() {
  const mutation = useMutation((data: FormData) => sendFile(data), {
    onSuccess: () => queryClient.invalidateQueries(UPLOAD_FILES),
  });
  return { sendFile: mutation };
}
