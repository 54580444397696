import { ConversionPeriod } from '../OrderConversionListPeriodToggle/OrderConversionListPeriodToggle';
import { getPeriod } from '../utils/getPeriod';

export function getConfiguredPeriod() {
  return {
    [ConversionPeriod.Minutes60]: getPeriod({ minutes: 60 }),
    [ConversionPeriod.Hours3]: getPeriod({ hours: 3 }),
    [ConversionPeriod.Hours5]: getPeriod({ hours: 5 }),
    [ConversionPeriod.Hours7]: getPeriod({ hours: 7 }),
  };
}
