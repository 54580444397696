import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';

export const RefundChargebackReportEmptyAlert = (): JSX.Element => (
  <Alert severity="info">
    <Typography fontWeight={500} mb="5px">
      Здесь пока нет отчета по рефандам и чарджбекам.
    </Typography>
    <Typography fontSize={14}>
      Чтобы сформировать отчет, выберите параметры в фильтре и нажмите кнопку “Применить”.
    </Typography>
  </Alert>
);
