import { useState } from 'react';

import {
  CumulativeReportFilterValuesNew,
  CumulativeReportNewFilter,
} from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { CumulativeReportListFilterChips } from 'components/Finance/CumulativeReport/CumulativeReportListFilterChips';
import { CumulativeReportResponse } from 'config/requests';
import { getUnixTime, secondsToMilliseconds } from 'date-fns';
import { ListContextProvider, useList, useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { cleanEmpty } from 'utils/cleanEmpty';

import { CumulativeReportSuccessAlertNew } from './components/CumulativeReportAlerts/CumulativeReportSuccessAlertNew';
import { CumulativeReportDatagridCollapsible } from './components/CumulativeReportDatagrid/CumulativeReportDatagridCollapsible';
import { CumulativeReportSummaryCollapsible } from './components/CumulativeReportSummary/CumulativeReportSummaryCollapsible';

type Props = {
  cumulativeReport: CumulativeReportResponse | undefined;
  isLoading: boolean;
};

const DEFAULT_CUMULATIVE_REPORT_FILTER_VALUES = {
  appliedDate: [null, null, null] as [
    DateRangePickerValue,
    DateRangePickerValue,
    DateRangeTimezoneValue,
  ],
  company: null,
  merchant: null,
  currency: null,
  provider: null,
  merchantAccounts: [],
  accountsSummary: null,
};

export const CumulativeReportListContent: React.FC<Props> = ({ cumulativeReport, isLoading }) => {
  const { filterValues } = useListContext();

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<CumulativeReportFilterValuesNew>({
      mode: 'all',
      defaultValues: {
        appliedDate: [
          filterValues?.periodFrom ? secondsToMilliseconds(Number(filterValues.periodFrom)) : null,
          filterValues?.periodTo ? secondsToMilliseconds(Number(filterValues.periodTo)) : null,
          filterValues?.timezone || null,
        ],
        company: filterValues?.company || null,
        merchant: filterValues?.merchant || null,
        currency: filterValues?.currency || null,
        provider: filterValues?.provider || null,
        merchantAccounts: filterValues?.merchantAccounts || [],
        accountsSummary: filterValues?.accountsSummary || null,
      },
      resetValues: DEFAULT_CUMULATIVE_REPORT_FILTER_VALUES,
    });

  const [showAlert, setShowAlert] = useState(true);

  const isListFilterExist = Object.keys(filterValues).length > 0;

  const summaryListContext = useList({
    data: cumulativeReport?.accountsSummaryItems || [],
    isLoading,
  });

  useSyncFilter<CumulativeReportFilterValuesNew>({
    appliedFilters,
    transform: (appliedFilters) => {
      const { appliedDate = [null, null, null], ...restFilters } = appliedFilters;
      return cleanEmpty({
        ...restFilters,
        periodFrom: appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
        periodTo: appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
        timezone: appliedDate?.[2] || null,
      });
    },
  });

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton
            key="clear-filters"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <CumulativeReportNewFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider
          appliedFilters={appliedFilters}
          onReset={onReset}
          setAppliedFilter={setAppliedFilter}
        >
          <FormProvider {...form}>
            <CumulativeReportListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      {cumulativeReport?.reportItems.length && showAlert && (
        <CumulativeReportSuccessAlertNew
          filters={appliedFilters}
          onClose={() => setShowAlert(false)}
        />
      )}
      {!!cumulativeReport?.accountsSummaryItems?.length && (
        <ListContextProvider value={summaryListContext}>
          <CumulativeReportSummaryCollapsible filters={filterValues} />
        </ListContextProvider>
      )}
      <CumulativeReportDatagridCollapsible filters={filterValues} isLoading={isLoading} />
    </>
  );
};
