import { BankOperationStatuses } from 'constants/bankOperationStatuses';
import { OrderStatuses } from 'constants/orderStatuses';
import { UserActionTypes } from 'constants/userActionTypes';

import { CSSProperties } from '@mui/material/styles/createMixins';

import { AdjustmentStatuses } from '../constants/adjustmentStatuses';
import { InviteStatuses } from '../constants/inviteStatuses';

export const enum StatusColors {
  Created = '#42A5F5',
  Prepared = '#42A5F6',
  Waiting = '#FF9800',
  Success = '#4CAF50',
  Fail = '#EF5350',
  Expired = '#EF5351',
  Cancelled = '#BDBDBD',
  Refund = '#BDBDBC',
  ChargeBack = '#BDBDBA',
}

export const enum OrderStatusBackgroundColors {
  Created = '#E6F3FA',
  Success = '#EAF2EA',
  Fail = '#FBEAEA',
  Refund = '#F3F4F6',
  WaitingConfirmation = '#FDF0E6',
}

export const enum AdjustmentsStatusColors {
  Created = '#1976D2',
  InProgress = '#ED6C02',
  Rejected = '#D32F2F',
  Completed = '#2E7D32',
}

export const appColors = {
  primary: {
    main: '#1976D2',
    dark: '#1565C0',
    light: 'rgba(25, 118, 210, 0.3)',
  },
  secondary: {
    main: 'rgba(0,0,0, 0.54)',
    dark: '#7B1FA2',
  },
  background: '#ffffff',
  divider: 'rgba(0, 0, 0, 0.08)',
  inputBorder: 'rgba(0, 0, 0, 0.23)',
  header: {
    green: '#0F9244',
    black: '#191D29',
  },
  success: {
    main: '#2E7D32',
    light: '#4CAF50',
  },
  error: {
    main: '#D32F2F',
    light: '#EF5350',
  },
  warning: {
    main: '#ED6C02',
    light: '#FF9800',
  },
  text: {
    light: '#FFF',
    dark: 'rgba(0, 0, 0, 0.87)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    secondary: 'rgba(0, 0, 0, 0.6)',
  },
  tableHead: '#FAFAFA',
  orderStatus: {
    [OrderStatuses.Created]: StatusColors.Created,
    [OrderStatuses.Pending]: StatusColors.Waiting,
    [OrderStatuses.Failure]: StatusColors.Fail,
    [OrderStatuses.Success]: StatusColors.Success,
    [OrderStatuses.Refund]: StatusColors.Refund,
    [OrderStatuses.Expired]: StatusColors.Expired,
    [OrderStatuses.Chargeback]: StatusColors.ChargeBack,
    [OrderStatuses.Prepared]: StatusColors.Prepared,
    [OrderStatuses.Cancelled]: StatusColors.Cancelled,
    [OrderStatuses.WaitingConfirmation]: '#ED6C02',
  },
  orderStatusBackground: {
    [OrderStatuses.Created]: OrderStatusBackgroundColors.Created,
    [OrderStatuses.Pending]: OrderStatusBackgroundColors.Created,
    [OrderStatuses.Prepared]: OrderStatusBackgroundColors.Created,
    [OrderStatuses.Success]: OrderStatusBackgroundColors.Success,
    [OrderStatuses.Failure]: OrderStatusBackgroundColors.Fail,
    [OrderStatuses.Expired]: OrderStatusBackgroundColors.Fail,
    [OrderStatuses.Cancelled]: OrderStatusBackgroundColors.Fail,
    [OrderStatuses.Refund]: OrderStatusBackgroundColors.Refund,
    [OrderStatuses.Chargeback]: OrderStatusBackgroundColors.Refund,
    [OrderStatuses.WaitingConfirmation]: OrderStatusBackgroundColors.WaitingConfirmation,
  },
  adjustmentStatus: {
    [AdjustmentStatuses.Created]: AdjustmentsStatusColors.Created,
    [AdjustmentStatuses.InProgress]: AdjustmentsStatusColors.InProgress,
    [AdjustmentStatuses.Rejected]: AdjustmentsStatusColors.Rejected,
    [AdjustmentStatuses.Completed]: AdjustmentsStatusColors.Completed,
  },
  bankOperationStatus: {
    [BankOperationStatuses.Created]: StatusColors.Created,
    [BankOperationStatuses.Reviewing]: StatusColors.Waiting,
    [BankOperationStatuses.Canceled]: StatusColors.Fail,
    [BankOperationStatuses.Approved]: StatusColors.Success,
  },
  inviteStatus: {
    [InviteStatuses.Wait]: StatusColors.Waiting,
    [InviteStatuses.Expired]: StatusColors.Expired,
    [InviteStatuses.Sent]: StatusColors.Prepared,
    [InviteStatuses.Accepted]: StatusColors.Success,
  },
  userActionType: {
    [UserActionTypes.PUT]: StatusColors.Prepared,
    [UserActionTypes.POST]: StatusColors.Success,
    [UserActionTypes.DELETE]: StatusColors.Fail,
  },
};

export const sidebarWidth = 305;

export const fieldWidth = {
  default: { minWidth: 268 },
  medium: { minWidth: 240 },
  large: { minWidth: 300 },
  auto: { minWidth: 'auto' },
  maximum: { width: '100%' },
};

export const modalBase: CSSProperties = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: appColors.background,
  boxShadow:
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
};

export const layoutBase: CSSProperties = {
  border: `1px solid ${appColors.divider}`,
  borderRadius: '4px',
  backgroundColor: appColors.background,
  marginTop: '24px',
  '& .RaSimpleShowLayout-root': {
    margin: 0,
    padding: 0,
  },
};

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    header: true;
  }
}
