import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { ExpandIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { StyledCumulativeReportPeriod } from './CumulativeReportDatagrid.styled';

type CumulativeReportPeriodProps = {
  filters: CumulativeReportFilterValuesNew;
  toggleIsOpen: () => void;
  isOpen: boolean;
};

export const CumulativeReportDatagridPeriod: React.FC<CumulativeReportPeriodProps> = ({
  filters,
  toggleIsOpen,
  isOpen,
}) => {
  const alertText = `Нарастающий итог по счетам за период ${formatInTimeZone(
    fromUnixTime(filters?.periodFrom as number),
    filters?.timezone || DEFAULT_TIMEZONE,
    'dd.MM.yyyy',
  )} — ${formatInTimeZone(
    fromUnixTime(filters?.periodTo as number),
    filters?.timezone || DEFAULT_TIMEZONE,
    'dd.MM.yyyy',
  )}`;

  return (
    <StyledCumulativeReportPeriod isOpen={isOpen}>
      <Typography variant="h6">{alertText}</Typography>
      <ExpandIconButton expanded={isOpen} onClick={toggleIsOpen} />
    </StyledCumulativeReportPeriod>
  );
};
