import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { formatInTimeZone } from 'date-fns-tz';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DeleteIconButton, ShowLinkIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Link } from 'shared/mui/Link/Link';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
  WrapperField,
} from 'shared/react-admin/Fields';
import { CommissionStatusChipField } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField';
import { IconButtonStyled } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField.styled';
import { EditIconButton } from 'shared/react-admin/IconButtons';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { Commission, Currency, Permission } from 'types';
import { getLastUrlSegment } from 'utils/getLastUrlSegment';

import { StyledDatagrid } from './CommissionsListDatagrid.styled';
import { EMPTY_FIELD } from '../../../../constants';
import { CommissionStatuses } from '../../../../constants/commissionStatuses';
import { Pagination } from '../../../../shared/react-admin/Pagination/Pagination';
import { CommissionsCommentDialog } from '../../../../shared/widgets/CommissionsCommentDialog/CommissionsCommentDialog';
import { getId } from '../../../../utils';
import { CommissionsDeleteDialog } from '../../components/CommissionsDeleteDialog';

export const CommissionsListDatagrid: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const { setValue } = useFormContext();
  const { setAppliedFilter } = useFilterContext();

  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  const {
    record: recordComment,
    handleOpenRecordModal: handleOpenRecordModalComment,
    openRecordModal: openRecordModalComment,
    handleCloseRecordModal: handleCloseRecordModalComment,
  } = useRecordModal<Commission>();

  return (
    <>
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />

        <FunctionField
          label="Версий"
          render={(record: Commission) => {
            if (!record?.children?.length) {
              return EMPTY_FIELD;
            }

            return (
              <>
                <Link
                  onClick={() => {
                    setValue('parent.id', getId(record?.id));
                    setAppliedFilter('parent.id', getId(record?.id));
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {record?.children?.length}
                </Link>
              </>
            );
          }}
          sortable={false}
        />
        <FunctionField
          label="Период активности"
          render={(record: Commission) => {
            if (!record?.startDateMilliseconds && !record?.endDateMilliseconds) {
              return EMPTY_FIELD;
            }

            let start;
            let end;

            if (record?.startDateMilliseconds) {
              start = formatInTimeZone(
                record?.startDateMilliseconds,
                DEFAULT_TIMEZONE,
                'dd.MM.yyyy',
              );
            }

            if (record?.endDateMilliseconds) {
              end = formatInTimeZone(record?.endDateMilliseconds, DEFAULT_TIMEZONE, 'dd.MM.yyyy');
            }

            if (start && end) {
              return `${start} - ${end}`;
            }

            return start ? `От ${start}` : `До ${end}`;
          }}
          sortable={false}
          source="startDateMilliseconds"
        />
        <FunctionField
          label="Название"
          render={(record: Commission) => {
            return (
              <>
                <Link>{record.name}</Link> <ShowLinkIconButton target="_blank" />
              </>
            );
          }}
          source="name"
        />
        <FunctionField
          label="Статус"
          render={(record: Commission) => {
            return (
              <ReferenceField
                emptyText={EMPTY_FIELD}
                label="Статус"
                link={false}
                reference="commission_statuses"
                source="status"
              >
                <CommissionStatusChipField source="description">
                  {Boolean(record?.comments.length) && (
                    <IconButtonStyled onClick={() => handleOpenRecordModalComment(record)}>
                      <ModeCommentOutlinedIcon />
                    </IconButtonStyled>
                  )}
                </CommissionStatusChipField>
              </ReferenceField>
            );
          }}
        />
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('merchants') && (
          <ReferenceOneField
            emptyText={EMPTY_FIELD}
            label="Мерчант"
            link="show"
            reference="merchants"
            sortBy="merchant.name"
            source="merchant"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.alphaCode"
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record: Currency) => `${record?.alphaCode} / ${record?.name}`}
            />
          </ReferenceOneField>
        )}
        {hasResourceAccess('transaction_types') && (
          <ReferenceArrayField
            label="Типы транзакций"
            reference="transaction_types"
            sortable={false}
            source="transactionTypes"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <FunctionField
          label="Процент"
          render={(record: Commission) => {
            if (!record?.percent) {
              return EMPTY_FIELD;
            }

            return `${record?.percent}%`;
          }}
          source="percent"
        />
        <NumberField emptyText={EMPTY_FIELD} label="Фикс." sortable={false} source="fix" />
        <NumberField emptyText={EMPTY_FIELD} label="Макс." sortable={false} source="max" />
        <NumberField emptyText={EMPTY_FIELD} label="Мин." sortable={false} source="min" />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <WrapperField label="Действия">
          <ActionsSlots
            render={(record: Commission) => {
              const isShowButton = record?.status
                ? [CommissionStatuses.Created, CommissionStatuses.Rejected].includes(
                    getLastUrlSegment(record?.status) as CommissionStatuses,
                  )
                : false;

              return (
                <>
                  {hasResourceAccess('user_actions') && (
                    <UserActionsIconButton entityName="commission" target="_blank" />
                  )}
                  {isShowButton &&
                    hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
                      <EditIconButton target="_blank" />
                    )}
                  <ShowLinkIconButton target="_blank" />
                  {isShowButton &&
                    hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                      <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
                    )}
                </>
              );
            }}
          />
        </WrapperField>
      </StyledDatagrid>
      <Pagination />
      {openRecordModalDelete && (
        <CommissionsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      <CommissionsCommentDialog
        onClose={handleCloseRecordModalComment}
        open={openRecordModalComment}
        record={recordComment}
      />
    </>
  );
};
