import React, { useState } from 'react';

import { Clear } from '@mui/icons-material';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { CalendarTodayIcon } from '../../../Icons';

export type DatePickerMaskInputProps = {
  onResetInput?: () => void;
} & Pick<MaskedInputProps, 'mask' | 'placeholder' | 'onChange' | 'value' | 'onClick' | 'disabled'> &
  Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue'>;

export const DatePickerMaskInput: React.FC<DatePickerMaskInputProps> = ({
  mask,
  onChange,
  placeholder,
  onClick,
  disabled,
  value,
  onResetInput,
  ...restProps
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <MaskedInput
      guide={true}
      keepCharPositions={true}
      mask={mask}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      render={(innerRef, maskProps) => (
        <TextField
          {...restProps}
          {...maskProps}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <>
                {onResetInput && (
                  <IconButton
                    key="reset-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      onResetInput();
                    }}
                    sx={{
                      visibility: visible ? 'visible' : 'hidden',
                      width: '28px',
                      height: '28px',
                    }}
                  >
                    <Clear style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                )}
                <IconButton disabled={disabled} edge="end" key="calendar-icon">
                  <CalendarTodayIcon />
                </IconButton>
              </>
            ),
          }}
          autoComplete="off"
          disabled={disabled}
          inputRef={innerRef}
          onMouseEnter={() => {
            value && !disabled && setVisible(true);
          }}
          onMouseLeave={() => {
            setVisible(false);
          }}
          sx={{ width: '100%', ...restProps.sx }}
        />
      )}
      value={value}
    />
  );
};
