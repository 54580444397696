export const EXPORT_FIELDS = [
  {
    index: '0',
    source: 'kubera_id',
    label: 'Kubera ID',
  },
  {
    index: '1',
    source: 'merchant_reference',
    label: 'Мерчант ID',
  },
  {
    index: '2',
    source: 'provider_reference_id',
    label: 'Провайдер ID',
  },
  {
    index: '3',
    source: 'created_at ',
    label: 'Создан',
  },
  {
    index: '4',
    source: 'updated_at',
    label: 'Помечен возвратом',
  },
  {
    index: '5',
    source: 'status',
    label: 'Статус',
  },
  {
    index: '6',
    source: 'impact_to_balance',
    label: 'Движение по балансу',
  },
  {
    index: '7',
    source: 'amount',
    label: 'Сумма возврата',
  },
  {
    index: '8',
    source: 'currency',
    label: 'Валюта',
  },
  {
    index: '9',
    source: 'commission',
    label: 'Комиссия по возврату',
  },
  {
    index: '10',
    source: 'commission_currency',
    label: 'Валюта комисси по возврату',
  },
  {
    index: '11',
    source: 'commission_by_rule',
    label: 'Комиссия по правилу',
  },
  {
    index: '12',
    source: 'commission_rule_currency',
    label: 'Валюта комисси по правилу',
  },
  {
    index: '13',
    source: 'rule',
    label: 'Правило',
  },
  {
    index: '14',
    source: 'summary_amount',
    label: 'Итого по возврату',
  },
  {
    index: '15',
    source: 'merchant',
    label: 'Мерчант',
  },
  {
    index: '16',
    source: 'merchant_account',
    label: 'Счет мерчанта',
  },
  {
    index: '17',
    source: 'gateway',
    label: 'Шлюз',
  },
  {
    index: '18',
    source: 'method',
    label: 'Метод',
  },
];
