import { useLocation } from 'react-router-dom';

import { useHistoryContext } from '../../../../hooks/useHistoryContext';
import {
  TooltipTextLinkField,
  TooltipTextLinkFieldProps,
} from '../TooltipTextLinkField/TooltipTextLinkField';

export type TooltipTextDialogLinkFieldProps = Omit<TooltipTextLinkFieldProps, 'linkProps'>;

export const TooltipTextDialogLinkField: React.FC<TooltipTextDialogLinkFieldProps> = (props) => {
  const { baseRoute } = useHistoryContext();
  const location = useLocation();

  return (
    <TooltipTextLinkField
      linkProps={{ state: { backgroundLocation: baseRoute || location } }}
      {...props}
    />
  );
};
