import { styled } from '@mui/material/styles';

import { appColors } from '../../../../themes/variables';
import { Avatar } from '../../../mui/Avatars';

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `1px solid ${theme.palette.background.paper}`,
  backgroundColor: appColors.background,
  boxSizing: 'content-box',
}));
