import { appColors } from 'themes/variables';

type Colors = 'red' | 'yellow' | 'green';
type Field = 'icon' | 'text';

const COLORS_MAP: Record<Colors, Record<Field, string>> = {
  green: { icon: appColors.success.light, text: appColors.success.main },
  yellow: { icon: appColors.warning.light, text: appColors.warning.main },
  red: { icon: appColors.error.light, text: appColors.error.main },
};

export const getColor = (field: Field, color?: Colors) =>
  color ? COLORS_MAP[color][field] : appColors.text.disabled;
