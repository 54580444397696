import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, useCreate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Create } from 'shared/react-admin/Create/Create';

import { Commission } from '../../../../../types';
import { transform } from '../../../../Commissions/utils/transform';
import {
  CommissionsFormValues,
  MerchantShowCommissionsForm,
} from '../components/MerchantShowCommissionsForm/MerchantShowCommissionsForm';
import { COMMISSIONS_SCHEMA } from '../schemas/schema';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: Commission) => void;
  merchantId: string | undefined;
};

export const MerchantShowCommissionsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  merchantId,
}) => {
  const [create] = useCreate();
  const [errors, setErrors] = useState<string[]>([]);
  const notify = useNotify();

  const onSubmit = (data: CommissionsFormValues) => {
    const transformedData = transform(data);
    create(
      'commissions',
      {
        data: {
          ...transformedData,
          merchant: merchantId,
        },
      },
      {
        onSuccess: (data) => {
          onSuccess(data);
          onClose();
        },
        onError: (error: any) => {
          notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
          setErrors(error?.message.split('\n'));
        },
      },
    );
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <Create resource="commissions" sx={{ '.RaCreate-noActions': { margin: 0 } }}>
        <Form
          defaultValues={{
            merchant: merchantId,
            startDateMilliseconds: null,
            endDateMilliseconds: null,
            zeroed: false,
          }}
          onSubmit={onSubmit as any}
          resolver={yupResolver<FieldValues>(COMMISSIONS_SCHEMA)}
        >
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание новой комиссии"
          />
          <Box sx={{ maxHeight: '650px', overflowY: 'auto' }}>
            <MerchantShowCommissionsForm errors={errors} merchantId={merchantId} />
          </Box>
          <CardActionsButtons
            rightActionsSlot={[
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
              <Button key="create" label="Создать" type="submit" variant="contained" />,
            ]}
          />
        </Form>
      </Create>
    </Dialog>
  );
};
