import { useMemo, useState } from 'react';
import * as React from 'react';

import { Typography } from '@mui/material';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { WithListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { ReferenceButton } from 'shared/mui/Buttons/ReferenceButton/ReferenceButton';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { ListPage } from 'shared/react-admin/Pages';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { CommissionsAgreeDialog } from 'shared/widgets/CommissionsAgreeDialog/CommissionsAgreeDialog';
import { CommissionsArchivedDialog } from 'shared/widgets/CommissionsArchivedDialog/CommissionsArchivedDialog';
import { CommissionsArchivedErrorDialog } from 'shared/widgets/CommissionsArchivedErrorDialog/CommissionsArchivedErrorDialog';
import { CommissionsByAgreementDialog } from 'shared/widgets/CommissionsByAgreementDialog/CommissionsByAgreementDialog';
import { CommissionsCancelDialog } from 'shared/widgets/CommissionsCancelDialog/CommissionsCancelDialog';
import { CommissionsImplementDialog } from 'shared/widgets/CommissionsImplementDialog/CommissionsImplementDialog';
import { CommissionsImplementErrorDialog } from 'shared/widgets/CommissionsImplementErrorDialog/CommissionsImplementErrorDialog';
import { CommissionsRejectDialog } from 'shared/widgets/CommissionsRejectDialog/CommissionsRejectDialog';
import { Commission, Permission } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantShowCommissionsListDatagrid } from './MerchantShowCommissionsListDatagrid/MerchantShowCommissionsListDatagrid';
import { MerchantShowCommissionsListFilterChips } from './MerchantShowCommissionsListFilterChips';
import { MerchantShowCommissionsListFilterValues } from './MerchantShowCommissionsListFilterValues';
import { MerchantShowCommissionsListReferenceDialog } from './MerchantShowCommissionsListReferenceDialog';
import { Alerts } from '../../../../../constants/alerts';
import { useApiContext } from '../../../../../context/apiContext';
import { getId } from '../../../../../utils';
import { MerchantShowCommissionsCreateDialog } from '../MerchantShowCommissionsCreateDialog/MerchantShowCommissionsCreateDialog';
import { MerchantShowCommissionsCreateSuccessDialog } from '../MerchantShowCommissionsCreateDialog/MerchantShowCommissionsCreateSuccessDialog/MerchantShowCommissionsCreateSuccessDialog';
import { MerchantShowCommissionsCreateNewDialog } from '../MerchantShowCommissionsCreateNewDialog/MerchantShowCommissionsCreateNewDialog';
import { MerchantShowCommissionsCreateNewSuccessDialog } from '../MerchantShowCommissionsCreateNewDialog/MerchantShowCommissionsCreateNewSuccessDialog/MerchantShowCommissionsCreateNewSuccessDialog';
import { MerchantCommissionsDeleteDialog } from '../MerchantShowCommissionsDeleteDialog/MerchantCommissionsDeleteDialog';
import { MerchantShowCommissionsEditDialog } from '../MerchantShowCommissionsEditDialog/MerchantShowCommissionsEditDialog';
import { MerchantShowCommissionsShowDialog } from '../MerchantShowCommissionsShowDialog/MerchantShowCommissionsShowDialog';

export type MerchantShowCommissionsFormValues = {
  name: string;
  status: string[];
  provider: string[];
  currency: Record<string, any>;
  id: string;
  transactionTypes: string[];
  parent: { id: string | null };
};

export const DEFAULT_VALUES = {
  name: '',
  status: [],
  provider: [],
  currency: {
    id: [],
  },
  id: '',
  transactionTypes: [],
  parent: { id: null },
};

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantShowCommissionsList: React.FC<Props> = ({ merchantId }) => {
  const { user } = useApiContext();
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<MerchantShowCommissionsFormValues>({
      defaultValues: DEFAULT_VALUES,
      resetValues: DEFAULT_VALUES,
    });

  const [openReferenceDialog, setOpenReferenceDialog] = useState<boolean>(false);
  const { hasResourcePermissions } = usePermissions();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<Commission>();

  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<Commission>();

  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<Commission>();

  const {
    record: recordModalAgreement,
    handleOpenRecordModal: handleOpenModalAgreement,
    openRecordModal: openModalAgreement,
    handleCloseRecordModal: handleCloseModalAgreement,
  } = useRecordModal<Commission>();

  const {
    record: recordModalAgree,
    handleOpenRecordModal: handleOpenModalAgree,
    openRecordModal: openModalAgree,
    handleCloseRecordModal: handleCloseModalAgree,
  } = useRecordModal<Commission>();

  const {
    record: recordModalReject,
    handleOpenRecordModal: handleOpenModalReject,
    openRecordModal: openModalReject,
    handleCloseRecordModal: handleCloseModalReject,
  } = useRecordModal<Commission>();

  const {
    record: recordModalImplement,
    handleOpenRecordModal: handleOpenModalImplement,
    openRecordModal: openModalImplement,
    handleCloseRecordModal: handleCloseModalImplement,
  } = useRecordModal<Commission>();

  const {
    record: recordModalCancel,
    handleOpenRecordModal: handleOpenModalCancel,
    openRecordModal: openModalCancel,
    handleCloseRecordModal: handleCloseModalCancel,
  } = useRecordModal<Commission>();

  const {
    record: recordModalArchived,
    handleOpenRecordModal: handleOpenModalArchived,
    openRecordModal: openModalArchived,
    handleCloseRecordModal: handleCloseModalArchived,
  } = useRecordModal<Commission>();

  const {
    record: recordModalImplementError,
    handleOpenRecordModal: handleOpenModalImplementError,
    openRecordModal: openModalImplementError,
    handleCloseRecordModal: handleCloseModalImplementError,
  } = useRecordModal<Commission>();

  const {
    record: recordModalArchivedError,
    handleOpenRecordModal: handleOpenModalArchivedError,
    openRecordModal: openModalArchivedError,
    handleCloseRecordModal: handleCloseModalArchivedError,
  } = useRecordModal<Commission>();

  const {
    record: recordModalNewVersion,
    handleOpenRecordModal: handleOpenModalNewVersion,
    openRecordModal: openModalNewVersion,
    handleCloseRecordModal: handleCloseModalNewVersion,
  } = useRecordModal<Commission>();

  const {
    record: recordModalNewSuccess,
    handleOpenRecordModal: handleOpenModalNewSuccess,
    openRecordModal: openModalNewSuccess,
    handleCloseRecordModal: handleCloseModalNewSuccess,
  } = useRecordModal<Commission>();

  const {
    record: recordModalNewVersionSuccess,
    handleOpenRecordModal: handleOpenModalNewVersionSuccess,
    openRecordModal: openModalNewVersionSuccess,
    handleCloseRecordModal: handleCloseModalNewVersionSuccess,
  } = useRecordModal<Commission>();

  const listFilters = useMemo(() => {
    const { status: fullIdStatus = [], ...restAppliedFilters } = appliedFilters;

    return cleanEmpty({
      ...restAppliedFilters,
      merchant: merchantId,
      status: fullIdStatus?.map((status) => getId(status)),
    });
  }, [appliedFilters]);

  const isListFilterExist = Object.keys(cleanEmpty(appliedFilters)).length > 0;

  return (
    <ListPage
      actions={[
        <ReferenceButton
          key="reference-btn"
          onClick={() => setOpenReferenceDialog(true)}
          sx={{ marginTop: '8px', padding: '3px 16px' }}
        />,
        hasResourcePermissions('commissions', [Permission.Full, Permission.Create]) && (
          <CreateButton
            key="create-btn"
            onClick={(e) => {
              e.preventDefault();
              setOpenCreateDialog(true);
            }}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
          />
        ),
      ]}
      disableSyncWithLocation
      empty={false}
      filter={listFilters}
      headerTitle={{
        titleText: 'Список комиссий',
        titleSize: 'small',
      }}
      resource="commissions"
      sort={{ field: 'id', order: 'DESC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <>
        <FilterToolbar
          leftActionsSlot={[
            <FilterButton key="filter" onClick={toggleDrawer(true)} />,
            <SelectColumnButtonFilter key="select-column" />,
            <FilterClearButton
              key="clear"
              onClick={() => {
                onReset();
              }}
              visible={isListFilterExist}
            />,
          ]}
        />
        <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FilterDrawerContent>
                <MerchantShowCommissionsListFilterValues />
              </FilterDrawerContent>
              <FilterDrawerActionsButtons>
                <FilterApplyButton type="submit" />
                <FilterResetButton label="Очистить" onReset={onReset} />
              </FilterDrawerActionsButtons>
            </form>
          </FormProvider>
        </FilterDrawer>
        <FilterChipsToolbar appliedFilters={appliedFilters} isSkipBooleanValues={false}>
          <FilterContextProvider
            appliedFilters={appliedFilters}
            setAppliedFilter={setAppliedFilter}
          >
            <FormProvider {...form}>
              <MerchantShowCommissionsListFilterChips />
            </FormProvider>
          </FilterContextProvider>
        </FilterChipsToolbar>
      </>
      <WithListContext
        render={({ data, isLoading }) => {
          if (user.alerts.includes(Alerts.EmptyProviderAccountAccess)) {
            return (
              <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} отсутствует доступ к настройкам "Комиссий", обратитесь к руководителю вашей рабочей группы для предоставления доступа.`}</Alert>
            );
          }

          if (user.alerts.includes(Alerts.EmptyMerchantAccess)) {
            return (
              <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных мерчантов, обратитесь к администратору системы для настроек доступа.`}</Alert>
            );
          }

          if (!data?.length && !isLoading) {
            return (
              <Alert severity="info">
                <Typography variant="body2">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Нет созданных "Комиссий" для доступных "Мерчантов" и "Провайдеров"
                </Typography>
              </Alert>
            );
          }

          return (
            <FormProvider {...form}>
              <FilterContextProvider
                appliedFilters={appliedFilters}
                setAppliedFilter={setAppliedFilter}
              >
                <MerchantShowCommissionsListDatagrid
                  handleOpenDeleteDialog={handleOpenDeleteDialog}
                  handleOpenEditDialog={handleOpenEditDialog}
                  handleOpenShowDialog={handleOpenShowDialog}
                />
              </FilterContextProvider>
            </FormProvider>
          );
        }}
      />
      <MerchantShowCommissionsCreateDialog
        merchantId={merchantId}
        onClose={() => setOpenCreateDialog(false)}
        onSuccess={(data) => {
          setOpenCreateDialog(false);
          handleOpenModalNewSuccess(data);
        }}
        open={openCreateDialog}
      />
      <MerchantShowCommissionsCreateSuccessDialog
        onAgreement={(data) => {
          handleCloseModalNewSuccess();
          handleOpenShowDialog(data);
        }}
        onClose={handleCloseModalNewSuccess}
        open={openModalNewSuccess}
        record={recordModalNewSuccess}
      />
      <MerchantShowCommissionsEditDialog
        merchantId={merchantId}
        onClose={handleCloseEditDialog}
        open={openEditDialog}
        record={recordEditDialog}
      />
      <MerchantShowCommissionsShowDialog
        handleOpenModalAgree={(record) => {
          handleCloseShowDialog();
          handleOpenModalAgree(record);
        }}
        handleOpenModalAgreement={(record) => {
          handleCloseShowDialog();
          handleOpenModalAgreement(record);
        }}
        handleOpenModalArchived={(record) => {
          handleCloseShowDialog();
          handleOpenModalArchived(record);
        }}
        handleOpenModalArchivedError={(record) => {
          handleCloseShowDialog();
          handleOpenModalArchivedError(record);
        }}
        handleOpenModalCancel={(record) => {
          handleCloseShowDialog();
          handleOpenModalCancel(record);
        }}
        handleOpenModalImplement={(record) => {
          handleCloseShowDialog();
          handleOpenModalImplement(record);
        }}
        handleOpenModalImplementError={(record) => {
          handleCloseShowDialog();
          handleOpenModalImplementError(record);
        }}
        handleOpenModalNewVersion={(record) => {
          handleCloseShowDialog();
          handleOpenModalNewVersion(record);
        }}
        handleOpenModalReject={(record) => {
          handleCloseShowDialog();
          handleOpenModalReject(record);
        }}
        onClose={handleCloseShowDialog}
        onDelete={handleOpenDeleteDialog}
        onEdit={handleOpenEditDialog}
        open={openShowDialog}
        record={recordShowDialog}
      />
      <MerchantShowCommissionsCreateNewDialog
        merchantId={merchantId}
        onClose={handleCloseModalNewVersion}
        onSuccess={(data) => {
          handleCloseModalNewVersion();
          handleOpenModalNewVersionSuccess(data);
        }}
        open={openModalNewVersion}
        record={recordModalNewVersion}
      />

      <MerchantShowCommissionsCreateNewSuccessDialog
        onAgreement={(data) => {
          handleCloseModalNewVersionSuccess();
          handleOpenShowDialog(data);
        }}
        onClose={handleCloseModalNewVersionSuccess}
        open={openModalNewVersionSuccess}
        record={recordModalNewVersionSuccess}
      />
      <CommissionsByAgreementDialog
        onClose={handleCloseModalAgreement}
        open={openModalAgreement}
        record={recordModalAgreement}
      />
      <CommissionsAgreeDialog
        onClose={handleCloseModalAgree}
        open={openModalAgree}
        record={recordModalAgree}
      />
      <CommissionsRejectDialog
        onClose={handleCloseModalReject}
        open={openModalReject}
        record={recordModalReject}
      />
      <CommissionsImplementDialog
        onClose={handleCloseModalImplement}
        open={openModalImplement}
        record={recordModalImplement}
      />
      <CommissionsCancelDialog
        onClose={handleCloseModalCancel}
        open={openModalCancel}
        record={recordModalCancel}
      />
      <CommissionsArchivedDialog
        onClose={handleCloseModalArchived}
        open={openModalArchived}
        record={recordModalArchived}
      />
      <CommissionsImplementErrorDialog
        onBackClick={handleCloseModalImplementError}
        onClose={handleCloseModalImplementError}
        open={openModalImplementError}
        record={recordModalImplementError}
      />
      <CommissionsArchivedErrorDialog
        onBackClick={handleCloseModalArchivedError}
        onClose={handleCloseModalArchivedError}
        open={openModalArchivedError}
        record={recordModalArchivedError}
      />
      <MerchantCommissionsDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
      <MerchantShowCommissionsListReferenceDialog
        onClose={() => setOpenReferenceDialog(false)}
        open={openReferenceDialog}
      />
    </ListPage>
  );
};
