import { useProviderAccountName } from 'components/Finance/ProviderAccounts/hooks/useProviderAccountName';
import { useRedirect, useShowController } from 'react-admin';
import { DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';

import { ProviderAccountsShowTable } from './ProviderAccountsShowTable';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useRecordModal } from '../../../../hooks/useRecordModal';
import { CardActionsButtons } from '../../../../shared/mui/Card';
import { ShowDialog } from '../../../../shared/react-admin/Dialogs/ShowDialog/ShowDialog';
import { Company, Permission, Provider, ProviderAccount } from '../../../../types';

const providerAccountResource = 'provider_account_news';

export const ProviderAccountsShow = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record } = useShowController();
  const redirect = useRedirect();

  const { providerAccountName } = useProviderAccountName(record);
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<ProviderAccount>();

  const {
    record: company,
    handleCloseRecordModal: handleCloseCompanyModal,
    openRecordModal: openCompanyModal,
    handleOpenRecordModal: handleOpenCompanyModal,
  } = useRecordModal<Company>();

  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();

  return (
    <ShowDialog
      actions={
        hasResourceAccess('user_actions')
          ? [<UserActionsIconButton entityName="provider_account_new" key="user-action-link" />]
          : undefined
      }
      title="Личный кабинет"
    >
      <ProviderAccountsShowTable
        handleOpenCompanyModal={handleOpenCompanyModal}
        handleOpenRecordModalProvider={handleOpenRecordModalProvider}
      />
      <CardActionsButtons
        leftActionsSlot={[
          hasResourcePermissions(providerAccountResource, [Permission.Full, Permission.Update]) && (
            <EditLinkButton key="edit-button" label="Редактировать" />
          ),
        ]}
        rightActionsSlot={[
          hasResourcePermissions(providerAccountResource, [Permission.Full, Permission.Delete]) && (
            <DeleteButton
              key="delete-button"
              onClick={() => handleOpenRecordModal(record)}
              variant="outlined"
            />
          ),
        ]}
      />
      <DeleteDialog
        details={`Личный кабинет ${providerAccountName} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => redirect('list', providerAccountResource)}
        open={openRecordModal}
        record={record}
        title="Удаление личного кабинета"
      />
      {company && (
        <CompanyShowDialog
          company={company}
          onClose={handleCloseCompanyModal}
          open={openCompanyModal}
        />
      )}
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
    </ShowDialog>
  );
};
