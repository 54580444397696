import { useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DEFAULT_DATE_MASK } from 'shared/mui/DatePicker/config/defaultValues';
import { DateRangePicker } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterDateTimeRangeChip,
  FilterEnumAutocompleteChip,
  FilterEnumMultiselectChip,
  FilterMultiselectChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import {
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const BankOperationsFilterChips: React.FC = () => {
  const { control, watch, getValues, setValue, resetField } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();
  const [merchant, provider] = watch(['merchant', 'provider']);

  const { data: bankOperationStatuses } = useGetList('bank_operation_statuses');
  const { data: bankOperationTypes } = useGetList('bank_operation_types');

  return (
    <>
      <FilterDateTimeRangeChip
        label="Дата проведения / период"
        mask={DEFAULT_DATE_MASK}
        name="appliedDate"
      >
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateRangePicker
              label="Дата проведения / период"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeChip>
      <FilterMultiselectChip
        label="Мерчант"
        limitTags={1}
        name="merchant"
        onApply={() => {
          setAppliedFilter('provider', []);
          setAppliedFilter('merchantAccount', []);

          resetField('provider', { keepDirty: true });
          resetField('merchantAccount', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('provider', { keepDirty: true });
          resetField('merchantAccount', { keepDirty: true });

          setAppliedFilter('provider', []);
          setAppliedFilter('merchantAccount', []);
        }}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
        }}
        resource="merchants"
      >
        <ReferenceArrayInput
          filter={{ exists: { merchantAccounts: true } }}
          isFilter={true}
          name="merchant"
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Мерчант"
            limitTags={1}
            name="merchant"
            optionText="name"
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Провайдеры"
        limitTags={1}
        name="provider"
        onApply={() => {
          setAppliedFilter('merchantAccount', []);

          resetField('merchantAccount', { keepDirty: true });
        }}
        onDelete={() => {
          resetField('merchantAccount', { keepDirty: true });

          setAppliedFilter('merchantAccount', []);
        }}
        onReset={() => {
          setValue('merchantAccount', appliedFilters?.['merchantAccount']);

          setAppliedFilter('merchantAccount', appliedFilters?.['merchantAccount']);
        }}
        resource="providers"
      >
        <ReferenceArrayInput
          filter={{ merchantAccounts: { merchants: merchant } }}
          isFilter={true}
          name="provider"
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Провайдеры"
            limitTags={1}
            name="provider"
            optionText="name"
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Счета мерчантов"
        limitTags={1}
        name="merchantAccount"
        resource="merchant_accounts"
      >
        <ReferenceArrayInput
          filter={{ merchants: merchant, provider }}
          isFilter={true}
          name="merchantAccount"
          perPage={100}
          reference="merchant_accounts"
          source="merchantAccount"
        >
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Счета мерчантов"
            limitTags={1}
            name="merchantAccount"
            optionText="name"
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterEnumAutocompleteChip label="Направление" name="direction" resource="directions">
        <EnumAutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ description: searchText })}
          fullWidth
          helperText={false}
          label="Направление"
          name="direction"
          optionText="description"
          optionValue="value"
          resource="directions"
          source="direction"
          variant="outlined"
        />
      </FilterEnumAutocompleteChip>
      <FilterEnumMultiselectChip
        label="Тип операции"
        limitTags={1}
        name="type"
        resource="bank_operation_types"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          defaultValue={getValues('type')}
          getOptionLabel={(option, options) =>
            options?.find((opt) => opt.value === option)?.description
          }
          helperText={false}
          label="Тип операции"
          limitTags={1}
          name="type"
          optionText="description"
          optionValue="value"
          options={bankOperationTypes || []}
        />
      </FilterEnumMultiselectChip>
      <FilterEnumMultiselectChip
        label="Статус"
        limitTags={1}
        name="status"
        resource="bank_operation_statuses"
      >
        <AutocompleteMultiselectInputShrink
          defaultValue={getValues('status')}
          getOptionLabel={(option, options) =>
            options?.find((opt) => opt.value === option)?.description
          }
          helperText={false}
          label="Статус"
          limitTags={1}
          name="status"
          optionText="description"
          optionValue="value"
          options={bankOperationStatuses || []}
        />
      </FilterEnumMultiselectChip>
      <FilterMultiselectChip
        label="Автор"
        limitTags={1}
        name="user"
        renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
        resource="users"
      >
        <ReferenceArrayInput
          isFilter={true}
          name="user"
          perPage={200}
          reference="users"
          source="user"
        >
          <AutocompleteMultiselectInputShrink
            filterToQuery={(searchText: string) => ({
              fullName: searchText,
            })}
            helperText={false}
            label="Автор"
            name="user"
            optionText="name"
            renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
            size="small"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};
