import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleForm, useEditController, useNotify, useUpdate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditPage } from 'shared/react-admin/Pages/EditPage/EditPage';

import { queryClient } from '../../../App';
import { useGoBack } from '../../../hooks/useGoBack';
import {
  CommissionsForm,
  CommissionsFormValues,
} from '../components/CommissionsForm/CommissionsForm';
import { COMMISSIONS_SCHEMA } from '../schemas/schema';
import { transform } from '../utils/transform';

export const CommissionsEdit: React.FC = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const { goBack } = useGoBack();
  const notify = useNotify();
  const [update] = useUpdate();
  const { record } = useEditController();

  const onSubmit = (data: CommissionsFormValues) => {
    const transformedData = transform(data);

    update(
      'commissions',
      {
        id: record?.id,
        data: transformedData,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([['commissions'], ['commissions', record?.id]]);
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: 1 },
          });

          goBack();
        },
        onError: (error) => {
          notify(`Ошибка: ${(error as Error).message || 'ошибка сервера'}`, {
            type: 'error',
            autoHideDuration: 3000,
          });
          setErrors((error as Error)?.message.split('\n'));
        },
      },
    );
  };

  return (
    <EditPage listName="Комиссии" title="Редактирование комиссии" width="600px">
      <SimpleForm
        defaultValues={() => ({
          zeroed: false,
        })}
        onSubmit={onSubmit as any}
        resolver={yupResolver<FieldValues>(COMMISSIONS_SCHEMA)}
        sx={{ '&.MuiCardContent-root': { padding: 0 } }}
        toolbar={false}
      >
        <CardContent sx={{ width: '100%' }}>
          <CommissionsForm errors={errors} />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" />,
            <CancelButton key="cancel-button" variant="outlined" />,
          ]}
        />
      </SimpleForm>
    </EditPage>
  );
};
