import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { SimpleForm, useEditController, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { EditDialog } from 'shared/react-admin/Dialogs/EditDialog/EditDialog';
import { Permission, ProviderAccount } from 'types';

import { ProviderAccountsForm } from './components/ProviderAccountsForm';

export const ProviderAccountsEdit: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();
  const notify = useNotify();

  const transform = (data: ProviderAccount) =>
    record.workTeam === data.workTeam ? data : { ...data, members: [] };

  const {
    record: providerAccount,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<ProviderAccount>();

  async function onError(error: any) {
    notify(error.message, { type: 'error' });
  }

  return (
    <EditDialog mutationOptions={{ onError }} title="Редактирование личного кабинета">
      <DeleteDialog
        details={`Личный кабинет ${record?.name} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={providerAccount}
        title="Удаление личного кабинета"
      />
      <SimpleForm
        sx={{ padding: '8px 24px !important', width: '550px' }}
        toolbar={
          <CardActionsButtons
            leftActionsSlot={[
              <SaveButtonValidated
                alwaysEnable
                key="save-button"
                label="Сохранить"
                transform={transform}
              />,
              <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('provider_account_news', [
                Permission.Full,
                Permission.Delete,
              ]) && (
                <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
              )
            }
          />
        }
      >
        <ProviderAccountsForm />
      </SimpleForm>
    </EditDialog>
  );
};
