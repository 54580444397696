import * as React from 'react';
import {
  cloneElement,
  createElement,
  isValidElement,
  useCallback,
  useRef,
  useEffect,
  FC,
  useMemo,
} from 'react';

import clsx from 'clsx';
import difference from 'lodash/difference';
import union from 'lodash/union';
import { sanitizeListRestProps, useListContext, Identifier } from 'ra-core';
import {
  BulkActionsToolbar,
  BulkDeleteButton,
  DatagridBody,
  DatagridClasses,
  DatagridHeader,
  DatagridLoading,
  DatagridProps,
  DatagridRoot,
  ListNoResults,
  PureDatagridBody,
} from 'react-admin';
import { Table } from 'shared/mui/Table';

import { DatargidWithFooterContextProvider } from './DatargidWithFooterContext/DatargidWithFooterContextProvider';

const defaultBulkActionButtons = <BulkDeleteButton />;
const DefaultEmpty = <ListNoResults />;

export type DatagridWithFooterProps = DatagridProps & {
  footer?: React.ReactElement;
};

export const DatagridWithFooter: FC<DatagridWithFooterProps> = React.forwardRef((props, ref) => {
  const {
    optimized = false,
    body = optimized ? PureDatagridBody : DatagridBody,
    header = DatagridHeader,
    children,
    className,
    empty = DefaultEmpty,
    expand,
    bulkActionButtons = defaultBulkActionButtons,
    hover,
    isRowSelectable,
    isRowExpandable,
    resource,
    rowClick,
    rowSx,
    rowStyle,
    size = 'small',
    sx,
    expandSingle = false,
    footer,
    ...rest
  } = props;

  const { sort, data, isLoading, onSelect, onToggleItem, selectedIds, setSort, total } =
    useListContext(props);

  const hasBulkActions = !!bulkActionButtons !== false;

  const contextValue = useMemo(
    () => ({ isRowExpandable, expandSingle }),
    [isRowExpandable, expandSingle],
  );

  const lastSelected = useRef(null);

  useEffect(() => {
    if (!selectedIds || selectedIds.length === 0) {
      lastSelected.current = null;
    }
  }, [JSON.stringify(selectedIds)]);

  const handleToggleItem = useCallback(
    (id: any, event: any) => {
      const ids = data.map((record) => record.id);
      const lastSelectedIndex = ids.indexOf(lastSelected.current);
      lastSelected.current = event.target.checked ? id : null;

      if (event.shiftKey && lastSelectedIndex !== -1) {
        const index = ids.indexOf(id);
        const idsBetweenSelections = ids.slice(
          Math.min(lastSelectedIndex, index),
          Math.max(lastSelectedIndex, index) + 1,
        );

        const newSelectedIds = event.target.checked
          ? union(selectedIds, idsBetweenSelections)
          : difference(selectedIds, idsBetweenSelections);

        onSelect(
          isRowSelectable
            ? newSelectedIds.filter((id: Identifier) =>
                isRowSelectable(data.find((record) => record.id === id)),
              )
            : newSelectedIds,
        );
      } else {
        onToggleItem(id);
      }
    },
    [data, isRowSelectable, onSelect, onToggleItem, selectedIds],
  );

  if (isLoading === true) {
    return (
      <DatagridLoading
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  if (data == null || data.length === 0 || total === 0) {
    if (empty) {
      return empty;
    }

    return null;
  }

  return (
    <DatargidWithFooterContextProvider value={contextValue}>
      <DatagridRoot className={clsx(DatagridClasses.root, className)} sx={sx}>
        {bulkActionButtons !== false ? (
          <BulkActionsToolbar selectedIds={selectedIds}>
            {isValidElement(bulkActionButtons) ? bulkActionButtons : defaultBulkActionButtons}
          </BulkActionsToolbar>
        ) : null}
        <div className={DatagridClasses.tableWrapper}>
          <Table
            className={DatagridClasses.table}
            ref={ref}
            size={size}
            {...sanitizeRestProps(rest)}
          >
            {createOrCloneElement(
              header,
              {
                children,
                sort,
                data,
                hasExpand: !!expand,
                hasBulkActions,
                isRowSelectable,
                onSelect,
                resource,
                selectedIds,
                setSort,
              },
              children,
            )}
            {createOrCloneElement(
              body,
              {
                expand,
                rowClick,
                data,
                hasBulkActions,
                hover,
                onToggleItem: handleToggleItem,
                resource,
                rowSx,
                rowStyle,
                selectedIds,
                isRowSelectable,
              },
              children,
            )}
            {footer}
          </Table>
        </div>
      </DatagridRoot>
    </DatargidWithFooterContextProvider>
  );
});

const createOrCloneElement = (element: any, props: any, children: React.ReactNode) =>
  isValidElement(element)
    ? cloneElement(element, props, children)
    : createElement(element, props, children);

const injectedProps = [
  'isRequired',
  'setFilter',
  'setPagination',
  'limitChoicesToValue',
  'translateChoice',
  // Datagrid may be used as an alternative to SelectInput
  'field',
  'fieldState',
  'formState',
];

const sanitizeRestProps = (props: any) =>
  Object.keys(sanitizeListRestProps(props))
    .filter((propName) => !injectedProps.includes(propName))
    .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {});

DatagridWithFooter.displayName = 'DatagridWithFooter';
