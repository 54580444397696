import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import { Box } from '../../../../mui/Box';

export const StyledBoxWrapper = styled(Box)({
  border: `1px dashed ${grey[300]}`,
  backgroundColor: grey[50],
  width: '100%',
  display: 'flex',
  minHeight: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const PlaceholderWrapperStyled = styled(Box)({
  cursor: 'pointer',
  padding: '13px 16px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
