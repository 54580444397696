import { usePermissions } from 'hooks/usePermissions';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableCell, TableCellHeader, TableRow } from 'shared/mui/Table';
import { DateTimeField, TextField } from 'shared/react-admin/Fields';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { EMPTY_FIELD } from '../../../constants';

export function LimitsShowTable(): JSX.Element {
  const { hasResourceAccess } = usePermissions();

  return (
    <CardTable
      sx={{
        width: '600px',
      }}
    >
      <TableBody>
        <TableRow>
          <TableCellHeader title="ID" width={128} />
          <TableCell component="th" scope="row">
            <TextField source="originId" />
          </TableCell>
        </TableRow>
        {hasResourceAccess('gateways') && (
          <TableRow>
            <TableCellHeader title="Шлюз" />
            <TableCell component="th" scope="row">
              <ReferenceOneField
                label="Шлюз"
                link="show"
                reference="gateways"
                source="gateway"
                target="id"
              >
                <TextField source="name" />
              </ReferenceOneField>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCellHeader title="Валюта" />
          <TableCell component="th" scope="row">
            <ReferenceOneField reference="currencies" source="currency" target="id">
              <TextField source="name" />
            </ReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Мин. сумма пополнения" />
          <TableCell component="th" scope="row">
            <TextField emptyText={EMPTY_FIELD} source="paymentMin" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Макс. сумма пополнения" />
          <TableCell component="th" scope="row">
            <TextField emptyText={EMPTY_FIELD} source="paymentMax" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Мин. сумма вывода" />
          <TableCell component="th" scope="row">
            <TextField emptyText={EMPTY_FIELD} source="payoutMin" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Макс. сумма вывода" />
          <TableCell component="th" scope="row">
            <TextField emptyText={EMPTY_FIELD} source="payoutMax" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Дата создания" />
          <TableCell component="th" scope="row">
            <DateTimeField emptyText={EMPTY_FIELD} source="createdAt" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Дата редактир." />
          <TableCell component="th" scope="row">
            <DateTimeField emptyText={EMPTY_FIELD} source="updatedAt" />
          </TableCell>
        </TableRow>
      </TableBody>
    </CardTable>
  );
}
