import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { Divider } from 'shared/mui/Divider/Divider';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const MerchantShowCommissionsListReferenceDialog: React.FC<Props> = ({ open, onClose }) => (
  <Dialog onClose={onClose} open={open} transitionDuration={0}>
    <Box sx={{ width: 600, maxHeight: 'calc(100vh - 160px)', overflow: 'hidden' }}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title={
          <span>
            Справка по настройке <br /> и работе с комиссиями
          </span>
        }
      />
      <CardContent
        sx={{ paddingTop: '0!important', maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}
      >
        <Typography sx={{ marginBottom: 2 }} variant="body2">
          В системе реализован процесс управления комиссиями, включающий их создание, настройку,
          согласование и внедрение. Работа с комиссиями проходит через следующие статусы:
        </Typography>
        <Typography component="ol">
          <Typography component="li" variant="body2">
            <strong>Новая</strong> – комиссия создана, но не отправлена на согласование;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>На согласовании</strong> – комиссия рассматривается ответственными лицами;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Отклонена</strong> – комиссия не одобрена;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Согласована</strong> – комиссия одобрена и готова к внедрению;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Аннулирована</strong> – комиссия признана недействительной;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Внедрена</strong> – комиссия успешно реализована;
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Архив</strong> – завершённая комиссия перемещена в архив.
          </Typography>
        </Typography>
        <Divider
          orientation="horizontal"
          sx={{ marginTop: 2, marginBottom: 2, borderColor: appColors.divider }}
        />
        <Typography sx={{ fontWeight: 500, marginBottom: '8px' }} variant="body1">
          Роль: Менеджер платежного отдела
        </Typography>
        <Typography sx={{ marginBottom: 2 }} variant="body2">
          Менеджер платежного отдела выполняет следующие действия:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body2">
            <strong>Создание комиссии</strong> – инициирует новую комиссию, добавляя необходимую
            информацию.
          </Typography>
          <Typography component="li" variant="body2">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <strong>Отправка на согласование</strong> – переводит комиссию в статус "На{' '}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            согласовании". При этом финансовому менеджеру отправляется уведомление о необходимости
            согласования комиссии.
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Изменение отклоненной комиссии</strong> – после отклонения комиссии финансовым
            менеджером, её можно отредактировать и повторно отправить на согласование.
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Удаление отклоненной комиссии</strong> – при необходимости отклонённую комиссию
            можно удалить.
          </Typography>
          <Typography component="li" variant="body2">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <strong>Аннулирование комиссии</strong> – переводит комиссию в статус "Аннулирована".
          </Typography>
          <Typography component="li" variant="body2">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <strong>Внедрение комиссии</strong> – для перевода комиссии в статус "Внедрена"
            необходимо настроить её <strong>в правилах маршрутизации исполнения заказов</strong> в
            платёжном {/* eslint-disable-next-line react/no-unescaped-entities */}
            агрегаторе. После выполнения настройки комиссия переводится в статус "Внедрена".
          </Typography>
          <Typography component="li" variant="body2">
            {/* eslint-disable-next-line no-irregular-whitespace */}
            <strong>Перевод комиссии в архив</strong> – завершает работу с комиссией, перемещая её в
            архив.
          </Typography>
        </Typography>
        <Typography sx={{ marginBottom: 2, marginTop: 2 }} variant="body2">
          Если комиссия настраивается <strong>не для партнёра</strong>, менеджер платежного отдела
          может выполнить весь процесс согласования самостоятельно, без участия финансового
          менеджера.
        </Typography>
        <Divider
          orientation="horizontal"
          sx={{ marginTop: 2, marginBottom: 2, borderColor: appColors.divider }}
        />
        <Typography sx={{ fontWeight: 500, marginBottom: '8px' }} variant="body1">
          Роль: Менеджер финансового отдела
        </Typography>
        <Typography sx={{ marginBottom: 2 }} variant="body2">
          Менеджер финансового отдела участвует в процессе согласования комиссий:
        </Typography>
        <Typography component="ul">
          <Typography component="li" variant="body2">
            <strong>Согласование комиссии</strong> – одобряет комиссию, переводя её в статус
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {/* eslint-disable-next-line react/no-unescaped-entities */} "Согласована".
          </Typography>
          <Typography component="li" variant="body2">
            <strong>Отклонение комиссии</strong> – отклоняет комиссию, переводя её в статус
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {/* eslint-disable-next-line react/no-unescaped-entities */} "Отклонена". При этом
            платежному менеджеру отправляется уведомление о необходимости внести изменения или
            удалить комиссию.
          </Typography>
        </Typography>
      </CardContent>
      <CardActionsButtons
        rightActionsSlot={[
          <Button key="return" label="Вернуться" onClick={onClose} variant="outlined" />,
        ]}
      ></CardActionsButtons>
    </Box>
  </Dialog>
);
