import React from 'react';

import { getReportPeriod } from 'components/Orders/utils/getReportPeriod';
import { AlertProps } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import { StyledAlert } from './OrderListSuccessAlert.styled';

type Props = {
  onClose: VoidFunction;
  onClick: VoidFunction;
  date?: {
    gte: number;
    lte: number;
  };
  timezone?: string;
} & AlertProps;

export const OrderListSuccessAlert: React.FC<Props> = ({ onClose, onClick, date, timezone }) => {
  return (
    <StyledAlert severity="success">
      <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
        <Typography sx={{ color: appColors.text.secondary }}>
          Отчет сформирован за период{' '}
          {getReportPeriod({ dateGte: date?.gte, dateLte: date?.lte, timezone })}
        </Typography>
        <Stack alignItems="center" direction="row" gap={2}>
          <Button
            label="сводка за период"
            onClick={onClick}
            size="small"
            sx={{ color: appColors.text.secondary }}
            variant="text"
          />
          <CloseIconButton onClose={onClose} sx={{ color: appColors.text.secondary }} />
        </Stack>
      </Stack>
    </StyledAlert>
  );
};
