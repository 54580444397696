import { styled } from '@mui/material/styles';

import { Badge } from '../../../shared/mui/Badge/Badge';

export const StyledBadge = styled(Badge)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    position: 'static',
    transform: 'none',
  },
});
