import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { Divider } from 'shared/mui/Divider/Divider';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography, TypographyTitle } from 'shared/mui/Typography';

import { TypographyBold } from './components/TypographyBold';
import { UlStyled } from './OrderAdjustmentsReferenceDialog.styled';

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const OrderAdjustmentsReferenceDialog: React.FC<Props> = ({ open, onClose }) => (
  <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
    <Box>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ height: 'auto' }}
        title={
          <span>
            Информационная справка:
            <br />
            Типы корректировок заказов
          </span>
        }
      />
      <CardContent
        sx={{
          padding: '0 24px !important',
        }}
      >
        <TypographyTitle fontWeight={500} size="xsmall">
          1. Корректировка суммы заказа
        </TypographyTitle>
        <Typography variant="body2">
          Применяется к заказам с направлением <TypographyBold text={'"Пополнение"'} /> и{' '}
          <TypographyBold text={'"Вывод"'} />, имеющим статус <TypographyBold text={'"Успех"'} />.
        </Typography>
        <TypographyBold text="Результат:" />
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>Изменение суммы заказа (увеличение или уменьшение).</li>
          <li>
            Обновленная сумма отражается на <TypographyBold text="счете мерчанта" />.
          </li>
          <li>
            Дополнительно (в зависимости от настройки{' '}
            <TypographyBold text={'"Изменение баланса клиента"'} />) корректировка может затронуть
            баланс игрока.
          </li>
        </UlStyled>
        <Divider sx={{ margin: '12px 0' }} />

        <TypographyTitle fontWeight={500} size="xsmall">
          2. Провести со статусом &quot;Успех&quot;
        </TypographyTitle>
        <Typography variant="body2">
          Применяется к заказам с направлением <TypographyBold text={'"Пополнение"'} /> и{' '}
          <TypographyBold text={'"Вывод"'} />, имеющим статус <TypographyBold text={'"Ожидание"'} />
          , <TypographyBold text={'"Провал"'} /> или <TypographyBold text={'"Просрочен"'} />.
        </Typography>
        <TypographyBold text="Результат:" />
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>
            Изменение статуса заказа на <TypographyBold text={'"Успех"'} />.
          </li>
          <li>
            Сумма заказа зачисляется на <TypographyBold text="счет мерчанта" />.
          </li>
          <li>Дополнительно (по настройке) может влиять на баланс игрока.</li>
        </UlStyled>
        <Divider sx={{ margin: '12px 0' }} />

        <TypographyTitle fontWeight={500} size="xsmall">
          3. Провести со статусом &quot;Рефанд&quot;
        </TypographyTitle>
        <Typography variant="body2">
          Применяется к заказам с направлением <TypographyBold text={'"Пополнение"'} />, имеющим
          статус <TypographyBold text={'"Успех"'} />.
        </Typography>
        <TypographyBold text="Результат:" />
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>
            Изменение статуса заказа на <TypographyBold text={'"Рефанд"'} />.
          </li>
          <li>
            Сумма заказа списывается со <TypographyBold text="счета мерчанта" />.
          </li>
          <li>Дополнительно (по настройке) может корректировать баланс игрока.</li>
        </UlStyled>
        <Divider sx={{ margin: '12px 0' }} />

        <TypographyTitle fontWeight={500} size="xsmall">
          4. Провести со статусом &quot;Чарджбек&quot;
        </TypographyTitle>
        <Typography variant="body2">
          Применяется к заказам с направлением <TypographyBold text={'"Пополнение"'} />, имеющим
          статус <TypographyBold text={'"Успех"'} />.
        </Typography>
        <TypographyBold text="Результат:" />
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>
            Изменение статуса заказа на <TypographyBold text={'"Чарджбек"'} />.
          </li>
          <li>
            Сумма заказа списывается со <TypographyBold text="счета мерчанта" />.
          </li>
          <li>Дополнительно (по настройке) может корректировать баланс игрока.</li>
        </UlStyled>
        <Divider sx={{ margin: '12px 0' }} />

        <TypographyTitle fontWeight={500} size="xsmall">
          5. Провести со статусом &quot;Провал&quot;
        </TypographyTitle>
        <Typography variant="body2">
          Применяется к заказам с направлением <TypographyBold text={'"Пополнение"'} /> и{' '}
          <TypographyBold text={'"Вывод"'} />, имеющим статус <TypographyBold text={'"Ожидание"'} />{' '}
          или <TypographyBold text={'"Успех"'} />.
        </Typography>
        <TypographyBold text="Результат:" />
        <UlStyled sx={{ marginLeft: '18px' }}>
          <li>
            Изменение статуса заказа на <TypographyBold text={'"Провал"'} />.
          </li>
          <li>
            Для заказов в статусе <TypographyBold text={'"Успех"'} />: сумма заказа списывается со{' '}
            <TypographyBold text="счета мерчанта" />.
          </li>
          <li>
            Для заказов в статусе <TypographyBold text={'"Ожидание"'} />: заказ отменяется без
            списания суммы.
          </li>
          <li>Дополнительно (по настройке) может корректировать баланс игрока.</li>
        </UlStyled>
      </CardContent>
    </Box>
    <CardActionsButtons
      rightActionsSlot={[
        <Button key="cancel-button" label="Вернуться" onClick={onClose} variant="outlined" />,
      ]}
    />
  </Dialog>
);
