import { Controller, useFormContext } from 'react-hook-form';
import {
  DateRangePickerValue,
  DateRangeTimezoneValue,
  DateTimeRangeTimezonePicker,
} from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import {
  AutocompleteInputShrink,
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

export type OrdersReportFilterFormValues = {
  id: string;
  merchantReference: string;
  providerReferenceId: string;
  transaction: string;
  company: string;
  merchant: string;
  provider: string;
  merchantAccount: string[];
  gateway: string[];
  createdAt: {
    gte: number;
    lte: number;
  };
  transactions: {
    createdAt: {
      gte: Date;
      lte: Date;
    };
  };
  appliedDate: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  transactionsAppliedDate: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  direction: string;
  timezone: string;
  currency: string[];
  method: string[];
};

export const OrdersReportFilterNew: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const [company, merchant, provider, gateway] = watch([
    'company',
    'merchant',
    'provider',
    'gateway',
  ]);
  const [appliedDate, transactionsAppliedDate] = watch(['appliedDate', 'transactionsAppliedDate']);

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangeTimezonePicker
            disabled={!!transactionsAppliedDate[0] || !!transactionsAppliedDate[1]}
            label="Дата создания от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        name="transactionsAppliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangeTimezonePicker
            disabled={!!appliedDate[0] || !!appliedDate[1]}
            label="Дата исполнения от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <ReferenceInput
        filter={{ exists: { 'merchants.merchantAccounts': true }, archive: false }}
        perPage={100}
        reference="companies"
        source="company"
      >
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Компания"
          onChange={() => {
            setValue('merchant', null);
            setValue('provider', null);
            setValue('gateway', []);
            setValue('merchantAccount', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!company}
        filter={{
          companies: company,
          exists: { merchantAccounts: true },
          archive: false,
        }}
        perPage={100}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteInputShrink
          disabled={!company}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Мерчант"
          onChange={() => {
            setValue('provider', null);
            setValue('gateway', []);
            setValue('merchantAccount', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!merchant}
        filter={{ merchantAccounts: { merchants: merchant } }}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          disabled={!merchant}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          onChange={() => {
            setValue('merchantAccount', []);
            setValue('gateway', []);
            setValue('method', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant?.length && !!provider?.length}
        filter={{
          merchants: merchant,
          provider: provider,
        }}
        isFilter={true}
        name="merchantAccount"
        perPage={1000}
        reference="merchant_accounts"
        resource="orders"
        source="merchantAccount"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!provider}
          helperText={false}
          label="Счет мерчанта"
          limitTags={1}
          name="merchantAccount"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant && !!provider}
        filter={{
          merchantAccounts: { merchants: merchant },
          provider,
        }}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          disabled={!provider}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          onChange={() => {
            setValue('method', []);
          }}
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{
          gateway,
        }}
        name="method"
        perPage={100}
        reference="methods"
        source="method"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!gateway?.length}
          helperText={false}
          label="Методы"
          limitTags={1}
          name="method"
          optionText="name"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <ReferenceArrayInput name="currency" perPage={50} reference="currencies" source="currency">
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта"
          limitTags={1}
          name="currency"
          optionText="name"
          renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
        />
      </ReferenceArrayInput>
    </>
  );
};
