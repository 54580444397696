import { AxiosResponse } from 'axios';
import { required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
  BooleanInput,
  KeyInputIntrospected,
  TextInputIntrospected,
  TextInputWithAliasIntrospected,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInputIntrospected } from 'shared/react-admin/ReferenceInputs';

import { useGenerateNotificationKey } from '../hooks/useGenerateNotificationKey';
import { useGetPlatforms } from '../hooks/useGetPlatforms';
import { useNotificationKeyValidation } from '../hooks/useNotificationKeyValidation';

export const MerchantsCreateForm = () => {
  const { setValue, clearErrors } = useFormContext();
  const { generateNotificationKey } = useGenerateNotificationKey({
    onSuccess: (data: AxiosResponse) => {
      clearErrors('notificationKey');
      setValue('notificationKey', data.data.value);
    },
  });
  const { notificationKeyValidation } = useNotificationKeyValidation();
  const platforms = useGetPlatforms();

  return (
    <>
      <TextInputIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Название"
        source="name"
        variant="outlined"
      />
      <TextInputWithAliasIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Алиас"
        source="alias"
        variant="outlined"
      />
      <KeyInputIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="Ключ нотификации"
        onClickGenerateKey={generateNotificationKey}
        source="notificationKey"
        validate={[required(), notificationKeyValidation]}
        variant="outlined"
      />
      <TextInputIntrospected
        InputLabelProps={{ shrink: true }}
        fullWidth
        label="ID канала для missMatch уведомлений"
        source="missmatchChatId"
        variant="outlined"
      />
      <ReferenceArrayInputIntrospected
        filter={{ archive: false }}
        perPage={200}
        reference="companies"
        source="companies"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Компания"
          name="companies"
          optionText="name"
          size="medium"
        />
      </ReferenceArrayInputIntrospected>
      <BooleanInput label="Партнерство" source="partner" sx={{ margin: '16px 0' }} />
      <SelectInputShrink
        choices={platforms}
        fullWidth
        helperText={false}
        label="Платформа"
        name="platform"
        optionText="label"
        size="medium"
        source="platform"
        sx={{ marginBottom: '24px!important' }}
        validate={required()}
        variant="outlined"
      />
    </>
  );
};
