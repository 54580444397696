import { styled } from '@mui/material/styles';
import { AvatarGroup } from 'shared/mui/AvatarGroup/AvatarGroup';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const columnStyle = getColumnStyle(200);

export const StyledDatagridWithStickyActions = styled(DatagridWithStickyActions)({
  '& .column-name': columnStyle,
  '& .column-countries': getColumnStyle(492),
  '& .column-manager': columnStyle,
  '& .column-members': columnStyle,
  '& .MuiTableCell-head': {
    padding: '10px 16px',
  },
  '& .MuiTableCell-body': {
    verticalAlign: 'middle',
  },
  '& .actions': getColumnStyle(44),
  th: {
    '&:last-child': {
      padding: '2px 4px 2px 6px',
      '&:after': {
        boxShadow: 'none',
      },
    },
  },
  td: {
    '&:last-child': {
      padding: '2px 4px 2px 6px',
      '&:after': {
        boxShadow: 'none',
      },
    },
  },
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  width: 'fit-content',
  '& .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: '11px',
    fontWeight: 500,
  },
});
