import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';

export const SpanStyled = styled('span')({
  fontWeight: '500',
});

export const OlStyled = styled('ol')({
  margin: 0,
  marginBottom: '16px',
  paddingLeft: '16px',
  '& ::marker': {
    fontWeight: 500,
  },
});

export const CardContentStyled = styled(CardContent)({
  paddingTop: '0!important',
  paddingBottom: '24px !important',
  display: 'flex',
  flexDirection: 'column',
});
