import { useContext, useEffect } from 'react';
import * as React from 'react';

import camelcaseKeys from 'camelcase-keys';
import { Layout as BaseLayout, LayoutProps, useRedirect } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocation } from 'react-router-dom';
import { PageLoading } from 'shared/react-admin/Loading';

import { AppBar } from './AppBar';
import { usePageTitle } from './hooks/usePageTitle';
import { getMenuList } from './Menu/constants/menuList';
import Menu from './Menu/Menu';
import { getUserInfo } from '../config/requests';
import { Role } from '../constants/roles';
import { APIContext } from '../context/apiContext';
import { usePermissions } from '../hooks/usePermissions';
import { Permission } from '../types';

const Layout = (props: LayoutProps): JSX.Element | null => {
  usePageTitle();

  const { pathname } = useLocation();
  const redirect = useRedirect();
  const { hasResourceAccess, permissions, hasResourcePermissions, roles } = usePermissions();

  const {
    user: { isLoaded },
    setUser,
  } = useContext(APIContext);

  useEffect(() => {
    if (!isLoaded) {
      getUserInfo()
        .then((res) => {
          setUser({ ...camelcaseKeys(res), isLoaded: true });
        })
        .catch(() => redirect('/login'));
    }
  }, []);

  useEffect(() => {
    if (!isLoaded || roles?.includes(Role.Admin)) {
      return;
    }

    const currentId = pathname?.split('/')[1];
    const isAccessOrdersSubPath = currentId === 'orders_bulk_update' && hasResourceAccess('orders');
    const isAccessMonitoringSubPath =
      currentId === 'monitoring' && hasResourceAccess('monitoring/order_conversion');

    if (hasResourceAccess(currentId) || isAccessOrdersSubPath || isAccessMonitoringSubPath) {
      return;
    }

    const menuLinks = getMenuList()
      .map((menuItem) => {
        if ('resource' in menuItem) {
          return menuItem.resource;
        } else {
          return menuItem.items.map(({ resource }) => resource);
        }
      })
      .flat();

    const firstResource = menuLinks.find((link) =>
      hasResourcePermissions(link, [Permission.Full, Permission.Read]),
    );

    if (!firstResource) {
      return;
    }

    redirect(firstResource);
  }, [pathname, isLoaded, roles, permissions]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      redirect('/login');
    }
  }, [pathname]);

  return isLoaded ? (
    <>
      <BaseLayout {...props} appBar={AppBar} menu={Menu} />
      <ReactQueryDevtools initialIsOpen={true} />
    </>
  ) : (
    <PageLoading />
  );
};

export default Layout;
