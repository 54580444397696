import { Alerts } from 'constants/alerts';

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { usePermissions } from 'hooks/usePermissions';
import { useListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  DateField,
  DateTimeField,
  FunctionField,
  TextField,
  TextLinkField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { IconButtonStyled } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField.styled';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { BankOperation, MerchantAccount, Permission } from 'types';
import { formatCurrency } from 'utils';

import { BankOperationsBulkActionButtons } from './BankOperationsBulkActionButtons/BankOperationsBulkActionButtons';
import { StyledDatagrid } from './BankOperationsDatagrid.styled';
import { useApiContext } from '../../../../../context/apiContext';
import { BankOperationsFinanceUserAlert } from '../../components/BankOperationsFinanceUserAlert';
import { BankOperationStatusChipField } from '../../components/BankOperationStatusChipField/BankOperationStatusChipField';
import { useBankOperationEditable } from '../../hooks/useBankOperationEditable';

type Props = {
  handleOpenDeleteModal: (record: BankOperation) => void;
  handleOpenMerchantAccountModal: (record: MerchantAccount) => void;
  handleOpenRecordModalComment: (record: BankOperation) => void;
};

export const BankOperationsDatagrid: React.FC<Props> = ({
  handleOpenDeleteModal,
  handleOpenMerchantAccountModal,
  handleOpenRecordModalComment,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const { checkBankOperationEditable } = useBankOperationEditable();
  const { total, filterValues } = useListContext();

  const { user } = useApiContext();
  const showEmptyListAlert = Boolean(total === 0 && !Object.keys(filterValues).length);

  if (user.alerts.includes(Alerts.EmptyProviderAccountAccess)) {
    return (
      <BankOperationsFinanceUserAlert userLastName={user.lastName} userName={user.firstName} />
    );
  }

  if (showEmptyListAlert) {
    return (
      <Alert severity="info">
        Нет созданных “Банковских операций” для доступных “Мерчантов” и “Провайдеров”
      </Alert>
    );
  }

  return (
    <StyledDatagrid
      bulkActionButtons={
        hasResourcePermissions('bank_operations', [Permission.Delete, Permission.Full]) && (
          <BankOperationsBulkActionButtons />
        )
      }
    >
      <TextLinkField
        label="ID"
        resource="bank_operations"
        sortBy="id"
        source="originId"
        type="show"
      />
      <DateField label="Дата проведения" locales="fr-FR" source="dateOfOperation" />
      <ReferenceField
        emptyText="—"
        label="Тип операции"
        reference="bank_operation_types"
        sortable={false}
        source="type"
      >
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField
        emptyText="—"
        label="Направление"
        reference="directions"
        sortable={false}
        source="direction"
      >
        <TextField source="description" />
      </ReferenceField>
      <FunctionField
        label="Сумма"
        render={({ amountPenny }: BankOperation) => {
          return formatCurrency(Number(amountPenny));
        }}
        sortable={false}
        source="amountPenny"
      />
      {hasResourceAccess('currencies') && (
        <ReferenceField
          label="Валюта"
          link={false}
          reference="currencies"
          sortable={false}
          source="currency"
        >
          <TextField fontWeight={500} source="alphaCode" />
        </ReferenceField>
      )}
      <FunctionField
        label="Статус"
        render={(record: BankOperation) => {
          return (
            <ReferenceField label="Статус" reference="bank_operation_statuses" source="status">
              <BankOperationStatusChipField source="description">
                {Boolean(record?.comments.length) && (
                  <IconButtonStyled onClick={() => handleOpenRecordModalComment(record)}>
                    <ModeCommentOutlinedIcon />
                  </IconButtonStyled>
                )}
              </BankOperationStatusChipField>
            </ReferenceField>
          );
        }}
      />
      {hasResourceAccess('merchants') && (
        <ReferenceField
          label="Мерчант"
          link={false}
          reference="merchants"
          sortable={false}
          source="merchant"
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      {hasResourceAccess('providers') && (
        <ReferenceField
          label="Провайдер"
          link={false}
          reference="providers"
          sortable={false}
          source="provider"
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <ReferenceField
        label="Счета мерчанта"
        reference="merchant_accounts"
        sortable={false}
        source="merchantAccount"
      >
        <FunctionField
          render={(record: MerchantAccount) => (
            <Typography
              color="secondary"
              onClick={() => handleOpenMerchantAccountModal(record)}
              sx={{ color: appColors.primary.main, cursor: 'pointer', textDecoration: 'underline' }}
              variant="body2"
            >
              {record.name}
            </Typography>
          )}
        />
      </ReferenceField>
      <ReferenceOneField
        label="Автор"
        link={false}
        reference="users"
        sortable={false}
        source="user"
        target="id"
      >
        <FunctionField render={() => <UserProfileField />} />
      </ReferenceOneField>
      <DateTimeField label="Дата создания" sortable={false} source="createdAt" />
      <DateTimeField label="Дата редактир." sortable={false} source="updatedAt" />
      <DatagridActions
        render={(record: BankOperation) => [
          <DatagridActionsShowButton key="show-button" />,
          hasResourcePermissions('bank_operations', [Permission.Full, Permission.Update]) &&
            checkBankOperationEditable(record as BankOperation) && (
              <DatagridActionsEditButton key="edit-button" />
            ),
          hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) &&
            checkBankOperationEditable(record as BankOperation) && (
              <DatagridActionsDeleteButton
                key="delete-button"
                onClick={() => handleOpenDeleteModal(record)}
              />
            ),
        ]}
      />
    </StyledDatagrid>
  );
};
