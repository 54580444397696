import { RecordContextProvider } from 'react-admin';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { JsonField } from 'shared/react-admin/Fields';
import { MerchantAccount } from 'types';

type AccountsCredentialsDialogProps = {
  open: boolean;
  onClose: () => void;
  actions?: React.ReactNode;
  record?: MerchantAccount;
};

export const AccountsCredentialsDialog: React.FC<AccountsCredentialsDialogProps> = ({
  open,
  onClose,
  record,
  actions,
}) => (
  <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
    <CardHeader
      actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
      title="Доступ к счету {json}"
      titleSize="small"
    />
    <RecordContextProvider value={record || {}}>
      <CardContent sx={{ minWidth: '552px' }}>
        <JsonField label="Доступ к счету" source="credentials" />
      </CardContent>
    </RecordContextProvider>
    {actions}
  </Dialog>
);
