import React from 'react';

import { Typography } from 'shared/mui/Typography';

import { StyledBoxCurrencyCell } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';

type Props = {
  convertedValue: string;
  convertedCurrency: string | null | undefined;
  originalValue?: string;
  originalCurrency?: string;
  color: string;
};

export const AmountDataField: React.FC<Props> = ({
  convertedValue,
  convertedCurrency,
  originalValue,
  originalCurrency,
  color,
}) => {
  if (!convertedCurrency) {
    return <></>;
  }

  return (
    <StyledBoxCurrencyCell>
      <Typography color={color} variant="body2">
        {convertedValue}{' '}
        <Typography component="span" fontWeight={500} variant="body2">
          {convertedCurrency}
        </Typography>
      </Typography>
      {originalValue && originalCurrency && (
        <Typography color="#00000061" variant="body2">
          {originalValue}{' '}
          <Typography component="span" fontWeight={500} variant="body2">
            {originalCurrency}
          </Typography>
        </Typography>
      )}
    </StyledBoxCurrencyCell>
  );
};
