import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { Filter } from 'shared/mui/FilterNew';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import {
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceInputWithChoices,
} from 'shared/react-admin/ReferenceInputs';

import { Currency } from '../../types';
import { cleanEmpty } from '../../utils/cleanEmpty';
import { OrdersListFilterFormValues } from '../Orders/OrdersListNew/OrderListContent';

type LimitsFilterValues = {
  gateway: string[];
  provider: string | null;
  merchant: string | null;
  currency: string[];
};

export const LimitsFilters: React.FC = () => {
  const { filterValues } = useListContext();

  const { form, onReset, appliedFilters, onSubmit } = useFilters<LimitsFilterValues>({
    defaultValues: {
      gateway: filterValues?.gateway || [],
      currency: filterValues?.currency || [],
      provider: filterValues?.['gateway.provider'] || null,
      merchant: filterValues?.merchant || null,
    },
    resetValues: {
      merchant: null,
      currency: [],
      gateway: [],
      provider: null,
    },
  });

  useSyncFilter<OrdersListFilterFormValues>({
    appliedFilters,
    transform: (appliedFilters) => {
      const { provider, ...restFilters } = appliedFilters;
      return cleanEmpty({
        ...restFilters,
        'gateway.provider': provider,
      });
    },
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow columnsCount={5}>
            <ReferenceInputWithChoices
              choices={[{ id: 'null', name: 'Нет' }]}
              isFilter={true}
              perPage={200}
              reference="merchants"
              resettable
              source="merchant"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчант"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInputWithChoices>
            <ReferenceInput
              isFilter={true}
              key="providers"
              perPage={100}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput name="gateway" perPage={100} reference="gateways" source="gateway">
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюзы"
                limitTags={1}
                name="gateway"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              filter={{ active: true }}
              name="currency"
              perPage={50}
              reference="currencies"
              source="currency"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                limitTags={1}
                name="currency"
                optionText="name"
                renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
              />
            </ReferenceArrayInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onClick={onReset} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
