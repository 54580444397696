import { useGetCounter } from './hooks/useGetCounter';
import { ProtectedMenuItemLink, ProtectedMenuItemLinkProps } from './ProtectedMenuItemLink';
import { StyledBadge } from './ProtectedMenuItemLinkWithCounter.styled';

type Props = {
  counter: string;
} & ProtectedMenuItemLinkProps;

export const ProtectedMenuItemLinkWithCounter: React.FC<Props> = ({
  counter,
  primaryText,
  ...restProps
}) => {
  const { data, isLoading } = useGetCounter(counter);

  return (
    <ProtectedMenuItemLink {...restProps}>
      <StyledBadge badgeContent={data?.count} color="error" invisible={!data?.count && isLoading}>
        {primaryText}
      </StyledBadge>
    </ProtectedMenuItemLink>
  );
};
