import { styled } from '@mui/material/styles';
import { Pagination } from 'react-admin';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const COMMON_STYLES = {
  position: 'relative',
  width: '30%',
  maxWidth: '200px',
  minWidth: '200px',
};

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-id': {
    maxWidth: '100%',
    minWidth: '64px',
  },
  '& .column-notificationTransport': COMMON_STYLES,
  '& .column-credentials': COMMON_STYLES,
  '& .column-comment': COMMON_STYLES,
  '& .column-createdAt': getColumnStyle(),
  '& .column-updatedAt': getColumnStyle(),
});

export const StyledPagination = styled(Pagination)({
  display: 'block',
  marginBottom: '24px',
});
