import { useState } from 'react';

import { RefundChargebackReportSummary as RefundChargebackReportSummaryType } from 'types/refundChagrebackReport';

import { BoxStyled } from './RefundChargebackReportSummary.styled';
import { RefundChargebackReportSummaryDatagrid } from './RefundChargebackReportSummaryDatagrid/RefundChargebackReportSummaryDatagrid';
import { RefundChargebackReportSummaryPeriod } from './RefundChargebackReportSummaryPeriod/RefundChargebackReportSummaryPeriod';

export type Props = {
  summary: RefundChargebackReportSummaryType;
  reportPeriod: string;
};

export const RefundChargebackReportSummary: React.FC<Props> = ({
  summary,
  reportPeriod,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  if (isOpen) {
    return (
      <BoxStyled>
        <RefundChargebackReportSummaryPeriod
          isOpen={isOpen}
          reportPeriod={reportPeriod}
          toggleIsOpen={toggleIsOpen}
        />
        <RefundChargebackReportSummaryDatagrid summary={summary} />
      </BoxStyled>
    );
  }

  return (
    <BoxStyled>
      <RefundChargebackReportSummaryPeriod
        isOpen={isOpen}
        reportPeriod={reportPeriod}
        toggleIsOpen={toggleIsOpen}
      />
    </BoxStyled>
  );
};
