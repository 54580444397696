import { usePermissions } from 'hooks/usePermissions';
import { Permission } from 'types';

import { TableFileViewerList } from './components/TableFileViewerList/TableFileViewerList';
import { TableFileViewerUploader } from './components/TableFileViewerUploader/TableFileViewerUploader';
import { ContainerStyled } from './TableFileViewer.styled';

type TableFileViewerProps = {
  entityId: number;
  entityName: string;
  allowUpdate?: boolean;
};

export const TableFileViewer: React.FC<TableFileViewerProps> = ({
  entityName,
  entityId,
  allowUpdate = true,
}) => {
  const { hasResourcePermissions } = usePermissions();
  return (
    <ContainerStyled>
      {hasResourcePermissions('upload_files', [Permission.Full, Permission.Update]) &&
        allowUpdate && <TableFileViewerUploader entityId={entityId} entityName={entityName} />}
      <TableFileViewerList allowUpdate={allowUpdate} entityId={entityId} entityName={entityName} />
    </ContainerStyled>
  );
};
