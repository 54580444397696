import React, { useEffect, useState } from 'react';

import { Button, Popover, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfDay, getTime, isValid } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { DateObject } from 'react-multi-date-picker';

import { Calendar } from '../../Calendar/Calendar';
import { DatePickerActions } from '../components/DatePickerActions/DatePickerActions';
import { DatePickerMaskInput } from '../components/DatePickerMaskInput/DatePickerMaskInput';
import { DatePickerShortcuts } from '../components/DatePickerShortcuts/DatePickerShortcuts';
import { DatePickerTime } from '../components/DatePickerTime/DatePickerTime';
import { DatePickerToolbar } from '../components/DatePickerToolbar/DatePickerToolbar';
import {
  DEFAULT_DATE_TIME_MASK,
  DEFAULT_DATE_TIME_RANGE_MASK,
  DEFAULT_DATE_TIME_RANGE_PLACEHOLDER,
  DEFAULT_SEPARATOR,
  DEFAULT_TIME_MASK,
} from '../config/defaultValues';
import {
  PopoverCalendarContainerStyled,
  PopoverContentStyled,
  PopoverDatesStyled,
  PopoverLayoutStyled,
  PopoverListStyled,
  PopoverTimesStyled,
} from '../DatePicker.styled';
import { useAnchor } from '../hooks/useAnchor';
import { formatStringToTimestamp } from '../utils/formatStringToTimestamp';
import { fromTimestampRangeToString } from '../utils/fromTimestampRangeToString';
import { getValidCalendarDate } from '../utils/getValidCalendarDate';
import { isValidDate } from '../utils/isValidDate';
import { parseStringToDate } from '../utils/parseStringToDate';
import { setCurrentTime } from '../utils/setCurrentTime';
import { setToday } from '../utils/setToday';

export type DateRangePickerValue = number | null;

export type DateTimeRangePickerProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'defaultValue'
> & {
  minDate?: Date | string | number;
  maxDate?: Date | string | number;
  value: [DateRangePickerValue, DateRangePickerValue];
  displayShortcuts?: boolean;
  onChange?: (newValue: [DateRangePickerValue, DateRangePickerValue]) => void;
};

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  label,
  variant = 'outlined',
  displayShortcuts = true,
  disabled,
  onChange: onChangeProp,
  minDate,
  maxDate,
  value,
  ...restProps
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const [error, setError] = useState(false);
  const [startTime, setStartTime] = useState<DateRangePickerValue>(value[0]);
  const [endTime, setEndTime] = useState<DateRangePickerValue>(value[1]);

  const [resetValue, setResetValue] = useState<[DateRangePickerValue, DateRangePickerValue]>(value);

  const [inputValue, setInputValue] = useState<string | undefined>(
    fromTimestampRangeToString(value, DEFAULT_DATE_TIME_MASK),
  );

  const [calendarValue, setCalendarValue] =
    useState<[DateRangePickerValue, DateRangePickerValue]>(value);

  const open = Boolean(anchorEl);
  const id = open ? 'date-time-picker-popover' : undefined;

  useEffect(() => {
    setStartTime(value[0]);
    setEndTime(value[1]);
    setInputValue(fromTimestampRangeToString(value, DEFAULT_DATE_TIME_MASK));
    setCalendarValue(value);
  }, [value]);

  const debouncedOnChangeInputHandler = useDebounce((target: string) => {
    const [start, end] = target.split(DEFAULT_SEPARATOR);

    if (!start && !end) {
      setError(false);
      setStartTime(null);
      setEndTime(null);
      setCalendarValue([null, null]);
      setInputValue(undefined);
      return;
    }

    const [startDate, startTime] = start.split(' ');
    const [endDate, endTime] = end.split(' ');

    setError(true);
    const validStartDate = isValidDate(startDate) ? formatStringToTimestamp(startDate) : null;
    const validEndDate = isValidDate(endDate) ? formatStringToTimestamp(endDate) : null;

    if (validStartDate && isValidDate(startDate) && isValidDate(startTime, DEFAULT_TIME_MASK)) {
      const newValue = setCurrentTime(
        validStartDate,
        parseStringToDate(startTime, DEFAULT_TIME_MASK),
      );

      setError(false);
      setCalendarValue((prev) => [newValue, prev[1]]);
      setStartTime(newValue);
    } else {
      setCalendarValue((prev) => [null, prev[1]]);
      setStartTime(null);
    }

    if (validEndDate && isValidDate(endDate) && isValidDate(endTime, DEFAULT_TIME_MASK)) {
      const newValue = setCurrentTime(validEndDate, parseStringToDate(endTime, DEFAULT_TIME_MASK));

      setError(false);
      setCalendarValue((prev) => [prev[0], newValue]);
      setEndTime(newValue);
    } else {
      setCalendarValue((prev) => [prev[0], null]);
      setEndTime(null);
    }

    setInputValue(target);
  }, 300);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedOnChangeInputHandler(event?.target?.value);
  }

  function onChangeCalendar(value: (DateObject | null)[]) {
    const [start, end] = value;

    const validStart = getValidCalendarDate(start, startTime);
    const validEnd = getValidCalendarDate(end, endTime);
    const validEndOfDay = validEnd ? getTime(endOfDay(validEnd)) : null;

    const validValue: [DateRangePickerValue, DateRangePickerValue] = [validStart, validEndOfDay];

    setStartTime(validStart);
    setEndTime(validEndOfDay);

    setInputValue(fromTimestampRangeToString(validValue, DEFAULT_DATE_TIME_MASK));
    setCalendarValue(validValue);
  }

  const onChangeStartTime = (value: Date) => {
    const [startDate, endDate] = calendarValue;

    if (!isValid(value)) {
      setStartTime(null);
      setCalendarValue((prevValue) => [null, prevValue[1]]);
      setInputValue(fromTimestampRangeToString([null, endDate], DEFAULT_DATE_TIME_MASK));

      return;
    }

    if (!startDate) {
      const newValue = setToday(value);
      setStartTime(newValue);
      setCalendarValue((prevValue) => [newValue, prevValue[1]]);
      setInputValue(fromTimestampRangeToString([newValue, endDate], DEFAULT_DATE_TIME_MASK));

      return;
    }

    const newDateTime = setCurrentTime(startDate, value);
    setStartTime(newDateTime);
    setCalendarValue((prevValue) => [newDateTime, prevValue[1]]);
    setInputValue(fromTimestampRangeToString([newDateTime, endDate], DEFAULT_DATE_TIME_MASK));
  };
  const onChangeEndTime = (value: Date) => {
    const [startDate, endDate] = calendarValue;

    if (!isValid(value)) {
      setEndTime(null);
      setCalendarValue((prevValue) => [prevValue[0], null]);
      setInputValue(fromTimestampRangeToString([startDate, null], DEFAULT_DATE_TIME_MASK));

      return;
    }

    if (!endDate) {
      const newValue = setToday(value);
      setEndTime(newValue);
      setCalendarValue((prevValue) => [prevValue[0], newValue]);
      setInputValue(fromTimestampRangeToString([startDate, newValue], DEFAULT_DATE_TIME_MASK));

      return;
    }

    const newDateTime = setCurrentTime(endDate, value);
    setEndTime(newDateTime);
    setCalendarValue((prevValue) => [prevValue[0], newDateTime]);
    setInputValue(fromTimestampRangeToString([startDate, newDateTime], DEFAULT_DATE_TIME_MASK));
  };

  const onClickShortcut = (value: [DateRangePickerValue, DateRangePickerValue]) => {
    const [startDate, endDate] = value;

    setStartTime(startDate);
    setEndTime(endDate);
    setInputValue(fromTimestampRangeToString(value, DEFAULT_DATE_TIME_MASK));
    setCalendarValue(value);
  };

  function onClickApply() {
    if (onChangeProp) {
      const validValue: [DateRangePickerValue, DateRangePickerValue] = [
        calendarValue[0],
        calendarValue[1] || Number(endOfDay(new Date())),
      ];
      onChangeProp(validValue);
      setResetValue(validValue);
      handleClose();
    }
  }

  function onClickReset() {
    if (onChangeProp) {
      onChangeProp(resetValue);
      setStartTime(resetValue[0]);
      setEndTime(resetValue[1]);
      setInputValue(fromTimestampRangeToString(resetValue, DEFAULT_DATE_TIME_MASK));
      setCalendarValue(resetValue);
      handleClose();
    }
  }

  return (
    <>
      <DatePickerMaskInput
        {...restProps}
        disabled={disabled}
        error={error}
        label={label}
        mask={DEFAULT_DATE_TIME_RANGE_MASK}
        onChange={onChangeInput}
        onClick={(e) => {
          if (disabled) {
            return;
          }

          handleClick(e);
        }}
        placeholder={DEFAULT_DATE_TIME_RANGE_PLACEHOLDER}
        value={inputValue}
        variant={variant}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <PopoverLayoutStyled>
          <PopoverCalendarContainerStyled>
            {displayShortcuts && (
              <PopoverListStyled>
                <DatePickerShortcuts onClick={onClickShortcut} />
              </PopoverListStyled>
            )}
            <PopoverContentStyled>
              <DatePickerToolbar
                end={calendarValue[1]}
                start={calendarValue[0]}
                title="Выберите диапазон дат и время"
              />
              <PopoverDatesStyled>
                <PopoverCalendarContainerStyled>
                  <Calendar
                    maxDate={maxDate}
                    minDate={minDate}
                    numberOfMonths={2}
                    onChange={onChangeCalendar}
                    range
                    value={calendarValue}
                  />
                </PopoverCalendarContainerStyled>
                <PopoverTimesStyled>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePickerTime
                      label="Время начала"
                      onChange={onChangeStartTime}
                      value={startTime}
                    />
                    <DatePickerTime
                      label="Время конца"
                      onChange={onChangeEndTime}
                      value={endTime}
                    />
                  </LocalizationProvider>
                </PopoverTimesStyled>
              </PopoverDatesStyled>
            </PopoverContentStyled>
          </PopoverCalendarContainerStyled>
          <DatePickerActions>
            <Button onClick={onClickReset} variant="outlined">
              Отменить
            </Button>
            <Button onClick={onClickApply} variant="contained">
              Подтвердить
            </Button>
          </DatePickerActions>
        </PopoverLayoutStyled>
      </Popover>
    </>
  );
};
