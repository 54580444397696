import { useMemo } from 'react';

import { Typography } from '@mui/material';
import { useApiContext } from 'context/apiContext';
import { WithListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { ListPage } from 'shared/react-admin/Pages';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';

import { CommissionsListDatagrid } from './CommissionsListDatagrid/CommissionsListDatagrid';
import {
  CommissionsListFilter,
  CommissionsListFilterFormValues,
} from './CommissionsListFilter/CommissionsListFilter';
import { CommissionsListFilterChips } from './CommissionsListFilterChips/CommissionsListFilterChips';
import { Alerts } from '../../../constants/alerts';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';
import { getId } from '../../../utils';
import { cleanEmpty } from '../../../utils/cleanEmpty';

export const CommissionsList: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();

  const { user } = useApiContext();

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<CommissionsListFilterFormValues>({
      defaultValues: {},
    });

  const listFilters = useMemo(() => {
    const { status: fullIdStatus = [], ...restAppliedFilters } = appliedFilters;

    return cleanEmpty({
      ...restAppliedFilters,
      status: fullIdStatus?.map((status) => getId(status)),
    });
  }, [appliedFilters]);

  const isListFilterExist = Object.keys(listFilters).length > 0;

  return (
    <ListPage
      actions={[
        hasResourcePermissions('commissions', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-comissions" />
        ),
      ]}
      disableSyncWithLocation={true}
      empty={false}
      filter={listFilters}
      headerTitle={{
        titleText: 'Комиссии',
        tooltipText: 'Данные, на основе которых рассчитываются комиссии, взимаемые провайдером.',
      }}
      listBoxProps={{
        sx: { maxHeight: 'initial' },

        overflow: 'auto',
      }}
      pagination={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <SelectColumnButtonFilter key="select-column" />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <CommissionsListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <CommissionsListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <WithListContext
        render={({ data, isLoading }) => {
          if (user.alerts.includes(Alerts.EmptyProviderAccountAccess)) {
            return (
              <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} отсутствует доступ к настройкам "Комиссий", обратитесь к руководителю вашей рабочей группы для предоставления доступа.`}</Alert>
            );
          }

          if (user.alerts.includes(Alerts.EmptyMerchantAccess)) {
            return (
              <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных мерчантов, обратитесь к администратору системы для настроек доступа.`}</Alert>
            );
          }

          if (!data?.length && !isLoading) {
            return (
              <Alert severity="info">
                <Typography variant="body2">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Нет созданных "Комиссий" для доступных "Мерчантов" и "Провайдеров"
                </Typography>
              </Alert>
            );
          }

          return (
            <FormProvider {...form}>
              <FilterContextProvider
                appliedFilters={appliedFilters}
                setAppliedFilter={setAppliedFilter}
              >
                <CommissionsListDatagrid />
              </FilterContextProvider>
            </FormProvider>
          );
        }}
      />
    </ListPage>
  );
};
