import { useRecordContext } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { TableRowUnitedWrapper } from 'shared/mui/Table/TableRowUnitedWrapper/TableRowUnitedWrapper';
import {
  DateField,
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceField } from 'shared/react-admin/ReferenceFields';
import {
  TableCellStyled,
  TableRowCommentsStyled,
} from 'shared/widgets/CommissionsShowTable/CommissionsShowTable.styled';
import { useGetFiles } from 'shared/widgets/TableFileViewer/hooks/useGetFiles';
import { TableFileViewer } from 'shared/widgets/TableFileViewer/TableFileViewer';
import { BankOperation, Currency } from 'types';
import { formatCurrency } from 'utils';

import { BankOperationsListComment } from '../BankOperationsListNew/BankOperationsListComment/BankOperationsListComment';
import { BankOperationStatusChipField } from '../components/BankOperationStatusChipField/BankOperationStatusChipField';
import { useBankOperationEditable } from '../hooks/useBankOperationEditable';

const HEADER_SX = { fontWeight: 'initial', fontSize: '16px', paddingTop: '24px' };

export const BankOperationsShowTableNew: React.FC = () => {
  const record = useRecordContext<BankOperation>();

  const { checkBankOperationEditable } = useBankOperationEditable();

  const { data: files } = useGetFiles('bank_operation', record.originId);

  return (
    <CardTable sx={{ tableLayout: 'fixed', borderTop: 'none' }}>
      <TableBody>
        <TableRowWrapper headerStyle={HEADER_SX} title="Основные настройки:" />
        <TableRowWrapper title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Мерчант">
          <ReferenceField link={false} reference="merchants" source="merchant">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Провайдер">
          <ReferenceField link={false} reference="providers" source="provider">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Направление">
          <ReferenceField link={false} reference="directions" source="direction">
            <TextField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Тип банковской операции">
          <ReferenceField link={false} reference="bank_operation_types" source="type">
            <TextField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Статус">
          <ReferenceField link={false} reference="bank_operation_statuses" source="status">
            <BankOperationStatusChipField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Счет мерчанта">
          <ReferenceField link={false} reference="merchant_accounts" source="merchantAccount">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Сумма">
          <FunctionField
            render={({ amountPenny }: BankOperation) => formatCurrency(Number(amountPenny))}
          />
        </TableRowWrapper>
        <TableRowWrapper title="Валюта">
          <ReferenceField label="Валюта" link={false} reference="currencies" source="currency">
            <FunctionField render={({ alphaCode, name }: Currency) => `${alphaCode}, ${name}`} />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Дата проведения">
          <DateField locales="fr-FR" source="dateOfOperation" />
        </TableRowWrapper>
        <TableRowUnitedWrapper title="Описание">
          <TextField emptyText="—" source="description" sx={{ wordBreak: 'break-word' }} />
        </TableRowUnitedWrapper>

        {(checkBankOperationEditable(record) || (files && files?.length > 0)) && (
          <TableRowUnitedWrapper title="Файлы">
            <TableFileViewer
              allowUpdate={checkBankOperationEditable(record)}
              entityId={record?.originId}
              entityName="bank_operation"
            />
          </TableRowUnitedWrapper>
        )}
        {record.comments.length > 0 && (
          <TableRowWrapper headerStyle={HEADER_SX} title="Комментарии:" />
        )}
        {record.comments.length > 0 && (
          <TableRowCommentsStyled>
            <TableCellStyled colSpan={2}>
              <ReferenceArrayField
                reference="bank_operation_comments"
                sortable={false}
                source="comments"
              >
                <SingleFieldList linkType={false}>
                  <BankOperationsListComment />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableCellStyled>
          </TableRowCommentsStyled>
        )}
        <TableRowWrapper headerStyle={HEADER_SX} title="История:" />
        <TableRowWrapper title="Дата создания">
          <DateTimeField source="createdAt" />
        </TableRowWrapper>
        <TableRowWrapper title="Дата изменения">
          <DateTimeField source="updatedAt" />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};
