import { RaRecord, useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';

import { StyledTypography } from './TextLinkField.styled';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

export type TextLinkFieldProps = {
  source: string;
  value?: string;
  resource: string;
  type: string;
} & Omit<FunctionFieldProps, 'render'>;

export const TextLinkField: React.FC<TextLinkFieldProps> = ({
  source,
  sx,
  resource,
  type,
  value,
  ...rest
}) => {
  const createPath = useCreatePath();

  return (
    <FunctionField
      render={(record: RaRecord) => {
        return (
          <Box>
            <Link to={createPath({ resource, id: record.id, type })}>
              <StyledTypography sx={sx}>{value || record[source]}</StyledTypography>
            </Link>
          </Box>
        );
      }}
      {...rest}
    />
  );
};
