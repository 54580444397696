import { Fragment } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { ArchiveConditionListChip } from 'shared/mui/Chips';
import { ExportButton } from 'shared/react-admin/Buttons';
import { LinkTextButton } from 'shared/react-admin/Buttons';
import {
  DatagridActions,
  DatagridActionsArchiveButton,
  DatagridActionsDeleteButton,
  DatagridActionsEditDialogPageButton,
  DatagridActionsShowDialogPageButton,
  DatagridActionsUnarchiveButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  DateTimeField,
  FunctionField,
  TextField,
  TooltipTextDialogLinkField,
  WrapperField,
} from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { Company, Permission } from 'types';

import { StyledDatagrid } from './CompaniesList.styled';
import { CompaniesFilter } from '../CompaniesFilter';
import { CompaniesArchiveDialog } from '../components/CompaniesArchiveDialog/CompaniesArchiveDialog';
import { CompaniesDeleteDialog } from '../components/CompaniesDeleteDialog/CompaniesDeleteDialog';
import { CompaniesUnarchiveDialog } from '../components/CompaniesUnarchiveDialog/CompaniesUnarchiveDialog';

export const CompaniesList: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Company>();
  const {
    record: recordUnarchive,
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Company>();
  const {
    record: recordArchive,
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Company>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('companies', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create_companies" />
        ),
        <ExportButton key="export_companies" />,
      ]}
      headerTitle={{
        titleText: 'Компании',
        tooltipText: 'Юридическое лицо, к которому принадлежит мерчант.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <CompaniesFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TooltipTextDialogLinkField
          label="Название"
          resource="companies"
          source="name"
          type="show"
        />
        <WrapperField>
          <Fragment />
        </WrapperField>
        <FunctionField
          label="Состояние"
          render={({ archive }: Company) => (
            <ArchiveConditionListChip activeLabel="Активна" archive={archive} />
          )}
          sortable={false}
          source="archive"
        />
        <FunctionField
          label="Кол-во мерчантов"
          render={({ merchantCount, id }: Company) => (
            <LinkTextButton
              filter={{ companies: [id] }}
              label={String(merchantCount)}
              pathname="/merchants"
              sx={{ textDecoration: 'underline' }}
            />
          )}
          sortable={false}
          source="merchantCount"
        />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <DatagridActions
          render={(record: Company) => [
            hasResourceAccess('user_actions') && (
              <DatagridActionsUserActionsButton entityName="company" key="user-action-button" />
            ),
            <DatagridActionsShowDialogPageButton key="show-button" />,
            hasResourcePermissions('companies', [Permission.Full, Permission.Update]) &&
              (record.archive ? (
                <DatagridActionsUnarchiveButton
                  key="unarchive-button"
                  onClick={() => handleOpenRecordModalUnarchive(record)}
                />
              ) : (
                <DatagridActionsEditDialogPageButton key="edit-button" />
              )),
            hasResourcePermissions('companies', [Permission.Full, Permission.Delete]) &&
              (record.archive ? (
                <DatagridActionsDeleteButton
                  key="delete-button"
                  onClick={() => handleOpenRecordModalDelete(record)}
                />
              ) : (
                <DatagridActionsArchiveButton
                  key="archive-button"
                  onClick={() => handleOpenRecordModalArchive(record)}
                />
              )),
          ]}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <CompaniesDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {openRecordModalUnarchive && recordUnarchive && (
        <CompaniesUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={recordUnarchive}
        />
      )}
      {openRecordModalArchive && recordArchive && (
        <CompaniesArchiveDialog
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={recordArchive}
        />
      )}
    </ListPage>
  );
};
