import { styled } from '@mui/material/styles';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { getColumnStyle } from 'utils';

const columnStyle200 = getColumnStyle(200);
const columnStyle90 = getColumnStyle(90);

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-name': {
    width: '100%',
    maxWidth: '200px',
    minWidth: '200px',
  },
  '& .column-alias': columnStyle200,
  '& .column-methodType': columnStyle200,
  '& .column-active': columnStyle90,
  '& .column-logo': columnStyle90,
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
