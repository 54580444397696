import { styled } from '@mui/material/styles';
import { appColors } from 'themes/variables';

import { DatagridWithFooter } from './DatagridWithFooter';

export const DatagridWithFooterStyled = styled(DatagridWithFooter)({
  minWidth: '36px',
  padding: 0,
  '.MuiButton-startIcon': {
    marginRight: '4px',
    '.MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  '& .RaDatagrid-headerCell': {
    verticalAlign: 'top',
    padding: '13px 16px',
    lineHeight: '21px',
    backgroundColor: '#FAFAFA',
  },
  '& .RaDatagrid-rowCell': {
    verticalAlign: 'middle',
    padding: '12px 16px',
  },
  '& .MuiTableCell-root': {
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    verticalAlign: 'top',
    overflow: 'hidden',
    textAlign: 'left',
  },
  '& .MuiTableCell-footer': {
    padding: '14px 16px',
    color: appColors.text.dark,
    backgroundColor: appColors.tableHead,
  },
  h6: {
    color: '#000000',
  },
  '& .MuiTableSortLabel-root': {
    alignItems: 'flex-start',
  },
});
