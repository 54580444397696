import { ChartData } from 'chart.js';
import { Typography } from 'shared/mui/Typography';

import {
  LegendItemDotStyled,
  LegendItemStyled,
  LegendWrapperStyled,
} from './ConversionLegend.styled';

type ConversionLegendProps = {
  data: ChartData<'bar', { x: number; count: number }[], string>;
};

export const ConversionLegend: React.FC<ConversionLegendProps> = ({ data }) => {
  return (
    <LegendWrapperStyled>
      {data.datasets?.map((item, index) => {
        const percent = item?.data[0].x;
        const count = item?.data[0].count;

        return (
          <LegendItemStyled key={`${percent}-${index}`}>
            <LegendItemDotStyled
              sx={{
                backgroundColor:
                  typeof item?.backgroundColor === 'string' ? item?.backgroundColor : 'grey',
              }}
            />
            {percent}% / {count}{' '}
            <Typography color="secondary" fontSize={14}>
              {item.label}
            </Typography>
          </LegendItemStyled>
        );
      })}
    </LegendWrapperStyled>
  );
};
