import * as React from 'react';

import { Card } from '@mui/material';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { truncateString } from 'utils/truncateString';

import { Commission } from '../../../../../../types';

type Props = {
  record: Commission | undefined;
  onAgreement: (data: Commission) => void;
} & DialogProps;

export const MerchantShowCommissionsCreateSuccessDialog: React.FC<Props> = ({
  record,
  onAgreement,
  ...restProps
}) => {
  const onClose = () => {
    restProps.onClose();
  };

  return (
    <Dialog {...restProps} fullWidth onClose={onClose}>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Создание комиссии успешно выполнено"
        />
        <CardContent>
          <Typography>
            Новая комиссия {truncateString(record?.name || '', 30)} успешно создана. Вы можете
            отправить изменения на согласование или вернуться к списку комиссий для дальнейшей
            работы.
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton
              key="back-button"
              label="Вернуться в список"
              onClick={onClose}
              variant="outlined"
            />,
            <Button
              color="primary"
              key="btn-agreement"
              label="На согласование"
              onClick={() => record && onAgreement(record)}
              variant="contained"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
