import { useState, MouseEvent, useCallback } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PopoverOrigin, SxProps } from '@mui/material';
import { RaRecord, useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';

import { DatagridActionsContextProvider } from './context/datagridActionsContext';
import { MenuStyled } from './DatagridActions.styled';

export type DatagridActionsProps<RecordType extends RaRecord> = {
  render?: (record: RecordType, source?: string) => JSX.Element | (JSX.Element | false)[];
  source?: string;
  children?: React.ReactNode;
  sx?: SxProps;
  transformOrigin?: PopoverOrigin;
};

export function DatagridActions<RecordType extends RaRecord>({
  children,
  render,
  sx,
  transformOrigin,
}: DatagridActionsProps<RecordType>) {
  const record = useRecordContext<RecordType>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <DatagridActionsContextProvider value={{ onCloseMenu }}>
      <Box sx={sx}>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon color="secondary" />
        </IconButton>
        <MenuStyled
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={onCloseMenu}
          open={open}
          transformOrigin={
            transformOrigin
              ? transformOrigin
              : {
                  vertical: 'top',
                  horizontal: 'right',
                }
          }
        >
          {render ? render(record) : children}
        </MenuStyled>
      </Box>
    </DatagridActionsContextProvider>
  );
}
