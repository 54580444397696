import { BankOperationStatusesNew } from 'constants/bankOperationStatusesNew';

export const STATUS_COLORS: Record<BankOperationStatusesNew | 'default', string> = {
  [BankOperationStatusesNew.Approved]: '#2E7D32',
  [BankOperationStatusesNew.Created]: '#1976D2',
  [BankOperationStatusesNew.Canceled]: '#D32F2F',
  [BankOperationStatusesNew.OnApproval]: '#FF9800',
  [BankOperationStatusesNew.Rejected]: '#D32F2F',
  default: '#BDBDBD',
};
