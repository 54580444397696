import { DragEvent, useRef } from 'react';

import { StyledBoxWrapper } from './TableFileViewerUploader.styled';
import { TableFileViewerUploaderPlaceholder } from './TableFileViewerUploaderPlaceholder';
import { CircularProgress } from '../../../../mui/CircularProgress/CircularProgress';
import { useSendFile } from '../../hooks/useSendFile';

type TableFileViewerUploaderProps = {
  entityId: number;
  entityName: string;
};

export const TableFileViewerUploader: React.FC<TableFileViewerUploaderProps> = ({
  entityName,
  entityId,
}) => {
  const { sendFile } = useSendFile();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (file: File) => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
      formData.append('name', file.name);
      formData.append('entityName', entityName);
      formData.append('entityId', String(entityId));
    }
    sendFile.mutate(formData);
  };

  const handleFileClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer?.files[0]) {
      const file = e.dataTransfer.files[0];
      handleFileUpload(file);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <input
        accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.tiff"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            handleFileUpload(file);
          }
          e.target.value = '';
        }}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      <StyledBoxWrapper onDragOver={handleDragOver} onDrop={handleDrop}>
        {sendFile.isLoading ? (
          <CircularProgress size="20px" />
        ) : (
          <TableFileViewerUploaderPlaceholder onClick={handleFileClick} />
        )}
      </StyledBoxWrapper>
    </>
  );
};
