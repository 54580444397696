import { InfoOutlined } from '@mui/icons-material';
import { FormControl } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from 'shared/mui/FormHelperText/FormHelperText';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { Tooltip } from 'shared/mui/Tooltip';
import { Typography } from 'shared/mui/Typography';

import { useGetOrderAdjustmentTypes } from '../hooks/useGetOrderAdjustmentTypes';
import { OrderAdjustmentsFormValues } from '../hooks/useOrderAdjustmentsForm';

export const OrderAdjustmentsAdjustmentTypeSelect = () => {
  const { control } = useFormContext<OrderAdjustmentsFormValues>();
  const orderAdjustmentTypes = useGetOrderAdjustmentTypes();

  return (
    <Controller
      control={control}
      name="adjustmentType"
      render={({ field, fieldState }) => (
        <FormControl fullWidth variant="outlined">
          <InputLabel error={!!fieldState.error} id="adjustmentType" required shrink>
            Тип корректировки
          </InputLabel>
          <Select
            error={!!fieldState.error}
            label="Тип корректировки"
            labelId="adjustmentType"
            notched
            onChange={field.onChange}
            renderValue={(value) =>
              orderAdjustmentTypes.find((type) => type.id === value)?.description
            }
            required
            size="medium"
            value={field?.value}
          >
            {orderAdjustmentTypes?.map((item) => (
              <MenuItem
                disabled={!item.disabled}
                key={item.id}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                value={item.id}
              >
                <Typography component="p" variant="body2">
                  {item.description}
                </Typography>
                <Tooltip
                  arrow
                  onClick={(e) => e.stopPropagation()}
                  placement="right-start"
                  sx={{
                    pointerEvents: 'auto',
                  }}
                  title={<Typography fontSize={12}>{item.tooltip}</Typography>}
                >
                  <InfoOutlined color="secondary" />
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!fieldState.error} sx={{ height: '20px' }}>
            {fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
      rules={{ required: 'Обязательно для заполнения' }}
    />
  );
};
