import { useListContext } from 'react-admin';

import { Order } from '../../../types';

const enum OrderStatuses {
  Success = '/admin/new/order_statuses/success',
  Failure = '/admin/new/order_statuses/failure',
  Expired = '/admin/new/order_statuses/expired',
  Created = '/admin/new/order_statuses/created',
  Pending = '/admin/new/order_statuses/pending',
}

export function useGetOrderAdjustmentTypes() {
  const { data: orders } = useListContext<Order>();
  return [
    {
      id: '/admin/adjustment_types/order_amount',
      description: 'Корректировка суммы заказа',
      tooltip: 'Доступно при заказах в статусе "Успех"',
      disabled: orders.every((order) => order.status === OrderStatuses.Success),
    },
    {
      id: '/admin/adjustment_types/status_to_success',
      description: 'Провести со статусом "Успех"',
      tooltip: 'Доступно при заказах в статусах "Ожидание", "Провал" и "Просрочен"',
      disabled: orders.every((order) =>
        [OrderStatuses.Failure, OrderStatuses.Expired, OrderStatuses.Pending].includes(
          order.status as OrderStatuses,
        ),
      ),
    },
    {
      id: '/admin/adjustment_types/status_to_refund',
      description: 'Провести со статусом "Рефанд"',
      tooltip: 'Доступно при заказах в статусе "Успех"',
      disabled: orders.every((order) => order.status === OrderStatuses.Success),
    },
    {
      id: '/admin/adjustment_types/status_to_chargeback',
      description: 'Провести со статусом "Чарджбек"',
      tooltip: 'Доступно при заказах в статусе "Успех"',
      disabled: orders.every((order) => order.status === OrderStatuses.Success),
    },
    {
      id: '/admin/adjustment_types/status_to_failure',
      description: 'Провести со статусом "Провал"',
      tooltip: 'Доступно при заказах в статусах "Успех" и "Ожидание"',
      disabled: orders.every(
        (order) => order.status === OrderStatuses.Success || order.status === OrderStatuses.Pending,
      ),
    },
    {
      id: '/admin/adjustment_types/status_to_cancelled',
      description: 'Провести со статусом "Отмена"',
      tooltip: 'Доступно при заказах в статусе "Создан"',
      disabled: orders.every((order) => order.status === OrderStatuses.Created),
    },
  ];
}
