import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import React, { useState } from 'react';

import { useApiContext } from 'context/apiContext';
import { useReportExport } from 'hooks/queries/useReportExport';
import { useListController, SortPayload, ListContextProvider, useNotify } from 'react-admin';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { PageHeader } from 'shared/mui/PageHeader';
import { Typography, TypographyTitle } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';

import { EXPORT_FIELDS } from './constants/exportFields';
import { useGetRefundChargebackReport } from './hooks/useGetRefundChargebackReport';
import { RefundChargebackReportListContent } from './RefundChargebackReportListContent';

export const RefundChargebackReportList: React.FC = () => {
  const { filterValues, ...restContext } = useListController({
    resource: 'refund_chargeback_report',
    sort: {} as SortPayload,
    queryOptions: { enabled: false },
  });

  const { report, total, isLoading: isReportLoading } = useGetRefundChargebackReport(filterValues);

  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);

  const { user } = useApiContext();
  const notify = useNotify();

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...filterValues,
      exportType: exportFormat,
      jobType: ExportJobTypes.Refund_chargeback,
      ...(isSendReportOnEmail && { notificationTransport: ExportTransportTypes.Email }),
    },
    preferenceKey: ReportNames.Refund_charegback_report,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по рефандам и чарджбекам формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  return (
    <ListContextProvider
      value={{
        filterValues,
        ...restContext,
        data: report?.orders || [],
        total: total,
        isLoading: isReportLoading,
      }}
    >
      <PageHeader
        actions={[
          <ExportFormatsButton
            disabled={!total}
            exportFormat={exportFormat}
            key="export-button"
            onChangeFormat={setExportFormat}
            onClick={() => setIsOpenNotifyDialog(true)}
          />,
        ]}
        headerTitle={<TypographyTitle size="large">Отчет по рефандам и чарджбекам</TypographyTitle>}
        mt={3}
      />
      <RefundChargebackReportListContent summary={report?.summary} />
      <NotifyDialog
        isLoading={isReportLoading || isExportLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportMutate}
        open={isOpenNotifyDialog}
        title={
          <>
            <Typography component="span" sx={{ verticalAlign: 'middle' }} variant="inherit">
              Экспорт отчета
            </Typography>
            <ExportEditButton
              fields={EXPORT_FIELDS}
              preferenceKey={ReportNames.Refund_charegback_report}
            />
          </>
        }
      />
    </ListContextProvider>
  );
};
