import { useGetList } from 'react-admin';

import { SmallAvatar } from './UserBadgeField.styled';
import { usePermissions } from '../../../../hooks/usePermissions';
import { RoleCategory, RoleEntity } from '../../../../types';
import { Avatar } from '../../../mui/Avatars';
import { Badge } from '../../../mui/Badge/Badge';
import { Tooltip } from '../../../mui/Tooltip';
import { ReferenceField } from '../../ReferenceFields';
import { FunctionField } from '../FunctionField/FunctionField';

export type UserBadgeFieldProps = {
  firstName: string;
  lastName: string;
};

export const UserBadgeField: React.FC<UserBadgeFieldProps> = ({ firstName, lastName }) => {
  const { data: categories } = useGetList<RoleCategory>('role_categories');
  const { hasResourceAccess } = usePermissions();

  const getRoleImg = (record: RoleEntity) => {
    if (!record || !categories) {
      return <></>;
    }
    const current = categories?.find((category) => category.id === record.roleCategories);
    return (
      <Tooltip arrow title={record.name}>
        <SmallAvatar src={current?.description} />
      </Tooltip>
    );
  };

  return (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        hasResourceAccess('roles') && (
          <ReferenceField link={false} reference="roles" source="roleEntities[0]">
            <FunctionField render={(record: RoleEntity) => getRoleImg(record)} />
          </ReferenceField>
        )
      }
      overlap="circular"
      sx={{}}
    >
      <Avatar>{`${firstName[0]}${lastName[0]}`.toUpperCase()}</Avatar>
    </Badge>
  );
};
