import { useGetOne, useRecordContext } from 'react-admin';
import { CumulativeReportSummary, MerchantAccount } from 'types';
import { convertReactAdminIdToHydraId } from 'utils';

import { TypographyStyled } from './CumulativeReportSummary.styled';

type Props = {
  label: string;
  onClick: (record?: MerchantAccount) => void;
  source?: string;
};

export const CumulativeReportSummaryMerchantAccountField: React.FC<Props> = ({ onClick }) => {
  const record = useRecordContext<CumulativeReportSummary>();
  const { data: merchantAccount } = useGetOne<MerchantAccount>('merchant_accounts', {
    id: convertReactAdminIdToHydraId(record?.merchantAccountId, 'merchant_accounts'),
  });

  return (
    <TypographyStyled onClick={() => onClick(merchantAccount)}>
      {record?.merchantAccountName}
    </TypographyStyled>
  );
};
