import { BankOperationStatusesNew } from 'constants/bankOperationStatusesNew';

import { ChipFieldProps, useRecordContext } from 'react-admin';

import {
  ChipFieldStyled,
  ChipFieldWrapperStyled,
  StatusIconStyled,
} from './BankOperationStatusChipField.styled';

export type CommissionStatusChipFieldProps = ChipFieldProps;

export const BankOperationStatusChipField: React.FC<CommissionStatusChipFieldProps> = ({
  children,
  ...restProps
}) => {
  const record = useRecordContext();

  return (
    <ChipFieldWrapperStyled>
      <ChipFieldStyled
        icon={<StatusIconStyled status={record?.value as BankOperationStatusesNew} />}
        {...restProps}
      />
      {children}
    </ChipFieldWrapperStyled>
  );
};
