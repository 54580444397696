import { Check, DoNotDisturbAlt, Title } from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputLabel } from 'shared/mui/Labels/InputLabel/InputLabel';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Select } from 'shared/mui/Select/Select';
import { appColors } from 'themes/variables';

import { FormControlStyled, menuStyles } from './GatewaysFiltersStatusSelectInput.styled';
import { ReactComponent as PilotIcon } from '../../../../images/pilotFilled.svg';
import { GATEWAY_STATUSES, GatewayStatus } from '../constants/gatewayStatuses';

export const GatewaysFiltersStatusSelectInput = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <FormControlStyled fullWidth sx={{ minWidth: 0 }}>
          <InputLabel shrink>Статус</InputLabel>
          <Select
            MenuProps={{ sx: menuStyles }}
            fullWidth
            label="Статус"
            notched
            onChange={(value) => {
              field.onChange(value);
            }}
            size="small"
            value={field.value || ''}
            variant="outlined"
          >
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Active].id}>
              <Check htmlColor={appColors.success.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Active].name}</span>
            </MenuItem>
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Disabled].id}>
              <DoNotDisturbAlt htmlColor={appColors.error.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Disabled].name}</span>
            </MenuItem>
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Test].id}>
              <Title htmlColor={appColors.warning.light} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Test].name}</span>
            </MenuItem>
            <MenuItem value={GATEWAY_STATUSES[GatewayStatus.Pilot].id}>
              <PilotIcon style={{ marginRight: '8px' }} />
              <span>{GATEWAY_STATUSES[GatewayStatus.Pilot].name}</span>
            </MenuItem>
          </Select>
        </FormControlStyled>
      )}
    />
  );
};
