import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from 'shared/mui/Buttons';

export type ReferenceButtonProps = ButtonProps;

export function ReferenceButton(props: ReferenceButtonProps): JSX.Element {
  const {
    label = 'Справка',
    startIcon = <ErrorOutlineIcon />,
    size = 'medium',
    variant = 'outlined',
    ...rest
  } = props;

  return (
    <Button
      component={Link}
      label={label}
      size={size}
      startIcon={startIcon}
      variant={variant}
      {...rest}
    />
  );
}
