import { EMPTY_FIELD } from 'constants/emptyField';

import { useLocalDatagrid } from 'hooks/useLocalDatagrid';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { RaRecord } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TablePagination } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import {
  ChipField,
  TextField,
  FunctionField,
  NumberField,
  DateTimeField,
  CopyToClipboardLinkField,
  CopyToClipboardField,
  OrderStatusChipField,
  CurrencyField,
} from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ReferenceLink } from 'shared/react-admin/ReferenceFields/ReferenceLink/ReferenceLink';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { Commission, MerchantAccount, Method, Order } from 'types';
import { RefundChargebackReportOrder } from 'types/refundChagrebackReport';
import { convertReactAdminIdToHydraId, getId } from 'utils';

import {
  DatagridConfigurableStyled,
  ImageFieldStyled,
} from './RefundChargebackReportDatagrid.styled';
import { RefundChargebackReportCommissionAmountField } from '../RefundChargebackReportCommissionAmountField/RefundChargebackReportCommissionAmountField';
import { RefundChargebackReportRuleField } from '../RefundChargebackReportRuleField/RefundChargebackReportRuleField';
import { RefundChargebackReportTotalAmountField } from '../RefundChargebackReportTotalAmountField/RefundChargebackReportTotalAmountField';

type Props = {
  orders: Order[];
  total: number;
  isLoading: boolean;
  timezone?: string;
  empty?: React.ReactElement;
};

export const RefundChargebackReportDatagrid: React.FC<Props> = ({
  total,
  isLoading,
  empty,
  orders = [],
  timezone = DEFAULT_TIMEZONE,
}) => {
  const { hasResourceAccess } = usePermissions();
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();
  const {
    record: commission,
    handleOpenRecordModal: handleOpenCommissionModal,
    handleCloseRecordModal: handleCloseCommissionModal,
    openRecordModal: openCommissionModal,
  } = useRecordModal<Commission>();

  const { sortedData, sort, setSort } = useLocalDatagrid({
    data: orders,
    defaultSort: {
      field: 'createdAt',
      order: 'ASC',
    },
  });

  const { dataPerPage, ...paginationProps } = useLocalDatagridPagination<Order>({
    data: sortedData,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  return (
    <>
      <DatagridConfigurableStyled
        bulkActionButtons={false}
        data={dataPerPage}
        empty={empty}
        isLoading={isLoading}
        setSort={setSort}
        sort={sort}
        sx={{ width: '100%' }}
      >
        <CopyToClipboardLinkField
          getLink={(record: RaRecord) =>
            `/orders/${encodeURIComponent(convertReactAdminIdToHydraId(record.id, 'orders'))}/show`
          }
          label="Kubera ID"
          sortBy="id"
          source="id"
          target="_blank"
        />
        <DateTimeField label="Создан" source="createdAt" timezone={timezone} />
        <DateTimeField
          emptyText={EMPTY_FIELD}
          label="Помечен возвратом"
          source="updatedAt"
          timezone={timezone}
        />
        <ReferenceField
          cellClassName="column-status"
          label="Статус"
          link={false}
          reference="new/order_statuses"
          source="status.id"
        >
          <OrderStatusChipField iconProps={{ size: 'l' }} source="description" />
        </ReferenceField>
        <FunctionField
          label="Движение по балансу"
          render={(record: RefundChargebackReportOrder) =>
            `${record.impactToBalance ? 'Да' : 'Нет'}`
          }
          source="impactToBalance"
        />
        <NumberField
          label="Сумма возврата"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          resource="orders"
          source="amount"
        />
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            cellClassName="column-currency"
            label="Валюта"
            link={false}
            reference="currencies"
            resource="currencies"
            sortable={false}
            source="currency.id"
            target="id"
          >
            <CurrencyField alphaCodeSource="alphaCode" nameSource="name" />
          </ReferenceOneField>
        )}
        <RefundChargebackReportCommissionAmountField
          label="Комиссия по возврату"
          source="commissionValueByCurrencyOrder"
        />
        <RefundChargebackReportCommissionAmountField
          label="Комиссия по правилу"
          source="commissionValueByCurrencyCommission"
        />
        {hasResourceAccess('commissions') && (
          <ReferenceOneField
            cellClassName="column-commission"
            emptyText={EMPTY_FIELD}
            label="Правило, %"
            link={false}
            reference="commissions"
            resource="commissions"
            sortable={false}
            source="commission.id"
            target="id"
          >
            <RefundChargebackReportRuleField
              label="Правило, %"
              onClick={handleOpenCommissionModal}
              source="commission"
            />
          </ReferenceOneField>
        )}
        <RefundChargebackReportTotalAmountField label="Итого по возврату" source="totalAmount" />
        <ReferenceOneField
          cellClassName="column-merchant"
          label="Мерчант"
          link={false}
          reference="merchants"
          resource="orders"
          sortable={false}
          source="merchant.id"
          target="id"
        >
          <ReferenceLink type="show">
            <ChipField source="name" />
          </ReferenceLink>
        </ReferenceOneField>
        <ReferenceOneField
          cellClassName="column-merchantAccount"
          label="Счет мерчанта"
          link={false}
          reference="merchant_accounts"
          resource="merchant_accounts"
          sortable={false}
          source="merchantAccount.id"
          target="id"
        >
          <FunctionField
            render={(record: MerchantAccount) => (
              <Typography
                color="secondary"
                onClick={() => handleOpenMerchantAccountModal(record)}
                sx={{
                  color: appColors.primary.main,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                variant="body2"
              >
                {record?.name}
              </Typography>
            )}
          />
        </ReferenceOneField>
        <ReferenceOneField
          cellClassName="column-gateway"
          label="Шлюз"
          link={false}
          reference="gateways"
          resource="orders"
          sortable={false}
          source="gateway.id"
          target="id"
        >
          <TextField source="name" />
        </ReferenceOneField>
        <ReferenceOneField
          emptyText={EMPTY_FIELD}
          label="Метод"
          link={false}
          reference="methods"
          resource="methods"
          sortable={false}
          source="method"
          target="id"
        >
          <FunctionField
            render={(record: Method) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {hasResourceAccess('method_logos') && (
                  <ReferenceOneField reference="method_logos" source="logo" target="id">
                    <ImageFieldStyled source="filePath" />
                  </ReferenceOneField>
                )}
                <Typography variant="body2">{record?.name}</Typography>
              </Box>
            )}
          />
        </ReferenceOneField>
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Мерчант ID"
          source="merchantReference"
        />
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Провайдер ID"
          source="providerReferenceId"
        />
      </DatagridConfigurableStyled>
      {total > 0 && (
        <TablePagination
          count={total}
          sx={{ display: 'block', maxHeight: '52px' }}
          {...paginationProps}
        />
      )}
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
      {commission && (
        <CommissionShowDialog
          commission={commission}
          onClose={handleCloseCommissionModal}
          open={openCommissionModal}
        />
      )}
    </>
  );
};
