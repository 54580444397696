import { ExportTransportTypes } from 'constants/exportFormats';

import { AxiosError, AxiosResponse } from 'axios';
import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';
import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { RefundChargebackReportListFilterValues } from 'components/Finance/RefundChargebackReport/RefundChargebackReportFilter/RefundChargebackReportFilter';
import { GatewayEditFormValues } from 'components/Gateways/GatewaysEdit/hooks/useGatewayEditForm';
import { MerchantSpacesFilter } from 'components/MerchantSpaces/MerchantSpacesList/MerchantSpacesList';
import { MerchantSpacesUsersFilter } from 'components/MerchantSpacesUsers/MerchantSpacesUsersList/MerchantSpacesUsersList';
import { OrderAdjustmentsFormValues } from 'components/OrderAdjustments/hooks/useOrderAdjustmentsForm';
import { OrdersListFilterFormValues } from 'components/Orders/OrdersListNew/OrderListContent';
import { ProviderReportsListFilterType } from 'components/ProviderReports/ProviderReportsListFilter';
import qs from 'qs';
import { Identifier } from 'react-admin';
import { UserEntityPermissionsNames } from 'shared/widgets/UserEntityPermissionsDialog/constants/userEntityPermissionsNames';
import { RefundChargebackReport } from 'types/refundChagrebackReport';
import { TransactionsReportSummary } from 'types/transactionsReportSummary';
import { removeActivePrefix } from 'utils/removeActivePrefix';
import { removeKeys } from 'utils/removeKyes';

import { axios, axiosCSV, axiosMultipart, axiosUnauthorized } from './axios';
import { ConditionGroup } from '../components/GatewayRules/components/GatewayRulesForm/GatewayRulesForm';
import { OrderMethodsListFilter } from '../components/OrderMethods/OrderMethodsList';
import { UNLIMITED_PAGINATION_PARAMS } from '../constants';
import { BankOperationStatusesNew } from '../constants/bankOperationStatusesNew';
import { CommissionStatuses } from '../constants/commissionStatuses';
import { NotificationTypes } from '../constants/notifications';
import {
  ExpressionParam,
  ExpressionParamWithData,
  MerchantSpacesUsers,
  MerchantSpaces,
  MerchantSpacesUsersSite,
  OrderDispute,
  OrdersBulkUpdateData,
  OrdersBulkUpdateResponse,
  ProviderReportResponse,
  RestorePasswordResponse,
  Site,
  User,
  BalanceReport,
  RoleOrderStatus,
  OrderStatus,
  CumulativeReport,
  CumulativeReportSummary,
  RoleInfo,
  Notification,
  MerchantPayoutControl,
  Gateway,
  ErrorData,
  MerchantNotification,
  NotificationTransport,
  CreateOrderAdjustmentResponse,
  FileData,
} from '../types';
import { GatewayConversionSetting } from '../types/gatewayConversionSetting';
import { ConversionItem, GatewayConversionListItem, TotalOrders } from '../types/orderConversion';
import { UserAction } from '../types/userAction';
import { getId } from '../utils';

export type ChangeMappingActivityResponse = { id: number; active: boolean };

export type GatewaySubmitFormValues =
  | GatewayEditFormValues
  | {
      attemptIntervalConfig?: number[];
    };

export const sendLogo = async (file: FormData): Promise<AxiosResponse> => {
  return axiosMultipart
    .post('method_logos', file)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const editLogo = async (id: string, file: FormData): Promise<void> => {
  return axiosMultipart
    .post(`method_logos/${id}`, file)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const ordersBulkUpdate = async (
  data: OrdersBulkUpdateData,
): Promise<OrdersBulkUpdateResponse> => {
  return axios
    .patch('orders/bulk_update', data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getUserInfo = async (): Promise<User> => {
  return axios
    .get('profile/me')
    .then((response: AxiosResponse) => ({
      ...response.data,
      corporateEmail: response.data.corporate_email,
    }))
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getConditionGroups = async (id: string): Promise<ConditionGroup> => {
  return axios
    .get(`condition_groups/${getId(id)}`, {
      headers: {
        Accept: 'application/ld+json',
      },
    })
    .then(({ data }: AxiosResponse) => {
      // ToDo при удалении флага wppa-4764-enum-expression удалить эту функция и возвращать просто data
      // на время удаления флага бэкенд еще присылает параметр comparisonOperatorNew. Поэтому на данном этапе выпилить нельзя.
      // проверить позже и удалить.
      const filteredFormValues = removeKeys(data, ['comparisonOperatorNew']);

      return filteredFormValues as any;
    })
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getExpressionParams = async (): Promise<ExpressionParam[]> => {
  return axios
    .get('expression_params')
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getExpressionParamWithData = async (
  expressionAlias: string,
): Promise<ExpressionParamWithData> => {
  return axios
    .get(`new/expression_param_with_data/${expressionAlias}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getOrdersCSV = async (
  filters?: string,
  exportFields?: string,
): Promise<AxiosResponse> => {
  let fields =
    exportFields ??
    'fields[]=amount&fields[]=currency&fields[]=status&fields[]=type' +
      '&fields[]=merchantReference&fields[]=customer&fields[]=method&fields[]=gateway&fields[]=merchant&fields[]=account' +
      '&fields[]=createdAt&fields[]=merchantAccount&fields[]=transactions&fields[]=orderErrorReason&fields[]=providerReferenceId';
  if (filters) {
    fields += filters;
  }

  return axios
    .get(`orders/export?${fields}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const orderAccept = async (id: string): Promise<void> => {
  return axios
    .post(`orders/${id}/pilot_mode/accept`, {})
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const orderDecline = async (id: string): Promise<void> => {
  return axios
    .post(`orders/${id}/pilot_mode/decline`, {})
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const sendRestorePasswordLink = async (
  reddyNumber: string,
): Promise<RestorePasswordResponse> => {
  return axiosUnauthorized
    .post('restore_password', { reddyNumber })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const sendNewPassword = async (
  token: string,
  password: string,
): Promise<RestorePasswordResponse> => {
  return axiosUnauthorized
    .post('new_password', { token, password })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const saveUsersProviderAccounts = async (
  userId: string,
  providerAccounts: string[],
): Promise<AxiosResponse> => {
  return axios
    .put(`users/${getId(userId)}`, { providerAccounts: providerAccounts })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const activateUsers = async (userIds?: Identifier[]): Promise<AxiosResponse> => {
  return axios
    .put('users/activate/multiple', { userIds })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deactivateUsers = async (userIds?: Identifier[]): Promise<AxiosResponse> => {
  return axios
    .put('users/deactivate/multiple', { userIds })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const sendOrderNotification = async (id: string): Promise<AxiosResponse> => {
  return axios
    .post(`order_notify/${getId(id)}`, {})
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deleteRecord = async (id: string): Promise<AxiosResponse> => {
  return axiosUnauthorized
    .delete(id)
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getOrderMethods = async (filter: OrderMethodsListFilter): Promise<any> => {
  const params = qs.stringify(
    {
      country: filter?.country,
      currency: filter.currency,
      direction: filter?.direction,
      customerId: filter?.customerId,
      merchantId: getId(filter?.merchantId),
      language: filter?.language,
      countDeposit: filter?.countDeposit,
      appVersion: filter?.appVersion,
      domain: filter?.domain,
    },
    { arrayFormat: 'indices', encode: false },
  );

  return axios
    .get(`order_methods?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const createDispute = async (form: Partial<OrderDispute>): Promise<AxiosResponse> => {
  return axios
    .post('order_disputes', form)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const updateDispute = async (
  id: string,
  form: Partial<OrderDispute>,
): Promise<AxiosResponse> => {
  return axios
    .patch(`order_disputes/${getId(id)}`, form)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const archiveCompany = async (id: string, archive: boolean): Promise<AxiosResponse> =>
  axios
    .patch(
      `/companies/${getId(id)}/archive`,
      { archive: archive },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));

export const updateProviderAccountWorkTeam = async (
  id: string,
  workTeam?: string,
): Promise<AxiosResponse> =>
  axios
    .patch(
      `provider_account_news/${getId(id)}/work_team`,
      { workTeam },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));

export const updateProviderAccountWorkTeamUsers = async (
  id: string,
  form: { workTeam?: string; members?: string[] },
): Promise<AxiosResponse> =>
  axios
    .patch(`provider_account_news/${getId(id)}/members`, form, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));

export const generateAlias = async (name: string): Promise<AxiosResponse> => {
  return axios
    .get('/utils/alias?originalText=' + name)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const generateName = async (
  entity: string,
  nameParams: Record<string, any>,
): Promise<AxiosResponse> => {
  const queryParams = qs.stringify(
    {
      ...nameParams,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`/utils/${entity}/name?${queryParams}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getUserActions = async (entity: string, id: string): Promise<UserAction[]> => {
  return axios
    .get(`user_action/${entity}/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getUserAction = async (id: string): Promise<UserAction> => {
  return axios
    .get(`user_actions/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getRoleInfo = async (id: string): Promise<RoleInfo> => {
  return axios
    .get(`roles/${id}`, {
      headers: {
        Accept: 'application/ld+json',
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getMerchantPayoutControls = async (id: string): Promise<MerchantPayoutControl> => {
  return axios
    .get(`merchants/${id}/merchant_payout_controls`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const deleteMerchantPayoutControls = async (id: number): Promise<AxiosResponse> => {
  return axios
    .delete(`merchant_payout_controls/${id}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const createMerchantPayoutControls = async (
  merchant: string,
): Promise<AxiosResponse<MerchantPayoutControl>> => {
  const response = await axios.post('merchant_payout_controls', { merchant });

  return response.data;
};

export const merchantPayoutControlsUpdateActivity = async (
  active: boolean,
  id: number,
): Promise<AxiosResponse<{ active: boolean }>> => {
  return axios
    .patch(
      `merchant_payout_controls/${id}/active`,
      { active },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

type MerchantPayoutControlsUpdateMappingType = {
  payoutControlId: number;
  mappingId?: string | null;
};

export const merchantPayoutControlsUpdateMapping = async ({
  payoutControlId,
  mappingId = null,
}: MerchantPayoutControlsUpdateMappingType): Promise<AxiosResponse<{ mapping: string }>> => {
  return axios
    .patch(
      `merchant_payout_controls/${payoutControlId}/mappings`,
      { mapping: mappingId },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const merchantPayoutControlsCustomersUpdate = async (
  payoutControlId: number,
  customers: string[],
): Promise<AxiosResponse<{ customers: string[] }>> => {
  return axios
    .patch(
      `merchant_payout_controls/${payoutControlId}/customers`,
      { customers },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const merchantPayoutControlsCustomersCreate = async (
  merchantPayoutControl: number,
  merchant: string,
  customerId: string,
): Promise<AxiosResponse<{ customerId: string }>> => {
  return axios
    .post(
      'customers',
      {
        isTest: true,
        customerId,
        merchant,
        merchantPayoutControl: `admin/merchant_payout_controls/${merchantPayoutControl}`,
      },
      {
        headers: {
          'content-type': 'application/ld+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const merchantPayoutControlsCustomersDelete = async (
  payoutControlId: number,
  customerId?: number,
): Promise<AxiosResponse<{ customers: string[] }>> => {
  return axios
    .delete(`merchant_payout_controls/${payoutControlId}/customers/${customerId}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const generateNotificationKeyRequest = async (): Promise<AxiosResponse> => {
  return axios
    .get('/generate/merchant_key')
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const changeMappingActivity = async (
  id: Identifier,
  active: boolean,
): Promise<AxiosResponse<ChangeMappingActivityResponse>> => {
  return axios
    .patch(
      `mappings/${id}/change_activity`,
      { active },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError<{ detail: string }>) =>
      Promise.reject(removeActivePrefix(error.response?.data.detail)),
    );
};

export const getMerchantsCSV = async (): Promise<string> => {
  return axiosCSV
    .get('merchants/export')
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getProviderReportsList = async (
  filter: ProviderReportsListFilterType,
): Promise<ProviderReportResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );

  return axios
    .get(`report/providers?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getProvidersReportCSV = async (filter: any): Promise<string> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );

  return axiosCSV
    .get(`report/providers/export?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type MerchantSpacesUsersResponse = { items: MerchantSpacesUsers[]; totalItems: number };

export const getMerchantSpacesUsersList = async (
  filter: MerchantSpacesUsersFilter = {} as MerchantSpacesUsersFilter,
): Promise<MerchantSpacesUsersResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );

  return axios
    .get(`merchant_spaces_users?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type MerchantSpaceResponse = { items: MerchantSpaces[]; totalItems: number };

export const getMerchantSpacesList = async (
  filter: MerchantSpacesFilter = {} as MerchantSpacesFilter,
): Promise<MerchantSpaceResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );

  return axios
    .get(`merchant_spaces?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getOrdersReportExport = async (filters?: any): Promise<AxiosResponse> => {
  const params = qs.stringify(
    {
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`orders/report/export?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type CreateMerchantSpaceParams = {
  spaceName: string;
  sourceCompanyId: string;
  active: boolean;
  sites: string[];
};

export const createMerchantSpaceRequest = async (
  data: CreateMerchantSpaceParams,
): Promise<AxiosResponse> => {
  return axios
    .post('merchant_spaces', data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type ChangeMerchantSpaceActivityParams = {
  id: Identifier;
  active: boolean;
  sourceCompanyId: string;
  spaceName: string;
};

export const changeMerchantSpaceActivity = async (
  data: ChangeMerchantSpaceActivityParams,
): Promise<AxiosResponse> => {
  return axios
    .patch(`merchant_spaces/${getId(data.id)}`, {
      ...data,
      id: getId(data.id),
      sourceCompanyId: getId(data.sourceCompanyId),
    })
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type EditMerchantSpaceParams = {
  spaceName: string;
  sourceCompanyId: string;
  active: boolean;
  sites: string[];
};

export const editMerchantSpaceRequest = async (
  id: number,
  data: EditMerchantSpaceParams,
): Promise<AxiosResponse> => {
  return axios
    .patch(`merchant_spaces/${getId(id)}`, data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getMerchantSpacesRequest = async (id: string): Promise<MerchantSpaces> => {
  return axios
    .get(`merchant_spaces/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const changeMerchantSpacesUserActivity = async (
  id: Identifier,
  active: boolean,
): Promise<AxiosResponse> => {
  return axios
    .patch(`merchant_spaces_users/${getId(id)}/active`, { active })
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type CreateMerchantUserInvitationParams = {
  users?: number[];
};

export const createMerchantUserInvitation = async (
  data: CreateMerchantUserInvitationParams,
): Promise<AxiosResponse> => {
  return axios
    .post('merchant_spaces_invitation', data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};
export const getMerchantSpacesUsersRoles = async (): Promise<string[]> => {
  return axios
    .get('merchant_spaces_users_roles')
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export type CreateMerchantSpacesUsersParams = {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  merchantSpaceId: number;
  email: string;
  phoneNumber: string;
  active: boolean;
  sites?: number[];
};

export const createMerchantSpacesUsersRequest = async (
  data: CreateMerchantSpacesUsersParams,
): Promise<AxiosResponse> => {
  return axios
    .post('merchant_spaces_users', data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type EditMerchantSpacesUsersParams = {
  firstName: string;
  lastName: string;
  role: string;
  password: string;
  merchantSpaceId: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  sites?: number[];
};

export const editMerchantSpacesUsersRequest = async (
  id: string,
  data: EditMerchantSpacesUsersParams,
): Promise<AxiosResponse> => {
  return axios
    .patch(`merchant_spaces_users/${getId(id)}`, data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getMerchantSpacesUsersRequest = async (id: string): Promise<MerchantSpacesUsers> => {
  return axios
    .get(`merchant_spaces_users/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getMerchantSpacesSitesRequest = async (id: number): Promise<Site> => {
  return axios
    .get(`merchant_spaces_sites/${id}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getMerchantsSpaceUsersSites = async (
  id: string,
): Promise<MerchantSpacesUsersSite[]> => {
  return axios
    .get(`merchant_spaces_sites/user/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export type CreateMerchantsSpaceUsersSitesParams = {
  sites: number[];
};

export const createMerchantsSpaceUsersSitesRequest = async (
  id: string,
  data: CreateMerchantsSpaceUsersSitesParams,
): Promise<AxiosResponse> => {
  return axios
    .patch(`merchant_spaces_sites/user/${getId(id)}`, data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getBalanceReport = async (
  filter: BalanceReportFilterValues,
): Promise<BalanceReport> => {
  const params = qs.stringify(
    {
      currency: filter.currency ? getId(filter.currency) : 1,
      conversionRateType: filter.conversionRateType,
      periodFrom: filter.periodFrom,
      periodTo: filter.periodTo,
      companies: filter.companies,
      merchants: filter.merchants,
      providers: filter.providers,
      merchantAccounts: filter.merchantAccounts,
      timezone: filter.timezone,
    },
    { arrayFormat: 'indices', encode: false },
  );
  return axios
    .get(`balance_report?${params}`, {
      headers: {
        Accept: 'application/ld+json',
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getBalanceReportExport = async (
  filters?: BalanceReportFilterValues,
): Promise<AxiosResponse> => {
  const params = qs.stringify(
    {
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`balance_report_export?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

type RoleOrderStatusResponse = { data: { statuses: RoleOrderStatus[] } };
export const getRoleOrderStatuses = async (): Promise<RoleOrderStatusResponse> => {
  const params = qs.stringify(
    {
      ...UNLIMITED_PAGINATION_PARAMS,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`new/role_order_statuses?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export const getOrderStatuses = async (): Promise<OrderStatus[]> => {
  const params = qs.stringify(
    {
      ...UNLIMITED_PAGINATION_PARAMS,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`new/order_statuses?${params}`, {
      headers: {
        Accept: 'application/ld+json',
      },
    })
    .then((response: AxiosResponse) => response.data['hydra:member'])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export type CumulativeReportResponse = {
  reportItems: CumulativeReport[];
  accountsSummaryItems: CumulativeReportSummary[];
};

export const getCumulativeReport = async (
  filter: CumulativeReportFilterValuesNew = {} as CumulativeReportFilterValuesNew,
): Promise<CumulativeReportResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`v2/cumulative_report?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getCumulativeReportExport = async (
  filters?: CumulativeReportFilterValuesNew,
): Promise<AxiosResponse> => {
  const params = qs.stringify(
    {
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`cumulative_report_export?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getReportExport = async (
  filters?: Record<string, any>,
  selectedFields?: string[],
): Promise<AxiosResponse> => {
  const params = qs.stringify(
    {
      fields: selectedFields,
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );

  return axios
    .get(`report_export?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getEntitiesList = async (): Promise<AxiosResponse<Record<string, string>>> => {
  return axios
    .get('user_action/entities')
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type NotificationsRequestParams = {
  createdAt: {
    gte: number;
    lte: number;
  };
  'transports.transport': ExportTransportTypes;
  notificationType: NotificationTypes[];
};
export type NotificationsResponse = Notification[];

export const getNotifications = async (
  filter: NotificationsRequestParams,
): Promise<NotificationsResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`notifications?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const updateNotificationsViewedStatus = async (ids?: string[]): Promise<AxiosResponse> => {
  return axios
    .patch('notifications/is_viewed', { ids })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.message));
};

export type NotificationsCountRequestParams = {
  createdAtFrom: number;
  createdAtTo: number;
  transport: ExportTransportTypes;
  isViewed: number;
};
export type NotificationsCountResponse = { count: string };

export const getNotificationsCount = async (
  filter: NotificationsCountRequestParams,
): Promise<NotificationsCountResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true, allowDots: true },
  );
  return axios
    .get(`notifications/count?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getJsonSchema = async (id: string): Promise<any> => {
  return axios
    .get(`user_actions/${id}/json`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type GetMerchantNotificationsResponse = {
  merchantNotifications: MerchantNotification[];
  totalItems: number;
};

export const sendTestMerchantNotifications = async (
  notificationId: string,
): Promise<AxiosResponse> => {
  return axios
    .get(`merchant_notification_settings/${notificationId}/test`, {
      headers: {
        Accept: '*/*',
      },
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type MerchantNotificationsFilters = {
  page?: number;
  itemsPerPage?: number;
  'merchant.id'?: string;
};

export const getMerchantNotifications = async (
  filters?: MerchantNotificationsFilters,
): Promise<GetMerchantNotificationsResponse> => {
  const params = qs.stringify(
    {
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`merchant_notification_settings${params ? '?' + params : ''}`, {
      headers: {
        Accept: '*/*',
      },
    })
    .then((response: AxiosResponse) => {
      return {
        merchantNotifications: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      };
    })
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getNotificationTransports = async (): Promise<NotificationTransport[]> => {
  return axios
    .get('notification_transports', {
      headers: {
        Accept: '*/*',
      },
    })
    .then((response: AxiosResponse) => {
      return response.data['hydra:member'];
    })
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type MerchantNotificationsEventNamesType = {
  '@id': string;
  description: string;
  label: string;
  name: string;
  value: string;
};

export const getMerchantNotificationsEventNames = async (): Promise<
  MerchantNotificationsEventNamesType[]
> => {
  return axios
    .get('merchant_notification_event_names', {
      headers: {
        Accept: '*/*',
      },
    })
    .then((response: AxiosResponse) => {
      return response.data['hydra:member'];
    })
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deleteGatewayRules = async (id: string): Promise<AxiosResponse> => {
  return axios
    .delete(`gateway_rules/${getId(id)}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError<{ detail: string }>) =>
      Promise.reject(removeActivePrefix(error.response?.data.detail)),
    );
};

export const updateGatewayRuleActive = async (
  id: string,
  active: boolean,
): Promise<AxiosResponse> => {
  return axios
    .put(`gateway_rules/${getId(id)}`, { active })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError<{ detail: string }>) =>
      Promise.reject(removeActivePrefix(error.response?.data.detail)),
    );
};

export const getUserEntityPermissions = async (filter: {
  entity: UserEntityPermissionsNames;
  rowId: number;
}): Promise<any> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`user_entity_permissions?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deleteUserEntityPermissions = async (id: number): Promise<AxiosResponse> => {
  return axios
    .delete(`user_entity_permissions/${id}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const createGateway = async (data: GatewaySubmitFormValues) => {
  return axios
    .post<Gateway>('gateways', data)
    .then((response: AxiosResponse<Gateway>) => response.data)
    .catch((error: AxiosError<{ data: { violations: ErrorData[] } }>) =>
      Promise.reject(error.response?.data),
    );
};

export const createMerchantAccesses = async (
  merchant: number | undefined,
  users: number[],
): Promise<AxiosResponse> => {
  return axios
    .post('merchant_accesses/bulk/create', {
      merchant,
      users,
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deleteMerchantAccesses = async (
  merchant: number | undefined,
  users: number[],
): Promise<AxiosResponse> => {
  return axios
    .delete('merchant_accesses/bulk/delete', {
      data: {
        merchant,
        users,
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const archiveMerchant = async (id: string, archive: boolean): Promise<AxiosResponse> =>
  axios
    .patch(
      `/merchants/${getId(id)}/archive`,
      { archive: archive },
      {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      },
    )
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));

export type OrderConversionRequestParams = {
  period: string;
  detailed?: boolean;
  merchantId: string | null | undefined;
  providerIds?: string[];
  gatewayIds?: string[];
  direction: string | null;
};

export type OrderConversionResponse = {
  summaryConversionItems: ConversionItem[];
  totalOrders: TotalOrders;
  gatewayConversionList: GatewayConversionListItem[];
};

export const getOrderConversion = async (
  filter: OrderConversionRequestParams,
): Promise<OrderConversionResponse> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true, allowDots: true },
  );
  return axios
    .get(`monitoring/order_conversion?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getGatewayConversionSettings = async (
  id: number,
): Promise<GatewayConversionSetting> => {
  return axios
    .get(`gateway_conversion_settings/${id}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type CreateGatewayConversionSettingsParams = {
  merchant: string;
  gateway: string;
  successDeviation: string;
  cancelDeviation: string;
  pendingDeviation: string;
  minOrdersCount: number;
  notify: boolean;
};

export const createGatewayConversionSettings = async (
  data: CreateGatewayConversionSettingsParams,
): Promise<AxiosResponse> => {
  return axios
    .post('gateway_conversion_settings', data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError<{ detail: string }>) => Promise.reject(error));
};

export type UpdateGatewayConversionSettingsParams = {
  successDeviation: string;
  cancelDeviation: string;
  pendingDeviation: string;
  minOrdersCount: number;
  notify: boolean;
};

export const updateGatewayConversionSettings = async (
  id: number,
  data: UpdateGatewayConversionSettingsParams,
): Promise<AxiosResponse> => {
  return axios
    .put(`gateway_conversion_settings/${id}`, data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type UpdateGatewayConversionSettingsNotifyParams = {
  notify: boolean;
};

export const updateGatewayConversionSettingsNotify = async (
  id: number,
  data: UpdateGatewayConversionSettingsNotifyParams,
): Promise<AxiosResponse> => {
  return axios
    .patch(`gateway_conversion_settings/${id}/notify`, data, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const deleteGatewayConversionSettings = async (id: number): Promise<AxiosResponse> => {
  return axios
    .delete(`gateway_conversion_settings/${id}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const createOrderAdjustments = async (
  data: OrderAdjustmentsFormValues,
): Promise<CreateOrderAdjustmentResponse> => {
  return axios
    .post('order_adjustment/bulk/create', data)
    .then((response: AxiosResponse<CreateOrderAdjustmentResponse>) => response.data)
    .catch((error: AxiosError<{ data: ErrorData }>) => Promise.reject(error.response?.data));
};

export const sendCommissionsFile = async (id: string, file: FormData): Promise<AxiosResponse> => {
  return axiosMultipart
    .post(`commission/${id}/upload-file`, file)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getCommissionsAvailableActions = async (id: number): Promise<CommissionStatuses[]> => {
  return axios
    .get(`commissions/${id}/available-status-list`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type UpdateCommissionsStatusParams = {
  status: CommissionStatuses;
  comment?: string;
};

export const updateCommissionsStatus = async (
  id: string,
  data: UpdateCommissionsStatusParams,
): Promise<CommissionStatuses[]> => {
  return axios
    .patch(`commissions/${id}/status`, data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const bulkDeleteBankOperation = async (ids: number[]): Promise<AxiosResponse> => {
  return axios
    .delete('/bank_operations/bulk/delete', { data: { ids } })
    .then((response: AxiosResponse) => response?.data)
    .catch((error: AxiosError<{ data: ErrorData }>) => Promise.reject(error.response?.data));
};

export const getBankOperationsAvailableActions = async (
  id: number,
): Promise<BankOperationStatusesNew[]> => {
  return axios
    .get(`bank_operations/${id}/available`)
    .then((response: AxiosResponse) => response.data.available)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export type UpdateBankOperationsStatusParams = {
  status: BankOperationStatusesNew;
  comment?: string;
};
export const updateBankOperationStatus = async (
  id: string,
  data: UpdateBankOperationsStatusParams,
): Promise<CommissionStatuses[]> => {
  return axios
    .patch(`bank_operations/${id}/status`, data, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getFilesList = async (entityName: string, entityId: number): Promise<FileData[]> => {
  return axios
    .get('upload_files', {
      params: {
        entityName,
        entityId,
      },
      headers: {
        Accept: 'application/ld+json',
      },
    })
    .then((response: AxiosResponse) => response.data['hydra:member'])
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const sendFile = async (file: FormData): Promise<AxiosResponse> => {
  return axiosMultipart
    .post('upload_files', file)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getRefundChargebackReport = async (
  filter: RefundChargebackReportListFilterValues = {} as RefundChargebackReportListFilterValues,
): Promise<RefundChargebackReport> => {
  const params = qs.stringify(
    {
      ...filter,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`refund_chargeback_report?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getCounter = async (resource: string): Promise<{ count: number }> => {
  return axios
    .get(resource)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};

export const getTransactionsReportSummary = async (
  filters: OrdersListFilterFormValues,
): Promise<TransactionsReportSummary> => {
  const params = qs.stringify(
    {
      ...filters,
    },
    { arrayFormat: 'indices', encode: true },
  );
  return axios
    .get(`transactions_report_summary?${params}`)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error.response));
};
