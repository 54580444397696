import { useState } from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { WithListContext } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CreateButton } from 'shared/mui/Buttons';
import { Link } from 'shared/mui/Link/Link';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  DateTimeField,
  FunctionField,
  SimpleCurrencyField,
  TextField,
} from 'shared/react-admin/Fields';
import { CurrencyField } from 'shared/react-admin/Fields/CurrencyField/CurrencyField';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';

import { DatagridStyled } from './MerchantsShowLimits.styled';
import { MerchantsShowLimitsCreateDialog } from './MerchantsShowLimitsCreateDialog/MerchantsShowLimitsCreateDialog';
import { MerchantsShowLimitsDeleteDialog } from './MerchantsShowLimitsDeleteDialog/MerchantsShowLimitsDeleteDialog';
import { MerchantsShowLimitsEditDialog } from './MerchantsShowLimitsEditDialog/MerchantsShowLimitsEditDialog';
import { MerchantsShowLimitsFilters } from './MerchantsShowLimitsFilters/MerchantsShowLimitsFilters';
import { MerchantsShowLimitsShowDialog } from './MerchantsShowLimitsShowDialog/MerchantsShowLimitsShowDialog';
import { EMPTY_FIELD } from '../../../../constants';
import { Limit, Permission } from '../../../../types';
import { getId } from '../../../../utils';

type Props = {
  merchantId?: string;
  merchantName?: string;
};

export const MerchantsShowLimits: React.FC<Props> = (props) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const {
    record: recordShowDialog,
    handleCloseRecordModal: handleCloseShowDialog,
    openRecordModal: openShowDialog,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<Limit>();

  const {
    record: recordDeleteDialog,
    handleCloseRecordModal: handleCloseDeleteDialog,
    openRecordModal: openDeleteDialog,
    handleOpenRecordModal: handleOpenDeleteDialog,
  } = useRecordModal<Limit>();

  const {
    record: recordEditDialog,
    handleCloseRecordModal: handleCloseEditDialog,
    openRecordModal: openEditDialog,
    handleOpenRecordModal: handleOpenEditDialog,
  } = useRecordModal<Limit>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('limits', [Permission.Full, Permission.Create]) && (
          <CreateButton
            key="create-limits"
            onClick={(e) => {
              e.preventDefault();
              setOpenCreateDialog(true);
            }}
            sx={{ marginTop: '8px', padding: '3px 16px' }}
          />
        ),
      ]}
      empty={false}
      filter={{ merchant: [props.merchantId] }}
      headerTitle={{
        titleText: 'Лимиты',
        titleSize: 'small',
      }}
      resource="limits"
      sort={{ field: 'id', order: 'DESC' }}
      sx={{ marginTop: 0, marginBottom: 2 }}
    >
      <WithListContext
        render={({ data, isLoading, filterValues }) => {
          const showAlert = !data?.length && !isLoading && !Object.keys(filterValues).length;

          if (showAlert) {
            return (
              <Alert severity="info">Мерчанту {props.merchantName} не добавлены лимиты.</Alert>
            );
          }

          return (
            <>
              <MerchantsShowLimitsFilters merchantId={props.merchantId} />
              <DatagridStyled bulkActionButtons={false}>
                <FunctionField
                  label="ID"
                  render={(record: Limit) => (
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenShowDialog(record);
                      }}
                    >
                      {getId(record?.originId)}
                    </Link>
                  )}
                  sortBy="id"
                  source="originId"
                />
                {hasResourceAccess('gateways') && (
                  <ReferenceOneField
                    label="Шлюз"
                    reference="gateways"
                    sortBy="gateway.name"
                    source="gateway"
                    target="id"
                  >
                    <TextField source="name" />
                  </ReferenceOneField>
                )}
                {hasResourceAccess('currencies') && (
                  <ReferenceOneField
                    label="Валюта"
                    reference="currencies"
                    sortBy="currency.name"
                    source="currency"
                    target="id"
                  >
                    <CurrencyField alphaCodeSource="alphaCode" nameSource="name" />
                  </ReferenceOneField>
                )}
                <SimpleCurrencyField
                  emptyText={EMPTY_FIELD}
                  label="Мин. сумма пополнения"
                  source="paymentMin"
                />
                <SimpleCurrencyField
                  emptyText={EMPTY_FIELD}
                  label="Макс. сумма пополнения"
                  source="paymentMax"
                />
                <SimpleCurrencyField
                  emptyText={EMPTY_FIELD}
                  label="Мин. сумма вывода"
                  source="payoutMin"
                />
                <SimpleCurrencyField
                  emptyText={EMPTY_FIELD}
                  label="Макс. сумма вывода"
                  source="payoutMax"
                />
                <DateTimeField label="Дата создания" source="createdAt" />
                <DateTimeField label="Дата редактир." source="updatedAt" />
                <DatagridActions
                  render={(record: Limit) => [
                    hasResourceAccess('user_actions') && (
                      <DatagridActionsUserActionsButton
                        entityName="limit"
                        key="user-action-button"
                      />
                    ),
                    <DatagridActionsShowButton
                      key="show-button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenShowDialog(record);
                      }}
                    />,
                    hasResourcePermissions('limits', [Permission.Full, Permission.Update]) && (
                      <DatagridActionsEditButton
                        key="edit-button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenEditDialog(record);
                        }}
                      />
                    ),
                    hasResourcePermissions('limits', [Permission.Full, Permission.Delete]) && (
                      <DatagridActionsDeleteButton
                        key="delete-button"
                        onClick={() => {
                          handleOpenDeleteDialog(record);
                        }}
                      />
                    ),
                  ]}
                />
              </DatagridStyled>
            </>
          );
        }}
      />
      <MerchantsShowLimitsShowDialog
        onClose={handleCloseShowDialog}
        onDelete={handleOpenDeleteDialog}
        onEdit={handleOpenEditDialog}
        open={openShowDialog}
        record={recordShowDialog}
      />
      <MerchantsShowLimitsDeleteDialog
        onClose={handleCloseDeleteDialog}
        open={openDeleteDialog}
        record={recordDeleteDialog}
      />
      <MerchantsShowLimitsCreateDialog
        merchantId={props.merchantId}
        onClose={() => setOpenCreateDialog(false)}
        open={openCreateDialog}
      />
      <MerchantsShowLimitsEditDialog
        merchantId={props.merchantId}
        onClose={handleCloseEditDialog}
        onDelete={handleOpenDeleteDialog}
        open={openEditDialog}
        record={recordEditDialog}
      />
    </ListPage>
  );
};
