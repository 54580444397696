import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { CumulativeReportSummary } from 'types';
import { formatCurrency } from 'utils';

import { EMPTY_FIELD } from '../../../../../constants/emptyField';

type Props = {
  summaryColor?: string;
  valueKey: keyof CumulativeReportSummary;
  originalValueKey: keyof CumulativeReportSummary;
} & Omit<FunctionFieldProps, 'render'>;

export const CumulativeReportSummaryField = ({
  label,
  summaryColor,
  valueKey,
  originalValueKey,
}: Props) => (
  <FunctionField
    label={label}
    render={(record: CumulativeReportSummary) => {
      if (Number(record[valueKey]) === 0) {
        return EMPTY_FIELD;
      }
      const isOriginalCurrency = record.merchantAccountCurrencyCode === record.currencyCode;

      return (
        <Stack direction="column">
          <span style={{ color: summaryColor }}>
            {formatCurrency(Number(record[valueKey]))}
            <Typography component="span" fontWeight={500} variant="body2">
              {' '}
              {record.currencyCode}
            </Typography>
          </span>
          {!isOriginalCurrency && (
            <span style={{ color: appColors.text.disabled }}>
              {formatCurrency(Number(record[originalValueKey]))}
              <Typography component="span" fontWeight={500} variant="body2">
                {' '}
                {record.merchantAccountCurrencyCode}
              </Typography>
            </span>
          )}
        </Stack>
      );
    }}
  />
);
