import { Role } from 'constants/roles';

import { useFormContext } from 'react-hook-form';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const ProviderAccountsFilterForm: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const [company, merchant, provider] = watch(['company', 'merchant', 'provider']);
  return (
    <>
      <ReferenceInput perPage={50} reference="work_teams" source="workTeam">
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Рабочая группа"
          name="workTeam"
          optionText="name"
          size="small"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={{
          'roleEntities.alias': [Role.Finance],
        }}
        perPage={200}
        reference="users"
        source="members"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({
            fullName: searchText,
          })}
          helperText={false}
          label="Участники"
          limitTags={1}
          name="members"
          optionText="name"
          renderChipLabel={(record: User) => `${record.firstName} ${record.lastName}`}
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceInput
        filter={{ exists: { 'merchants.merchantAccounts': true }, archive: false }}
        isFilter={true}
        name="company"
        perPage={200}
        reference="companies"
        source="company"
      >
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Компания"
          name="company"
          onChange={() => {
            setValue('merchant', null);
            setValue('provider', null);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!company}
        filter={{
          companies: company,
          exists: { merchantAccounts: true },
          archive: false,
        }}
        isFilter={true}
        name="merchant"
        perPage={200}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteInputShrink
          disabled={!company}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Мерчант"
          name="merchant"
          onChange={() => {
            setValue('provider', null);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!merchant}
        filter={{ merchantAccounts: { merchants: merchant } }}
        isFilter={true}
        name="provider"
        perPage={200}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          disabled={!merchant}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          onChange={() => {
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant && !!provider}
        filter={{
          merchants: merchant,
          provider: provider,
        }}
        isFilter={true}
        name="merchantAccounts"
        perPage={1000}
        reference="merchant_accounts"
        resource="merchant_accounts"
        source="merchantAccounts"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!provider}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Счета мерчанта"
          limitTags={1}
          name="merchantAccounts"
          optionText="name"
        />
      </ReferenceArrayInput>
    </>
  );
};
