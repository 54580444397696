import React from 'react';

import { getUnixTime } from 'date-fns';
import { ReactComponent as CompletedReport } from 'images/completedReport.svg';
import { Link } from 'react-router-dom';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { ListItemTextStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';
import {
  CompanyBalanceReport,
  MerchantAccountBalanceReport,
  MerchantBalanceReport,
  ProviderBalanceReport,
} from 'types';
import { convertReactAdminIdToHydraId } from 'utils';
import { createFilterQueryString } from 'utils/createFilterQueryString';

type Props = {
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  merchantAccount: MerchantAccountBalanceReport;
  merchant: MerchantBalanceReport;
  provider: ProviderBalanceReport;
  company: CompanyBalanceReport;
};

export const DatagridActionsOrdersReportLink: React.FC<Props> = ({
  merchantAccount,
  provider,
  merchant,
  company,
  appliedDate,
}) => {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      target="_blank"
      to={{
        pathname: '/orders_report',
        search: createFilterQueryString({
          merchantAccount: [convertReactAdminIdToHydraId(merchantAccount.id, 'merchant_accounts')],
          provider: convertReactAdminIdToHydraId(provider.id, 'providers'),
          merchant: convertReactAdminIdToHydraId(merchant.id, 'merchants'),
          company: convertReactAdminIdToHydraId(company.id, 'companies'),
          transactions: {
            createdAt: {
              gte: appliedDate && appliedDate[0] && getUnixTime(appliedDate[0]),
              lte: appliedDate && appliedDate[1] && getUnixTime(appliedDate[1]),
            },
          },
          timezone: appliedDate?.[2] || DEFAULT_TIMEZONE,
        }),
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <CompletedReport />
        </ListItemIcon>
        <ListItemTextStyled primary={'Исполенные транзакции\nза выбранный период'} />
      </MenuItem>
    </Link>
  );
};
