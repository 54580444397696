import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { Filter } from 'shared/mui/FilterNew';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { Currency } from '../../../../../types';
import { cleanEmpty } from '../../../../../utils/cleanEmpty';

type Props = {
  merchantId?: string;
};

type MerchantsShowLimitsFiltersValues = {
  provider: string;
  gateway: string[];
  currency: string[];
};

export const MerchantsShowLimitsFilters: React.FC<Props> = (props) => {
  const { form, resetFilter, onSubmit } = useFilters<MerchantsShowLimitsFiltersValues>({
    provider: '',
    gateway: [],
    currency: [],
  });
  const providerWatched = form.watch('provider');

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow columnsCount={5}>
            <ReferenceInput
              filter={{
                merchantAccounts: {
                  merchants: [props.merchantId],
                },
              }}
              perPage={100}
              reference="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                clearOnBlur={false}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceInput>
            <ReferenceArrayInput
              filter={cleanEmpty({
                provider: providerWatched,
                merchantAccounts: {
                  merchants: [props.merchantId],
                },
              })}
              name="gateway"
              perPage={100}
              reference="gateways"
              source="gateway"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Шлюзы"
                limitTags={1}
                name="gateway"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              filter={{ active: true }}
              name="currency"
              perPage={50}
              reference="currencies"
              source="currency"
            >
              <AutocompleteMultiselectInputShrink
                filterToQuery={(searchText: string) => ({ currency: searchText })}
                helperText={false}
                label="Валюта"
                limitTags={1}
                name="currency"
                optionText="name"
                renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
              />
            </ReferenceArrayInput>
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
