import { getRefundChargebackReport } from 'config/requests';
import { useQuery } from 'react-query';
import { RefundChargebackReport } from 'types/refundChagrebackReport';

import { RefundChargebackReportListFilterValues } from '../RefundChargebackReportFilter/RefundChargebackReportFilter';

type UseGetRefundChargebackReportReturnValue = {
  report?: RefundChargebackReport;
  total: number;
  isLoading: boolean;
};

export function useGetRefundChargebackReport(
  filters: RefundChargebackReportListFilterValues,
): UseGetRefundChargebackReportReturnValue {
  const { data: report, isLoading } = useQuery<RefundChargebackReport>(
    [
      'cumulative_report',
      filters.currency,
      filters.gateway,
      filters.status,
      filters.merchant,
      filters.provider,
      filters.appliedDate,
      filters.updatedDate,
      filters.createdAt,
      filters.updatedAt,
      filters.summary,
      filters.conversionCurrency,
      filters.impactToBalance,
      filters.company,
      filters.updatedDate,
      filters.id,
      filters.merchantReference,
      filters.providerReferenceId,
      filters.transaction,
      filters.timezone,
      filters.merchantAccount,
    ],
    () => getRefundChargebackReport(filters),
    { enabled: Object.keys(filters).length > 0 },
  );
  const total = report?.orders?.length || 0;

  return { report, total, isLoading };
}
