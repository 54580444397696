import * as React from 'react';

import { Card } from '@mui/material';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { useNavigateToRoot } from '../../../hooks/useNavigateToRoot';
import { Commission } from '../../../types';
import { truncateString } from '../../../utils/truncateString';

type Props = {
  record: Commission | undefined;
  onBackClick?: () => void;
} & DialogProps;

export const CommissionsArchivedErrorDialog: React.FC<Props> = ({
  record,
  onBackClick,
  ...restProps
}) => {
  const { toRoot } = useNavigateToRoot();

  const onClose = () => {
    restProps.onClose();
  };

  return (
    <Dialog {...restProps} fullWidth transitionDuration={0}>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Архивирование комиссии"
        />
        <CardContent>
          <Typography>
            Архивировать комиссию {truncateString(record?.name || '', 50)} невозможно, так как она
            связана с настройкой маршрутизации. Отключите настройки комиссии в настройках
            маршрутизации и повторите попытку заново.
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton
              key="back-button"
              label="Вернуться в список"
              onClick={onBackClick || toRoot}
              variant="outlined"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
