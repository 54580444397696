import { Role } from 'constants/roles';

import { useState } from 'react';

import { OrdersReportFilterContextProvider } from 'components/Finance/OrdersReport/context/ordersReportContext';
import { OrdersReportFilterChips } from 'components/Finance/OrdersReport/OrdersReportFilterChips';
import { OrdersReportFilterNew } from 'components/Finance/OrdersReport/OrdersReportFilterNew';
import { OrdersReportFilterFormValues } from 'components/Finance/OrdersReport/OrdersReportFilterNew';
import { OrdersReportListLimitAlert } from 'components/Finance/OrdersReport/OrdersReportList/components/OrdersReportListLimitAlert';
import { OrdersReportListNotFoundOrdersAlert } from 'components/Finance/OrdersReport/OrdersReportList/components/OrdersReportListNotFoundOrdersAlert';
import { OrdersReportLocalDatagrid } from 'components/Finance/OrdersReport/OrdersReportList/components/OrdersReportLocalDatagrid/OrdersReportLocalDatagrid';
import { useApiContext } from 'context/apiContext';
import { getUnixTime, secondsToMilliseconds } from 'date-fns';
import { useGetNotFoundOrderIds } from 'hooks/useGetNotFoundOrderIds';
import { useListContext, WithListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FinanceUserAlert } from 'shared/mui/Alert/FinanceUserAlert/FinanceUserAlert';
import { Box } from 'shared/mui/Box';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterIdButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterIdChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterIdDialog } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialog';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { FilterIdTypes, useIdFilters } from 'shared/mui/NewFilter/hooks/useIdFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { Order } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { OrdersReportPeriodSuccessAlert } from './OrdersReportPeriodSuccessAlert';

type Props = {
  isFiltersApplied: boolean;
  setIsOpenNotifyDialog: (v: boolean) => void;
};

const FILTER_RESET_VALUES: any = {
  appliedDate: [null, null, null],
  transactionsAppliedDate: [null, null, null],
  company: null,
  merchant: null,
  provider: null,
  gateway: [],
  method: [],
  direction: null,
  currency: [],
  timezone: null,
};

export const OrdersReportListContent: React.FC<Props> = ({
  isFiltersApplied,
  setIsOpenNotifyDialog,
}) => {
  const { filterValues, total } = useListContext();
  const [currentIdFilter, setCurrentIdFilter] = useState<FilterIdTypes>(FilterIdTypes.Id);
  const [isOpenIdDialog, setIsOpenIdDialog] = useState<boolean>(false);
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<OrdersReportFilterFormValues>({
      mode: 'all',
      defaultValues: {
        merchant: filterValues?.merchant || null,
        appliedDate: [
          filterValues?.createdAt?.gte
            ? secondsToMilliseconds(Number(filterValues?.createdAt?.gte))
            : null,
          filterValues?.createdAt?.lte
            ? secondsToMilliseconds(Number(filterValues?.createdAt?.lte))
            : null,
          filterValues.timezone || null,
        ],
        transactionsAppliedDate: [
          filterValues?.transactions?.createdAt?.gte
            ? secondsToMilliseconds(Number(filterValues?.transactions?.createdAt?.gte))
            : null,
          filterValues?.transactions?.createdAt?.lte
            ? secondsToMilliseconds(Number(filterValues?.transactions?.createdAt?.lte))
            : null,
          filterValues.timezone || null,
        ],
        company: filterValues?.company || null,
        direction: filterValues?.direction || null,
        gateway: filterValues?.gateway || [],
        provider: filterValues?.provider || null,
        merchantAccount: filterValues?.merchantAccount || [],
        currency: filterValues?.currency || [],
        method: filterValues?.method || [],
      },
      resetValues: FILTER_RESET_VALUES,
    });

  const {
    setAppliedFilter: setAppliedIdFilter,
    onSubmit: onSubmitIds,
    onReset: onResetIds,
    appliedIdFilters,
    form: formId,
  } = useIdFilters({
    defaultValues: {
      merchantReference: filterValues?.merchantReference || [],
      id: filterValues?.id || [],
      providerReferenceId: filterValues?.providerReferenceId || [],
    },
    resetValues: {
      merchantReference: [],
      id: [],
      providerReferenceId: [],
    },
  });

  useSyncFilter<OrdersReportFilterFormValues>({
    appliedFilters,
    appliedIdFilters,
    transform: (appliedFilters) => {
      const {
        appliedDate = [null, null, null],
        transactionsAppliedDate = [null, null, null],
        ...restFilters
      } = appliedFilters;

      return cleanEmpty({
        ...restFilters,
        createdAt: {
          gte: appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
          lte: appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
        },
        transactions: {
          createdAt: {
            gte: transactionsAppliedDate?.[0]
              ? getUnixTime(transactionsAppliedDate?.[0])
              : undefined,
            lte: transactionsAppliedDate?.[1]
              ? getUnixTime(transactionsAppliedDate?.[1])
              : undefined,
          },
        },
        timezone: appliedDate?.[2] || transactionsAppliedDate?.[2] || null,
      });
    },
  });

  const formValuesWatched = form.watch();
  const idsFormValuesWatched = formId.watch();
  const isFormValuesExist = Object.values(cleanEmpty(formValuesWatched)).length > 0;
  const isIdFormValuesExist = Object.values(cleanEmpty(idsFormValuesWatched)).length > 0;

  const { user } = useApiContext();
  const isFinanceUser = user?.roles.includes(Role.Finance);
  const showFinanceUserAlert = total === 0 && isFinanceUser && Object.keys(filterValues).length;

  return (
    <WithListContext
      render={({ isLoading, data: orders, total, isFetching }) => {
        const notFoundIds = appliedIdFilters.id?.filter(
          (id) => id && !orders?.some((order) => order?.originId === id),
        );
        const notFoundMerchantIds = appliedIdFilters.merchantReference?.filter(
          (id) => !orders?.some((order) => order?.merchantReference === id),
        );
        const notFoundProviderIds = appliedIdFilters.providerReferenceId?.filter(
          (id) => !orders?.some((order) => order?.providerReferenceId === id),
        );

        const notFoundOrdersReportIds = useGetNotFoundOrderIds(orders as Order[], filterValues);
        function renderAlert() {
          if (isFetching) {
            return <></>;
          }
          if ((total || 0) >= 1001 && isFiltersApplied) {
            return <OrdersReportListLimitAlert onClickExport={() => setIsOpenNotifyDialog(true)} />;
          }

          if (showFinanceUserAlert) {
            return <FinanceUserAlert userFirstName={user.firstName} userLastName={user.lastName} />;
          }

          return (
            <OrdersReportListNotFoundOrdersAlert
              filterValues={filterValues}
              isLoading={isLoading}
              notFoundOrdersReportIds={notFoundOrdersReportIds}
            />
          );
        }

        function renderPeriodAlert() {
          if (filterValues.createdAt && !isFetching && total !== 0) {
            return <OrdersReportPeriodSuccessAlert filterValues={filterValues} />;
          }
        }

        return (
          <OrdersReportFilterContextProvider value={{ notFoundOrdersReportIds }}>
            <FilterToolbar
              leftActionsSlot={[
                <FilterIdButton
                  disabled={isFormValuesExist}
                  key="filter-by-id"
                  onClick={() => {
                    setIsOpenIdDialog(true);
                  }}
                />,
                <FilterButton
                  disabled={isIdFormValuesExist}
                  key="filter"
                  onClick={toggleDrawer(true)}
                />,
                <SelectColumnButtonFilter key="select-column" />,
                <FilterClearButton
                  key={`clear-${currentIdFilter}`}
                  onClick={() => {
                    onReset();
                    onResetIds(currentIdFilter);
                  }}
                  visible={isFiltersApplied}
                />,
              ]}
            />
            <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FilterDrawerContent>
                    <OrdersReportFilterNew />
                  </FilterDrawerContent>
                  <FilterDrawerActionsButtons>
                    <FilterApplyButton type="submit" />
                    <FilterResetButton label="Очистить" onReset={onReset} />
                  </FilterDrawerActionsButtons>
                </form>
              </FormProvider>
            </FilterDrawer>
            <FilterChipsToolbar appliedFilters={appliedFilters} appliedIdFilters={appliedIdFilters}>
              <FilterContextProvider
                appliedFilters={appliedFilters}
                setAppliedFilter={setAppliedFilter}
              >
                <FormProvider {...form}>
                  <OrdersReportFilterChips />
                </FormProvider>
              </FilterContextProvider>
              <FilterContextProvider
                appliedFilters={appliedIdFilters}
                setAppliedFilter={setAppliedIdFilter}
              >
                <FormProvider {...formId}>
                  <FilterIdChip
                    label="ID Kubera"
                    limitTags={2}
                    name="id"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Id);
                    }}
                  />
                  <FilterIdChip
                    label="ID Мерчантов"
                    limitTags={2}
                    name="merchantReference"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Merchant);
                    }}
                  />
                  <FilterIdChip
                    label="ID Провайдеров"
                    limitTags={2}
                    name="providerReferenceId"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Provider);
                    }}
                  />
                </FormProvider>
              </FilterContextProvider>
            </FilterChipsToolbar>
            <FormProvider {...formId}>
              <FilterIdDialog
                currentIdFilter={currentIdFilter}
                notFoundIds={notFoundIds}
                notFoundMerchantIds={notFoundMerchantIds}
                notFoundProviderIds={notFoundProviderIds}
                onChange={(currentFilter) => setCurrentIdFilter(currentFilter)}
                onClose={() => setIsOpenIdDialog(false)}
                onReset={onResetIds}
                onSubmit={onSubmitIds}
                open={isOpenIdDialog}
              />
            </FormProvider>
            <Box>
              {renderPeriodAlert()}
              {renderAlert()}
            </Box>
            <Box
              sx={{
                minHeight: '100%',
                display: 'initial',
              }}
            >
              <OrdersReportLocalDatagrid
                isIdFormValuesExist={isIdFormValuesExist}
                isLoading={isLoading}
                orders={isFiltersApplied && !isFetching ? (orders as Order[]) || [] : []}
                timezone={filterValues?.timezone}
                toggleDrawer={toggleDrawer(true)}
                total={isFiltersApplied && !isFetching ? total || 0 : 0}
              />
            </Box>
          </OrdersReportFilterContextProvider>
        );
      }}
    />
  );
};
