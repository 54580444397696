import { useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { TableBody, TableCell, TableHead, TableRow } from 'shared/mui/Table';
import { JsonField } from 'shared/react-admin/Fields';

import { StyledBox, StyledJsonBox, StyledTable } from './OrderShowLogsExpand.styled';
import { OrderShowLogsExpandCopyButton } from './OrderShowLogsExpandCopyButton';
import { OrderShowLogsIdField } from '../OrderShowLogsIdField/OrderShowLogsIdField';

export const OrderRequestLogsExpand = (): JSX.Element => {
  const record = useRecordContext();
  const responseStatus = record?.responseData?.statusCode;

  return (
    <Box>
      <StyledBox>
        <OrderShowLogsIdField />
      </StyledBox>
      {Boolean(responseStatus) && (
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <OrderShowLogsExpandCopyButton label="Запрос" source="requestData" />
              </TableCell>
              <TableCell>
                <OrderShowLogsExpandCopyButton label="Ответ" source="responseData" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <StyledJsonBox>
                  <JsonField source="requestData" />
                </StyledJsonBox>
              </TableCell>
              <TableCell>
                <StyledJsonBox>
                  <JsonField source="responseData" />
                </StyledJsonBox>
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      )}
    </Box>
  );
};
