import { useMemo } from 'react';

import { getUnixTime, secondsToMilliseconds } from 'date-fns';
import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { cleanEmpty } from 'utils/cleanEmpty';

import { BankOperationsFilterChips } from './BankOperationsFilterChips';
import { BankOperationsFilterForm } from './BankOperationsFilterForm';

type BankOperationsFilterValues = {
  appliedDate: [DateRangePickerValue, DateRangePickerValue];
  merchant: string[];
  provider: string[];
  merchantAccount: string[];
  type: string[];
  direction: string;
  status: string[];
  user: string;
};

export const DEFAULT_VALUES = {
  appliedDate: [null, null] as [DateRangePickerValue, DateRangePickerValue],
  merchant: [],
  provider: [],
  merchantAccount: [],
  type: [],
  status: [],
  direction: '',
  user: '',
};

export const BankOperationsFilter: React.FC = () => {
  const { filterValues } = useListContext();
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<BankOperationsFilterValues>({
      defaultValues: {
        appliedDate: [
          filterValues['dateOfOperation[gte]']
            ? secondsToMilliseconds(Number(filterValues['dateOfOperation[gte]']))
            : null,
          filterValues['dateOfOperation[lte]']
            ? secondsToMilliseconds(Number(filterValues['dateOfOperation[lte]']))
            : null,
        ],
        merchant: filterValues.merchant || [],
        provider: filterValues.provider || [],
        merchantAccount: filterValues.merchantAccount || [],
        type: filterValues.type || [],
        status: filterValues.status || [],
        direction: filterValues.direction || '',
        user: filterValues.user || '',
      },
      resetValues: DEFAULT_VALUES,
    });

  useSyncFilter<BankOperationsFilterValues>({
    appliedFilters,
    transform: (appliedFilters) => {
      const { appliedDate = [null, null], ...restFilters } = appliedFilters;
      return cleanEmpty({
        ...restFilters,
        'dateOfOperation[gte]': appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
        'dateOfOperation[lte]': appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
      });
    },
  });

  const listFilters = useMemo(() => {
    return cleanEmpty(appliedFilters, false);
  }, [appliedFilters]);

  const isListFilterExist = Object.keys(listFilters).length > 0;

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <SelectColumnButtonFilter key="select-column" />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <BankOperationsFilterForm />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters} isSkipBooleanValues={false}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <BankOperationsFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
    </>
  );
};
