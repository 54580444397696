import * as React from 'react';

import { Card } from '@mui/material';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { Link } from 'react-router-dom';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { Commission } from '../../../../types';
import { truncateString } from '../../../../utils/truncateString';

type Props = {
  record: Commission | undefined;
} & DialogProps;

export const CommissionsCreateNewVersionSuccessDialog: React.FC<Props> = ({
  record,
  ...restProps
}) => {
  const { toRoot } = useNavigateToRoot();

  const onClose = () => {
    restProps.onClose();
    toRoot();
  };

  return (
    <Dialog {...restProps} fullWidth onClose={onClose}>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Создание комиссии успешно выполнено"
        />
        <CardContent>
          <Typography>
            Новая версия комиссии {truncateString(record?.name || '', 30)} успешно создана. Вы
            можете отправить изменения на согласование или вернуться к списку комиссий для
            дальнейшей работы.
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton
              key="back-button"
              label="Вернуться в список"
              onClick={toRoot}
              variant="outlined"
            />,
            <Button
              color="primary"
              component={Link}
              key="btn-agreement"
              label="На согласование"
              to={{
                pathname: record ? `/commissions/${encodeURIComponent(record?.id)}/show` : '',
              }}
              variant="contained"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
