import { useCallback } from 'react';

import { BankOperation } from 'types';

export function useBankOperationEditable() {
  const checkBankOperationEditable = useCallback(
    (record: BankOperation) =>
      record?.status.endsWith('created') || record?.status.endsWith('rejected'),
    [],
  );

  return { checkBankOperationEditable };
}
