import { useState } from 'react';

import { BoxStyled } from './CumulativeReportSummary.styled';
import { CumulativeReportSummaryDatagrid } from './CumulativeReportSummaryDatagrid';
import { CumulativeReportSummaryPeriod } from './CumulativeReportSummaryPeriod';
import { CumulativeReportFilterValuesNew } from '../../CumulativeReportFilterNew';

export type Props = {
  filters: CumulativeReportFilterValuesNew;
};

export const CumulativeReportSummaryCollapsible: React.FC<Props> = ({ filters }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  if (isOpen) {
    return (
      <BoxStyled>
        <CumulativeReportSummaryPeriod
          filters={filters}
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
        />
        <CumulativeReportSummaryDatagrid />
      </BoxStyled>
    );
  }

  return (
    <BoxStyled>
      <CumulativeReportSummaryPeriod
        filters={filters}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
      />
    </BoxStyled>
  );
};
