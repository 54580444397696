import { formatInTimeZone } from 'date-fns-tz';

import { EMPTY_FIELD } from '../../../../constants';
import { DEFAULT_TIMEZONE } from '../../../mui/DatePicker/config/defaultValues';
import { FunctionField, FunctionFieldProps } from '../FunctionField/FunctionField';

type Props = Omit<FunctionFieldProps, 'render'>;

export const PeriodActivityField: React.FC<Props> = (props) => {
  return (
    <FunctionField
      {...props}
      render={(record: {
        startDateMilliseconds: number | null;
        endDateMilliseconds: number | null;
      }) => {
        if (!record?.startDateMilliseconds && !record?.endDateMilliseconds) {
          return EMPTY_FIELD;
        }

        let start;
        let end;

        if (record?.startDateMilliseconds) {
          start = formatInTimeZone(record?.startDateMilliseconds, DEFAULT_TIMEZONE, 'dd.MM.yyyy');
        }

        if (record?.endDateMilliseconds) {
          end = formatInTimeZone(record?.endDateMilliseconds, DEFAULT_TIMEZONE, 'dd.MM.yyyy');
        }

        if (start && end) {
          return `${start} - ${end}`;
        }

        return start ? `От ${start}` : `До ${end}`;
      }}
      sortable={false}
    />
  );
};
