import { styled } from '@mui/material';
import { Box, BoxProps } from 'shared/mui/Box';

export const StyledBox = styled(Box)({
  position: 'relative',
  background: '#FFF',
  borderBottom: 0,
  overflowX: 'auto',
  overflowY: 'auto',
});

export const StyledBoxCell = styled(Box)({
  padding: '8px 16px',
  verticalAlign: 'middle',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textWrap: 'nowrap',
});

export const StyledBoxCurrencyCell = styled(Box)({
  padding: '8px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  textAlign: 'end',
});

export const StyledRowBox = styled(Box)<BoxProps & { firstCellWidth: number }>(
  ({ firstCellWidth }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: `${firstCellWidth}px 88px repeat(10, minmax(168px, 1fr))`,
    width: '100%',
    alignItems: 'center',
    borderLeft: 0,
    borderRight: 0,
  }),
);
