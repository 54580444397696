import { RaRecord, useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Row } from 'shared/mui/Row/Row';
import {
  AutocompleteInput,
  AutocompleteInputIntrospected,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { fieldWidth } from 'themes/variables';
import { Gateway } from 'types';

export const LimitsCreateForm = ({ gatewayDefaultValue }: { gatewayDefaultValue: string }) => {
  const { watch, resetField } = useFormContext();

  const gatewayWatched = watch('gateway');
  const merchantWatched = watch('merchant');

  const { data: gateway } = useGetOne<Gateway>(
    'gateways',
    { id: gatewayWatched },
    { enabled: !!gatewayWatched },
  );

  return (
    <>
      <Row marginBottom={2}>
        <ReferenceInput
          filter={{
            merchantAccounts: { merchants: merchantWatched },
          }}
          perPage={200}
          reference="gateways"
          source="gateway"
        >
          <AutocompleteInputIntrospected
            defaultValue={gatewayDefaultValue || ''}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Шлюз"
            optionText="name"
            size="medium"
            source="name"
            sx={fieldWidth.default}
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput
          filter={{
            id: [...(gateway?.supportedCurrencies || []), gateway?.defaultCurrency],
            active: true,
          }}
          perPage={1000}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteInputIntrospected
            disabled={!watch('gateway')}
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            helperText={false}
            label="Валюта"
            optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
            size="medium"
            source="name"
            sx={fieldWidth.default}
            variant="outlined"
          />
        </ReferenceInput>
      </Row>
      <Row marginBottom={2}>
        <ReferenceInput
          filter={{ archive: false }}
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInput
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth={true}
            helperText={false}
            label="Мерчант"
            onChange={() => {
              resetField('gateway', { keepDirty: true });
            }}
            optionText="name"
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      </Row>
      <Row marginBottom={2}>
        <TextInputIntrospected
          helperText={false}
          label="Мин. сумма пополнения"
          size="medium"
          source="paymentMin"
          sx={fieldWidth.default}
          variant="outlined"
        />
        <TextInputIntrospected
          helperText={false}
          label="Макс. сумма пополнения"
          size="medium"
          source="paymentMax"
          sx={fieldWidth.default}
          variant="outlined"
        />
      </Row>
      <Row>
        <TextInputIntrospected
          helperText={false}
          label="Мин. сумма вывода"
          size="medium"
          source="payoutMin"
          sx={fieldWidth.default}
          variant="outlined"
        />
        <TextInputIntrospected
          helperText={false}
          label="Макс. сумма вывода"
          size="medium"
          source="payoutMax"
          sx={fieldWidth.default}
          variant="outlined"
        />
      </Row>
    </>
  );
};
