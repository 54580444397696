import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { FunctionField } from 'shared/react-admin/Fields';
import { WorkTeam } from 'types';

type Props = {
  label: string;
  source: keyof WorkTeam;
};

export const WorkTeamsListNameField: React.FunctionComponent<Props> = ({ label, source }) => {
  return (
    <FunctionField
      label={label}
      render={(record: WorkTeam) => (
        <Link
          style={{ color: blue[700], fontSize: 14 }}
          to={{
            pathname: `/work_teams/${encodeURIComponent(record?.id)}/edit`,
          }}
        >
          {record?.name}
        </Link>
      )}
      source={source}
    />
  );
};
