import React from 'react';

import { Box, Typography } from '@mui/material';
import { appColors } from 'themes/variables';

import { ProtectedMenuItemLink } from '../../../components/Common/Protected/ProtectedMenuItemLink';
import { ProtectedMenuItemLinkWithCounter } from '../../../components/Common/Protected/ProtectedMenuItemLinkWithCounter';
import { MultiLevelMenuItem } from '../constants/menuList';

type Props = {
  selectedResourceMenuItem: MultiLevelMenuItem;
  setCurrentResourceMenuItem: (resource: string | null) => void;
};

export function ResourceSidebar({
  selectedResourceMenuItem: { title, items },
  setCurrentResourceMenuItem,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        position: 'absolute',
        minWidth: '256px',
        backgroundColor: appColors.background,
        height: '100%',
        left: '64px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        zIndex: 1,
      }}
    >
      <Typography fontSize="14px" mb={2} ml={3} mt={2}>
        {title}
      </Typography>
      {items.map(({ resource, title, counter }) => {
        const props = {
          className: 'RaMenuItemLink__submenu',
          onClick: () => setCurrentResourceMenuItem(null),
          primaryText: title,
          resource,
          to: `/${resource}`,
          tooltipProps: {
            title: false,
            children: <></>,
          },
        };

        if (counter) {
          return <ProtectedMenuItemLinkWithCounter counter={counter} key={title} {...props} />;
        }
        return <ProtectedMenuItemLink key={title} {...props} />;
      })}
    </Box>
  );
}
