import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Create } from 'shared/react-admin/Create/Create';

export const CreateStyled = styled(Create)({
  '& .RaCreate-main': {
    margin: '0!important',
  },
});

export const CardContentStyled = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});
