import { MerchantNotificationsEventNamesType } from 'config/requests';
import { number, required, email as validateEmail } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { BooleanInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { MerchantNotification } from 'types';
import { capitalizeFirstLetter } from 'utils';
import { getLastUrlSegment } from 'utils/getLastUrlSegment';

import { MerchantsShowNotificationsEvent } from '../../MerchantsShowNotificationsEvent/MerchantsShowNotificationsEvent';

export enum MerchantsNotificationsTypes {
  Reddy = 'reddy',
  Email = 'email',
}

export type MerchantsShowNotificationsFormValues = {
  active: boolean;
  chatId?: string;
  email?: string;
  token?: string;
  type: string;
  event: Record<string, boolean>;
  comment?: string;
};

type Props = {
  record?: MerchantNotification;
  eventNames?: MerchantNotificationsEventNamesType[];
};

const getDefaultEventValues = (eventName: string, record?: MerchantNotification) =>
  !!record?.events?.find(({ name }) => name.value.toLocaleUpperCase() === eventName);

export const MerchantsShowNotificationsForm: React.FC<Props> = ({ record, eventNames }) => {
  const { setValue } = useFormContext<MerchantsShowNotificationsFormValues>();
  const type = useWatch({ name: 'type' }) || getLastUrlSegment(record?.notificationTransport);

  const isEmailNotification = record?.notificationTransport?.includes('email');
  const isReddyNotification = record?.notificationTransport?.includes('reddy');

  const onChangeType = () => {
    if (type) {
      setValue('email', '');
      setValue('chatId', '');
      setValue('token', '');

      if (isEmailNotification && record?.credentials?.email) {
        setValue('email', record.credentials.email);
      }
      if (isReddyNotification && record?.credentials?.chatId) {
        setValue('chatId', record.credentials.chatId.toString());
        record.credentials?.token && setValue('token', record.credentials.token);
      }
    }
  };

  return (
    <CardContent sx={{ paddingTop: '0!important', width: '552px' }}>
      <BooleanInput label="Активно" source="active" sx={{ marginBottom: '32px' }} />
      <SelectInputShrink
        choices={[
          {
            id: MerchantsNotificationsTypes.Reddy,
            label: capitalizeFirstLetter(MerchantsNotificationsTypes.Reddy),
          },
          {
            id: MerchantsNotificationsTypes.Email,
            label: capitalizeFirstLetter(MerchantsNotificationsTypes.Email),
          },
        ]}
        defaultValue={type}
        fullWidth
        helperText={false}
        label="Тип уведомления"
        name="type"
        onChange={onChangeType}
        optionText="label"
        size="medium"
        source="type"
        sx={{ marginBottom: '24px!important' }}
        validate={required()}
        variant="outlined"
      />
      <TextInputShrink
        fullWidth
        label="Комментарий"
        multiline
        name="comment"
        size="medium"
        source="comment"
        variant="outlined"
      />
      {type === MerchantsNotificationsTypes.Reddy && (
        <>
          <TextInputShrink
            defaultValue={record?.credentials?.chatId?.toString()}
            fullWidth
            helperText={false}
            label="ID канала"
            name="chatId"
            size="medium"
            source="chatId"
            sx={{ marginBottom: '24px!important' }}
            validate={[required(), number()]}
            variant="outlined"
          />
          <TextInputShrink
            defaultValue={record?.credentials?.token}
            fullWidth
            helperText={false}
            label="Токен"
            name="token"
            size="medium"
            source="token"
            sx={{ marginBottom: '24px!important' }}
            validate={[required()]}
            variant="outlined"
          />
        </>
      )}
      {type === MerchantsNotificationsTypes.Email && (
        <TextInputShrink
          defaultValue={record?.credentials?.email}
          fullWidth
          helperText={false}
          label="Email"
          name="email"
          size="medium"
          source="email"
          sx={{ marginBottom: '24px!important' }}
          validate={[validateEmail(), required()]}
          variant="outlined"
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {eventNames?.map((props) => (
          <MerchantsShowNotificationsEvent
            {...props}
            defaultValue={getDefaultEventValues(props.name, record)}
            key={props.value}
          />
        ))}
      </Box>
    </CardContent>
  );
};
