import { EMPTY_FIELD } from 'constants/emptyField';
import { Role } from 'constants/roles';

import { blue } from '@mui/material/colors';
import { useProviderAccountName } from 'components/Finance/ProviderAccounts/hooks/useProviderAccountName';
import { getUserInfo } from 'config/requests';
import { useHistoryContext } from 'hooks/useHistoryContext';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { AvatarStackAdd } from 'shared/mui/Avatars';
import { CreateButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { Tooltip } from 'shared/mui/Tooltip';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditDialogPageButton,
  DatagridActionsShowDialogPageButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { FunctionField, SingleFieldList } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { ProviderShowDialog } from 'shared/widgets/ProviderShowDialog/ProviderShowDialog';

import {
  StyledChip,
  StyledChipFieldLink,
  StyledDatagrid,
  StyledTextField,
} from './ProviderAccountsList.styled';
import { ProviderAccountsListFilter } from './ProviderAccountsListFilter';
import { WorkTeamAddDialog } from './WorkTeamAddDialog/WorkTeamAddDialog';
import { WorkTeamUsersAddDialog } from './WorkTeamUsersAddDialog/WorkTeamUsersAddDialog';
import {
  Company,
  Merchant,
  MerchantAccount,
  Permission,
  Provider,
  ProviderAccount,
} from '../../../../types';
import { ProviderAccountsManagerField } from '../components/ProviderAccountsManagerField';
import { ProviderAccountsMembers } from '../components/ProviderAccountsMembers/ProviderAccountsMembers';
import { ProviderAccountsName } from '../components/ProviderAccountsName';

const providerAccountResource = 'provider_account_news';

export const ProviderAccountsList = () => {
  const { data: user } = useQuery('me', getUserInfo);
  const isEditMembersDisabled = user?.roles.some((element) =>
    [Role.Finance, Role.PaymentLead, Role.Payment].includes(element),
  );

  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { record, handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Company>();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<ProviderAccount>();

  const { providerAccountName } = useProviderAccountName(recordDelete);
  const { baseRoute } = useHistoryContext();

  const {
    record: provider,
    handleCloseRecordModal: handleCloseRecordModalProvider,
    openRecordModal: openRecordModalProvider,
    handleOpenRecordModal: handleOpenRecordModalProvider,
  } = useRecordModal<Provider>();
  const {
    record: recordWorkTeam,
    handleCloseRecordModal: handleCloseRecordModalWorkTeam,
    openRecordModal: openRecordModalWorkTeam,
    handleOpenRecordModal: handleOpenRecordModalWorkTeam,
  } = useRecordModal<{ providerAccount: ProviderAccount }>();
  const {
    record: recordWorkTeamUsers,
    handleCloseRecordModal: handleCloseRecordModalWorkTeamUsers,
    openRecordModal: openRecordModalWorkTeamUsers,
    handleOpenRecordModal: handleOpenRecordModalWorkTeamUsers,
  } = useRecordModal<ProviderAccount>();
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions(providerAccountResource, [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal={true} key="create-provider-account" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Личные кабинеты',
        tooltipText:
          'Учетная запись мерчанта в системе провайдера. Выдается мерчанту провайдером и обычно представляет собой URL для входа в кабинет, логин и пароль.',
      }}
    >
      <ProviderAccountsListFilter />
      <StyledDatagrid bulkActionButtons={false}>
        <FunctionField
          label="Личный кабинет"
          render={(record: ProviderAccount) => (
            <Link
              state={{ backgroundLocation: baseRoute || location }}
              style={{
                cursor: 'pointer',
                color: blue[700],
              }}
              to={{
                pathname: `/provider_account_news/${encodeURIComponent(record.id)}/show`,
              }}
            >
              <ProviderAccountsName />
            </Link>
          )}
          source="id"
        />
        <ReferenceOneField
          emptyText="—"
          label="Компания"
          link={false}
          reference="companies"
          sortBy="company.name"
          source="company"
          target="id"
        >
          <FunctionField
            render={(record: Company) => (
              <StyledTextField onClick={() => handleOpenRecordModal(record)} source="name" />
            )}
          />
        </ReferenceOneField>
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <FunctionField
              render={(record: Provider | undefined) => (
                <StyledTextField
                  onClick={() => handleOpenRecordModalProvider(record)}
                  source="name"
                />
              )}
            />
          </ReferenceOneField>
        )}
        {hasResourcePermissions('work_teams', [Permission.Full, Permission.Partial]) && (
          <FunctionField
            label="Рабочая группа"
            render={(providerAccount: ProviderAccount) => {
              if (!providerAccount.workTeam) {
                return (
                  <Tooltip arrow placement="bottom" title="Назначить группу">
                    <AvatarStackAdd
                      onClick={() => handleOpenRecordModalWorkTeam({ providerAccount })}
                    />
                  </Tooltip>
                );
              }

              return (
                <ReferenceOneField
                  label="Рабочая группа"
                  link="show"
                  reference="work_teams"
                  source="workTeam"
                  target="id"
                >
                  <FunctionField render={() => <StyledTextField source="name" />} />
                </ReferenceOneField>
              );
            }}
            source="workTeam"
          />
        )}
        <ReferenceOneField
          emptyText="—"
          label="Руководитель группы"
          link={false}
          reference="work_teams"
          source="workTeam"
          target="id"
        >
          <ProviderAccountsManagerField />
        </ReferenceOneField>
        <ReferenceArrayField
          label="Ответственные"
          reference="users"
          sortable={false}
          source="members"
        >
          <FunctionField
            render={(record: ProviderAccount) =>
              record.workTeam ? (
                <ProviderAccountsMembers
                  addSlot={
                    !isEditMembersDisabled && (
                      <AvatarStackAdd onClick={() => handleOpenRecordModalWorkTeamUsers(record)} />
                    )
                  }
                />
              ) : (
                EMPTY_FIELD
              )
            }
          />
        </ReferenceArrayField>
        <ReferenceArrayField
          emptyText="-"
          label="Мерчанты"
          reference="merchants"
          source="merchants"
        >
          <SingleFieldList linkType="show">
            <FunctionField
              render={(record: Merchant) => (
                <StyledChipFieldLink
                  color="default"
                  emptyText="-"
                  record={record}
                  source="name"
                  sx={{ color: 'blue!important' }}
                />
              )}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          emptyText="-"
          label="Счета мерчанта"
          reference="merchant_accounts"
          source="merchantAccounts"
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: MerchantAccount) => (
                <StyledChip
                  label={record.name}
                  onClick={() => handleOpenMerchantAccountModal(record)}
                />
              )}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <DatagridActions
          render={(record: ProviderAccount) => [
            hasResourceAccess('user_actions') && (
              <DatagridActionsUserActionsButton
                entityName="provider_account_new"
                key="user-action-button"
              />
            ),
            <DatagridActionsShowDialogPageButton key="show-button" />,
            hasResourcePermissions(providerAccountResource, [
              Permission.Full,
              Permission.Update,
            ]) && <DatagridActionsEditDialogPageButton key="edit-button" />,
            hasResourcePermissions(providerAccountResource, [
              Permission.Full,
              Permission.Delete,
            ]) && (
              <DatagridActionsDeleteButton
                key="delete-button"
                onClick={() => handleOpenRecordModalDelete(record)}
              />
            ),
          ]}
        />
      </StyledDatagrid>
      <DeleteDialog
        details={`Личный кабинет ${providerAccountName} будет удален без возможности восстановления.`}
        onClose={handleCloseRecordModalDelete}
        open={openRecordModalDelete}
        record={recordDelete}
        title="Удаление личного кабинета"
      />
      {record && (
        <CompanyShowDialog
          company={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
      {provider && (
        <ProviderShowDialog
          onClose={handleCloseRecordModalProvider}
          open={openRecordModalProvider}
          provider={provider}
        />
      )}
      {recordWorkTeam && (
        <WorkTeamAddDialog
          onClose={handleCloseRecordModalWorkTeam}
          open={openRecordModalWorkTeam}
          provider={recordWorkTeam}
        />
      )}
      {recordWorkTeamUsers && (
        <WorkTeamUsersAddDialog
          onClose={handleCloseRecordModalWorkTeamUsers}
          open={openRecordModalWorkTeamUsers}
          provider={recordWorkTeamUsers}
        />
      )}
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </ListPage>
  );
};
