import { styled } from '@mui/material';

import { Datagrid } from '..';

const shadow = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: '-4px',
  width: '4px',
  height: '100%',
  boxShadow: '1px 0px 3px rgba(0, 0, 0, 0.1)',
};

export const DatagridWithStickyActions = styled(Datagrid)({
  // цвет по-умолчанию при ховере имеет прозрачность. Панелька с actionButtons наследует цвет родителя и тоже становится прозрачной.
  // для предовращения этого поведения меняем цвет на непрозрачный.
  '& .MuiTableRow-root:hover': {
    backgroundColor: '#F5F5F5!important',
  },
  th: {
    '&:last-child': {
      position: 'sticky',
      right: 0,
      borderBottom: '1px solid #EBEBEB',
      width: '44px',
      maxWidth: '44px',
      '&:after': shadow,
    },
  },
  td: {
    '&:last-child': {
      position: 'sticky',
      right: 0,
      borderBottom: '1px solid #EBEBEB',
      backgroundColor: 'inherit',
      padding: '12px 4px 12px 6px',
      width: '44px',
      maxWidth: '44px',
      '&:after': shadow,
    },
  },
});
