import { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { OrderConversionRequestParams } from 'config/requests';
import { useQueryFilters } from 'hooks/useQueryFilters';
import { Identifier } from 'react-admin';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { getId } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';
import * as yup from 'yup';

import { OrderConversionListFormValues } from '../OrderConversionList';
import { ConversionPeriod } from '../OrderConversionListPeriodToggle/OrderConversionListPeriodToggle';

const initialFilters = {
  merchant: null,
  gateway: [],
  provider: [],
};

export const useOrderConversionFilter = () => {
  const { setQueryFilterString, queryFilterObject, isEmptyFilters } = useQueryFilters();
  const [period, setPeriodState] = useState<ConversionPeriod>(
    queryFilterObject?.period || ConversionPeriod.Minutes60,
  );
  const [direction, setDirectionState] = useState<string>(queryFilterObject?.direction || '');

  const {
    form,
    onSubmit: onSubmitFilters,
    onReset: onResetFilters,
    appliedFilters,
    setAppliedFilter,
    openDrawer,
    toggleDrawer,
  } = useFilters<OrderConversionListFormValues>({
    resolver: yupResolver<any>(
      yup.object().shape({
        merchant: yup.string().required('Обязательное поле'),
      }),
    ),
    defaultValues: queryFilterObject || initialFilters,
    resetValues: initialFilters,
  });

  const setPeriod = (value: ConversionPeriod) => {
    setPeriodState(value);
    setQueryFilterString(cleanEmpty({ ...queryFilterObject, period: value }));
  };

  const setDirection = (value: string) => {
    setDirectionState(value);
    setQueryFilterString(cleanEmpty({ ...queryFilterObject, direction: value }));
  };

  const onSubmit = (values: OrderConversionListFormValues) => {
    setQueryFilterString(cleanEmpty(values));
    onSubmitFilters(values);
  };

  const onReset = () => {
    setQueryFilterString({});
    onResetFilters();
  };

  const params = useMemo<OrderConversionRequestParams>(() => {
    return cleanEmpty({
      gatewayIds: appliedFilters?.gateway?.map((hydraId) => getId(hydraId)),
      providerIds: appliedFilters?.provider?.map((hydraId) => getId(hydraId)),
      period,
      merchantId: getId(appliedFilters?.merchant as Identifier),
      direction,
    }) as OrderConversionRequestParams;
  }, [direction, period, appliedFilters]);

  useEffect(() => {
    if (isEmptyFilters) {
      onReset();
    }
  }, [isEmptyFilters]);

  const isListFilterExist = Object.keys(cleanEmpty(appliedFilters)).length > 0;

  return {
    params,
    form,
    setPeriod,
    onSubmit,
    toggleDrawer,
    isListFilterExist,
    period,
    openDrawer,
    appliedFilters,
    setAppliedFilter,
    onReset,
    setDirection,
    direction,
  };
};
