import { getUnixTime } from 'date-fns';
import { ReactComponent as BankOperations } from 'images/bankOperations.svg';
import { Link } from 'react-router-dom';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { ListItemTextStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';
import { MerchantAccountBalanceReport, MerchantBalanceReport, ProviderBalanceReport } from 'types';
import { convertReactAdminIdToHydraId } from 'utils';
import { createFilterQueryString } from 'utils/createFilterQueryString';

type Props = {
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  merchantAccount: MerchantAccountBalanceReport;
  merchant: MerchantBalanceReport;
  provider: ProviderBalanceReport;
};

export const DatagridActionsBankOperationsLink: React.FC<Props> = ({
  merchantAccount,
  provider,
  merchant,
  appliedDate,
}) => {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      target="_blank"
      to={{
        pathname: '/bank_operations',
        search: createFilterQueryString({
          merchantAccount: [convertReactAdminIdToHydraId(merchantAccount.id, 'merchant_accounts')],
          provider: [convertReactAdminIdToHydraId(provider.id, 'providers')],
          merchant: [convertReactAdminIdToHydraId(merchant.id, 'merchants')],
          'dateOfOperation[gte]': appliedDate && appliedDate[0] && getUnixTime(appliedDate[0]),
          'dateOfOperation[lte]': appliedDate && appliedDate[1] && getUnixTime(appliedDate[1]),
        }),
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <BankOperations />
        </ListItemIcon>
        <ListItemTextStyled primary={'Банковские операции\nза выбранный период'} />
      </MenuItem>
    </Link>
  );
};
