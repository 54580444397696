import { useNotify } from 'react-admin';
import { TransactionsReportSummary } from 'types/transactionsReportSummary';

type Props = {
  summary?: TransactionsReportSummary;
  reportPeriod?: string;
};
type ReturnValue = {
  copyData: string;
  handleCopy: () => Promise<any>;
};

export const useGetSummaryCopyData = ({ summary, reportPeriod }: Props): ReturnValue => {
  const notify = useNotify();

  const statusesSourceMap: Record<keyof TransactionsReportSummary, string> = {
    created: 'Создан',
    prepared: 'Подготовлен',
    pending: 'Ожадание',
    success: 'Успех',
    failure: 'Провал',
    cancelled: 'Отменен',
    refund: 'Рефанд',
    chargeback: 'Чарджбек',
    expired: 'Просрочен',
    waitingConfirmation: 'На проверке',
    executedOrderCount: 'Исполненные',
    notExecutedOrderCount: 'Не исполненные',
  };

  const summaryTitle = 'Сводка по заказам за период:\n';
  const summaryPeriod = `Период: ${reportPeriod}\n`;
  const statusesSummary = summary
    ? (Object.entries(statusesSourceMap) as Array<[keyof TransactionsReportSummary, string]>)
        .map(([source, label]) => `${label}: ${summary[source] ?? 'значение отсутствует'}`)
        .join('\n')
    : '';
  const copyData = summaryTitle + summaryPeriod + statusesSummary;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyData);
      notify('Скопировано', {
        type: 'success',
        autoHideDuration: 1000,
      });
    } catch (e: unknown) {
      notify('Копирование не удалось', {
        type: 'error',
        autoHideDuration: 1000,
      });
    }
  };

  return { copyData, handleCopy };
};
