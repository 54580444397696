import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

export function usePageTitle() {
  const { pathname } = useLocation();
  const [title, setTitle] = useState('Kubera');

  useEffect(() => {
    if (pathname) {
      let pageTitle = pathname?.split('/')[1]?.replaceAll('_', ' ');

      if (pageTitle) {
        pageTitle = pageTitle[0]?.toUpperCase() + pageTitle.slice(1) + ' - Kubera';
        setTitle(pageTitle);
      }
    }
  }, [pathname]);

  useEffect(() => {
    document.title = title;
  }, [title]);
}
