import { useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateRangePicker } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { EnumAutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const BankOperationsFilterForm: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const [merchant, provider] = watch(['merchant', 'provider']);

  const { data: bankOperationStatuses } = useGetList('bank_operation_statuses');
  const { data: bankOperationTypes } = useGetList('bank_operation_types');

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateRangePicker
            label="Дата проведения / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <ReferenceArrayInput
        filter={{ exists: { merchantAccounts: true } }}
        isFilter={true}
        name="merchant"
        perPage={100}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Мерчант"
          limitTags={1}
          name="merchant"
          onChange={() => {
            setValue('provider', []);
            setValue('merchantAccount', []);
          }}
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{ merchantAccounts: { merchants: merchant } }}
        isFilter={true}
        name="provider"
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Провайдеры"
          limitTags={1}
          name="provider"
          onChange={() => {
            setValue('merchantAccount', []);
          }}
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{ merchants: merchant, provider }}
        isFilter={true}
        name="merchantAccount"
        perPage={100}
        reference="merchant_accounts"
        source="merchantAccount"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Счета мерчантов"
          limitTags={1}
          name="merchantAccount"
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <AutocompleteMultiselectInputShrink
        defaultValue={watch('type')}
        getOptionLabel={(option, options) =>
          options?.find((opt) => opt.value === option)?.description
        }
        helperText={false}
        label="Тип операции"
        limitTags={1}
        name="type"
        optionText="description"
        optionValue="value"
        options={bankOperationTypes || []}
        resource="bank_operation_types"
      />
      <AutocompleteMultiselectInputShrink
        defaultValue={watch('status')}
        getOptionLabel={(option, options) =>
          options?.find((opt) => opt.value === option)?.description
        }
        helperText={false}
        label="Статус"
        limitTags={1}
        name="status"
        optionText="description"
        optionValue="value"
        options={bankOperationStatuses || []}
        resource="bank_operation_statuses"
      />
      <ReferenceArrayInput
        isFilter={true}
        name="user"
        perPage={200}
        reference="users"
        source="user"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({
            fullName: searchText,
          })}
          helperText={false}
          label="Автор"
          name="user"
          optionText="name"
          renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
          size="small"
        />
      </ReferenceArrayInput>
    </>
  );
};
