import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getUnixTime } from 'date-fns';
import { useRecordModal } from 'hooks/useRecordModal';
import { SimpleForm } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreatePage } from 'shared/react-admin/Pages/CreatePage/CreatePage';
import { BankOperation } from 'types';

import { BankOperationsCreateSuccessDialog } from './BankOperationsCreateSuccessDialog/BankOperationsCreateSuccessDialog';
import { queryClient } from '../../../../App';
import { BankOperationsFormNew } from '../components/BankOperationsFormNew';
import { BANK_OPERATION_SCHEMA } from '../schemas/schema';

export const BankOperationsCreateNew: React.FC = () => {
  const { handleOpenRecordModal, openRecordModal, record, handleCloseRecordModal } =
    useRecordModal<BankOperation>();

  return (
    <CreatePage
      fullWidth
      listName="Банковские операции"
      mutationOptions={{
        onError: () => undefined,
        onSuccess: async (data: BankOperation) => {
          await queryClient.invalidateQueries(['bank_operations']);
          handleOpenRecordModal(data);
        },
      }}
      sx={{ marginTop: 0, padding: 0, width: '550px' }}
      title="Создание банковской операции"
      transform={(data) => ({
        ...data,
        dateOfOperation: getUnixTime(new Date(data.dateOfOperation)),
        description: data.description || '',
        amountPenny: data.amountPenny.toString(),
      })}
    >
      <SimpleForm
        noValidate
        resolver={yupResolver<FieldValues>(BANK_OPERATION_SCHEMA)}
        shouldUseNativeValidation={false}
        sx={{ '&.MuiCardContent-root': { padding: 0 } }}
        toolbar={false}
      >
        <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
          <BankOperationsFormNew />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
        />
      </SimpleForm>
      <BankOperationsCreateSuccessDialog
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={record}
      />
    </CreatePage>
  );
};
