import { BankOperationStatusesNew } from 'constants/bankOperationStatusesNew';

import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ChipField } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { IconButton } from 'shared/mui/IconButtons';

import { STATUS_COLORS } from './constants/statusColors';

export const ChipFieldWrapperStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '5px',
  height: 'auto',
  alignItems: 'center',
});

export const ChipFieldStyled = styled(ChipField)({
  margin: '0 !important',
  height: '24px',
});

export const StatusIconStyled = styled('div')<{ status?: BankOperationStatusesNew }>(
  ({ status }) => ({
    height: '16px',
    width: '16px',
    backgroundColor: status
      ? STATUS_COLORS[status as BankOperationStatusesNew]
      : STATUS_COLORS['default'],
    borderRadius: '100%',
  }),
);

export const IconButtonStyled = styled(IconButton)({
  '&.MuiButtonBase-root': {
    backgroundColor: grey[600],
    padding: '3px',
  },
  '& .MuiSvgIcon-root': {
    width: 14,
    height: 14,
    color: '#ffffff',
  },
});
