import { useMemo } from 'react';

import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { cleanEmpty } from 'utils/cleanEmpty';

import { ProviderAccountsFilterChips } from './ProviderAccountsFilterChips';
import { ProviderAccountsFilterForm } from './ProviderAccountsFilterForm';

type ProviderAccountsFilterValues = {
  company: string;
  merchant: string;
  provider: string;
  merchantAccounts: string[];
  workTeam: string;
  members: string[];
};

export const DEFAULT_VALUES = {
  company: '',
  merchant: '',
  provider: '',
  merchantAccounts: [],
  workTeam: '',
  members: [],
};

export const ProviderAccountsListFilter: React.FC = () => {
  const { filterValues } = useListContext();
  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<ProviderAccountsFilterValues>({
      defaultValues: {
        company: filterValues.company || '',
        merchant: filterValues.merchant || '',
        provider: filterValues.provider || '',
        merchantAccounts: filterValues.merchantAccounts || [],
        members: filterValues.members || [],
        workTeam: filterValues.workTeam || '',
      },
      resetValues: DEFAULT_VALUES,
    });

  useSyncFilter<ProviderAccountsFilterValues>({
    appliedFilters,
    transform: (appliedFilters) => {
      return cleanEmpty({
        ...appliedFilters,
      });
    },
  });

  const listFilters = useMemo(() => {
    return cleanEmpty(appliedFilters, false);
  }, [appliedFilters]);

  const isListFilterExist = Object.keys(listFilters).length > 0;

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton
            key="clear"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <ProviderAccountsFilterForm />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters} isSkipBooleanValues={false}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <ProviderAccountsFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
    </>
  );
};
