import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { RefundChargebackReportSummary } from 'types/refundChagrebackReport';

import { DatargidFooterStyled } from './RefundChargebackReportSummaryDatagridFooter.styled';
import { RefundChargebackReportSummaryDatagridFooterAmountField } from './RefundChargebackReportSummaryDatagridFooterAmountField';

type Props = {
  data: RefundChargebackReportSummary[];
};

export const RefundChargebackReportSummaryDatagridFooter: React.FC<Props> = ({ data }) => {
  return (
    <DatargidFooterStyled data={data}>
      <></>
      <Typography sx={{ fontWeight: 500, textTransform: 'uppercase' }} variant="body2">
        Итого:
      </Typography>
      <FunctionField
        render={(record: RefundChargebackReportSummary) => (
          <Typography variant="body2">{record?.refundSummaryCount}</Typography>
        )}
        source="refundSummaryCount"
      />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="refundSummaryAmount" />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="refundSummaryCommissionAmount" />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="refundTotalSummaryAmount" />
      <FunctionField
        render={(record: RefundChargebackReportSummary) => (
          <Typography variant="body2">{record?.chargebackSummaryCount}</Typography>
        )}
        source="chargebackSummaryCount"
      />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="chargebackSummaryAmount" />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="chargebackSummaryCommissionAmount" />
      <RefundChargebackReportSummaryDatagridFooterAmountField source="chargebackTotalSummaryAmount" />
    </DatargidFooterStyled>
  );
};
