export const FILTER_RESET_VALUES: any = {
  appliedDate: [null, null, null],
  updatedDate: [null, null, null],
  company: null,
  merchant: null,
  provider: null,
  merchantAccount: [],
  gateway: [],
  method: [],
  status: [],
  impactToBalance: null,
  currency: [],
  conversionCurrency: null,
  summary: false,
  transaction: null,
  timezone: null,
};
