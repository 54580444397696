import { Box } from '@mui/material';
import { AppPanelHeader, AppPanelHeaderToolbar } from 'components/Common/PanelHeader';
import { usePermissions } from 'hooks/usePermissions';
import { Show, SimpleShowLayout, Tab, useGetOne, useShowController } from 'react-admin';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { PageHeaderBreadcrumbs } from 'shared/mui/PageHeader';

import { OrderShowCommonInfo } from './OrderShowCommonInfo/OrderShowCommonInfo';
import { OrdersShowLogs } from './OrderShowLogs/OrdersShowLogs';
import OrdersShowCustomData from './OrdersShowCustomData';
import OrdersShowNotifications from './OrdersShowNotifications';
import OrdersShowSendNotification from './OrdersShowSendNotification';
import { OrdersTabbedShowLayout } from './OrdersTabbedShowLayout';
import { Role } from '../../../constants/roles';
import { useApiContext } from '../../../context/apiContext';

export const OrdersShow = (): JSX.Element => {
  const { record } = useShowController();
  const { user } = useApiContext();
  const { hasResourceAccess } = usePermissions();

  const { data: notificationData } = useGetOne('order_notification_urls', {
    id: record?.orderNotificationUrl || '',
  });

  return (
    <Box>
      <PageHeaderBreadcrumbs currentPage="Просмотр заказа" listName="Заказы" />
      <Show
        sx={{
          '& .RaShow-noActions': {
            marginTop: '8px',
          },
        }}
      >
        <AppPanelHeader pb={1} sx={{ backgroundColor: '#F9FAFB' }}>
          <AppPanelHeaderToolbar
            actions={
              hasResourceAccess('user_actions')
                ? [<UserActionsIconButton entityName="order" key="user-action-link" />]
                : undefined
            }
            titleText={`Заказ #${record?.originId || ''}`}
          />
        </AppPanelHeader>
        <SimpleShowLayout sx={{ padding: '0px 0px', backgroundColor: '#F9FAFB' }}>
          <OrdersTabbedShowLayout>
            <Tab label="Информация">
              <OrderShowCommonInfo />
            </Tab>

            {!user.roles.includes(Role.Observer) && (
              <Tab label="Дополнительные данные">
                <OrdersShowCustomData />
              </Tab>
            )}
            {!user.roles.includes(Role.Observer) && (
              <Tab label="Уведомления">
                <OrdersShowNotifications />
                <OrdersShowSendNotification notificationUrl={notificationData?.notificationUrl} />
              </Tab>
            )}
            {hasResourceAccess('order_events') && (
              <Tab label="Логи запросов">
                <OrdersShowLogs />
              </Tab>
            )}
          </OrdersTabbedShowLayout>
        </SimpleShowLayout>
      </Show>
    </Box>
  );
};
