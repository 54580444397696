import { PAYMENT_ROLES, Role } from 'constants/roles';

import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterBaseApplyButton } from 'shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from 'shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

type Props = {
  form: UseFormReturn<MerchantShowAccessFilterValues>;
  onSubmit: () => void;
  onReset: () => void;
};

export type MerchantShowAccessFilterValues = {
  user: {
    firstName: string;
    lastName: string;
    roleEntities: string[];
  };
};

export const MerchantShowAccessListFilter: React.FC<Props> = ({ form, onSubmit, onReset }) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInputShrink
              helperText={false}
              key="user.firstName"
              label="Имя"
              name="user.firstName"
              source="user.firstName"
              variant="outlined"
            />
            <TextInputShrink
              helperText={false}
              key="user.lastName"
              label="Фамилия"
              name="user.lastName"
              source="user.lastName"
              variant="outlined"
            />
            <ReferenceArrayInput
              filter={{
                alias: [...PAYMENT_ROLES, Role.Observer],
              }}
              isFilter={true}
              name="user.roleEntities"
              perPage={100}
              reference="roles"
              source="user.roleEntities"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Роль"
                name="user.roleEntities"
                optionText="name"
                size="small"
              />
            </ReferenceArrayInput>
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterBaseApplyButton key="apply-button" type="submit" />
              <FilterBaseResetButton key="reset-button" onClick={onReset} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
