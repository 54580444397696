import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids/DatagridConfigurableWithStickyActions/DatagridConfigurableWithStickyActions';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(DatagridConfigurableWithStickyActions)({
  '& .column-originId': getColumnStyle(88),
  '& .column-dateOfOperation': getColumnStyle(136),
  '& .column-type': getColumnStyle(280),
  '& .column-direction': getColumnStyle(200),
  '& .column-amountPenny': {
    ...getColumnStyle(136),
    textAlign: 'right',
  },
  '& .column-currency': getColumnStyle(88),
  '& .column-status': getColumnStyle(206),
  '& .column-merchant': getColumnStyle(168),
  '& .column-provider': getColumnStyle(168),
  '& .column-merchantAccount': getColumnStyle(200),
  '& .column-user': getColumnStyle(280),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
