import { useCallback, useMemo, useState } from 'react';

import * as classnames from 'classnames';
import { MenuProps, useSidebarState } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { appColors } from 'themes/variables';

import { AppSidebarButton } from './components/AppSidebarButton';
import { MenuBase } from './components/MenuBase';
import { MenuCollapseItem } from './components/MenuCollapseItem';
import { ResourceSidebar } from './components/ResourceSidebar';
import { getMenuList, MultiLevelMenuItem } from './constants/menuList';
import { ProtectedMenuItemLink } from '../../components/Common/Protected/ProtectedMenuItemLink';
import { ProtectedMenuItemLinkWithCounter } from '../../components/Common/Protected/ProtectedMenuItemLinkWithCounter';
import { Role } from '../../constants/roles';
import { usePermissions } from '../../hooks/usePermissions';

const Menu = ({ className, ...rest }: MenuProps): JSX.Element => {
  const { roles } = usePermissions();
  const [openSidebar, setOpenSidebar] = useSidebarState();

  const [currentResourceMenuItem, setCurrentResourceMenuItem] = useState<string | null>(null);

  const currentMenuList = getMenuList({
    isLimitsEnabled: !roles.includes(Role.PaymentLead) && !roles.includes(Role.Payment),
  });

  const selectedResourceMenuItem = useMemo(
    () =>
      currentMenuList.find((item) => currentResourceMenuItem === item.title) as MultiLevelMenuItem,
    [currentResourceMenuItem],
  );

  const handleOpenSidebar = useCallback(() => {
    setOpenSidebar(!openSidebar);
  }, [openSidebar]);

  return (
    <>
      {selectedResourceMenuItem && (
        <>
          <ResourceSidebar
            selectedResourceMenuItem={selectedResourceMenuItem}
            setCurrentResourceMenuItem={setCurrentResourceMenuItem}
          />
          <div
            onClick={() => setCurrentResourceMenuItem(null)}
            style={{
              background: 'rgba(0,0,0, 0.5)',
              width: '100vw',
              height: '100vh',
              zIndex: 0,
              position: 'absolute',
            }}
          />
        </>
      )}
      <MenuBase
        className={classnames(
          {
            'RaSidebar-closed': !openSidebar,
          },
          className,
        )}
        isClosed={!openSidebar}
        {...rest}
      >
        <Box
          sx={{
            position: 'relative',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingTop: '8px',
            zIndex: 1,
          }}
        >
          {currentMenuList.map((menuItem) => {
            if ('resource' in menuItem) {
              const props = {
                leftIcon: menuItem.icon,
                onClick: () => setCurrentResourceMenuItem(null),
                primaryText: menuItem.title,
                resource: menuItem.resource,
                to: `/${menuItem.resource}`,
              };

              if (menuItem.counter) {
                return (
                  <ProtectedMenuItemLinkWithCounter
                    counter={menuItem.counter}
                    key={menuItem.title}
                    {...props}
                  />
                );
              }
              return <ProtectedMenuItemLink key={menuItem.title} {...props} />;
            }

            return (
              <MenuCollapseItem
                currentResourceMenuItem={currentResourceMenuItem}
                key={menuItem.title}
                multiLevelMenuItem={menuItem}
                setCurrentResourceMenuItem={setCurrentResourceMenuItem}
              />
            );
          })}
        </Box>
        <div style={{ boxShadow: `inset 0px 1px 0px ${appColors.divider}` }}>
          <AppSidebarButton
            onClick={handleOpenSidebar}
            sx={{ color: 'rgba(0,0,0, 0.6)', paddingLeft: '12px', height: '48px' }}
          />
        </div>
      </MenuBase>
    </>
  );
};

export default Menu;
