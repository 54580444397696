import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { CommissionsNameInput } from 'components/Commissions/components/CommissionsNameInput';
import { RaRecord, required } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DateTimePicker } from 'shared/mui/DatePicker/DateTimePicker/DateTimePicker';
import { Divider } from 'shared/mui/Divider/Divider';
import { Row } from 'shared/mui/Row/Row';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputShrink, BooleanInput, WatchedTextInput } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { FormItemStyled } from './MerchantShowCommissionsFormNewVersion.styled';

type Props = {
  errors: string[];
  merchantId: string | undefined;
};

export const MerchantShowCommissionsFormNewVersion: React.FC<Props> = ({ errors, merchantId }) => {
  const { formState, watch, trigger, setError, control } = useFormContext();
  const zeroed = watch('zeroed');
  const triggerPercentValidation = () => trigger(['percent', 'fix', 'max', 'min']);

  useEffect(() => {
    errors.forEach((error: string) => {
      const [field, message] = error.split(':');
      setError(field, { type: 'custom', message });
    });
  }, [errors]);

  return (
    <CardContent sx={{ paddingBottom: '0!important' }}>
      <Typography variant="body1">Основные настройки:</Typography>
      <Divider sx={{ marginTop: '8px', marginBottom: '24px' }} />
      <FormItemStyled>
        <CommissionsNameInput
          fullWidth
          helperText={false}
          label="Название"
          size="medium"
          source="name"
          validate={required()}
          variant="outlined"
        />
      </FormItemStyled>
      <FormItemStyled>
        <ReferenceInput
          filter={{
            merchantAccounts: {
              merchants: [merchantId],
            },
          }}
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            isRequired
            label="Провайдер"
            optionText="name"
            readOnly={true}
            size="medium"
            source="provider"
            variant="outlined"
          />
        </ReferenceInput>
      </FormItemStyled>
      <FormItemStyled>
        <ReferenceArrayInput
          isFilter={true}
          perPage={100}
          reference="transaction_types/commission/list"
          source="transactionTypes"
        >
          <AutocompleteMultiselectInputShrink
            isRequired
            label="Типы транзакций"
            name="transactionTypes"
            optionText="name"
            readOnly={true}
            size="medium"
            sx={{
              '.MuiTextField-root': {
                marginTop: 0,
              },
            }}
          />
        </ReferenceArrayInput>
      </FormItemStyled>
      <FormItemStyled>
        <ReferenceInput
          filter={{ active: true }}
          perPage={100}
          reference="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            fullWidth
            isRequired
            label="Валюта"
            optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
            readOnly={true}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                error={!!formState.errors?.currency}
                helperText={(formState.errors?.currency?.message as string) || ' '}
                label="Валюта"
                size="medium"
                variant="outlined"
              />
            )}
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      </FormItemStyled>
      <Typography variant="body1">Условия комиссии:</Typography>
      <Divider sx={{ marginTop: '8px', marginBottom: '24px' }} />
      <BooleanInput
        label="Нулевая комиссия"
        source="zeroed"
        sx={{ width: '100%', marginBottom: '24px' }}
        variant="outlined"
      />
      <FormItemStyled>
        <Row>
          <WatchedTextInput
            defaultValue={null}
            disabled={zeroed}
            fullWidth
            label="Процент"
            onChange={triggerPercentValidation}
            source="percent"
            variant="outlined"
            watchField="zeroed"
          />
          <WatchedTextInput
            defaultValue={null}
            disabled={zeroed}
            fullWidth
            label="Фиксированная"
            onChange={triggerPercentValidation}
            source="fix"
            variant="outlined"
            watchField="zeroed"
          />
        </Row>
      </FormItemStyled>
      <FormItemStyled>
        <Row>
          <WatchedTextInput
            defaultValue={null}
            disabled={zeroed}
            fullWidth
            label="Максимальная"
            onChange={triggerPercentValidation}
            source="max"
            variant="outlined"
            watchField="zeroed"
          />
          <WatchedTextInput
            defaultValue={null}
            disabled={zeroed}
            fullWidth
            label="Минимальная"
            onChange={triggerPercentValidation}
            source="min"
            variant="outlined"
            watchField="zeroed"
          />
        </Row>
      </FormItemStyled>
      <Typography variant="body1">Дополнительные настройки:</Typography>
      <Divider sx={{ marginTop: '8px', marginBottom: '24px' }} />
      <FormItemStyled>
        <Row>
          <Controller
            control={control}
            name="startDateMilliseconds"
            render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
              <DateTimePicker
                error={!!errors.startDateMilliseconds}
                helperText={fieldState?.error?.message}
                label="Период активности от"
                onBlur={onBlur}
                onChange={onChange}
                size="medium"
                value={value || null}
              />
            )}
          />
          <Controller
            control={control}
            name="endDateMilliseconds"
            render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
              <DateTimePicker
                error={!!errors.endDateMilliseconds}
                helperText={fieldState?.error?.message}
                label="Период активности до"
                onBlur={onBlur}
                onChange={onChange}
                size="medium"
                value={value || null}
              />
            )}
          />
        </Row>
      </FormItemStyled>
      <Alert severity="info">
        <Typography variant="body2">
          Настройки периода активности определяют даты начала и окончания срока действия комиссии.
          Если дата окончания заранее неизвестна, она указывается по факту завершения срока действия
          при создании новой версии.
        </Typography>
      </Alert>
    </CardContent>
  );
};
