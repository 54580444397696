import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';

type Props = {
  onClickExport: VoidFunction;
};

export const OrdersReportListLimitAlert: React.FC<Props> = ({ onClickExport }) => {
  return (
    <Alert
      action={
        <Button
          color="warning"
          label="Экспорт"
          onClick={onClickExport}
          size="small"
          sx={{ color: 'inherit' }}
        />
      }
      severity="warning"
      sx={{ margin: '15px 0 10px 0' }}
    >
      Применённый фильтр слишком широкий, поэтому показано только 1000 записей. Чтобы получить все
      результаты фильтрации, сделайте экспорт.
    </Alert>
  );
};
