import { ExpandIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { RefundChargebackReportSummaryPeriodStyled } from '../RefundChargebackReportSummary.styled';

type RefundChargebackReportSummaryPeriodProps = {
  toggleIsOpen: () => void;
  isOpen: boolean;
  reportPeriod: string;
};

export const RefundChargebackReportSummaryPeriod: React.FC<
  RefundChargebackReportSummaryPeriodProps
> = ({ toggleIsOpen, isOpen, reportPeriod }) => {
  return (
    <RefundChargebackReportSummaryPeriodStyled isOpen={isOpen}>
      <Typography variant="h6">Итоговая сводка за период: {reportPeriod}</Typography>
      <ExpandIconButton expanded={isOpen} onClick={toggleIsOpen} />
    </RefundChargebackReportSummaryPeriodStyled>
  );
};
