import React, { ReactElement, ReactNode } from 'react';

import { DatagridContextValue } from 'react-admin';

import { DatargidWithSummaryContext } from './DatargidWithFooterContext';

export const DatargidWithFooterContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: DatagridContextValue;
}): ReactElement => (
  <DatargidWithSummaryContext.Provider value={value}>
    {children}
  </DatargidWithSummaryContext.Provider>
);
