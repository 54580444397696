import {
  ChoicesContextProvider,
  ResourceContextProvider,
  useReferenceInputController,
} from 'ra-core';
import { RaRecord } from 'react-admin';

import { ReferenceInputProps } from '../ReferenceInput/ReferenceInput';

export type ReferenceInputWithChoicesProps = {
  choices: RaRecord[];
} & ReferenceInputProps;

export const ReferenceInputWithChoices: React.FC<ReferenceInputWithChoicesProps> = ({
  choices,
  ...props
}) => {
  const { children, reference, sort = { field: 'id', order: 'DESC' }, filter = {} } = props;

  const controllerProps = useReferenceInputController({
    ...props,
    sort,
    filter,
  });
  controllerProps.allChoices = [...choices, ...controllerProps.allChoices];

  return (
    <ResourceContextProvider value={reference}>
      <ChoicesContextProvider value={controllerProps}>{children}</ChoicesContextProvider>
    </ResourceContextProvider>
  );
};
