import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ChipField, TextField } from 'shared/react-admin/Fields';

import { Chip } from '../../../../shared/mui/Chips';
import { appColors } from '../../../../themes/variables';

export const StyledChipFieldLinked = styled(ChipField)({
  '.MuiChip-label': {
    color: blue[700],
    lineHeight: '12px',
  },
});

export const StyledTextField = styled(TextField)({
  cursor: 'pointer',
  color: blue[700],
  textDecoration: 'underline',
});

export const StyledChip = styled(Chip)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
