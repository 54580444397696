import { styled } from '@mui/material';
import { Datagrid } from 'shared/react-admin/Datagrids';

export const DatagridStyled = styled(Datagrid)({
  '& .column-merchant': {
    width: '200px',
  },
  '& .column-gateway': {
    width: '200px',
  },
  '& .column-currency': {
    width: '200px',
  },
  '& .column-paymentMin': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-paymentMax': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-payoutMin': {
    width: '158px',
    textAlign: 'right',
  },
  '& .column-payoutMax': {
    width: '168px',
    textAlign: 'right',
  },
  '& .column-createdAt': {
    width: '136px',
  },
  '& .column-updatedAt': {
    width: '136px',
  },
});
