import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Stack } from 'shared/mui/Stack/Stack';

type Props = {
  periodFrom: number;
  timezone?: string;
};

export const CumulativeReportPeriodDateField: React.FC<Props> = ({ periodFrom, timezone }) => {
  return (
    <Stack alignItems="start" direction="column" flexWrap="nowrap">
      <span>
        {formatInTimeZone(fromUnixTime(periodFrom), timezone || DEFAULT_TIMEZONE, 'dd.MM.yyyy')}
      </span>
    </Stack>
  );
};
