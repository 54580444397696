import dayjs, { Dayjs } from 'dayjs';
import { DateObject } from 'react-multi-date-picker';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';

type Props = {
  timezone: string | null;
  date: DateObject;
  dayjsValue?: Dayjs;
  time?: string;
};

export const DAY_START = 'start';
export const DAY_END = 'end';

export function setDayjs(props: Props): Dayjs {
  const { timezone, date, dayjsValue = dayjs(), time } = props;

  const newValue = dayjsValue
    .tz(timezone || DEFAULT_TIMEZONE)
    .set('year', date?.year)
    .set('month', date?.monthIndex)
    .set('date', date?.day);

  if (time) {
    if (time === DAY_START) {
      return newValue.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    }

    if (time === DAY_END) {
      return newValue.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999);
    }

    const [hour, minute] = time.split(':');
    return newValue.set('hour', Number(hour)).set('minute', Number(minute));
  }

  return newValue;
}
