import { DeleteStages } from 'constants/deleteStages';

import { queryClient } from 'App';
import { useGoBack } from 'hooks/useGoBack';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { Button, CancelButton, DeleteButton, EditLinkButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { Company, Merchant, Permission } from 'types';

import { MerchantsShowCommonInformationTable } from './MerchantsShowCommonInformationTable';
import { MerchantsArchiveDialog } from '../../components/MerchantsArchiveDialog/MerchantsArchiveDialog';
import { MerchantsUnarchiveDialog } from '../../components/MerchantsUnarchiveDialog/MerchantsUnarchiveDialog';

type MerchantsShowCommonInformationProps = {
  record?: Merchant;
};

export const MerchantsShowCommonInformation: React.FC<MerchantsShowCommonInformationProps> = ({
  record,
}): JSX.Element => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const { goBack } = useGoBack();
  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } =
    useRecordModal<Merchant>();
  const {
    record: company,
    handleOpenRecordModal: handleOpenCompanyModal,
    handleCloseRecordModal: handleCloseCompanyModal,
    openRecordModal: openCompanyModal,
  } = useRecordModal<Company>();

  const {
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Merchant>();
  const {
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Merchant>();

  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record,
    onSuccess: async () => {
      await queryClient.refetchQueries(['merchants']);
      handleCloseRecordModal();
      setStage(DeleteStages.Initial);
      goBack();
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModal();
  };

  return (
    <Card sx={{ border: '1px solid rgba(0,0,0,0.12)', width: '600px' }}>
      <CardHeader
        actions={
          hasResourceAccess('user_actions') && [
            <UserActionsIconButton entityName="merchant" key="user-action-link" />,
          ]
        }
        title="Общая информация"
      />
      <MerchantsShowCommonInformationTable
        handleOpenCompanyModal={handleOpenCompanyModal}
        record={record}
      />
      <CardActionsButtons
        leftActionsSlot={
          hasResourcePermissions('companies', [Permission.Full, Permission.Update]) &&
          (record?.archive ? (
            <Button
              key="archive-button"
              label="восстановить"
              onClick={() => handleOpenRecordModalUnarchive()}
              variant="contained"
            />
          ) : (
            <EditLinkButton />
          ))
        }
        rightActionsSlot={
          hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) &&
          (record?.archive ? (
            <DeleteButton onClick={() => handleOpenRecordModal()} variant="outlined" />
          ) : (
            <DeleteButton
              label="В архив"
              onClick={() => handleOpenRecordModalArchive()}
              variant="outlined"
            />
          ))
        }
      />
      {openRecordModalUnarchive && record && (
        <MerchantsUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={record}
        />
      )}
      {openRecordModalArchive && record && (
        <MerchantsArchiveDialog
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={record}
        >
          <CancelButton label="Вернуться к списку мерчантов" linkType="back" variant="outlined" />
        </MerchantsArchiveDialog>
      )}
      <DeleteStageDialog
        deleteOneRecord={deleteOneRecord}
        details={`Мерчант ${record?.name} будет удален без возможности восстановления. `}
        onClose={handleCloseDeleteDialog}
        open={openRecordModal}
        stage={stage}
        title="Удаление мерчанта"
      >
        <DeleteStageDialogDenyReason reason={denyDeleteReason}>
          <CancelButton
            key="history-back-button"
            label="Вернуться к списку мерчантов"
            onClick={handleCloseDeleteDialog}
            variant="outlined"
          />
        </DeleteStageDialogDenyReason>
      </DeleteStageDialog>
      {company && (
        <CompanyShowDialog
          company={company}
          onClose={handleCloseCompanyModal}
          open={openCompanyModal}
        />
      )}
    </Card>
  );
};
