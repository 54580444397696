import { MerchantNotificationsEventNamesType } from 'config/requests';

import { MerchantsShowNotificationsFormValues } from '../components/MerchantsShowNotificationsForm/MerchantsShowNotificationsForm';

export const getMerchantNotificationData = (
  formValues: MerchantsShowNotificationsFormValues,
  merchantId: string,
  eventNames?: MerchantNotificationsEventNamesType[],
) => {
  const { active, chatId, email, token, type, event, comment } = formValues;

  const currentEvents = Object.entries(event)
    .filter(([, value]) => !!value)
    .map(([event]) => event);
  const events =
    !!currentEvents.length && !!eventNames?.length
      ? currentEvents?.map((eventName) => {
          const currentEvent = eventNames.find(({ name }) => eventName === name);
          return { name: `/admin/merchant_notification_event_names/${currentEvent?.value}` };
        })
      : [];

  return {
    merchant: merchantId,
    active,
    comment: comment || '',
    notificationTransport: `/admin/notification_transports/${type}`,
    credentials: {
      ...(email && { email }),
      ...(token && { token }),
      ...(chatId && { chatId: Number(chatId) }),
    },
    events,
  };
};
