import * as React from 'react';

import { Card } from '@mui/material';
import { useNavigateToRoot } from 'hooks/useNavigateToRoot';
import { Link } from 'react-router-dom';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';

import { BankOperation } from '../../../../../types';

type Props = {
  record: BankOperation | undefined;
} & DialogProps;

export const BankOperationsCreateSuccessDialog: React.FC<Props> = ({ record, ...restProps }) => {
  const { toRoot } = useNavigateToRoot();

  const onClose = () => {
    restProps.onClose();
    toRoot();
  };

  return (
    <Dialog {...restProps} fullWidth>
      <Card>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Банковская операция успешно создана"
        />
        <CardContent>
          <Typography>
            Создание банковской операции успешно выполнено. Вы можете отправить запись на
            согласование или вернуться к списку банковских операций для дальнейшей работы.
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <Button
              color="primary"
              component={Link}
              key="btn-agreement"
              label="На согласование"
              to={{
                pathname: record ? `/bank_operations/${encodeURIComponent(record?.id)}/show` : '',
              }}
              variant="contained"
            />,
            <CancelButton
              key="back-button"
              label="Вернуться в список"
              onClick={toRoot}
              variant="outlined"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
