import { RaRecord } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';

import { EMPTY_FIELD } from '../../../../constants';

export type CurrencyFieldProps = {
  label?: string | React.ReactElement;
  alphaCodeSource: string;
  nameSource: string;
} & Omit<FunctionFieldProps, 'render'>;

export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  alphaCodeSource,
  nameSource,
  ...rest
}) => {
  return (
    <FunctionField
      label={label}
      render={(record: RaRecord) => {
        if (!record[alphaCodeSource] || !record[nameSource]) {
          return EMPTY_FIELD;
        }

        return (
          <Stack>
            <Typography fontWeight={500} variant="body2">
              {record[alphaCodeSource]}
            </Typography>
            <Typography color={appColors.secondary.main} variant="body2">
              {record[nameSource]}
            </Typography>
          </Stack>
        );
      }}
      {...rest}
    />
  );
};
