import { EMPTY_FIELD } from 'constants/emptyField';

import { UserDeleteModal } from 'components/Users/components/UserDeleteModal';
import { UsersListBulkActionsToolbar } from 'components/Users/UsersList/UsersListBulkActionsToolbar';
import { UsersListCorporateEmailField } from 'components/Users/UsersList/UsersListCorporateEmailField';
import { getUnixTime, secondsToMilliseconds } from 'date-fns';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ReferenceArrayField, SingleFieldList, TextField, useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { UserRoleChip } from 'shared/mui/Chips';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterResetButton,
  FilterClearButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  BooleanField,
  DateTimeField,
  FunctionField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { Permission, User } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { StyledDatagridWithStickyActions } from './UsersListContent.styled';
import { UsersListFilter, UsersListFilterFormValues } from './UsersListFilter';
import { UsersListFilterChips } from './UsersListFilterChips';

const FILTER_RESET_VALUES = {
  appliedDate: [null, null] as [DateRangePickerValue, DateRangePickerValue],
  lastName: '',
  firstName: '',
  roleEntities: [],
  email: '',
  twoFactor: null,
  enabled: null,
  reddyNumber: '',
};

export const UsersListContent: React.FC = () => {
  const { filterValues } = useListContext();
  const isListFilterExist = Object.keys(filterValues).length > 0;
  const { record, handleOpenRecordModal, openRecordModal, handleCloseRecordModal } =
    useRecordModal<User>();
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<UsersListFilterFormValues>({
      defaultValues: {
        appliedDate: [
          filterValues['lastActivity[gte]']
            ? secondsToMilliseconds(Number(filterValues['lastActivity[gte]']))
            : null,
          filterValues['lastActivity[lte]']
            ? secondsToMilliseconds(Number(filterValues['lastActivity[lte]']))
            : null,
        ],
        lastName: filterValues.lastName || null,
        firstName: filterValues.firstName || null,
        roleEntities: filterValues.roleEntities || [],
        email: filterValues.email || null,
        twoFactor: filterValues.twoFactor || null,
        enabled: filterValues.enabled || null,
        reddyNumber: filterValues.reddyNumber || null,
      },
      resetValues: FILTER_RESET_VALUES,
    });

  useSyncFilter<UsersListFilterFormValues>({
    appliedFilters,
    transform: (appliedFilters) => {
      const { appliedDate = [null, null], ...restFilters } = appliedFilters;
      return cleanEmpty({
        ...restFilters,
        'lastActivity[gte]': appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
        'lastActivity[lte]': appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
      });
    },
  });

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton key="clear-button" onClick={onReset} visible={isListFilterExist} />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <UsersListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <UsersListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <Box
        sx={{
          minHeight: '100%',
          display: 'initial',
        }}
      >
        <StyledDatagridWithStickyActions
          bulkActionButtons={
            hasResourcePermissions('users', [Permission.Full, Permission.Update]) && (
              <UsersListBulkActionsToolbar />
            )
          }
        >
          <TextField label="ID" sortBy="id" source="originId" />
          <FunctionField
            label="Пользователь"
            render={() => <UserProfileField showLink />}
            source="firstName"
          />
          <ReferenceArrayField
            label="Роль"
            reference="roles"
            sortable={false}
            source="roleEntities"
          >
            <SingleFieldList linkType={false}>
              <UserRoleChip />
            </SingleFieldList>
          </ReferenceArrayField>
          <BooleanField label="Активен" source="enabled" />
          <DateTimeField label="Последняя активность" source="lastActivity" />
          <TextField emptyText={EMPTY_FIELD} label="Reddy" source="reddyNumber" />
          <FunctionField
            label="Групповой email"
            render={() => <UsersListCorporateEmailField />}
            source="corporateEmail"
          />
          <BooleanField label="2FA" source="twoFactor" />
          <DateTimeField label="Дата создания" source="createdAt" />
          <DateTimeField label="Дата редактир." source="updatedAt" />
          <DatagridActions
            render={(record: User) => [
              hasResourceAccess('user_actions') && (
                <DatagridActionsUserActionsButton entityName="user" key="user-actions-button" />
              ),
              <DatagridActionsShowButton key="show_button" />,
              hasResourcePermissions('users', [(Permission.Full, Permission.Update)]) && (
                <DatagridActionsEditButton key="edit-button" />
              ),
              hasResourcePermissions('users', [Permission.Full, Permission.Delete]) && (
                <DatagridActionsDeleteButton
                  key="delete-button"
                  onClick={() => handleOpenRecordModal(record)}
                />
              ),
            ]}
          />
        </StyledDatagridWithStickyActions>
      </Box>
      {openRecordModal && (
        <UserDeleteModal
          handleCloseRecordModal={handleCloseRecordModal}
          openRecordModal={openRecordModal}
          record={record}
        />
      )}
    </>
  );
};
