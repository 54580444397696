import * as yup from 'yup';

export const BANK_OPERATION_SCHEMA = yup.object().shape({
  merchant: yup.string().required('Обязательное поле'),
  provider: yup.string().required('Обязательное поле'),
  merchantAccount: yup.string().required('Обязательное поле'),
  currency: yup.string().notRequired(),
  direction: yup.string().required('Обязательное поле'),
  type: yup.string().required('Обязательное поле'),
  dateOfOperation: yup.mixed().required('Обязательное поле'),
  amountPenny: yup
    .number()
    .typeError('Введите число. Например 5 или 0.5')
    .moreThan(0, 'Значение должно быть больше нуля')
    .required('Обязательное поле'),
  description: yup
    .string()
    .max(1024, 'Это значение слишком длинное. Оно должно содержать 1024 символов или меньше')
    .notRequired(),
});
