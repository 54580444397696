import { Role } from './roles';
import { User } from '../types';

export const DEFAULT_USER: User = {
  id: '',
  email: '',
  roles: [Role.User],
  reddyNumber: 1,
  twoFactor: true,
  firstName: '',
  lastName: '',
  isLoaded: false,
  permissions: {},
  providerAccounts: [],
  roleEntities: [],
  corporateEmail: null,
  createdAt: '',
  updatedAt: '',
  alerts: [],
};
