import { Alert } from 'shared/mui/Alert/Alert';

type Props = {
  userName: string;
  userLastName: string;
};

export const BankOperationsFinanceUserAlert: React.FC<Props> = ({ userName, userLastName }) => (
  <Alert severity="warning" sx={{ marginBottom: '8px' }}>
    {`Для пользователя ${userName} ${userLastName} отсутствует доступ к “Банковским операциям”, обратитесь к руководителю вашей рабочей группы для предоставления доступа`}
  </Alert>
);
