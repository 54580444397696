import { useGetOne } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

type Props = {
  onClose: () => void;
  reportPeriod: string;
  conversionCurrency?: string | null;
};

export const RefundChargebackReportSummarySuccessAlert: React.FC<Props> = ({
  onClose,
  reportPeriod,
  conversionCurrency,
}) => {
  const { data: currency } = useGetOne<Currency>(
    'currencies',
    {
      id: conversionCurrency ? conversionCurrency : '',
    },
    { enabled: !!conversionCurrency },
  );
  const currencyName = currency ? `${currency.alphaCode}, ${currency.name}` : '';

  return (
    <Alert onClose={onClose} severity="success" sx={{ marginBottom: '16px' }}>
      <Typography fontWeight={500}>Актуальный курс</Typography>
      <Typography>
        {`Отчет сформирован за период ${reportPeriod} и сконвертирован в валюте
          "${currencyName}" на текущую дату запроса.`}
      </Typography>
    </Alert>
  );
};
