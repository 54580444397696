import { Directions } from 'constants/directions';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type Props = {
  value: string;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
};

export const OrderConversionListDirectionToggle: React.FC<Props> = ({ value, onChange }) => {
  return (
    <ToggleButtonGroup
      aria-label="Platform"
      color="primary"
      exclusive
      onChange={onChange}
      value={value}
    >
      <ToggleButton value="">Все</ToggleButton>
      <ToggleButton value={Directions.Payment}>Пополнения</ToggleButton>
      <ToggleButton value={Directions.Payout}>Вывод</ToggleButton>
    </ToggleButtonGroup>
  );
};
