import { getTime, sub } from 'date-fns';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import {
  getZonedEndOfDay,
  getZonedEndOfMonth,
  getZonedEndOfWeek,
  getZonedStartOfDay,
  getZonedStartOfMonth,
  getZonedStartOfWeek,
} from 'shared/mui/DatePicker/utils/getZonedTime';

import { Shortcut } from '../DatePickerShortcuts';

export type Shortcuts = (timezone: string) => Shortcut[][];

export const DEFAULT_SHORTCUTS: Shortcuts = (timezone: string = DEFAULT_TIMEZONE) => [
  [
    {
      label: 'Сегодня',
      getValue: () => {
        const today = new Date();
        return [
          getTime(getZonedStartOfDay(today, timezone)),
          getTime(getZonedEndOfDay(today, timezone)),
        ];
      },
    },
    {
      label: 'Вчера',
      getValue: () => {
        const today = new Date();
        const yesterday = sub(today, { days: 1 });
        return [
          getTime(getZonedStartOfDay(yesterday, timezone)),
          getTime(getZonedEndOfDay(yesterday, timezone)),
        ];
      },
    },
    {
      label: 'Текущая неделя',
      getValue: () => {
        const today = new Date();
        return [
          getTime(getZonedStartOfWeek(today, timezone)),
          getTime(getZonedEndOfDay(today, timezone)),
        ];
      },
    },
    {
      label: 'Последняя неделя',
      getValue: () => {
        const today = new Date();
        const prevWeek = sub(today, { days: 7 });
        return [
          getTime(getZonedStartOfWeek(prevWeek, timezone)),
          getTime(getZonedEndOfWeek(prevWeek, timezone)),
        ];
      },
    },
    {
      label: 'Текущий месяц',
      getValue: () => {
        const today = new Date();
        return [
          getTime(getZonedStartOfMonth(today, timezone)),
          getTime(getZonedEndOfDay(today, timezone)),
        ];
      },
    },
    {
      label: 'Последний месяц',
      getValue: () => {
        const today = new Date();
        const prevMonth = sub(today, { months: 1 });
        return [
          getTime(getZonedStartOfMonth(prevMonth, timezone)),
          getTime(getZonedEndOfMonth(prevMonth, timezone)),
        ];
      },
    },
  ],
  [
    {
      label: 'Последние 24 часа',
      getValue: () => {
        const today = new Date();
        return [getTime(sub(today, { hours: 24 })), getTime(today)];
      },
    },
    {
      label: 'Последние 7 дней',
      getValue: () => {
        const today = new Date();
        return [getTime(sub(today, { days: 7 })), getTime(today)];
      },
    },
    {
      label: 'Последние 30 дней',
      getValue: () => {
        const today = new Date();
        return [getTime(sub(today, { days: 30 })), getTime(today)];
      },
    },
  ],
];
