import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getUnixTime } from 'date-fns';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { SimpleForm, useEditController } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditPage } from 'shared/react-admin/Pages/EditPage/EditPage';
import { BankOperation, Permission } from 'types';

import { BankOperationsFormNew } from '../components/BankOperationsFormNew';
import { BANK_OPERATION_SCHEMA } from '../schemas/schema';

export const BankOperationsEditNew: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: bankOperation,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<BankOperation>();

  return (
    <EditPage
      listName="Банковские операции"
      mutationMode="pessimistic"
      mutationOptions={{ onError: () => undefined }}
      sx={{ marginTop: 0, padding: 0, width: '550px' }}
      title="Редактирование банковской операции"
      transform={(data) => ({
        ...data,
        dateOfOperation: getUnixTime(new Date(data.dateOfOperation)),
        description: data.description || '',
        amountPenny: data.amountPenny.toString(),
      })}
    >
      <SimpleForm
        defaultValues={{ ...record, dateOfOperation: Date.parse(record?.dateOfOperation) }}
        noValidate
        resolver={yupResolver<FieldValues>(BANK_OPERATION_SCHEMA)}
        shouldUseNativeValidation={false}
        sx={{ '&.MuiCardContent-root': { padding: 0 } }}
        toolbar={false}
      >
        <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
          <BankOperationsFormNew />
        </CardContent>
        <CardActionsButtons
          leftActionsSlot={[
            <SaveButton key="save-button" label="Сохранить" />,
            <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
          ]}
          rightActionsSlot={
            hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) ? (
              <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
            ) : undefined
          }
        />
      </SimpleForm>
      <DeleteDialog
        details="Банковская операция будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={bankOperation}
        title="Удаление банковской операции"
      />
    </EditPage>
  );
};
