import { AuthStep } from 'constants/authStep';
import { BankOperationStatuses } from 'constants/bankOperationStatuses';
import { ExportJobTypes } from 'constants/exportFormats';
import { InviteStatuses } from 'constants/inviteStatuses';
import { RESPONSE_STATUSES } from 'constants/responseStatuses';

import { FieldProps, Identifier, RaRecord } from 'react-admin';

import { Alerts } from '../constants/alerts';
import { CommissionStatuses } from '../constants/commissionStatuses';
import { OrderAdjustmentsTypes } from '../constants/orderAdjustmentTypes';
import { Role } from '../constants/roles';

export const enum TotalValue {
  Company = 'company',
  Merchant = 'merchant',
  Provider = 'provider',
  Period = 'period',
}

declare global {
  export interface Window {
    grecaptcha: Grecaptcha;
  }

  type Nullable<T> = {
    [P in keyof T]: T[P] | null;
  };
}

export type AuthStepProps = {
  callback: (submit: (captchaToken: string) => void) => void;
};

export type AuthStepOneProps = {
  updateStep: (step: AuthStep) => void;
};

export type Grecaptcha = {
  execute: (captchaKey: string, additional: Record<string, unknown>) => PromiseLike<string>;
};

export type AppFieldProps = {
  source: string;
} & FieldProps;

export type AppExpressionProps = {
  expressionId: string;
} & FieldProps;

export type AppExpressionParamProps = {
  record: ExpressionParam & RaRecord;
  value: Array<string | number>;
} & FieldProps;

export type AppConditionGroupProps = {
  record?: GatewayRuleRecord;
  source?: string;
  conditionGroupId?: string;
} & FieldProps;

export type AppCurrencyExpressionParamProps = {
  codes: Array<string>;
} & FieldProps;

export type AppCountryExpressionParamProps = {
  codes: Array<string>;
} & FieldProps;

export type QrCode = {
  qrCode: string;
};

export type Merchant = {
  alias: string;
  company: string;
  companies: Company[];
  name: string;
  notificationKey: string;
  originId: string;
  id: string;
  archive: boolean;
  platform: string;
  partner: boolean;
} & RaRecord;

export type MerchantPlatform = {
  id: string;
  description: string;
  name: string;
  value: string;
};

export type Currency = {
  alphaCode: string;
  currencyRate: string;
  name: string;
  numericCode: string;
  originId: string | number;
  id: string | number;
} & RaRecord;

export type Provider = {
  id: string;
  alias: string;
  active: boolean;
  name: string;
  originId: string;
  requireAmount: boolean;
} & RaRecord;

export type GatewayRuleRecord = {
  id: string;
  active: boolean;
  conditionGroup: string;
  merchant: string;
} & RaRecord;

export const enum SecurityIssuesTypes {
  NoWhitelist = 'ip_whitelist_not_set',
  Open = 'open_for_all',
  NoProblem = 'no_problems_found',
}

export type ApiClient = {
  id: string;
  apiKeys: string[];
  alias: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  name: string;
  originId: string;
  roles: Role[];
  merchant?: string | null;
  merchants?: string[] | null;
  securityIssues: SecurityIssuesTypes[];
  activityUpdatedAtColor: 'red' | 'yellow' | 'green';
  activityUpdatedAtMessage: string;
  activityUpdatedAt: number;
} & RaRecord;

export type ApiKey = {
  id: string;
  active: boolean;
  value: string;
  apiClient: ApiClient;
} & RaRecord;

export type ApiClientWhiteIp = {
  apiClient: Identifier;
  id: string;
  ip: string;
  mask: number;
  createdAt: string;
  updatedAt: string;
};

export type ConditionGroup = {
  id: number;
  children: ConditionGroup[];
  isAnd: boolean;
  expressions: Expression[];
};

export type Expression = {
  id: number;
  value: string[] | number[];
  comparisonOperator: Identifier;
  expressionParam: Identifier;
};

export type ComparisonOperator = {
  id: number | string;
  name: string;
  alias: string;
  description: string;
};

export enum ExpressionParamFieldType {
  MultiSelect = 'multiple_select',
  Input = 'input',
}

export type ExpressionParam = {
  id: string;
  name: string;
  alias: string;
  fieldType: ExpressionParamFieldType;
};

export type AppReferenceFieldProps = {
  reference: string;
  source: string;
  sourceIdField?: string;
  sourceTitleField?: string;
  link?: string;
  record?: RaRecord;
} & FieldProps;

export type AppGroup = {
  id: string;
  originId: string;
  members: string[];
  name: string;
  head: string | null;
  providerAccounts: string[];
  color: string;
};

export type WorkTeamMember = {
  id: number;
  firstName: string;
  lastName: string;
};

export type WorkTeam = {
  id: string;
  name: string;
  manager: WorkTeamMember;
  members: WorkTeamMember[];
  countries: string[];
  color: string;
};

export type AppGroupProps = {
  group: AppGroup;
  record?: RaRecord;
};

export type ActionType = 'update' | 'create';

export type AppEditFormProps = {
  actionType: ActionType;
};

export type SelectItem<T> = {
  id: T;
  name: string;
};

export type RoleCategory = {
  id: string;
  type: string;
  description: string;
  name: string;
  value: string;
};

export const enum Permission {
  Full = 'full',
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Partial = 'partial',
  UpdatePost = 'updatePost',
  Patch = 'patch',
}

export type Permissions = Record<string, Permission[]>;

export type User = {
  id: string;
  email: string;
  roles: Role[];
  permissions: Permissions;
  reddyNumber: number;
  twoFactor: boolean;
  firstName: string;
  lastName: string;
  isLoaded: boolean;
  providerAccounts: string[];
  roleEntities?: string[];
  isTemporaryPassword?: boolean;
  corporateEmail: string | null;
  createdAt: string;
  updatedAt: string;
  showAlertEmptyAccess?: boolean;
  alerts: Alerts[];
};

export type OrdersFilterFormValues = {
  id: string;
  merchantReference: string;
  providerReferenceId: string;
  transaction: string;
  merchant: string;
  status: string;
  gateway: string;
  direction: string;
  amount: {
    gte: string;
    lte: string;
  };
  createdAt: {
    gte: Date;
    lte: Date;
  };
};

export type OrdersBulkUpdateValues = {
  order_ids: string;
  order_status: string;
};

export type OrdersBulkUpdateData = {
  order_ids: string[];
  order_status: string;
};

export type OrdersBulkUpdateResponse = {
  data: {
    found_ids: string[];
    not_found_ids: string[];
  };
};

export type ResponseError = {
  code: string;
  title: string;
};

export type OrdersBulkUpdateError = {
  source: {
    parameter: 'orderStatus' | 'orderIds';
  };
} & ResponseError;

export type ModalProps = {
  openModal: boolean;
  closeModal: () => void;
};

export type GroupsTabs = 'cabinets' | 'employees';

export type RestorePasswordResponse = {
  data: {
    message: string;
  };
};

export type AxiosParams = {
  contentType: string;
  accept?: string;
  unauthorized?: boolean;
};

export type ReportBalance = {
  reportItem: string;
  merchant: string;
  provider: string;
  merchantAccount: string;
  currency: string;
  balance?: number;
  convertedBalance: number;
  rollingReserve: number;
  convertedRollingReserve: number;
  hold: number;
  convertedHold: number;
  pendingBalance: number;
  convertedPendingBalance: number;
  availableBalance: number;
  convertedAvailableBalance: number;
  total?: TotalValue;
  label?: string;
  id?: number;
} & RaRecord;

export type BalanceListTotal = {
  convertedAvailableBalance: number;
  convertedBalance: number;
  convertedHold: number;
  convertedPendingBalance: number;
  convertedRollingReserve: number;
};

export type BalanceListGroup = {
  row: ReportBalance[];
  total: BalanceListTotal;
};

export type BalanceListGroupNew = {
  collectionItems: ReportBalance[];
  totalAmountItems: BalanceListTotal;
};

export type BalanceList = {
  reportItems: Record<number, BalanceListGroupNew>;
  totalAmountReport: BalanceListTotal;
};

export type EmployeesFilterState = {
  groupFilter: string[];
  nameFilter: string;
  cabinetsFilter: string[];
};

export type ProviderAccount = {
  id: string;
  name: string;
  partner: boolean;
  provider: string;
  wallets: string[];
  workTeam?: string | null;
} & RaRecord;

type ProviderAccountData = {
  id: string;
  type: string;
  name: string;
};

export type ProviderAccountNew = {
  id: string;
  company: ProviderAccountData;
  partner: boolean;
  provider: ProviderAccountData;
  merchants: ProviderAccountData[];
  merchantAccounts: MerchantAccount[];
  workTeam: WorkTeam;
  members: WorkTeamMember[];
} & RaRecord;

export type GatewayPilotModeSchedule = {
  id?: string;
  gateway?: string;
  schedule: boolean;
  periodStart: null | string | Date;
  periodEnd: null | string | Date;
  timeStart: null | string | Date;
  timeEnd: null | string | Date;
};

export type GatewayMethod = {
  id: string;
  gateway: string;
  method: string;
  methodName: string;
  active: boolean;
  pilotMode: boolean;
  autoDeactivation: boolean;
};

export type Gateway = {
  id: string;
  originId: string;
  active: boolean;
  alias: string;
  deactivationReason: string | null;
  pilotMode: boolean;
  defaultCurrency: Currency;
  gatewayPilotModeSchedule: GatewayPilotModeSchedule;
  direction: string;
  gatewayMethods: GatewayMethod[];
  methods: string[];
  name: string;
  provider: string;
  requireAmount: boolean;
  supportedCurrencies: Currency[];
  attemptIntervalConfig: number[];
  transactionRequestMaxAttempts: number;
  permissionsCount: number;
  locked: boolean;
  pushApi: boolean;
};

export type GatewayDeactivationReason = {
  id: string;
  originId: string;
  gatewayCount: number;
  reason: string;
};

export type OrderStatus = {
  id: string;
  alias: string;
  name: string;
  refund: boolean;
  success: boolean;
  description?: string;
  value: string;
};

export type RoleOrderStatus = {
  alias: string;
  name: string;
};

export type Company = {
  id: string;
  archive: boolean;
  createdAt: string;
  updatedAt: string;
  merchantCount: number;
  name: string;
} & RaRecord;

export type Limit = {
  id: string;
  currency: Currency;
  gateway: Gateway;
  paymentMax: string;
  paymentMin: string;
  payoutMax: string;
  payoutMin: string;
} & RaRecord;

export type UserWithMemberId = User & { isHead?: boolean; member?: string; name: string };

export type MethodLogo = {
  id: string;
  name?: string;
  filePath: string;
  originId: string;
} & RaRecord;

export type MethodType = {
  id: string;
  alias: string;
  name: string;
};

export type Method = {
  id: string;
  alias: string;
  name: string;
  active: boolean;
  logo: string;
  methodType: string;
  requestFormAmount: boolean;
};

type ExternalError = {
  id: string;
  errorCode: string;
  reason: string;
};

export type Order = {
  id: string;
  allowChargeback: boolean;
  allowRefund: boolean;
  amount: number;
  availableRefundAmount: number;
  commissions: string[];
  createdAt: string;
  updatedAt: string;
  currency: string;
  currencyRate: string[];
  customer: string;
  customerData: string;
  direction: string;
  externalError: string;
  gateway: string;
  merchant: string;
  merchantAccount: string;
  merchantReference: string;
  method: string;
  orderActionUrls: string;
  orderExternalError?: ExternalError;
  orderCustomData: string;
  orderNotificationUrl: string[];
  status: string;
  transactions: string[];
  providerReferenceId: string;
  originId: string;
  provider: string;
  orderDisputes: string[];
  orderExtendedInfo: Record<string, any>;
};

export type Hold = {
  id: number;
  merchantAccounts: string[];
  currency: string;
  value: number;
};

export type Credentials = {
  captchaToken: string;
  email: string;
  password: string;
  remember: boolean;
};

export type MerchantAccount = {
  id: string;
  active: boolean;
  balance: number;
  balanceUpdatedAt: number | null;
  balanceUpdatedAtColor?: 'red' | 'yellow' | 'green';
  balanceUpdatedAtMessage?: string;
  credentials: Credentials;
  currency: string;
  gateways: string[];
  hold?: string;
  merchant: string;
  merchants: string[];
  merchantCredentials: string;
  name: string;
  originId: string;
  locked?: boolean;
  permissionsCount?: number;
  provider: string;
  merchantIdEnabled: boolean;
};

export type ExpressionParamWithDataSelectItem = {
  id: number;
  name: string;
  value: string;
  active?: boolean;
};

export type ExpressionParamWithData = {
  id: number;
  name: string;
  fieldType: string;
  alias: string;
  comparisonOperators: ComparisonOperator[];
  data: ExpressionParamWithDataSelectItem[];
};

export enum FilterFieldTypes {
  STRING = 'string',
  ID = 'id',
  ID_ARRAY = 'id_array',
  BOOLEAN = 'boolean',
  EXISTS = 'exists',
  DATE_BETWEEN = 'dateBetween',
  AMOUNT_BETWEEN = 'amountBetween',
}

export type FilterFields = {
  [key: string]: { name: string; type: FilterFieldTypes };
};

export type Country = {
  id: string;
  name: string;
  alphaCode: string;
  numericCode: string;
};

export type OrderDispute = {
  id: string;
  order: string;
  status: string;
  openReason: string;
  closeReason: string;
  openedAt: number;
  closedAt: number;
  statusId?: string;
};

export type GatewayRuleInfo = {
  id: string;
  gatewayRuleName: string;
  createdAt: string;
  updatedAt: string;
  priority: boolean;
};

export type OrderMethod = {
  name: string;
  alias: string;
  gateway: string;
  logo: string;
  requestFormAmount: boolean;
  gatewayRuleInfo: GatewayRuleInfo[];
};

type OrderMonitoringPeriod = {
  dateFrom: string;
  dateTo: string;
};

export type OrderMonitoringStatisticValue = {
  count: number;
  isDropping: boolean;
  isThresholdLow?: boolean;
};

export type OrderMonitoringStatistic = {
  cancelled: OrderMonitoringStatisticValue;
  chargeback: OrderMonitoringStatisticValue;
  created: OrderMonitoringStatisticValue;
  expired: OrderMonitoringStatisticValue;
  failure: OrderMonitoringStatisticValue;
  pending: OrderMonitoringStatisticValue;
  period: OrderMonitoringPeriod;
  prepared: OrderMonitoringStatisticValue;
  refund: OrderMonitoringStatisticValue;
  success: OrderMonitoringStatisticValue;
  successRate: OrderMonitoringStatisticValue;
  summary: OrderMonitoringStatisticValue;
  waitingConfirmation: OrderMonitoringStatisticValue;
};

export type OrderMonitoring = {
  orderStatistic: OrderMonitoringStatistic[];
};

export type MappingRecord = {
  name: string;
  method: string;
  gatewayRule: string;
  originId: string;
  active: boolean;
  direction: string;
  mappingItems: string[];
} & RaRecord;

export type MappingItemRecord = {
  commissions: string[];
  mapping: string;
  gateway: string;
  merchantAccount: string;
  value: number;
} & RaRecord;

export type RoleEntity = {
  id: string;
  alias: string;
  name: string;
  roleCategories: string;
};

export type BankOperationComment = {
  id: number;
  text: string;
  createdAt: string;
  bankOperation: string;
  commentText: string;
  createdAtTimestamp: number;
  status: string;
  user: string;
};

export type BankOperation = {
  id: string;
  amountPenny: number | string;
  currency: string;
  gateway: string;
  provider: string;
  merchant: string;
  merchantAccount: string;
  originId: number;
  comment: string;
  dateOfOperation: string | number | Date;
  status: string;
  comments: BankOperationComment[];
};

export type BankOperationsFilterFormValues = {
  merchantAccount: string;
  provider: string;
  merchant: string;
  gateway: string;
  amountPennyFrom: string;
  amountPennyTo: string;
  startDate: Date;
  endDate: Date;
  direction: string;
  type: string;
};
export type ProviderReport = {
  _id: string;
  gateway: Gateway;
} & Provider;

export type ProviderReportResponse = { data: ProviderReport[]; totalItems: number };

export type BooleanToString = `${boolean}`;

export type BankOperationStatus = {
  value: BankOperationStatuses;
  description: string;
  id: string;
};

export type FilterInterval = {
  gte: string;
  lte: string;
};

export type Site = {
  id: number;
  active: boolean;
  merchantSpaceId: number;
  sourceMerchantId: string;
};

export type MerchantSpaces = {
  id: number;
  spaceName: string;
  active: boolean;
  sites: Site[];
  sourceCompany: string;
  createdAt: string;
  updatedAt: string;
  deactivationReason: unknown;
};

export type MerchantSpacesUsersSite = {
  id: number;
  active: boolean;
  sourceMerchantId: string;
};

export type CommissionComment = {
  id: number;
  commissionId: number;
  toStatus:
    | CommissionStatuses.OnApproval
    | CommissionStatuses.Rejected
    | CommissionStatuses.Cancelled;
  text: string;
  userId: number;
  createdAt: number;
};

export type Commission = {
  currency: string;
  fix: string;
  id: string;
  originId: number;
  status: CommissionStatuses;
  parent: string | null;
  startDate: number | null;
  endDate: number | null;
  startDateMilliseconds: number | null;
  endDateMilliseconds: number | null;
  max: string;
  min: string;
  name: string;
  percent: string;
  provider: string;
  merchant: string;
  active: boolean;
  alias: string;
  comment: string | null;
  comments: CommissionComment[];
  children: string[];
  transactionTypes: string[];
  zeroed: boolean;
  filePath: string;
  fileSize: string;
  fileName: string;
};

export type MerchantSpacesUsers = {
  active: boolean;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  merchantSpaceId: string;
  password: string;
  phoneNumber: string;
  role: string;
  sites: string[];
  updatedAt: string;
  inviteStatus: keyof typeof InviteStatuses;
};

export type MerchantSpaceUsersInvitation = {
  id: string;
  hash: string;
  status: string;
  userId: string;
  createdAt: number;
  sentAt: number;
  expiresAt: number;
};

export type AmountDataType = {
  convertedPaymentAmount: number;
  convertedCommissionAmount: number;
  convertedChargebackAmount: number;
  convertedRefundAmount: number;
  convertedPayoutAmount: number;
  convertedTotal: number;
  convertedBeginPeriodBalance: number;
  convertedEndPeriodBalance: number;
  convertedBankOperationPaymentAmount: number;
  convertedBankOperationPayoutAmount: number;
};

export type AccountAmountDataType = {
  paymentAmount: number;
  commissionAmount: number;
  chargebackAmount: number;
  refundAmount: number;
  payoutAmount: number;
  total: number;
  beginPeriodBalance: number;
  endPeriodBalance: number;
  bankOperationPaymentAmount: number;
  bankOperationPayoutAmount: number;
} & AmountDataType;

export type CompanyBalanceReport = {
  id: string;
  name: string;
  amountData: AmountDataType;
  merchants: MerchantBalanceReport[];
};

export type MerchantBalanceReport = {
  id: string;
  name: string;
  amountData: AmountDataType;
  providers: ProviderBalanceReport[];
};

export type ProviderBalanceReport = {
  id: string;
  name: string;
  amountData: AmountDataType;
  merchantAccounts: MerchantAccountBalanceReport[];
};

export type MerchantAccountBalanceReport = {
  id: string;
  name: string;
  amountData: AccountAmountDataType;
  currency: string;
  currencyCode: string;
};

export type BalanceReport = {
  items: CompanyBalanceReport[];
};

export type ExportFormat = 'xlsx' | 'csv';

export type CumulativeReport = {
  id: number;
  commissionValue: string;
  cumulativeTotalAmount: string;
  currencyCode: string;
  currencyName: string;
  paymentAmount: string;
  payoutAmount: string;
  periodFrom: number;
  periodTo: number;
  refundAmount: string;
  totalAmount: string;
  chargeback: string;
};

export type CumulativeReportSummary = {
  id: number;
  chargebackAmount: string;
  commissionValue: string;
  currencyCode: string;
  currencyName: string;
  merchantAccountId: number;
  merchantAccountName: string;
  paymentAmount: string;
  payoutAmount: string;
  refundAmount: string;
  totalAmount: string;
  merchantAccountCurrencyCode: string;
  merchantAccountCurrencyName: string;
  originalCommissionValue: string;
  originalPaymentAmount: string;
  originalPayoutAmount: string;
  originalRefundAmount: string;
  originalTotalAmount: string;
  originalChargebackAmount: string;
};

export type Transaction = {
  id: number;
  amount?: number;
  commissionValue: string;
  commissions: string[];
  createdAt: string;
  currency: Currency;
  currencyRate?: string;
  order: string;
  providerProcessedAt: string;
  type: string;
};

export type RoleInfo = {
  id: number;
  alias: string;
  name: string;
  roleCategories: RoleCategory;
  allowedStatusesEditing: string[];
};

export type NotificationBodyContent = {
  fileUrl: string;
  fileExtension: string;
  createdAt: number;
  exportType: ExportJobTypes;
  reportPeriod: {
    to: number;
    from: number;
  };
};

export type NotificationBodyContentOrderAdjustment = {
  createdAt: number;
  requestId: string;
  type: OrderAdjustmentsTypes;
};

export type NotificationBody = {
  icon: string;
  status: string;
  content: NotificationBodyContent | NotificationBodyContentOrderAdjustment;
};

export type Notification = {
  id: string;
  notificationType: string;
  body: NotificationBody;
  isViewed: false;
};

export type MerchantCustomer = {
  customerId: string;
  id: string;
  originId: string;
  merchantPayoutControl: string;
};

export type MerchantPayoutControl = {
  active: boolean;
  customers: MerchantCustomer[];
  id: number;
  mapping: null | string;
  merchant: string;
  originId: string;
};

export type MerchantCredentials = {
  name: string;
  comment: string | null;
  createdAt: string;
  credentials: Record<string, string>;
  id: string;
  merchant: string | null;
  originId: string;
  provider: { name: string } | null;
  updateAt: string;
  permissionsCount?: number;
  locked?: boolean;
};

export type MerchantAccess = {
  id: string;
  user: string;
  merchant: string;
  originId: number;
};

export type ErrorData = {
  detail: string;
  title: string;
  type: string;
  violations: {
    code: string;
    message: string;
    propertyPath: string;
  }[];
};

type MerchantNotificationEvent = {
  '@id': string;
  description: string;
  name: string;
  value: string;
  label: string;
};

type MerchantNotificationEventData = {
  id?: number;
  name: MerchantNotificationEvent;
};

export type MerchantNotification = {
  id: string;
  merchant?: string;
  active: boolean;
  notificationTransport: string;
  credentials: {
    email?: string;
    token?: string;
    chatId?: number;
  };
  events: MerchantNotificationEventData[];
  originId: string;
};

export type NotificationTransport = {
  '@id': string;
  name: string;
  value: string;
  description: string;
};

export type TransactionType = {
  id: string;
  name: string;
  alias: string;
  description: string;
  originId: string;
};

export type Customer = {
  id: number;
  isTest: boolean;
  merchant: string;
  customerId: string;
  paymentGatewaysData: string[];
  merchantPayoutControl: string | null;
};

export type OrderLog = {
  id: string;
  orderId: number;
  actionType: string;
  direction: string;
  host: string;
  requestIp: string;
  requestUrl: string;
  responseData: {
    statusCode: keyof typeof RESPONSE_STATUSES;
  };
  requestData: Record<string, any>;
  delay: number;
  statusChanges: string[][];
  createdAt: string;
  requestAt: string;
};

export type CreateOrderAdjustmentResponse = {
  count: number;
  requestId: string;
};

export type FileData = {
  entity: string;
  entityId: number;
  filePath: string;
  id: number;
  name: string;
};
