import { EMPTY_FIELD } from 'constants/emptyField';

import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { RefundChargebackReportOrder } from 'types/refundChagrebackReport';
import { formatCurrency } from 'utils';

type Props = Omit<FunctionFieldProps, 'render'> & {
  source: 'commissionValueByCurrencyOrder' | 'commissionValueByCurrencyCommission';
};

export const RefundChargebackReportCommissionAmountField: React.FC<Props> = ({ source }) => {
  return (
    <FunctionField
      render={(record: RefundChargebackReportOrder) => {
        if (!record?.[source]) {
          return EMPTY_FIELD;
        }

        return (
          <>
            <Typography component="span" variant="body2">
              {formatCurrency(record?.[source]?.value)}
            </Typography>{' '}
            <Typography component="span" sx={{ fontWeight: 500 }} variant="body2">
              {record?.[source]?.currency?.alphaCode}
            </Typography>
          </>
        );
      }}
    />
  );
};
