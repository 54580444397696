import { MenuItemLink, MenuItemLinkProps } from 'react-admin';

import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';

export type ProtectedMenuItemLinkProps = {
  resource: string;
} & MenuItemLinkProps;

export function ProtectedMenuItemLink({
  resource,
  ...restProps
}: ProtectedMenuItemLinkProps): JSX.Element | null {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  if (
    !hasResourceAccess(resource) ||
    !hasResourcePermissions(resource, [Permission.Full, Permission.Read])
  ) {
    return null;
  }

  return <MenuItemLink {...restProps} />;
}
