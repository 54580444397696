import * as React from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { RecordContextProvider } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardAdditionalButtons } from 'shared/mui/Card/CardAdditionalButtons/CardAdditionalButtons';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { CommissionsShowTable } from 'shared/widgets/CommissionsShowTable/CommissionsShowTable';
import { Commission, Permission } from 'types';

import { CommissionStatuses } from '../../../../../constants/commissionStatuses';
import { Role } from '../../../../../constants/roles';
import { getId, getLastUrlSegment } from '../../../../../utils';
import { CommissionsNewVersionButton } from '../../../../Commissions/components/CommissionsNewVersionButton/CommissionsNewVersionButton';
import { useCommissionsAvailableActions } from '../../../../Commissions/hooks/useCommissionsAvailableActions';

type Props = {
  open: boolean;
  onEdit: (record: Commission) => void;
  onDelete: (record: Commission) => void;
  handleOpenModalImplementError: (record: Commission) => void;
  handleOpenModalImplement: (record: Commission) => void;
  handleOpenModalNewVersion: (record: Commission) => void;
  handleOpenModalArchivedError: (record: Commission) => void;
  handleOpenModalAgreement: (record: Commission) => void;
  handleOpenModalAgree: (record: Commission) => void;
  handleOpenModalReject: (record: Commission) => void;
  handleOpenModalCancel: (record: Commission) => void;
  handleOpenModalArchived: (record: Commission) => void;
  onClose: () => void;
  record?: Commission;
};

export const MerchantShowCommissionsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  onDelete,
  handleOpenModalImplementError,
  handleOpenModalImplement,
  handleOpenModalNewVersion,
  handleOpenModalArchivedError,
  handleOpenModalAgreement,
  handleOpenModalAgree,
  handleOpenModalReject,
  handleOpenModalCancel,
  handleOpenModalArchived,
  record,
}) => {
  const { hasResourceAccess, hasResourcePermissions, roles } = usePermissions();
  const { data = [] } = useCommissionsAvailableActions(record?.originId);

  const renderActions = (): React.ReactElement[] | false => {
    const actions = [];

    if (
      !data.includes(CommissionStatuses.Implemented) &&
      getId(record?.status) === CommissionStatuses.Approved &&
      (roles.includes(Role.Admin) || roles.includes(Role.Tech))
    ) {
      actions.push(
        <Button
          color="primary"
          key="btn-implement-ex"
          label="Внедрить"
          onClick={() => record && handleOpenModalImplementError(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.CreateChildAvailable)) {
      actions.push(
        <CommissionsNewVersionButton
          key="btn-new-version"
          onClick={(e) => {
            e.preventDefault();
            record && handleOpenModalNewVersion({ ...record, parent: record?.id });
          }}
          pathname="/commissions/create-new"
        />,
      );
    }

    if (
      !data.includes(CommissionStatuses.Implemented) &&
      ![
        CommissionStatuses.Created,
        CommissionStatuses.OnApproval,
        CommissionStatuses.Rejected,
        CommissionStatuses.Cancelled,
        CommissionStatuses.Implemented,
        CommissionStatuses.Archived,
      ].includes(getId(record?.status) as CommissionStatuses) &&
      (roles.includes(Role.Payment) || roles.includes(Role.PaymentLead))
    ) {
      actions.push(
        <Button
          color="primary"
          key="btn-implement-ex"
          label="Внедрить"
          onClick={() => record && handleOpenModalImplementError(record)}
          variant="contained"
        />,
      );
    }

    if (
      !data.includes(CommissionStatuses.Implemented) &&
      !data.includes(CommissionStatuses.Archived) &&
      getId(record?.status) === CommissionStatuses.Implemented &&
      (roles.includes(Role.Admin) ||
        roles.includes(Role.Tech) ||
        roles.includes(Role.PaymentLead) ||
        roles.includes(Role.Payment))
    ) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Архивировать"
          onClick={() => record && handleOpenModalArchivedError(record)}
          variant="outlined"
        />,
      );
    }

    if (
      !data.includes(CommissionStatuses.Archived) &&
      ![
        CommissionStatuses.Created,
        CommissionStatuses.OnApproval,
        CommissionStatuses.Rejected,
        CommissionStatuses.Approved,
        CommissionStatuses.Cancelled,
        CommissionStatuses.Implemented,
        CommissionStatuses.Archived,
      ].includes(getId(record?.status) as CommissionStatuses) &&
      (roles.includes(Role.Payment) || roles.includes(Role.PaymentLead))
    ) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Архивировать"
          onClick={() => record && handleOpenModalArchivedError(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.OnApproval)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agreement"
          label="На согласование"
          onClick={() => record && handleOpenModalAgreement(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Approved)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agree"
          label="Согласовать"
          onClick={() => record && handleOpenModalAgree(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Rejected)) {
      actions.push(
        <Button
          color="error"
          key="btn-reject"
          label="Отклонить"
          onClick={() => record && handleOpenModalReject(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Implemented)) {
      actions.push(
        <Button
          color="primary"
          key="btn-implement"
          label="Внедрить"
          onClick={() => record && handleOpenModalImplement(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Cancelled)) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Аннулировать"
          onClick={() => record && handleOpenModalCancel(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Archived)) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Архивировать"
          onClick={() => {
            record && handleOpenModalArchived(record);
          }}
          variant="outlined"
        />,
      );
    }

    return actions;
  };

  const isShowButton = record?.status
    ? [CommissionStatuses.Created, CommissionStatuses.Rejected].includes(
        getLastUrlSegment(record?.status) as CommissionStatuses,
      )
    : false;

  const actions = renderActions();

  return (
    <Dialog fullWidth onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <CardHeader
          actions={[
            hasResourceAccess('user_actions') && (
              <UserActionsIconButton entityName="commission" key="user-action-link" />
            ),
            <CloseIconButton key="close-button" onClose={onClose} />,
          ]}
          title="Просмотр комиссии"
        />
        {actions && actions.length > 0 && <CardAdditionalButtons>{actions}</CardAdditionalButtons>}
        <Box sx={{ maxHeight: '650px', overflowY: 'auto' }}>
          <CommissionsShowTable omit="provider" />
        </Box>
        <CardActionsButtons
          leftActionsSlot={
            isShowButton &&
            hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  record && onDelete(record);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            isShowButton &&
            hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  record && onEdit(record);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
