import React from 'react';

import { getUnixTime } from 'date-fns';
import { ReactComponent as CumulativeReport } from 'images/cumulativeReport.svg';
import { Link } from 'react-router-dom';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { ListItemIcon } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { ListItemTextStyled } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions.styled';
import {
  CompanyBalanceReport,
  MerchantAccountBalanceReport,
  MerchantBalanceReport,
  ProviderBalanceReport,
} from 'types';
import { convertReactAdminIdToHydraId } from 'utils';
import { createFilterQueryString } from 'utils/createFilterQueryString';

type Props = {
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  merchantAccount: MerchantAccountBalanceReport;
  merchant: MerchantBalanceReport;
  provider: ProviderBalanceReport;
  company: CompanyBalanceReport;
};

export const DatagridActionsCumulativeReportLink: React.FC<Props> = ({
  merchantAccount,
  provider,
  merchant,
  company,
  appliedDate,
}) => {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      target="_blank"
      to={{
        pathname: '/cumulative_report',
        search: createFilterQueryString({
          company: convertReactAdminIdToHydraId(company.id, 'companies'),
          merchantAccounts: [convertReactAdminIdToHydraId(merchantAccount.id, 'merchant_accounts')],
          provider: convertReactAdminIdToHydraId(provider.id, 'providers'),
          merchant: convertReactAdminIdToHydraId(merchant.id, 'merchants'),
          currency: merchantAccount?.currencyCode,
          periodFrom: appliedDate && appliedDate[0] && getUnixTime(appliedDate[0]),
          periodTo: appliedDate && appliedDate[1] && getUnixTime(appliedDate[1]),
          timezone: appliedDate?.[2] || DEFAULT_TIMEZONE,
        }),
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <CumulativeReport />
        </ListItemIcon>
        <ListItemTextStyled primary={'Нарастающий итог\nза выбранный период'} />
      </MenuItem>
    </Link>
  );
};
