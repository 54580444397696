import { getUnixTime } from 'date-fns';

import { CommissionsFormValues } from '../components/CommissionsForm/CommissionsForm';

export const transform = (data: CommissionsFormValues): CommissionsFormValues => {
  return {
    ...data,
    min: data.min ? data.min + '' : null,
    max: data.max ? data.max + '' : null,
    fix: data.fix ? data.fix + '' : null,
    percent: data.percent ? data.percent + '' : null,
    startDate: data?.startDateMilliseconds ? getUnixTime(data?.startDateMilliseconds) : null,
    endDate: data?.endDateMilliseconds ? getUnixTime(data?.endDateMilliseconds) : null,
  };
};
