import { EMPTY_FIELD } from 'constants/emptyField';

import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { Link } from 'react-router-dom';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { MerchantAccount } from 'types';

import { getColor } from './utils/getColor';

export type LastActiveFieldProps = {
  label: string;
  source?: string;
  merchantId?: string;
};

export const LastActiveField: React.FC<LastActiveFieldProps> = ({ label, merchantId }) => {
  return (
    <FunctionField
      label={label}
      render={({
        balanceUpdatedAtColor,
        balanceUpdatedAtMessage,
        provider,
        gateways,
      }: MerchantAccount) => {
        const encodeFilter = encodeURIComponent(
          JSON.stringify({ merchant: merchantId, provider: [provider], gateway: gateways }),
        );
        return (
          <Link
            style={{ textDecoration: 'none' }}
            target="_blank"
            to={{
              pathname: '/monitoring/order_conversion',
              search: `filter=${encodeFilter}`,
            }}
          >
            <Stack
              alignItems="start"
              direction="row"
              gap={1}
              sx={{ cursor: balanceUpdatedAtMessage ? 'pointer' : 'auto' }}
            >
              <MonitorHeartOutlinedIcon htmlColor={getColor('icon', balanceUpdatedAtColor)} />
              <Typography color={getColor('text', balanceUpdatedAtColor)} component="span">
                {balanceUpdatedAtMessage || EMPTY_FIELD}
              </Typography>
            </Stack>
          </Link>
        );
      }}
    />
  );
};
