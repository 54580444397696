import { useEffect } from 'react';

import { useListContext } from 'react-admin';
import { DefaultValues } from 'react-hook-form';
import { FilterIdsValues } from 'shared/mui/NewFilter/hooks/useIdFilters';

type Props<TFieldValues> = {
  appliedFilters?: DefaultValues<TFieldValues>;
  appliedIdFilters?: FilterIdsValues;
  transform?: (appliedFilters: DefaultValues<TFieldValues>) => any;
  transformId?: (appliedIdFilters: FilterIdsValues) => any;
};

export function useSyncFilter<TFieldValues>({
  appliedFilters,
  appliedIdFilters,
  transform,
  transformId,
}: Props<TFieldValues>) {
  const { setFilters, filterValues, displayedFilters } = useListContext();
  useEffect(() => {
    const combinedFilters = {
      ...(appliedFilters ? (transform ? transform(appliedFilters) : appliedFilters) : {}),
      ...(appliedIdFilters ? (transformId ? transformId(appliedIdFilters) : appliedIdFilters) : {}),
    };
    setFilters(combinedFilters, displayedFilters);
  }, [appliedFilters, appliedIdFilters, JSON.stringify(filterValues)]);
}
