import { styled } from '@mui/material/styles';
import { Box } from 'shared/mui/Box';
import { TableCell } from 'shared/mui/Table';

export const StyledBox = styled(Box)({
  display: 'flex',
  top: '50%',
  flexDirection: 'column',
  padding: 0,
  width: '600px',
});

export const StyledTableCell = styled(TableCell)({
  padding: '2px 0',
  border: 'none',
  fontSize: '16px',
});
