import { useMemo, useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { BulkActionProps, useListContext, useResourceContext, useUnselectAll } from 'react-admin';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { BaseDialog } from 'shared/mui/Dialogs';
import { getId } from 'utils';

import { BankOperationStatusesNew } from '../../../../../../constants/bankOperationStatusesNew';
import { BankOperation } from '../../../../../../types';
import { useBulkDeleteBankOperations } from '../../../hooks/useBulkDeleteBankOperations';

export const BankOperationsBulkActionButtons: React.FC<BulkActionProps> = ({ selectedIds }) => {
  const { data } = useListContext<BankOperation>();
  const [isOpen, setIsOpen] = useState(false);
  const ids = selectedIds?.map((order) => Number(getId(order))) || [];
  const isDeletable = useMemo(
    () =>
      selectedIds?.every((id) => {
        const bankOperation = data.find((obj) => obj.id === id);
        return (
          !bankOperation ||
          bankOperation.status.includes(BankOperationStatusesNew.Created) ||
          bankOperation.status.includes(BankOperationStatusesNew.Rejected)
        );
      }),
    [selectedIds, data],
  );
  const { bulkDeleteBankOperations } = useBulkDeleteBankOperations();
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);

  return (
    <>
      {isDeletable && (
        <Button
          color="error"
          label="Удалить"
          onClick={() => setIsOpen(true)}
          startIcon={<RemoveCircleOutlineIcon />}
        />
      )}
      <BaseDialog
        message={`Подтвердите удаление ${selectedIds?.length} ${selectedIds?.length === 1 ? 'банковской операции' : 'банковских операций'}.`}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        rightActionsSlot={[
          <CancelButton
            key="cancel-button"
            label="Отменить"
            onClick={() => setIsOpen(false)}
            variant="outlined"
          />,
          <Button
            color="error"
            key="ok-button"
            label="Подтвердить"
            onClick={() => {
              bulkDeleteBankOperations.mutate({ ids });
              unselectAll();
              setIsOpen(false);
            }}
            variant="contained"
          />,
        ]}
        title="Удаление банковской операции"
      />
    </>
  );
};
