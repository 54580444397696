import React, { useEffect, useState } from 'react';

import { AutocompleteProps } from '@mui/material';
import { debounce } from 'lodash';
import {
  Validator,
  useChoicesContext,
  useInput,
  FieldTitle,
  InputHelperText,
  RaRecord,
} from 'react-admin';
import { Autocomplete } from 'shared/mui/Autocomplete/Autocomplete';
import { Box } from 'shared/mui/Box';
import { Chip } from 'shared/mui/Chips';
import { TextField } from 'shared/mui/TextField/TextField';
import { Typography } from 'shared/mui/Typography';
import { Gateway } from 'types';

export type MerchantShowAccountsGatewayInputProps<
  OptionType = Gateway,
  DisableClearable extends boolean | undefined = false,
  SupportCreate extends boolean | undefined = false,
> = Omit<
  AutocompleteProps<OptionType, true, DisableClearable, SupportCreate>,
  'renderInput' | 'options' | 'renderOption' | 'defaultValue' | 'optionText'
> & {
  defaultValue?: Gateway[];
  name: string;
  label: string;
  validate?: Validator | Validator[];
  error?: boolean;
  disabled?: boolean;
  newLogo?: string | null;
  helperText?: string;
};

const defaultSx = {
  '& .MuiInputBase-input': {
    minWidth: '0!important',
  },
};

export const MerchantShowAccountsGatewayInput: React.FC<MerchantShowAccountsGatewayInputProps> = ({
  name,
  label,
  defaultValue,
  disabled,
  sx,
  ...restProps
}) => {
  const {
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    field,
  } = useInput({
    name,
    source: name,
    ...restProps,
    defaultValue,
  });
  const { allChoices, selectedChoices, setFilters, displayedFilters } = useChoicesContext();
  const [inputValue, setInputValue] = useState('');
  const [currentSelectedChoices, setCurrentSelectedChoices] = useState<RaRecord[]>([]);

  useEffect(() => {
    if (selectedChoices?.length) {
      if (!currentSelectedChoices.length) {
        setCurrentSelectedChoices(selectedChoices);
        return;
      }
      const sortedSelectedChoices = selectedChoices.reduce<RaRecord[]>((arr, choice) => {
        const currentChoice = selectedChoices?.find(
          (selectedChoice) => selectedChoice?.id === choice?.id || selectedChoice?.id === choice,
        );

        if (currentChoice) {
          arr.push(currentChoice);
        }

        return arr;
      }, []);

      setCurrentSelectedChoices(sortedSelectedChoices);
    }
  }, [selectedChoices]);

  useEffect(() => {
    if (defaultValue) {
      setCurrentSelectedChoices(defaultValue);
    }
  }, [defaultValue?.length]);

  const handleInputChange = (event: any, newInputValue: string) => {
    if (!event) {
      return;
    }
    setInputValue(newInputValue);
    const debouncedSetFilters = debounce(() => {
      setFilters({ name: newInputValue }, displayedFilters);
    }, 500);
    debouncedSetFilters();
  };

  const handleBlur = () => {
    setFilters({}, displayedFilters);
  };

  return (
    <Autocomplete
      {...field}
      {...restProps}
      defaultValue={defaultValue}
      disabled={disabled}
      getOptionLabel={(option) => {
        return option?.name ?? '';
      }}
      id={name}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      multiple
      onBlur={handleBlur}
      onChange={(_e, data) => {
        if (!data.length) {
          setCurrentSelectedChoices([]);
        }
        const ids = data.map((item) => item.id || item);
        setCurrentSelectedChoices(data);
        return field.onChange(ids);
      }}
      onInputChange={handleInputChange}
      options={allChoices || []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          error={(isTouched || isSubmitted) && invalid}
          helperText={
            <InputHelperText
              error={error?.message}
              helperText={restProps.helperText}
              touched={isTouched || isSubmitted}
            />
          }
          label={
            <FieldTitle
              isRequired={(restProps as any)?.validate?.isRequired}
              label={label}
              resource={restProps.resource}
              source={name}
            />
          }
          size="medium"
          variant="outlined"
        />
      )}
      renderOption={(props, { name, pushApi }) => (
        <Box
          component="li"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start !important' }}
          {...props}
        >
          <Typography
            overflow="hidden"
            textAlign="left"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="100%"
          >
            {name}
          </Typography>
          <Typography color="secondary" fontSize={14} textAlign="left">
            Push API: {pushApi ? 'Да' : 'Нет'}
          </Typography>
        </Box>
      )}
      renderTags={(_, getTagProps) => {
        return currentSelectedChoices.map((option, index, array) => (
          <Chip
            {...getTagProps({ index: array.length - 1 - index })}
            key={index}
            label={option.name}
            variant="filled"
          />
        ));
      }}
      sx={{ ...defaultSx, ...sx }}
      value={defaultValue ? currentSelectedChoices : field.value}
    />
  );
};
