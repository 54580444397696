import { useEffect } from 'react';

import { useFilters } from 'hooks/useFilters';
import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { Filter, FilterRow } from 'shared/mui/FilterNew';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

type CompaniesFilterValues = {
  name: string;
  archive: boolean | null;
};

const DEFAULT_FILTERS = {
  name: '',
  archive: false,
};

export const CompaniesFilter: React.FC = () => {
  const { form, resetFilter, onSubmit } = useFilters<CompaniesFilterValues>({
    name: '',
    archive: null,
  });
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.size) {
      setFilters(DEFAULT_FILTERS, displayedFilters);
    }
  }, [searchParams, setFilters]);

  useEffect(() => {
    if (!('archive' in filterValues)) {
      form.setValue('archive', false, { shouldDirty: true });
    }
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Filter>
          <FilterRow sx={{ gridTemplateColumns: '1fr 1fr 272px' }}>
            <TextInputShrink
              helperText={false}
              label="Название"
              name="name"
              source="name"
              sx={{ marginBottom: 0 }}
              variant="outlined"
            />
            <AutocompleteInputShrink
              choices={[
                { id: true, name: 'В архиве' },
                { id: false, name: 'Активна' },
              ]}
              helperText={false}
              label="Состояние"
              name="archive"
              source="archive"
              variant="outlined"
            />
            <FilterActions>
              <FilterApplyButton type="submit" />
              <FilterResetButton onClick={resetFilter} />
            </FilterActions>
          </FilterRow>
        </Filter>
      </form>
    </FormProvider>
  );
};
