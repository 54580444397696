import { EMPTY_FIELD } from 'constants/emptyField';

import { useGetOne, useListContext } from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { Currency } from 'types';
import { RefundChargebackReportSummary } from 'types/refundChagrebackReport';
import { convertReactAdminIdToHydraId, formatCurrency } from 'utils';

type Props = {
  source: keyof RefundChargebackReportSummary;
} & Omit<FunctionFieldProps, 'render'>;

export const RefundChargebackReportSummaryDatagridFooterAmountField: React.FC<Props> = ({
  source,
  ...rest
}) => {
  const {
    filterValues: { conversionCurrency },
  } = useListContext();
  const { data: currency } = useGetOne<Currency>(
    'currencies',
    {
      id: convertReactAdminIdToHydraId(conversionCurrency, 'currencies'),
    },
    { enabled: !!conversionCurrency },
  );

  return (
    <FunctionField
      render={(record: RefundChargebackReportSummary) => {
        if (Number(record?.[source]) === 0) {
          return EMPTY_FIELD;
        }

        return (
          <Typography variant="body2">
            {formatCurrency(Number(record?.[source]))}{' '}
            <Typography component="span" fontWeight={500} variant="body2">
              {currency?.alphaCode}
            </Typography>
          </Typography>
        );
      }}
      source={source}
      {...rest}
    />
  );
};
