import { EMPTY_FIELD } from 'constants/emptyField';

import KeyIcon from '@mui/icons-material/Key';
import { blue } from '@mui/material/colors';
import { usePermissions } from 'hooks/usePermissions';
import { Link } from 'react-router-dom';
import { ArchiveConditionListChip } from 'shared/mui/Chips/ArchiveConditionListChip/ArchiveConditionListChip';
import {
  DatagridActions,
  DatagridActionsArchiveButton,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUnarchiveButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  DateTimeField,
  FunctionField,
  SingleFieldList,
  TextField,
  TooltipTextField,
} from 'shared/react-admin/Fields';
import { ReferenceArrayField } from 'shared/react-admin/ReferenceFields';

import { ChipFieldStyled, StyledDatagrid } from './MerchantsListDatagrid.styled';
import { Company, Merchant, Permission } from '../../../../types';
import { MerchantPlatformField } from '../../components/MerchantPlatformField/MerchantPlatformField';
import { MerchantsPartnersField } from '../../components/MerchantsPartnersField/MerchantsPartnersField';
import { NotificationKeyAccessDenied } from '../../components/NotificationKeyAccessDenied/NotificationKeyAccessDenied';
import { NotificationKeyTooltip } from '../../components/NotificationKeyTooltip/NotificationKeyTooltip';

type Props = {
  handleOpenRecordModalShow: (record?: Company) => void;
  handleOpenRecordModalDelete: (record?: Merchant) => void;
  handleOpenRecordModalArchive: (record?: Merchant) => void;
  handleOpenRecordModalUnarchive: (record?: Merchant) => void;
};

export const MerchantsListDatagrid: React.FC<Props> = ({
  handleOpenRecordModalShow,
  handleOpenRecordModalUnarchive,
  handleOpenRecordModalArchive,
  handleOpenRecordModalDelete,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <StyledDatagrid bulkActionButtons={false}>
      <TextField label="ID" sortBy="id" source="originId" />
      <FunctionField
        label="Название"
        render={(record: Merchant) => (
          <Link
            style={{ color: blue[700] }}
            to={{
              pathname: `/merchants/${encodeURIComponent(record.id)}/show`,
            }}
          >
            {record?.name}
          </Link>
        )}
        sortBy="id"
        source="name"
      />
      <TooltipTextField label="Алиас" source="alias" />
      <FunctionField
        label="Состояние"
        render={({ archive }: Merchant) => <ArchiveConditionListChip archive={archive} />}
        sortable={false}
        source="archive"
      />
      {hasResourceAccess('companies') && (
        <ReferenceArrayField
          label="Компании"
          reference="company"
          sortable={false}
          source="companies"
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record?: Company) => (
                <ChipFieldStyled
                  color="primary"
                  onClick={() => handleOpenRecordModalShow(record)}
                  record={record}
                  source="name"
                />
              )}
            />
          </SingleFieldList>
        </ReferenceArrayField>
      )}
      <FunctionField
        label="Ключ нотификации"
        render={({ notificationKey }: Merchant) =>
          notificationKey ? (
            <NotificationKeyTooltip icon={<KeyIcon />} value={notificationKey} />
          ) : (
            <NotificationKeyAccessDenied />
          )
        }
        sortable={false}
        source="notificationKey"
      />
      <TextField
        emptyText={EMPTY_FIELD}
        label={
          <span>
            ID канала для <br /> missMatch уведомлений
          </span>
        }
        source="missmatchChatId"
      />
      <MerchantsPartnersField label="Партнерский" source="partner" />
      <MerchantPlatformField label="Платформа" source="platform" />
      <DateTimeField label="Дата создания" source="createdAt" />
      <DateTimeField label="Дата редактир." source="updatedAt" />
      <DatagridActions
        render={(record: Merchant) => [
          hasResourceAccess('user_actions') && (
            <DatagridActionsUserActionsButton entityName="merchant" key="user-actions-button" />
          ),
          <DatagridActionsShowButton key="show-button" />,
          hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) &&
            (record.archive ? (
              <DatagridActionsUnarchiveButton
                key="unarchive-button"
                onClick={() => handleOpenRecordModalUnarchive(record)}
              />
            ) : (
              <DatagridActionsEditButton key="edit-button" />
            )),
          hasResourcePermissions('merchants', [Permission.Full, Permission.Delete]) &&
            (record.archive ? (
              <DatagridActionsDeleteButton
                key="delete-button"
                onClick={() => handleOpenRecordModalDelete(record)}
              />
            ) : (
              <DatagridActionsArchiveButton
                key="archive-button"
                onClick={() => handleOpenRecordModalArchive(record)}
              />
            )),
        ]}
      />
    </StyledDatagrid>
  );
};
