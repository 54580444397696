import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const DatagridStyled = styled(DatagridConfigurableWithStickyActions)({
  '& .column-originId': getColumnStyle(80),
  '& .column-startDateMilliseconds': getColumnStyle(120),
  '& .column-name': getColumnStyle(240),
  '& .column-parent': getColumnStyle(88),
  '& .column-status': getColumnStyle(240),
  '& .column-provider': getColumnStyle(200),
  '& .column-currency': getColumnStyle(200),
  '& .column-transactionTypes': getColumnStyle(200),
  '& .column-percent': getColumnStyle(90),
  '& .column-fix': getColumnStyle(90),
  '& .column-max': getColumnStyle(90),
  '& .column-min': getColumnStyle(90),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
});
