import { Role } from 'constants/roles';

import { useMemo, useState } from 'react';

import { useApiContext } from 'context/apiContext';
import { useListContext, WithListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { FinanceUserAlert } from 'shared/mui/Alert/FinanceUserAlert/FinanceUserAlert';
import { Box } from 'shared/mui/Box';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterIdButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterIdChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterIdDialog } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialog';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { FilterIdTypes, useIdFilters } from 'shared/mui/NewFilter/hooks/useIdFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { SelectColumnButtonFilter } from 'shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { Order } from 'types';
import { RefundChargebackReportSummary as RefundChargebackReportSummaryType } from 'types/refundChagrebackReport';
import { cleanEmpty } from 'utils/cleanEmpty';

import { FILTER_RESET_VALUES } from './constants/filterResetValues';
import { useGetReportPeriodDateTimeString } from './hooks/useGetReportPeriodDateTimeString';
import { RefundChargebackReportDatagrid } from './RefundChargebackReportDatagrid/RefundChargebackReportDatagrid';
import { RefundChargebackReportEmptyAlert } from './RefundChargebackReportEmptyAlert/RefundChargebackReportEmptyAlert';
import {
  RefundChargebackReportFilter,
  RefundChargebackReportListFilterValues,
} from './RefundChargebackReportFilter/RefundChargebackReportFilter';
import { RefundChargebackReportFilterChips } from './RefundChargebackReportFilter/RefundChargebackReportFilterChips';
import { RefundChargebackReportSummary } from './RefundChargebackReportSummary/RefundChargebackReportSummary';
import { RefundChargebackReportSummarySuccessAlert } from './RefundChargebackReportSummary/RefundChargebackReportSummarySuccessAlert/RefundChargebackReportSummarySuccessAlert';
import { getDateFilterDefaultValue } from './utils/getDateFilterDefaultValue';
import { getIdArrayFilterDefaultValue } from './utils/getIdArrayFilterDefaultValue';
import { getIdFilterDefaultValue } from './utils/getIdFilterDefaultValue';
import { transformFilterValues } from './utils/transformFilterValues';

type Props = {
  summary?: RefundChargebackReportSummaryType;
};

export const RefundChargebackReportListContent: React.FC<Props> = ({ summary }) => {
  const { filterValues, total } = useListContext();

  const isFiltersApplied = useMemo(() => Object.keys(filterValues).length > 0, [filterValues]);
  const [currentIdFilter, setCurrentIdFilter] = useState<FilterIdTypes>(FilterIdTypes.Id);
  const [isOpenIdDialog, setIsOpenIdDialog] = useState<boolean>(false);
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState<boolean>(true);

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<RefundChargebackReportListFilterValues>({
      mode: 'all',
      defaultValues: {
        appliedDate: [
          getDateFilterDefaultValue(filterValues?.createdAt?.gte),
          getDateFilterDefaultValue(filterValues?.createdAt?.lte),
          filterValues.timezone || null,
        ],
        updatedDate: [
          getDateFilterDefaultValue(filterValues?.updatedAt?.gte),
          getDateFilterDefaultValue(filterValues?.updatedAt?.lte),
          filterValues.timezone || null,
        ],
        company: getIdFilterDefaultValue(filterValues?.company, 'companies'),
        merchant: getIdFilterDefaultValue(filterValues?.merchant, 'merchants'),
        provider: getIdFilterDefaultValue(filterValues?.provider, 'providers'),
        status: filterValues?.status || [],
        merchantAccount: getIdArrayFilterDefaultValue(
          filterValues?.merchantAccount,
          'merchant_accounts',
        ),
        gateway: getIdArrayFilterDefaultValue(filterValues?.gateway, 'gateways'),
        currency: getIdArrayFilterDefaultValue(filterValues?.currency, 'currencies'),
        method: getIdArrayFilterDefaultValue(filterValues?.method, 'methods'),
        conversionCurrency: getIdFilterDefaultValue(filterValues?.conversionCurrency, 'currencies'),
        summary: Boolean(filterValues?.summary),
        impactToBalance: filterValues?.impactToBalance || null,
      },
      resetValues: FILTER_RESET_VALUES,
    });

  const {
    setAppliedFilter: setAppliedIdFilter,
    onSubmit: onSubmitIds,
    onReset: onResetIds,
    appliedIdFilters,
    form: formId,
  } = useIdFilters({
    defaultValues: {
      merchantReference: filterValues?.merchantReference || [],
      id: filterValues?.id || [],
      providerReferenceId: filterValues?.providerReferenceId || [],
    },
    resetValues: {
      merchantReference: [],
      id: [],
      providerReferenceId: [],
    },
  });

  useSyncFilter<RefundChargebackReportListFilterValues>({
    appliedFilters,
    appliedIdFilters,
    transform: transformFilterValues,
  });

  const formValuesWatched = form.watch();
  const idsFormValuesWatched = formId.watch();
  const isFormValuesExist = Object.values(cleanEmpty(formValuesWatched)).length > 0;
  const isIdFormValuesExist = Object.values(cleanEmpty(idsFormValuesWatched)).length > 0;

  const { user } = useApiContext();
  const isFinanceUser = user?.roles.includes(Role.Finance);
  const showFinanceUserAlert = total === 0 && isFinanceUser && !!Object.keys(filterValues).length;

  const reportPeriod = useGetReportPeriodDateTimeString({
    appliedDate: appliedFilters.appliedDate,
    updatedDate: appliedFilters.updatedDate,
  });

  return (
    <WithListContext
      render={({ isLoading, data: orders, total, isFetching }) => {
        const notFoundIds = appliedIdFilters.id?.filter(
          (id) => id && !orders?.some((order) => order?.id === Number(id)),
        );
        const notFoundMerchantIds = appliedIdFilters.merchantReference?.filter(
          (id) => !orders?.some((order) => order?.merchantReference === id),
        );
        const notFoundProviderIds = appliedIdFilters.providerReferenceId?.filter(
          (id) => !orders?.some((order) => order?.providerReferenceId === id),
        );
        const isShowSummary = !!orders.length && !!summary;

        return (
          <>
            <FilterToolbar
              leftActionsSlot={[
                <FilterIdButton
                  disabled={isFormValuesExist}
                  key="filter-by-id"
                  onClick={() => {
                    setIsOpenIdDialog(true);
                  }}
                />,
                <FilterButton
                  disabled={isIdFormValuesExist}
                  key="filter"
                  onClick={toggleDrawer(true)}
                />,
                <SelectColumnButtonFilter key="select-column" />,
                <FilterClearButton
                  key={`clear-${currentIdFilter}`}
                  onClick={() => {
                    onReset();
                    onResetIds(currentIdFilter);
                  }}
                  visible={isFiltersApplied}
                />,
              ]}
            />
            <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
              <FormProvider {...form}>
                <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
                  <FilterDrawerContent>
                    <RefundChargebackReportFilter />
                  </FilterDrawerContent>
                  <FilterDrawerActionsButtons>
                    <FilterApplyButton type="submit" />
                    <FilterResetButton label="Очистить" onReset={onReset} />
                  </FilterDrawerActionsButtons>
                </form>
              </FormProvider>
            </FilterDrawer>
            <FilterChipsToolbar appliedFilters={appliedFilters} appliedIdFilters={appliedIdFilters}>
              <FilterContextProvider
                appliedFilters={appliedFilters}
                onReset={onReset}
                setAppliedFilter={setAppliedFilter}
              >
                <FormProvider {...form}>
                  <RefundChargebackReportFilterChips />
                </FormProvider>
              </FilterContextProvider>
              <FilterContextProvider
                appliedFilters={appliedIdFilters}
                setAppliedFilter={setAppliedIdFilter}
              >
                <FormProvider {...formId}>
                  <FilterIdChip
                    label="ID Kubera"
                    limitTags={2}
                    name="id"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Id);
                    }}
                  />
                  <FilterIdChip
                    label="ID Мерчантов"
                    limitTags={2}
                    name="merchantReference"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Merchant);
                    }}
                  />
                  <FilterIdChip
                    label="ID Провайдеров"
                    limitTags={2}
                    name="providerReferenceId"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                      setCurrentIdFilter(FilterIdTypes.Provider);
                    }}
                  />
                </FormProvider>
              </FilterContextProvider>
            </FilterChipsToolbar>
            <FormProvider {...formId}>
              <FilterIdDialog
                currentIdFilter={currentIdFilter}
                notFoundIds={notFoundIds}
                notFoundMerchantIds={notFoundMerchantIds}
                notFoundProviderIds={notFoundProviderIds}
                onChange={(currentFilter) => setCurrentIdFilter(currentFilter)}
                onClose={() => setIsOpenIdDialog(false)}
                onReset={onResetIds}
                onSubmit={onSubmitIds}
                open={isOpenIdDialog}
              />
            </FormProvider>
            <Box sx={{ marginTop: '4px' }}>
              {showFinanceUserAlert && (
                <FinanceUserAlert userFirstName={user.firstName} userLastName={user.lastName} />
              )}
              {isShowSummary && isFiltersApplied && isShowSuccessAlert && (
                <RefundChargebackReportSummarySuccessAlert
                  conversionCurrency={appliedFilters?.conversionCurrency}
                  onClose={() => setIsShowSuccessAlert(false)}
                  reportPeriod={reportPeriod}
                />
              )}
            </Box>
            <Box sx={{ marginBottom: '24px' }}>
              {isShowSummary && isFiltersApplied && (
                <RefundChargebackReportSummary reportPeriod={reportPeriod} summary={summary} />
              )}
              <RefundChargebackReportDatagrid
                empty={!isFiltersApplied ? <RefundChargebackReportEmptyAlert /> : undefined}
                isLoading={isLoading}
                orders={isFiltersApplied && !isFetching ? (orders as Order[]) || [] : []}
                timezone={filterValues?.timezone}
                total={isFiltersApplied && !isFetching ? total || 0 : 0}
              />
            </Box>
          </>
        );
      }}
    />
  );
};
