import { Link } from 'react-router-dom';
import { Alert } from 'shared/mui/Alert/Alert';
import { Stack } from 'shared/mui/Stack/Stack';
import { TypographyTitle } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

export const MerchantShowCommissionsListNew = (): JSX.Element => {
  return (
    <Stack>
      <TypographyTitle size="small" sx={{ marginBottom: '24px', marginTop: '8px' }}>
        Список комиссий
      </TypographyTitle>
      <Alert severity="info">
        Работа с комиссиями в данном разделе временно недоступна, для управления комиссиями
        перейдите по{' '}
        <Link
          style={{ textDecoration: 'none', fontWeight: 500, color: appColors.primary.dark }}
          to="/commissions"
        >
          ссылке
        </Link>
      </Alert>
    </Stack>
  );
};
