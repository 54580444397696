import { CommissionStatuses } from 'constants/commissionStatuses';

export const CONVERSION_COLORS: Record<CommissionStatuses | 'default', string> = {
  [CommissionStatuses.Approved]: '#2E7D32',
  [CommissionStatuses.Created]: '#1976D2',
  [CommissionStatuses.Cancelled]: '#D32F2F',
  [CommissionStatuses.Archived]: '#BDBDBD',
  [CommissionStatuses.OnApproval]: '#FF9800',
  [CommissionStatuses.Implemented]: '#2E7D32',
  [CommissionStatuses.Rejected]: '#D32F2F',
  [CommissionStatuses.CreateChildAvailable]: '#BDBDBD',
  default: '#BDBDBD',
};
