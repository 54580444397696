import { styled } from '@mui/material/styles';
import { appColors } from 'themes/variables';

import { Box } from '../../../../mui/Box';

export const WrapperStyled = styled(Box)({
  display: 'flex',
  gap: '4px',
  padding: '13px 16px',
  border: `1px ${appColors.inputBorder} solid`,
  justifyContent: 'space-between',
});

export const ContentStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const RowStyled = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '4px',
});
