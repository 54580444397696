import { getTransactionsReportSummary } from 'config/requests';
import { useQuery } from 'react-query';
import { TransactionsReportSummary } from 'types/transactionsReportSummary';

import { OrdersListFilterFormValues } from '../OrdersListNew/OrderListContent';

type ReturnValue = {
  isLoading: boolean;
  isFetching: boolean;
  refetch: VoidFunction;
  data?: TransactionsReportSummary;
};

type Props = {
  filters: OrdersListFilterFormValues;
};

export function useGetTransactionsReportSummary({ filters }: Props): ReturnValue {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['transactions_report_summary', filters],
    () => getTransactionsReportSummary(filters),
    {
      enabled: !!filters,
    },
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
}
