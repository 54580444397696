import React from 'react';

import { appColors } from 'themes/variables';
import { AmountDataType } from 'types';

import { AmountDataField } from './BalanceReportAmountField';
import { BalanceReportBoxCell } from './BalanceReportBoxCell';
import { NAME_COLUMN_WIDTH } from './BalanceReportDatagrid/BalanceReportDatagrid';
import { StyledRowBox } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';
import { formatCurrency } from '../../../../utils';
import { useBalanceReportContext } from '../context/BalanceReportContext';

type Props = {
  paddingFirstCell: number;
  name: string;
  amountData: AmountDataType;
};

export const BalanceReportRow: React.FC<Props> = ({ paddingFirstCell, name, amountData }) => {
  const { filters } = useBalanceReportContext();

  return (
    <StyledRowBox firstCellWidth={NAME_COLUMN_WIDTH - paddingFirstCell}>
      <BalanceReportBoxCell>{name}</BalanceReportBoxCell>
      <BalanceReportBoxCell />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedBeginPeriodBalance)}
      />
      <AmountDataField
        color={appColors.success.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData.convertedPaymentAmount)}
      />
      <AmountDataField
        color={appColors.error.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData.convertedPayoutAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedRefundAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedChargebackAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedCommissionAmount)}
      />
      <AmountDataField
        color={appColors.error.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedBankOperationPayoutAmount)}
      />
      <AmountDataField
        color={appColors.success.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedBankOperationPaymentAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedTotal)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(amountData?.convertedEndPeriodBalance)}
      />
    </StyledRowBox>
  );
};
