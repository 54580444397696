import { styled } from '@mui/material';
import { DatagridWithFooter } from 'shared/react-admin/Datagrids';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

const getAlignedColumnStyle = (width: number) => {
  return { ...getColumnStyle(width), textAlign: 'right' };
};

export const DatargidWithFooterStyled = styled(DatagridWithFooter)({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottom: `1px solid ${appColors.divider}`,
  '.RaDatagrid-headerCell': {
    backgroundColor: appColors.tableHead,
    padding: '8px 16px',
    lineHeight: '18px',
  },
  '.MuiTableCell-root': {
    verticalAlign: 'middle',
  },
  '.MuiTableCell-body': {
    padding: '8px 16px',
  },
  '& .column-id': getColumnStyle(184),
  '& .column-currency': getColumnStyle(88),
  '& .column-refundCount': getAlignedColumnStyle(112),
  '& .column-originalRefundAmount': getAlignedColumnStyle(168),
  '& .column-originalRefundCommissionAmount': getAlignedColumnStyle(168),
  '& .column-originalRefundTotalAmount': getAlignedColumnStyle(168),
  '& .column-chargebackCount': getAlignedColumnStyle(112),
  '& .column-originalChargebackAmount': getAlignedColumnStyle(168),
  '& .column-originalChargebackCommissionAmount': getAlignedColumnStyle(168),
  '& .column-originalChargebackTotalAmount': getAlignedColumnStyle(168),
});
