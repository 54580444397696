export type TimezoneType = {
  name: string;
  offset: string;
};

export const TIMEZONES = [
  {
    name: 'Africa/Abidjan',
    offset: 'UTC',
  },
  {
    name: 'Africa/Accra',
    offset: 'UTC',
  },
  {
    name: 'Africa/Addis_Ababa',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Algiers',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Asmara',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Bamako',
    offset: 'UTC',
  },
  {
    name: 'Africa/Bangui',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Banjul',
    offset: 'UTC',
  },
  {
    name: 'Africa/Bissau',
    offset: 'UTC',
  },
  {
    name: 'Africa/Blantyre',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Brazzaville',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Bujumbura',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Cairo',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Casablanca',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Ceuta',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Conakry',
    offset: 'UTC',
  },
  {
    name: 'Africa/Dakar',
    offset: 'UTC',
  },
  {
    name: 'Africa/Dar_es_Salaam',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Djibouti',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Douala',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/El_Aaiun',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Freetown',
    offset: 'UTC',
  },
  {
    name: 'Africa/Gaborone',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Harare',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Johannesburg',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Juba',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Kampala',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Khartoum',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Kigali',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Kinshasa',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Lagos',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Libreville',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Lome',
    offset: 'UTC',
  },
  {
    name: 'Africa/Luanda',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Lubumbashi',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Lusaka',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Malabo',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Maputo',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Maseru',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Mbabane',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Mogadishu',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Monrovia',
    offset: 'UTC',
  },
  {
    name: 'Africa/Nairobi',
    offset: 'UTC +3',
  },
  {
    name: 'Africa/Ndjamena',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Niamey',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Nouakchott',
    offset: 'UTC',
  },
  {
    name: 'Africa/Ouagadougou',
    offset: 'UTC',
  },
  {
    name: 'Africa/Porto-Novo',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Sao_Tome',
    offset: 'UTC',
  },
  {
    name: 'Africa/Tripoli',
    offset: 'UTC +2',
  },
  {
    name: 'Africa/Tunis',
    offset: 'UTC +1',
  },
  {
    name: 'Africa/Windhoek',
    offset: 'UTC +2',
  },
  {
    name: 'America/Adak',
    offset: '-9 UTC',
  },
  {
    name: 'America/Anchorage',
    offset: '-8 UTC',
  },
  {
    name: 'America/Anguilla',
    offset: '-4 UTC',
  },
  {
    name: 'America/Antigua',
    offset: '-4 UTC',
  },
  {
    name: 'America/Araguaina',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Catamarca',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Cordoba',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Jujuy',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/La_Rioja',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Mendoza',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Rio_Gallegos',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Salta',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/San_Juan',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/San_Luis',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Tucuman',
    offset: '-3 UTC',
  },
  {
    name: 'America/Argentina/Ushuaia',
    offset: '-3 UTC',
  },
  {
    name: 'America/Aruba',
    offset: '-4 UTC',
  },
  {
    name: 'America/Asuncion',
    offset: '-4 UTC',
  },
  {
    name: 'America/Atikokan',
    offset: '-5 UTC',
  },
  {
    name: 'America/Bahia',
    offset: '-3 UTC',
  },
  {
    name: 'America/Bahia_Banderas',
    offset: '-6 UTC',
  },
  {
    name: 'America/Barbados',
    offset: '-4 UTC',
  },
  {
    name: 'America/Belem',
    offset: '-3 UTC',
  },
  {
    name: 'America/Belize',
    offset: '-6 UTC',
  },
  {
    name: 'America/Blanc-Sablon',
    offset: '-4 UTC',
  },
  {
    name: 'America/Boa_Vista',
    offset: '-4 UTC',
  },
  {
    name: 'America/Bogota',
    offset: '-5 UTC',
  },
  {
    name: 'America/Boise',
    offset: '-6 UTC',
  },
  {
    name: 'America/Cambridge_Bay',
    offset: '-6 UTC',
  },
  {
    name: 'America/Campo_Grande',
    offset: '-4 UTC',
  },
  {
    name: 'America/Cancun',
    offset: '-5 UTC',
  },
  {
    name: 'America/Caracas',
    offset: '-4 UTC',
  },
  {
    name: 'America/Cayenne',
    offset: '-3 UTC',
  },
  {
    name: 'America/Cayman',
    offset: '-5 UTC',
  },
  {
    name: 'America/Chicago',
    offset: '-5 UTC',
  },
  {
    name: 'America/Chihuahua',
    offset: '-6 UTC',
  },
  {
    name: 'America/Ciudad_Juarez',
    offset: '-6 UTC',
  },
  {
    name: 'America/Costa_Rica',
    offset: '-6 UTC',
  },
  {
    name: 'America/Creston',
    offset: '-7 UTC',
  },
  {
    name: 'America/Cuiaba',
    offset: '-4 UTC',
  },
  {
    name: 'America/Curacao',
    offset: '-4 UTC',
  },
  {
    name: 'America/Danmarkshavn',
    offset: 'UTC',
  },
  {
    name: 'America/Dawson',
    offset: '-7 UTC',
  },
  {
    name: 'America/Dawson_Creek',
    offset: '-7 UTC',
  },
  {
    name: 'America/Denver',
    offset: '-6 UTC',
  },
  {
    name: 'America/Detroit',
    offset: '-4 UTC',
  },
  {
    name: 'America/Dominica',
    offset: '-4 UTC',
  },
  {
    name: 'America/Edmonton',
    offset: '-6 UTC',
  },
  {
    name: 'America/Eirunepe',
    offset: '-5 UTC',
  },
  {
    name: 'America/El_Salvador',
    offset: '-6 UTC',
  },
  {
    name: 'America/Fort_Nelson',
    offset: '-7 UTC',
  },
  {
    name: 'America/Fortaleza',
    offset: '-3 UTC',
  },
  {
    name: 'America/Glace_Bay',
    offset: '-3 UTC',
  },
  {
    name: 'America/Goose_Bay',
    offset: '-3 UTC',
  },
  {
    name: 'America/Grand_Turk',
    offset: '-4 UTC',
  },
  {
    name: 'America/Grenada',
    offset: '-4 UTC',
  },
  {
    name: 'America/Guadeloupe',
    offset: '-4 UTC',
  },
  {
    name: 'America/Guatemala',
    offset: '-6 UTC',
  },
  {
    name: 'America/Guayaquil',
    offset: '-5 UTC',
  },
  {
    name: 'America/Guyana',
    offset: '-4 UTC',
  },
  {
    name: 'America/Halifax',
    offset: '-3 UTC',
  },
  {
    name: 'America/Havana',
    offset: '-4 UTC',
  },
  {
    name: 'America/Hermosillo',
    offset: '-7 UTC',
  },
  {
    name: 'America/Indiana/Indianapolis',
    offset: '-4 UTC',
  },
  {
    name: 'America/Indiana/Knox',
    offset: '-5 UTC',
  },
  {
    name: 'America/Indiana/Marengo',
    offset: '-4 UTC',
  },
  {
    name: 'America/Indiana/Petersburg',
    offset: '-4 UTC',
  },
  {
    name: 'America/Indiana/Tell_City',
    offset: '-5 UTC',
  },
  {
    name: 'America/Indiana/Vevay',
    offset: '-4 UTC',
  },
  {
    name: 'America/Indiana/Vincennes',
    offset: '-4 UTC',
  },
  {
    name: 'America/Indiana/Winamac',
    offset: '-4 UTC',
  },
  {
    name: 'America/Inuvik',
    offset: '-6 UTC',
  },
  {
    name: 'America/Iqaluit',
    offset: '-4 UTC',
  },
  {
    name: 'America/Jamaica',
    offset: '-5 UTC',
  },
  {
    name: 'America/Juneau',
    offset: '-8 UTC',
  },
  {
    name: 'America/Kentucky/Louisville',
    offset: '-4 UTC',
  },
  {
    name: 'America/Kentucky/Monticello',
    offset: '-4 UTC',
  },
  {
    name: 'America/Kralendijk',
    offset: '-4 UTC',
  },
  {
    name: 'America/La_Paz',
    offset: '-4 UTC',
  },
  {
    name: 'America/Lima',
    offset: '-5 UTC',
  },
  {
    name: 'America/Los_Angeles',
    offset: '-7 UTC',
  },
  {
    name: 'America/Lower_Princes',
    offset: '-4 UTC',
  },
  {
    name: 'America/Maceio',
    offset: '-3 UTC',
  },
  {
    name: 'America/Managua',
    offset: '-6 UTC',
  },
  {
    name: 'America/Manaus',
    offset: '-4 UTC',
  },
  {
    name: 'America/Marigot',
    offset: '-4 UTC',
  },
  {
    name: 'America/Martinique',
    offset: '-4 UTC',
  },
  {
    name: 'America/Matamoros',
    offset: '-5 UTC',
  },
  {
    name: 'America/Mazatlan',
    offset: '-7 UTC',
  },
  {
    name: 'America/Menominee',
    offset: '-5 UTC',
  },
  {
    name: 'America/Merida',
    offset: '-6 UTC',
  },
  {
    name: 'America/Metlakatla',
    offset: '-8 UTC',
  },
  {
    name: 'America/Mexico_City',
    offset: '-6 UTC',
  },
  {
    name: 'America/Miquelon',
    offset: '-2 UTC',
  },
  {
    name: 'America/Moncton',
    offset: '-3 UTC',
  },
  {
    name: 'America/Monterrey',
    offset: '-6 UTC',
  },
  {
    name: 'America/Montevideo',
    offset: '-3 UTC',
  },
  {
    name: 'America/Montserrat',
    offset: '-4 UTC',
  },
  {
    name: 'America/Nassau',
    offset: '-4 UTC',
  },
  {
    name: 'America/New_York',
    offset: '-4 UTC',
  },
  {
    name: 'America/Nome',
    offset: '-8 UTC',
  },
  {
    name: 'America/Noronha',
    offset: '-2 UTC',
  },
  {
    name: 'America/North_Dakota/Beulah',
    offset: '-5 UTC',
  },
  {
    name: 'America/North_Dakota/Center',
    offset: '-5 UTC',
  },
  {
    name: 'America/North_Dakota/New_Salem',
    offset: '-5 UTC',
  },
  {
    name: 'America/Nuuk',
    offset: '-1 UTC',
  },
  {
    name: 'America/Ojinaga',
    offset: '-5 UTC',
  },
  {
    name: 'America/Panama',
    offset: '-5 UTC',
  },
  {
    name: 'America/Paramaribo',
    offset: '-3 UTC',
  },
  {
    name: 'America/Phoenix',
    offset: '-7 UTC',
  },
  {
    name: 'America/Port-au-Prince',
    offset: '-4 UTC',
  },
  {
    name: 'America/Port_of_Spain',
    offset: '-4 UTC',
  },
  {
    name: 'America/Porto_Velho',
    offset: '-4 UTC',
  },
  {
    name: 'America/Puerto_Rico',
    offset: '-4 UTC',
  },
  {
    name: 'America/Punta_Arenas',
    offset: '-3 UTC',
  },
  {
    name: 'America/Rankin_Inlet',
    offset: '-5 UTC',
  },
  {
    name: 'America/Recife',
    offset: '-3 UTC',
  },
  {
    name: 'America/Regina',
    offset: '-6 UTC',
  },
  {
    name: 'America/Resolute',
    offset: '-5 UTC',
  },
  {
    name: 'America/Rio_Branco',
    offset: '-5 UTC',
  },
  {
    name: 'America/Santarem',
    offset: '-3 UTC',
  },
  {
    name: 'America/Santiago',
    offset: '-4 UTC',
  },
  {
    name: 'America/Santo_Domingo',
    offset: '-4 UTC',
  },
  {
    name: 'America/Sao_Paulo',
    offset: '-3 UTC',
  },
  {
    name: 'America/Scoresbysund',
    offset: '-1 UTC',
  },
  {
    name: 'America/Sitka',
    offset: '-8 UTC',
  },
  {
    name: 'America/St_Barthelemy',
    offset: '-4 UTC',
  },
  {
    name: 'America/St_Johns',
    offset: '-2.5 UTC',
  },
  {
    name: 'America/St_Kitts',
    offset: '-4 UTC',
  },
  {
    name: 'America/St_Lucia',
    offset: '-4 UTC',
  },
  {
    name: 'America/St_Thomas',
    offset: '-4 UTC',
  },
  {
    name: 'America/St_Vincent',
    offset: '-4 UTC',
  },
  {
    name: 'America/Swift_Current',
    offset: '-6 UTC',
  },
  {
    name: 'America/Tegucigalpa',
    offset: '-6 UTC',
  },
  {
    name: 'America/Thule',
    offset: '-3 UTC',
  },
  {
    name: 'America/Tijuana',
    offset: '-7 UTC',
  },
  {
    name: 'America/Toronto',
    offset: '-4 UTC',
  },
  {
    name: 'America/Tortola',
    offset: '-4 UTC',
  },
  {
    name: 'America/Vancouver',
    offset: '-7 UTC',
  },
  {
    name: 'America/Whitehorse',
    offset: '-7 UTC',
  },
  {
    name: 'America/Winnipeg',
    offset: '-5 UTC',
  },
  {
    name: 'America/Yakutat',
    offset: '-8 UTC',
  },
  {
    name: 'Asia/Aden',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Almaty',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Amman',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Anadyr',
    offset: 'UTC +12',
  },
  {
    name: 'Asia/Aqtau',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Aqtobe',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Ashgabat',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Atyrau',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Baghdad',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Bahrain',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Baku',
    offset: 'UTC +4',
  },
  {
    name: 'Asia/Bangkok',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Barnaul',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Beirut',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Bishkek',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Brunei',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Chita',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Choibalsan',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Colombo',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Damascus',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Dhaka',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Dili',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Dubai',
    offset: 'UTC +4',
  },
  {
    name: 'Asia/Dushanbe',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Famagusta',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Gaza',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Hebron',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Hong_Kong',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Hovd',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Irkutsk',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Jakarta',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Jayapura',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Jerusalem',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Kabul',
    offset: 'UTC +4',
  },
  {
    name: 'Asia/Kamchatka',
    offset: 'UTC +12',
  },
  {
    name: 'Asia/Karachi',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Kathmandu',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Khandyga',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Kolkata',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Krasnoyarsk',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Kuala_Lumpur',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Kuching',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Kuwait',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Macau',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Magadan',
    offset: 'UTC +11',
  },
  {
    name: 'Asia/Makassar',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Manila',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Muscat',
    offset: 'UTC +4',
  },
  {
    name: 'Asia/Nicosia',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Novokuznetsk',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Novosibirsk',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Omsk',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Oral',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Phnom_Penh',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Pontianak',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Pyongyang',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Qatar',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Qostanay',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Qyzylorda',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Riyadh',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Sakhalin',
    offset: 'UTC +11',
  },
  {
    name: 'Asia/Samarkand',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Seoul',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Shanghai',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Singapore',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Srednekolymsk',
    offset: 'UTC +11',
  },
  {
    name: 'Asia/Taipei',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Tashkent',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Tbilisi',
    offset: 'UTC +4',
  },
  {
    name: 'Asia/Tehran',
    offset: 'UTC +3',
  },
  {
    name: 'Asia/Thimphu',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Tokyo',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Tomsk',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Ulaanbaatar',
    offset: 'UTC +8',
  },
  {
    name: 'Asia/Urumqi',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Ust-Nera',
    offset: 'UTC +10',
  },
  {
    name: 'Asia/Vientiane',
    offset: 'UTC +7',
  },
  {
    name: 'Asia/Vladivostok',
    offset: 'UTC +10',
  },
  {
    name: 'Asia/Yakutsk',
    offset: 'UTC +9',
  },
  {
    name: 'Asia/Yangon',
    offset: 'UTC +6',
  },
  {
    name: 'Asia/Yekaterinburg',
    offset: 'UTC +5',
  },
  {
    name: 'Asia/Yerevan',
    offset: 'UTC +4',
  },
  {
    name: 'Atlantic/Azores',
    offset: 'UTC',
  },
  {
    name: 'Atlantic/Bermuda',
    offset: '-3 UTC',
  },
  {
    name: 'Atlantic/Canary',
    offset: 'UTC +1',
  },
  {
    name: 'Atlantic/Cape_Verde',
    offset: '-1 UTC',
  },
  {
    name: 'Atlantic/Faroe',
    offset: 'UTC +1',
  },
  {
    name: 'Atlantic/Madeira',
    offset: 'UTC +1',
  },
  {
    name: 'Atlantic/Reykjavik',
    offset: 'UTC',
  },
  {
    name: 'Atlantic/South_Georgia',
    offset: '-2 UTC',
  },
  {
    name: 'Atlantic/St_Helena',
    offset: 'UTC',
  },
  {
    name: 'Atlantic/Stanley',
    offset: '-3 UTC',
  },
  {
    name: 'Australia/Adelaide',
    offset: 'UTC +9',
  },
  {
    name: 'Australia/Brisbane',
    offset: 'UTC +10',
  },
  {
    name: 'Australia/Broken_Hill',
    offset: 'UTC +9',
  },
  {
    name: 'Australia/Darwin',
    offset: 'UTC +9',
  },
  {
    name: 'Australia/Eucla',
    offset: 'UTC +8',
  },
  {
    name: 'Australia/Hobart',
    offset: 'UTC +10',
  },
  {
    name: 'Australia/Lindeman',
    offset: 'UTC +10',
  },
  {
    name: 'Australia/Lord_Howe',
    offset: 'UTC +10',
  },
  {
    name: 'Australia/Melbourne',
    offset: 'UTC +10',
  },
  {
    name: 'Australia/Perth',
    offset: 'UTC +8',
  },
  {
    name: 'Australia/Sydney',
    offset: 'UTC +10',
  },
  {
    name: 'Europe/Amsterdam',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Andorra',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Astrakhan',
    offset: 'UTC +4',
  },
  {
    name: 'Europe/Athens',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Belgrade',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Berlin',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Bratislava',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Brussels',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Bucharest',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Budapest',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Busingen',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Chisinau',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Copenhagen',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Dublin',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Gibraltar',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Guernsey',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Helsinki',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Isle_of_Man',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Istanbul',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Jersey',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Kaliningrad',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Kirov',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Kyiv',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Lisbon',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Ljubljana',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/London',
    offset: 'UTC +1',
  },
  {
    name: 'Europe/Luxembourg',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Madrid',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Malta',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Mariehamn',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Minsk',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Monaco',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Moscow',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Oslo',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Paris',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Podgorica',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Prague',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Riga',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Rome',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Samara',
    offset: 'UTC +4',
  },
  {
    name: 'Europe/San_Marino',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Sarajevo',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Saratov',
    offset: 'UTC +4',
  },
  {
    name: 'Europe/Simferopol',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Skopje',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Sofia',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Stockholm',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Tallinn',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Tirane',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Ulyanovsk',
    offset: 'UTC +4',
  },
  {
    name: 'Europe/Vaduz',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Vatican',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Vienna',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Vilnius',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Volgograd',
    offset: 'UTC +3',
  },
  {
    name: 'Europe/Warsaw',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Zagreb',
    offset: 'UTC +2',
  },
  {
    name: 'Europe/Zurich',
    offset: 'UTC +2',
  },
  {
    name: 'Indian/Antananarivo',
    offset: 'UTC +3',
  },
  {
    name: 'Indian/Chagos',
    offset: 'UTC +6',
  },
  {
    name: 'Indian/Christmas',
    offset: 'UTC +7',
  },
  {
    name: 'Indian/Cocos',
    offset: 'UTC +6',
  },
  {
    name: 'Indian/Comoro',
    offset: 'UTC +3',
  },
  {
    name: 'Indian/Kerguelen',
    offset: 'UTC +5',
  },
  {
    name: 'Indian/Mahe',
    offset: 'UTC +4',
  },
  {
    name: 'Indian/Maldives',
    offset: 'UTC +5',
  },
  {
    name: 'Indian/Mauritius',
    offset: 'UTC +4',
  },
  {
    name: 'Indian/Mayotte',
    offset: 'UTC +3',
  },
  {
    name: 'Indian/Reunion',
    offset: 'UTC +4',
  },
];
