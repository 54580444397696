import React from 'react';

import { RaRecord, useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Row } from 'shared/mui/Row/Row';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { fieldWidth } from '../../../../../../themes/variables';
import { Gateway } from '../../../../../../types';
import { cleanEmpty } from '../../../../../../utils/cleanEmpty';
import { CardContentStyled } from '../MerchantsShowLimitsEditDialog.styled';

type Props = {
  merchantId: string | undefined;
};

export const MerchantsShowLimitsEditDialogForm: React.FC<Props> = ({ merchantId }) => {
  const { watch } = useFormContext();
  const gatewayWatched = watch('gateway');

  const { data: gateway } = useGetOne<Gateway>(
    'gateways',
    { id: gatewayWatched },
    { enabled: !!gatewayWatched },
  );

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CardContentStyled>
        <Row marginBottom={2}>
          <ReferenceInput
            filter={cleanEmpty({
              merchantAccounts: {
                merchants: [merchantId],
              },
            })}
            perPage={200}
            reference="gateways"
            source="gateway"
          >
            <AutocompleteInputShrink
              defaultValue=""
              filterToQuery={(searchText: string) => ({ name: searchText })}
              helperText={false}
              label="Шлюз"
              optionText="name"
              readOnly={true}
              size="medium"
              source="name"
              sx={fieldWidth.default}
              variant="outlined"
            />
          </ReferenceInput>
          <ReferenceInput
            filter={{
              id: [...(gateway?.supportedCurrencies || []), gateway?.defaultCurrency],
              active: true,
            }}
            perPage={1000}
            reference="currencies"
            sort={{ field: 'id', order: 'ASC' }}
            source="currency"
          >
            <AutocompleteInputShrink
              disabled={!gatewayWatched}
              filterToQuery={(searchText: string) => ({ currency: searchText })}
              helperText={false}
              label="Валюта"
              optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
              size="medium"
              source="name"
              sx={fieldWidth.default}
              variant="outlined"
            />
          </ReferenceInput>
        </Row>
        <Row marginBottom={2}>
          <TextInputShrink
            helperText={false}
            label="Мин. сумма пополнения"
            size="medium"
            source="paymentMin"
            sx={fieldWidth.default}
            variant="outlined"
          />
          <TextInputShrink
            helperText={false}
            label="Макс. сумма пополнения"
            size="medium"
            source="paymentMax"
            sx={fieldWidth.default}
            variant="outlined"
          />
        </Row>
        <Row>
          <TextInputShrink
            helperText={false}
            label="Мин. сумма вывода"
            size="medium"
            source="payoutMin"
            sx={fieldWidth.default}
            variant="outlined"
          />
          <TextInputShrink
            helperText={false}
            label="Макс. сумма вывода"
            size="medium"
            source="payoutMax"
            sx={fieldWidth.default}
            variant="outlined"
          />
        </Row>
      </CardContentStyled>
    </Box>
  );
};
