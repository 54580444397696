import { bulkDeleteBankOperation } from 'config/requests';
import { useMutation } from 'react-query';

import { queryClient } from '../../../../App';

export function useBulkDeleteBankOperations() {
  const mutation = useMutation(({ ids }: { ids: number[] }) => bulkDeleteBankOperation(ids), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('bank_operations');
    },
  });

  return { bulkDeleteBankOperations: mutation };
}
