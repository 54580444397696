import React, { useEffect, useState } from 'react';

import { Button, Popover, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { DateObject } from 'react-multi-date-picker';

import { Calendar } from '../../Calendar/Calendar';
import { DatePickerActions } from '../components/DatePickerActions/DatePickerActions';
import { DatePickerMaskInput } from '../components/DatePickerMaskInput/DatePickerMaskInput';
import { DatePickerTime } from '../components/DatePickerTime/DatePickerTime';
import {
  DEFAULT_DATE_TIME_MASK,
  DEFAULT_DATE_TIME_PICKER_MASK,
  DEFAULT_DATE_TIME_PICKER_PLACEHOLDER,
  DEFAULT_TIME_MASK,
} from '../config/defaultValues';
import {
  PopoverCalendarContainerStyled,
  PopoverContentStyled,
  PopoverDatesStyled,
  PopoverLayoutStyled,
  PopoverTimesStyled,
} from '../DatePicker.styled';
import { useAnchor } from '../hooks/useAnchor';
import { formatStringToTimestamp } from '../utils/formatStringToTimestamp';
import { fromTimestampToString } from '../utils/fromTimestampToString';
import { getValidCalendarDate } from '../utils/getValidCalendarDate';
import { isValidDate } from '../utils/isValidDate';
import { parseStringToDate } from '../utils/parseStringToDate';
import { setCurrentTime } from '../utils/setCurrentTime';
import { setToday } from '../utils/setToday';

export type DateRangePickerValue = number | null;

export type DateTimePickerProps = Omit<TextFieldProps, 'value' | 'onChange' | 'defaultValue'> & {
  minDate?: Date | string | number;
  maxDate?: Date | string | number;
  value: DateRangePickerValue;
  onChange?: (newValue: DateRangePickerValue) => void;
  defaultValue?: number;
};

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  variant = 'outlined',
  disabled,
  onChange: onChangeProp,
  minDate,
  maxDate,
  value,
  defaultValue,
  ...restProps
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const [error, setError] = useState(false);
  const [startTime, setStartTime] = useState<DateRangePickerValue>(value);

  const [resetValue, setResetValue] = useState<DateRangePickerValue>(value);

  const [inputValue, setInputValue] = useState<string | undefined>(
    fromTimestampToString(value, DEFAULT_DATE_TIME_MASK),
  );

  const [calendarValue, setCalendarValue] = useState<DateRangePickerValue>(value);

  const open = Boolean(anchorEl);
  const id = open ? 'date-time-picker-popover' : undefined;

  useEffect(() => {
    setStartTime(value);
    setInputValue(fromTimestampToString(value, DEFAULT_DATE_TIME_MASK));
    setCalendarValue(value);
  }, [value]);

  const debouncedOnChangeInputHandler = useDebounce((target: string) => {
    const start = target;

    if (!start) {
      setError(false);
      setStartTime(null);
      setCalendarValue(null);
      setInputValue(undefined);
      return;
    }

    const [startDate, startTime] = start.split(' ');

    setError(true);
    const validStartDate = isValidDate(startDate) ? formatStringToTimestamp(startDate) : null;

    if (validStartDate && isValidDate(startDate) && isValidDate(startTime, DEFAULT_TIME_MASK)) {
      const newValue = setCurrentTime(
        validStartDate,
        parseStringToDate(startTime, DEFAULT_TIME_MASK),
      );

      setError(false);
      setCalendarValue(newValue);
      setStartTime(newValue);
    } else {
      setCalendarValue(null);
      setStartTime(null);
    }

    setInputValue(target);
  }, 300);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedOnChangeInputHandler(event?.target?.value);
  }

  function onChangeCalendar(value: DateObject | null) {
    const validStart = getValidCalendarDate(value, startTime);

    setStartTime(validStart);

    setInputValue(fromTimestampToString(validStart, DEFAULT_DATE_TIME_MASK));
    setCalendarValue(validStart);
  }

  const onChangeStartTime = (value: Date) => {
    const startDate = calendarValue;

    if (!isValid(value)) {
      setStartTime(null);
      setCalendarValue(null);
      setInputValue(fromTimestampToString(null, DEFAULT_DATE_TIME_MASK));

      return;
    }

    if (!startDate) {
      const newValue = setToday(value);
      setStartTime(newValue);
      setCalendarValue(newValue);
      setInputValue(fromTimestampToString(newValue, DEFAULT_DATE_TIME_MASK));

      return;
    }

    const newDateTime = setCurrentTime(startDate, value);
    setStartTime(newDateTime);
    setCalendarValue(newDateTime);
    setInputValue(fromTimestampToString(newDateTime, DEFAULT_DATE_TIME_MASK));
  };

  function onClickApply() {
    if (onChangeProp) {
      onChangeProp(calendarValue);
      setResetValue(resetValue);
      handleClose();
    }
  }

  function onClickReset() {
    if (onChangeProp) {
      onChangeProp(resetValue);
      setStartTime(resetValue);
      setInputValue(fromTimestampToString(resetValue, DEFAULT_DATE_TIME_MASK));
      setCalendarValue(resetValue);
      handleClose();
    }
  }

  const onResetInput = () => {
    if (onChangeProp) {
      onChangeProp(null);
      setResetValue(defaultValue || null);
      setError(false);
    }
  };

  return (
    <>
      <DatePickerMaskInput
        {...restProps}
        disabled={disabled}
        error={error || restProps?.error}
        label={label}
        mask={DEFAULT_DATE_TIME_PICKER_MASK}
        onChange={onChangeInput}
        onClick={(e) => {
          if (disabled) {
            return;
          }

          handleClick(e);
        }}
        onResetInput={onResetInput}
        placeholder={DEFAULT_DATE_TIME_PICKER_PLACEHOLDER}
        value={inputValue}
        variant={variant}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        disableScrollLock={true}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverLayoutStyled
          sx={{
            height: '465px',
          }}
        >
          <PopoverCalendarContainerStyled>
            <PopoverContentStyled>
              <PopoverDatesStyled>
                <PopoverCalendarContainerStyled>
                  <Calendar
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={onChangeCalendar}
                    value={calendarValue}
                  />
                </PopoverCalendarContainerStyled>
                <PopoverTimesStyled>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePickerTime label="Время" onChange={onChangeStartTime} value={startTime} />
                  </LocalizationProvider>
                </PopoverTimesStyled>
              </PopoverDatesStyled>
            </PopoverContentStyled>
          </PopoverCalendarContainerStyled>
          <DatePickerActions>
            <Button onClick={onClickReset} variant="outlined">
              Отменить
            </Button>
            <Button onClick={onClickApply} variant="contained">
              Подтвердить
            </Button>
          </DatePickerActions>
        </PopoverLayoutStyled>
      </Popover>
    </>
  );
};
