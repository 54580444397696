import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { Currency } from '../../../../../types';

export const MerchantShowCommissionsListFilterValues: React.FC = () => {
  return (
    <>
      <TextInputShrink
        helperText={false}
        key="name"
        label="Название"
        resettable
        source="name"
        variant="outlined"
      />
      <ReferenceArrayInput perPage={100} reference="commission_statuses" source="status">
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Статус"
          limitTags={1}
          name="status"
          optionText="description"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput perPage={100} reference="providers" source="provider">
        <AutocompleteMultiselectInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          limitTags={1}
          name="provider"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput perPage={50} reference="currencies" source="currency.id">
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта"
          limitTags={1}
          name="currency.id"
          optionText="name"
          renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput perPage={100} reference="transaction_types" source="transactionTypes">
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Типы транзакций"
          name="transactionTypes"
          optionText="name"
        />
      </ReferenceArrayInput>
      <TextInputShrink
        helperText={false}
        label="ID комиссии"
        resettable
        source="id"
        variant="outlined"
      />
      <TextInputShrink
        helperText={false}
        label="ID родительской комиссии"
        resettable
        source="parent.id"
        variant="outlined"
      />
    </>
  );
};
