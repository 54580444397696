import * as yup from 'yup';

const paymentMaxField = yup.number().typeError('Введите число').nullable();
const paymentMinField = yup
  .number()
  .typeError('Введите число')
  .when('paymentMax', {
    is: (value: number) => !!value,
    then: (schema) =>
      schema.lessThan(
        yup.ref<number>('paymentMax'),
        'Значение "MIN" не может быть больше значения "MAX"',
      ),
  })
  .nullable();

const payoutMaxField = yup.number().typeError('Введите число').nullable();
const payoutMinField = yup
  .number()
  .typeError('Введите число')
  .when('payoutMax', {
    is: (value: number) => !!value,
    then: (schema) =>
      schema.lessThan(
        yup.ref<number>('payoutMax'),
        'Значение "MIN" не может быть больше значения "MAX"',
      ),
  })
  .nullable();

export const LIMITS_SCHEMA = yup.object().shape({
  paymentMax: paymentMaxField,
  paymentMin: paymentMinField,
  payoutMax: payoutMaxField,
  payoutMin: payoutMinField,
});
