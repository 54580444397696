import { useQuery } from 'react-query';

import { getFilesList } from '../../../../config/requests';

export const UPLOAD_FILES = 'upload_files';

export function useGetFiles(entity: string, id: number) {
  const { data, isLoading } = useQuery([UPLOAD_FILES, id], () => getFilesList(entity, id!), {
    enabled: Boolean(id),
  });

  return { data, isLoading };
}
