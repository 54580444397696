import { styled } from '@mui/material/styles';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';
import { getColumnStyle } from 'utils';

export const StyledDatagrid = styled(DatagridConfigurable)({
  '& .column-originId': {
    width: '50px',
    maxWidth: '120px',
  },
  '& .column-zeroed': getColumnStyle(),
  '& .column-parent': getColumnStyle(200),
  '& .column-startDateMilliseconds': getColumnStyle(200),
  '& .column-name': getColumnStyle(240),
  '& .column-status': getColumnStyle(206),
  '& .column-provider': getColumnStyle(165),
  '& .column-merchant': getColumnStyle(182),
  '& .column-currency': getColumnStyle(168),
  '& .column-transactionTypes': getColumnStyle(195),
  '& .column-percent': getColumnStyle(90),
  '& .column-fix': getColumnStyle(90),
  '& .column-max': getColumnStyle(90),
  '& .column-min': getColumnStyle(90),
});
