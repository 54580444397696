import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { AvatarGroup } from 'shared/mui/AvatarGroup/AvatarGroup';
import { Chip } from 'shared/mui/Chips';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { ChipField, TextField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

const trimmedStrStyle = {
  'a, span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
const columnStyle200 = getColumnStyle(200);

export const StyledDatagrid = styled(DatagridWithStickyActions)({
  '& .column-id': columnStyle200,
  '& .column-company': columnStyle200,
  '& .column-provider': columnStyle200,
  '& .column-members': getColumnStyle(152),
  '& .column-workTeam': columnStyle200,
  '& .column-merchants': {
    ...getColumnStyle(280),
    ...trimmedStrStyle,
  },
  '& .column-merchantAccounts': {
    ...getColumnStyle(320),
    ...trimmedStrStyle,
  },
});

export const StyledTextField = styled(TextField)({
  cursor: 'pointer',
  color: blue[700],
  textDecoration: 'underline',
});

export const StyledChipFieldLink = styled(ChipField)({
  '& .MuiChip-label': {
    color: blue[700],
  },
});

export const StyledAvatarGroup = styled(AvatarGroup)({
  width: 'fit-content',
  '& .MuiAvatarGroup-avatar': {
    height: 24,
    width: 24,
    fontSize: '11px',
    fontWeight: 500,
  },
});

export const StyledChip = styled(Chip)({
  '& .MuiChip-label': {
    color: appColors.primary.main,
  },
});
