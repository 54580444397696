import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { Typography } from 'shared/mui/Typography';

type Props = {
  isIdFormValuesExist: boolean;
  toggleDrawer: VoidFunction;
};

export const OrdersReportListEmptyAlert: React.FC<Props> = ({
  isIdFormValuesExist,
  toggleDrawer,
}) => {
  return (
    <Alert
      action={
        <Button
          disabled={isIdFormValuesExist}
          label="Фильтр"
          onClick={() => toggleDrawer()}
          size="small"
          sx={{ color: 'inherit' }}
        />
      }
      severity="info"
    >
      <Typography>
        Чтобы сформировать отчет по заказам, выберите период и необходимые параметры в фильтре
      </Typography>
    </Alert>
  );
};
