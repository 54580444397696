import { getUnixTime } from 'date-fns';
import { DefaultValues } from 'react-hook-form';
import { getId } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';

import { RefundChargebackReportListFilterValues } from '../RefundChargebackReportFilter/RefundChargebackReportFilter';

export const transformFilterValues = (
  filterValues: DefaultValues<RefundChargebackReportListFilterValues>,
): RefundChargebackReportListFilterValues => {
  const {
    appliedDate = [null, null, null],
    updatedDate = [null, null, null],
    company = null,
    merchant = null,
    provider = null,
    conversionCurrency = null,
    summary = false,
    currency = [],
    merchantAccount = [],
    gateway = [],
    method = [],
    ...restFilters
  } = filterValues;

  return cleanEmpty({
    ...restFilters,
    createdAt: {
      gte: appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
      lte: appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
    },
    updatedAt: {
      gte: updatedDate?.[0] ? getUnixTime(updatedDate?.[0]) : undefined,
      lte: updatedDate?.[1] ? getUnixTime(updatedDate?.[1]) : undefined,
    },
    timezone: appliedDate?.[2] || updatedDate?.[2] || null,
    company: company ? getId(company) : null,
    merchant: merchant ? getId(merchant) : null,
    provider: provider ? getId(provider) : null,
    conversionCurrency: conversionCurrency && summary ? getId(conversionCurrency) : null,
    currency: currency?.map((item) => getId(item)),
    merchantAccount: merchantAccount?.map((item) => getId(item)),
    gateway: gateway?.map((item) => getId(item)),
    method: method?.map((item) => getId(item)),
    summary: summary ? '1' : null,
  });
};
