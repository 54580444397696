import { EMPTY_FIELD } from 'constants/emptyField';

import { useRecordContext } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { BankOperationComment } from 'types';

import { AvatarWrapperStyled, BoxStyled } from './BankOperationsListComment.styled';
import { BankOperationsListCommentUser } from './BankOperationsListCommentUser/BankOperationsListCommentUser';
import { BankOperationStatusChipField } from '../../components/BankOperationStatusChipField/BankOperationStatusChipField';

export const BankOperationsListComment: React.FC = () => {
  const record = useRecordContext<BankOperationComment>();

  return (
    <BoxStyled>
      <AvatarWrapperStyled>
        <ReferenceField link={false} reference="users" sortable={false} source="user">
          <BankOperationsListCommentUser createdAt={record?.createdAt} />
        </ReferenceField>
        <ReferenceField
          emptyText={EMPTY_FIELD}
          label="Статус"
          link={false}
          reference="bank_operation_statuses"
          source="status"
        >
          <BankOperationStatusChipField source="description" />
        </ReferenceField>
      </AvatarWrapperStyled>
      <Box sx={{ overflowX: 'auto' }}>
        <Typography>{record?.commentText}</Typography>
      </Box>
    </BoxStyled>
  );
};
