import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

export const BoxStyled = styled(Box)({
  marginBottom: '24px',
});

export const RefundChargebackReportSummaryPeriodStyled = styled('div')(
  ({ isOpen }: { isOpen: boolean }) => ({
    width: '100%',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${appColors.divider}`,
    borderBottom: isOpen ? 'none' : `1px solid ${appColors.divider}`,
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: isOpen ? 0 : '4px',
    borderBottomLeftRadius: isOpen ? 0 : '4px',
    padding: '0 16px',
    backgroundColor: appColors.background,
  }),
);

export const TypographyStyled = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
  color: blue[700],
  cursor: 'pointer',
  fontSize: '14px',
});

export const RefundChargebackReportSummaryAmountBox = styled(Box)({
  width: '100%',
  height: '48px',
  backgroundColor: appColors.tableHead,
  border: `1px solid ${appColors.divider}`,
});
