import { queryClient } from 'App';
import { useDelete } from 'react-admin';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { FileData } from 'types';

import { UPLOAD_FILES } from '../../hooks/useGetFiles';

type Props = {
  onClose: VoidFunction;
  open: boolean;
  record?: FileData;
};

export const TableFileViewerDeleteDialog: React.FC<Props> = ({ onClose, open, record }) => {
  const [deleteOne, { isLoading }] = useDelete(
    'upload_files',
    {
      id: `admin/upload_files/${record?.id}` || '',
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([UPLOAD_FILES]);
        onClose();
      },
    },
  );

  return (
    <DeleteDialog
      details={`После подтверждения, вложенный файл ${record?.name} будет недоступен для восстановления.`}
      isLoading={isLoading}
      onClose={onClose}
      onDelete={deleteOne}
      onSuccess={onClose}
      open={open}
      title="Удалить вложение?"
    />
  );
};
