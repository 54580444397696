import * as React from 'react';

import { RecordContextProvider } from 'react-admin';
import { Card, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { CommissionsListComment } from '../../../components/Commissions/CommissionsListNew/CommissionsListComment/CommissionsListComment';
import { Commission } from '../../../types';
import { truncateString } from '../../../utils/truncateString';
import { SingleFieldList } from '../../react-admin/Fields';
import { ReferenceArrayField } from '../../react-admin/ReferenceFields';

type Props = DialogProps & {
  record: Commission | undefined;
};

export const CommissionsCommentDialog: React.FC<Props> = ({ record, ...restProps }) => {
  return (
    <Dialog {...restProps} fullWidth transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Card>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={restProps.onClose} />]}
            sx={{ height: 'auto' }}
            title={
              <>
                Комментарии к комиссии <br /> {truncateString(record?.name || '', 30)}
              </>
            }
          />
          <CardContent sx={{ padding: '0px 24px 24px 24px!important' }}>
            <ReferenceArrayField reference="commission_comments" sortable={false} source="comments">
              <SingleFieldList linkType={false}>
                <CommissionsListComment />
              </SingleFieldList>
            </ReferenceArrayField>
          </CardContent>
        </Card>
      </RecordContextProvider>
    </Dialog>
  );
};
