import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { CreateButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ListPage } from 'shared/react-admin/Pages/ListPage/ListPage';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { BankOperation, MerchantAccount, Permission } from 'types';

import { BankOperationsDatagrid } from './BankOperationsDatagrid/BankOperationsDatagrid';
import { BankOperationsFilter } from './BankOperationsFilterNew/BankOperationsFilter';
import { BankOperationsListCommentDialog } from './BankOperationsListCommentDialog/BankOperationsListCommentDialog';

export const BankOperationsListNew: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const {
    record,
    handleOpenRecordModal: handleOpenDeleteModal,
    openRecordModal: openDeleteModal,
    handleCloseRecordModal: handleCloseDeleteModal,
  } = useRecordModal<BankOperation>();

  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  const {
    record: recordComment,
    handleOpenRecordModal: handleOpenRecordModalComment,
    openRecordModal: openRecordModalComment,
    handleCloseRecordModal: handleCloseRecordModalComment,
  } = useRecordModal<BankOperation>();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-button" />
        ),
      ]}
      empty={false}
      headerTitle={{
        titleText: 'Банковские операции',
        tooltipText:
          'Ручная операция, связанная с созданием заявки на движение денежных средств (с направлением на ввод или вывод).',
      }}
      sort={{ field: 'id', order: 'DESC' }}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'auto',
        },
      }}
    >
      <DeleteDialog
        details="Банковская операция будет удалена без возможности восстановления."
        onClose={handleCloseDeleteModal}
        open={openDeleteModal}
        record={record}
        title="Удаление банковской операции"
      />
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
      <BankOperationsListCommentDialog
        onClose={handleCloseRecordModalComment}
        open={openRecordModalComment}
        record={recordComment}
      />
      <BankOperationsFilter />
      <BankOperationsDatagrid
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenMerchantAccountModal={handleOpenMerchantAccountModal}
        handleOpenRecordModalComment={handleOpenRecordModalComment}
      />
    </ListPage>
  );
};
