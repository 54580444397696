import { getBankOperationsAvailableActions } from 'config/requests';
import { useQuery } from 'react-query';

export const useBankOperationsAvailableActions = (id: number | undefined) => {
  const { data, isLoading } = useQuery(
    ['bank_operations/available', id],
    () => getBankOperationsAvailableActions(id!),
    {
      enabled: Boolean(id),
    },
  );

  return { data, isLoading };
};
