import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useRecordContext } from 'react-admin';
import { BankOperation, FileData, Permission } from 'types';

import { TableFileViewerDeleteDialog } from './TableFileViewerDeleteDialog';
import { WrapperStyled, ContentStyled, RowStyled } from './TableFileViewerList.styled';
import { DeleteIconButton } from '../../../../mui/IconButtons';
import { Link } from '../../../../mui/Link/Link';
import { useGetFiles } from '../../hooks/useGetFiles';

type FileUploaderInstantProps = {
  entityId: number;
  entityName: string;
  allowUpdate: boolean;
};

export const TableFileViewerList: React.FC<FileUploaderInstantProps> = ({
  entityName,
  entityId,
  allowUpdate,
}) => {
  const { hasResourcePermissions } = usePermissions();
  const record = useRecordContext<BankOperation>();

  const { data } = useGetFiles(entityName, entityId);

  const {
    record: fileRecord,
    handleOpenRecordModal,
    openRecordModal,
    handleCloseRecordModal,
  } = useRecordModal<FileData>();

  if (!record || !data?.length) {
    return <></>;
  }

  return (
    <>
      {data?.map((file, index) => (
        <WrapperStyled key={index}>
          <ContentStyled>
            <RowStyled>
              <InsertDriveFileOutlinedIcon sx={{ color: 'grey' }} />
              <Link href={file.filePath} rel="noreferrer" target="_blank">
                {file?.name}
              </Link>
            </RowStyled>
          </ContentStyled>
          {hasResourcePermissions('upload_files', [Permission.Full, Permission.Delete]) &&
            allowUpdate && <DeleteIconButton onClick={() => handleOpenRecordModal(file)} />}
        </WrapperStyled>
      ))}
      <TableFileViewerDeleteDialog
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={fileRecord}
      />
    </>
  );
};
