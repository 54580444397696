import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, RecordContextProvider, useNotify, useUpdate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Commission } from 'types';

import { transform } from '../../../../Commissions/utils/transform';
import {
  CommissionsFormValues,
  MerchantShowCommissionsForm,
} from '../components/MerchantShowCommissionsForm/MerchantShowCommissionsForm';
import { COMMISSIONS_SCHEMA } from '../schemas/schema';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
  record: Commission | undefined;
};

export const MerchantShowCommissionsEditDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
  record,
}) => {
  const [update] = useUpdate();
  const [errors, setErrors] = useState<string[]>([]);
  const notify = useNotify();

  const onSubmit = (data: CommissionsFormValues) => {
    const transformedData = transform(data);
    update(
      'commissions',
      {
        id: record?.id,
        data: {
          ...transformedData,
          merchant: merchantId,
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          notify('Элемент обновлен', {
            type: 'success',
          });
          onClose();
        },
        onError: (error: any) => {
          notify(`Ошибка: ${(error as Error).message}`, { type: 'error' });
          setErrors(error?.message.split('\n'));
        },
      },
    );
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Form
          defaultValues={{ merchant: merchantId, zeroed: false }}
          onSubmit={onSubmit as any}
          resolver={yupResolver<FieldValues>(COMMISSIONS_SCHEMA)}
        >
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Редактирование комиссии"
          />
          <Box sx={{ maxHeight: '650px', overflowY: 'auto' }}>
            <MerchantShowCommissionsForm errors={errors} merchantId={merchantId} />
          </Box>
          <CardActionsButtons
            rightActionsSlot={[
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
              <Button key="update" label="Сохранить" type="submit" variant="contained" />,
            ]}
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
