import { useGetOne, useRecordContext } from 'react-admin';
import { MerchantAccount } from 'types';
import { RefundChargebackReportSummaryMerchantAccount } from 'types/refundChagrebackReport';
import { convertReactAdminIdToHydraId } from 'utils';

import { TypographyStyled } from '../RefundChargebackReportSummary.styled';

type Props = {
  label: string;
  onClick: (record?: MerchantAccount) => void;
  source?: string;
};

export const RefundChargebackReportSummaryMerchantAccountField: React.FC<Props> = ({ onClick }) => {
  const record = useRecordContext<RefundChargebackReportSummaryMerchantAccount>();
  const { data: merchantAccount } = useGetOne<MerchantAccount>('merchant_accounts', {
    id: convertReactAdminIdToHydraId(record?.id, 'merchant_accounts'),
  });

  return (
    <TypographyStyled onClick={() => onClick(merchantAccount)}>{record?.name}</TypographyStyled>
  );
};
