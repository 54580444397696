import { usePermissions } from 'hooks/usePermissions';
import { RaRecord, RecordContextProvider } from 'react-admin';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { TableBody, TableCell, TableCellHeader, TableRow, TableRowWrapper } from 'shared/mui/Table';
import { DateTimeField, FunctionField, TextField } from 'shared/react-admin/Fields';
import { SimpleCurrencyField } from 'shared/react-admin/Fields/SimpleCurrencyField/SimpleCurrencyField';
import { ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { Limit, Permission } from 'types';

import { EMPTY_FIELD } from '../../../../../constants';

type Props = {
  open: boolean;
  onEdit: (record: Limit) => void;
  onDelete: (record: Limit) => void;
  onClose: () => void;
  record?: Limit;
};

export const MerchantsShowLimitsShowDialog: React.FC<Props> = ({
  open,
  onClose,
  onEdit,
  onDelete,
  record,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  return (
    <Dialog fullWidth onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Просмотр лимита"
        />
        <CardTable>
          <TableBody>
            <TableRowWrapper headerStyle={{ width: 160 }} title="ID">
              <TextField label="Id" source="originId" />
            </TableRowWrapper>
            {hasResourceAccess('gateways') && (
              <TableRow>
                <TableCellHeader title="Шлюз" />
                <TableCell component="th" scope="row">
                  <ReferenceOneField
                    label="Шлюз"
                    link="show"
                    reference="gateways"
                    source="gateway"
                    target="id"
                  >
                    <TextField source="name" />
                  </ReferenceOneField>
                </TableCell>
              </TableRow>
            )}
            {hasResourceAccess('currencies') && (
              <TableRowWrapper title="Валюта">
                <ReferenceOneField
                  label="Валюта"
                  reference="currencies"
                  source="currency"
                  target="id"
                >
                  <FunctionField render={(record: RaRecord | undefined) => record?.alphaCode} />
                </ReferenceOneField>
              </TableRowWrapper>
            )}
            <TableRow>
              <TableCellHeader title="Мин. сумма пополнения" />
              <TableCell component="th" scope="row">
                <SimpleCurrencyField emptyText={EMPTY_FIELD} source="paymentMin" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader title="Макс. сумма пополнения" />
              <TableCell component="th" scope="row">
                <SimpleCurrencyField emptyText={EMPTY_FIELD} source="paymentMax" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader title="Мин. сумма вывода" />
              <TableCell component="th" scope="row">
                <SimpleCurrencyField emptyText={EMPTY_FIELD} source="payoutMin" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader title="Макс. сумма вывода" />
              <TableCell component="th" scope="row">
                <SimpleCurrencyField emptyText={EMPTY_FIELD} source="payoutMax" />
              </TableCell>
            </TableRow>
            <TableRowWrapper title="Дата создания">
              <DateTimeField source="createdAt" />
            </TableRowWrapper>
            <TableRowWrapper title="Дата редактир.">
              <DateTimeField source="updatedAt" />
            </TableRowWrapper>
          </TableBody>
        </CardTable>
        <CardActionsButtons
          leftActionsSlot={
            hasResourcePermissions('limits', [Permission.Full, Permission.Update]) && (
              <Button
                label="Редактировать"
                onClick={() => {
                  onClose();
                  record && onEdit(record);
                }}
                variant="outlined"
              />
            )
          }
          rightActionsSlot={
            hasResourcePermissions('limits', [Permission.Full, Permission.Delete]) && (
              <DeleteButton
                onClick={() => {
                  onClose();
                  record && onDelete(record);
                }}
                variant="outlined"
              />
            )
          }
        />
      </RecordContextProvider>
    </Dialog>
  );
};
