import { useRecordModal } from 'hooks/useRecordModal';
import { useListContext } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { MerchantAccount } from 'types';
import {
  RefundChargebackReportSummary,
  RefundChargebackReportSummaryMerchantAccount,
} from 'types/refundChagrebackReport';

import { DatargidWithFooterStyled } from './RefundChargebackReportSummaryDatagrid.styled';
import { RefundChargebackReportSummaryDatagridFooter } from './RefundChargebackReportSummaryDatagridFooter/RefundChargebackReportSummaryDatagridFooter';
import { RefundChargebackReportSummaryAmountField } from '../RefundChargebackReportSummaryAmountField/RefundChargebackReportSummaryAmountField';
import { RefundChargebackReportSummaryMerchantAccountField } from '../RefundChargebackReportSummaryMerchantAccountField/RefundChargebackReportSummaryMerchantAccountField';

type Props = { summary: RefundChargebackReportSummary };

export const RefundChargebackReportSummaryDatagrid: React.FC<Props> = ({ summary }) => {
  const { isLoading } = useListContext();

  const {
    record: merchantAccount,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<MerchantAccount>();

  return (
    <>
      <DatargidWithFooterStyled
        bulkActionButtons={false}
        data={summary?.merchantAccounts || []}
        footer={<RefundChargebackReportSummaryDatagridFooter data={[summary]} />}
        isLoading={isLoading}
      >
        <RefundChargebackReportSummaryMerchantAccountField
          label="Счет мёрчанта"
          onClick={handleOpenShowDialog}
          source="id"
        />
        <FunctionField
          label="Валюта счета"
          render={(record: RefundChargebackReportSummaryMerchantAccount) => (
            <Stack>
              <Typography variant="body2">{record?.currency.alphaCode}</Typography>
              <Typography color={appColors.secondary.main} variant="body2">
                {record?.currency.name}
              </Typography>
            </Stack>
          )}
          source="currency"
        />
        <FunctionField
          label="Количество рефандов"
          render={(record: RefundChargebackReportSummaryMerchantAccount) => (
            <Typography variant="body2">{record?.refundCount}</Typography>
          )}
          source="refundCount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionRefundAmount"
          label="Сумма возврата по рефандам"
          originalValueKey="originalRefundAmount"
          source="originalRefundAmount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionRefundCommissionAmount"
          label="Сумма комиссий по рефандам"
          originalValueKey="originalRefundCommissionAmount"
          source="originalRefundCommissionAmount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionRefundTotalAmount"
          label="Итого по рефандам"
          originalValueKey="originalRefundTotalAmount"
          source="originalRefundTotalAmount"
        />
        <FunctionField
          label="Количество чарджбеков"
          render={(record: RefundChargebackReportSummaryMerchantAccount) => (
            <Typography variant="body2">{record?.chargebackCount}</Typography>
          )}
          source="chargebackCount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionChargebackAmount"
          label="Сумма возврата по чарджбекам"
          originalValueKey="originalChargebackAmount"
          source="originalChargebackAmount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionChargebackCommissionAmount"
          label="Сумма комиссий по чарджбекам"
          originalValueKey="originalChargebackCommissionAmount"
          source="originalChargebackCommissionAmount"
        />
        <RefundChargebackReportSummaryAmountField
          conversionValueKey="conversionChargebackTotalAmount"
          label="Итого по чарджбекам"
          originalValueKey="originalChargebackTotalAmount"
          source="originalChargebackTotalAmount"
        />
      </DatargidWithFooterStyled>
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </>
  );
};
