import { TextField, useRecordContext } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';

import { OrderShowLogsDelayField } from './OrderShowLogsDelayField/OrderShowLogsDelayField';
import { OrderShowLogsStatusField } from './OrderShowLogsStatusField/OrderShowLogsStatusField';

export const OrderShowLogsResponseFields = (): JSX.Element | null => {
  const record = useRecordContext();
  const responseStatus = record?.responseData?.statusCode;

  if (!responseStatus) {
    return null;
  }

  return (
    <Stack alignItems="center" direction="row" gap="8px">
      <OrderShowLogsStatusField />
      <OrderShowLogsDelayField />
      <TextField source="requestUrl" />
    </Stack>
  );
};
