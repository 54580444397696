import { useGetSummaryCopyData } from 'components/Orders/hooks/useGetSummaryCopyData';
import { useGetTransactionsReportSummary } from 'components/Orders/hooks/useGetTransactionsReportSummary';
import { getReportPeriod } from 'components/Orders/utils/getReportPeriod';
import { Box } from 'shared/mui/Box';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { LinearProgress } from 'shared/mui/LinearProgress/LinearProgress';
import { Typography } from 'shared/mui/Typography';

import { StyledBox } from './OrderListSummaryDialog.styled';
import { OrderListSummaryDialogTable } from './OrderListSummaryDialogTable';
import { OrdersListFilterFormValues } from '../OrderListContent';

type Props = {
  filterValues: OrdersListFilterFormValues;
} & DialogProps;

const DIRECTIONS_NAMES = {
  payment: 'пополнение',
  payout: 'вывод',
};

export const OrderListSummaryDialog: React.FC<Props> = ({
  filterValues,
  open,
  onClose,
}): JSX.Element => {
  const { direction } = filterValues;

  const { data: summary, isLoading } = useGetTransactionsReportSummary({
    filters: filterValues,
  });

  const { executedOrderCount = 0, notExecutedOrderCount = 0 } = summary || {};
  const total = Number(executedOrderCount) + Number(notExecutedOrderCount);

  const reportPeriod = getReportPeriod({
    dateGte: filterValues?.createdAt?.gte,
    dateLte: filterValues?.createdAt?.lte,
    timezone: filterValues?.timezone,
  });

  const { handleCopy } = useGetSummaryCopyData({ summary, reportPeriod });

  const periodMessage = `За период отчета ${reportPeriod} по направлению "${direction ? DIRECTIONS_NAMES[direction as keyof typeof DIRECTIONS_NAMES] : 'все направления'}" в системе зарегистрированы заказы в следующих статусах:`;

  return (
    <Dialog onClose={onClose} open={open}>
      <StyledBox>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Сводка по заказам за период"
        />
        <CardContent>
          {isLoading ? (
            <LinearProgress sx={{ margin: '16px auto' }} />
          ) : (
            <Box>
              <Typography>{periodMessage}</Typography>
              <OrderListSummaryDialogTable summary={summary} />
              <Typography sx={{ fontWeight: 500, textTransform: 'uppercase' }} variant="h6">
                Итого: {total}
              </Typography>
              <Typography
                color="secondary"
                component="span"
                sx={{ marginRight: '8px' }}
                variant="body1"
              >
                Исполнено: {summary?.executedOrderCount}
              </Typography>
              <Typography color="secondary" component="span" variant="body1">
                Не исполнено: {summary?.notExecutedOrderCount}
              </Typography>
            </Box>
          )}
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={<Button label="Копировать" onClick={handleCopy} variant="outlined" />}
        />
      </StyledBox>
    </Dialog>
  );
};
