import { styled } from '@mui/material/styles';
import { Alert } from 'shared/mui/Alert/Alert';

export const StyledAlert = styled(Alert)({
  margin: '4px 0 10px 0',
  alignItems: 'center',
  '& .MuiAlert-icon': {
    padding: 0,
  },
  '& .MuiAlert-message': {
    padding: 0,
    flex: 1,
  },
});
