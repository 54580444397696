import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';

const DATE_FORMAT = 'dd.MM.yyyy HH:mm';

export const getReportPeriod = ({
  dateGte,
  dateLte,
  timezone,
}: {
  dateGte?: number;
  dateLte?: number;
  timezone?: string | null;
}): string => {
  const startDate = dateGte
    ? formatInTimeZone(fromUnixTime(dateGte), timezone || DEFAULT_TIMEZONE, DATE_FORMAT)
    : '';
  const endDate = dateLte
    ? formatInTimeZone(fromUnixTime(dateLte), timezone || DEFAULT_TIMEZONE, DATE_FORMAT)
    : '';
  const currentTimezone = `${TIMEZONES.find((tz) => tz?.name === timezone)?.offset || '+3 UTC'}`;
  return `${startDate} - ${endDate} (${currentTimezone})`;
};
