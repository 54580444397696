import { AlertProps } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { appColors } from 'themes/variables';

import { StyledAlert } from './OrderListEmptyAlert.styled';

type Props = {
  onClick: VoidFunction;
} & AlertProps;

export const OrderListEmptyAlert: React.FC<Props> = ({ onClick }) => {
  return (
    <StyledAlert severity="info">
      <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
        <Typography sx={{ color: appColors.text.secondary }} variant="body2">
          Чтобы сформировать отчет, выберите период и необходимые параметры в фильтре.
        </Typography>
        <Button
          label="фильтр"
          onClick={onClick}
          size="small"
          sx={{ color: appColors.text.secondary }}
          variant="text"
        />
      </Stack>
    </StyledAlert>
  );
};
