import { appColors } from 'themes/variables';

export const UNPROCESSABLE_ENTITY_STATUS = 422;

export const RESPONSE_STATUSES = {
  200: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  201: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  202: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  203: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  204: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  205: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  206: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  207: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  208: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  226: {
    name: 'Ok',
    description: 'Everything worked as expected',
    color: appColors.success.light,
  },
  400: {
    name: 'Bad Request',
    description: 'The request was unacceptable, often due to missing a required parameter',
    color: appColors.warning.light,
  },
  401: {
    name: 'Unauthorized',
    description: 'No valid API key provided',
    color: appColors.warning.light,
  },
  402: {
    name: 'Failed',
    description: 'The requested resource doesn’t exist',
    color: appColors.warning.light,
  },
  403: {
    name: 'Unauthenticated',
    description: 'API key or signature are incorrect or they are not being sent as they should',
    color: appColors.warning.light,
  },
  404: {
    name: 'Not Found',
    description: 'The parameters were valid but the request failed',
    color: appColors.warning.light,
  },
  405: {
    name: 'Client error',
    description: 'The request was unacceptable',
    color: appColors.warning.light,
  },
  406: {
    name: 'Client error',
    description: 'The request was unacceptable',
    color: appColors.warning.light,
  },
  407: {
    name: 'Client error',
    description: 'The request was unacceptable',
    color: appColors.warning.light,
  },
  408: {
    name: 'The request was unacceptable',
    description: 'The parameters were valid but the request failed',
    color: appColors.warning.light,
  },
  409: {
    name: 'Conflict',
    description: 'The request conflicts with another request',
    color: appColors.warning.light,
  },
  429: {
    name: 'Too Many Requests',
    description: 'Too many requests hit the API too quickly',
    color: appColors.warning.light,
  },
  500: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  501: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  502: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  503: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  504: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  505: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
  506: {
    name: 'Server Errors',
    description: 'Something went wrong on services end',
    color: appColors.error.light,
  },
};
