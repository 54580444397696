import { useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Row } from 'shared/mui/Row/Row';
import {
  AutocompleteInput,
  AutocompleteInputIntrospected,
  TextInput,
  TextInputIntrospected,
} from 'shared/react-admin/Inputs';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { fieldWidth } from 'themes/variables';
import { Gateway } from 'types';

import { LimitsEditCurrencyInput } from './LimitsEditCurrencyInput';

export const LimitsEditForm = () => {
  const { watch } = useFormContext();
  const gatewayValue = watch('gateway');
  const { data: gateway } = useGetOne<Gateway>(
    'gateways',
    { id: gatewayValue },
    { enabled: !!gatewayValue },
  );

  return (
    <>
      <Row marginBottom={2}>
        <ReferenceInput perPage={200} reference="gateways" source="gateway">
          <AutocompleteInputIntrospected
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Шлюз"
            optionText="name"
            readOnly={true}
            size="medium"
            source="name"
            sx={fieldWidth.default}
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput
          filter={{
            id: [...(gateway?.supportedCurrencies || []), gateway?.defaultCurrency],
            active: true,
          }}
          helperText={false}
          perPage={1000}
          reference="currencies"
          resettable
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <LimitsEditCurrencyInput />
        </ReferenceInput>
      </Row>
      <Row marginBottom={2}>
        <ReferenceInput
          filter={{ archive: false }}
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInput
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth={true}
            helperText={false}
            label="Мерчант"
            optionText="name"
            readOnly={true}
            size="medium"
            variant="outlined"
          />
        </ReferenceInput>
      </Row>
      <Row marginBottom={2}>
        <TextInputIntrospected
          helperText={false}
          label="Минимальная сумма пополнения"
          size="medium"
          source="paymentMin"
          sx={fieldWidth.default}
          variant="outlined"
        />
        <TextInputIntrospected
          helperText={false}
          label="Максимальная сумма пополнения"
          size="medium"
          source="paymentMax"
          sx={fieldWidth.default}
          variant="outlined"
        />
      </Row>
      <Row>
        <TextInputIntrospected
          helperText={false}
          label="Минимальная сумма вывода"
          size="medium"
          source="payoutMin"
          sx={fieldWidth.default}
          variant="outlined"
        />
        <TextInputIntrospected
          helperText={false}
          label="Максимальная сумма вывода"
          size="medium"
          source="payoutMax"
          sx={fieldWidth.default}
          variant="outlined"
        />
      </Row>
      {/* TODO: После того, как бекенд удалить это поле у себя, их нужно удалить здесь */}
      <TextInput label="" source="max" sx={{ display: 'none' }} variant="outlined" />
      {/* TODO: После того, как бекенд удалить это поле у себя, их нужно удалить здесь */}
      <TextInput label="а" source="min" sx={{ display: 'none' }} variant="outlined" />
    </>
  );
};
