import React, { useEffect, useMemo, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useSidebarState } from 'react-admin';

import { ProtectedMenuItemLink } from '../../../components/Common/Protected/ProtectedMenuItemLink';
import { ProtectedMenuItemLinkWithCounter } from '../../../components/Common/Protected/ProtectedMenuItemLinkWithCounter';
import { usePermissions } from '../../../hooks/usePermissions';
import { Permission } from '../../../types';
import { MultiLevelMenuItem } from '../constants/menuList';

type Props = {
  multiLevelMenuItem: MultiLevelMenuItem;
  setCurrentResourceMenuItem: (resource: string | null) => void;
  currentResourceMenuItem: string | null;
};

export function MenuCollapseItem({
  multiLevelMenuItem: { title, icon, items },
  setCurrentResourceMenuItem,
  currentResourceMenuItem,
}: Props): JSX.Element {
  const [openSidebar] = useSidebarState();
  const [openList, setOpenList] = useState(false);

  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const RESOURCES_LIST = useMemo(() => items.map(({ resource }) => resource), [items]);

  const isShowResourceSubMenu = useMemo(
    () =>
      RESOURCES_LIST?.some(
        (resource) =>
          hasResourceAccess(resource) &&
          hasResourcePermissions(resource, [Permission.Full, Permission.Read]),
      ),
    [RESOURCES_LIST, hasResourcePermissions, hasResourceAccess],
  );

  const handleOpenList = () => {
    openSidebar
      ? setOpenList((prev) => !prev)
      : setCurrentResourceMenuItem(currentResourceMenuItem === title ? null : title);
  };

  useEffect(() => {
    setOpenList(false);
    setCurrentResourceMenuItem(null);
  }, [openSidebar]);

  if (!isShowResourceSubMenu) {
    return <></>;
  }

  return (
    <>
      <Tooltip placement="right" title={!openSidebar ? title : ''}>
        <ListItemButton className="MuiMenuItem-root" onClick={handleOpenList}>
          <ListItemIcon sx={{ marginLeft: '-4px' }}>{icon}</ListItemIcon>
          <ListItemText primary={openSidebar ? title : ''} sx={{ color: 'rgba(0,0,0,0.87)' }} />
          {openSidebar && (
            <>
              {openList ? (
                <ExpandLess sx={{ color: 'rgba(0,0,0,0.6)' }} />
              ) : (
                <ExpandMore sx={{ color: 'rgba(0,0,0,0.6)' }} />
              )}
            </>
          )}
        </ListItemButton>
      </Tooltip>
      <Collapse in={openList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ resource, title, counter }) => {
            const props = {
              primaryText: title,
              resource,
              sx: { paddingLeft: '70px' },
              to: `/${resource}`,
            };

            if (counter) {
              return <ProtectedMenuItemLinkWithCounter counter={counter} key={title} {...props} />;
            }
            return <ProtectedMenuItemLink key={title} {...props} />;
          })}
        </List>
      </Collapse>
    </>
  );
}
