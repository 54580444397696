import { Role, ROLES_ICONS, ROLES_SELECT_ITEMS } from 'constants/roles';

import { useMemo } from 'react';

import { ApiClientsLastActivityField } from 'components/ApiClients/components/ApiClientsLastActivityField/ApiClientsLastActivityField';
import { useRecordModal } from 'hooks/useRecordModal';
import { FormProvider } from 'react-hook-form';
import { CreateButton } from 'shared/mui/Buttons';
import { Chip } from 'shared/mui/Chips';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { ExportButton } from 'shared/react-admin/Buttons';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { DateTimeField, ListField, TextField } from 'shared/react-admin/Fields';
import { TooltipTextField } from 'shared/react-admin/Fields/TooltipTextField/TooltipTextField';
import { TooltipTextLinkField } from 'shared/react-admin/Fields/TooltipTextLinkField/TooltipTextLinkField';
import { ListPage } from 'shared/react-admin/Pages';
import { cleanEmpty } from 'utils/cleanEmpty';

import { StyledDatagridConfigurableWithStickyActions } from './ApiClientsList.styled';
import { ApiClientsListActiveField } from './ApiClientsListActiveField/ApiClientsListActiveField';
import { ApiClientsListApiKeysField } from './ApiClientsListApiKeysField/ApiClientsListApiKeysField';
import { ApiClientsListFilter } from './ApiClientsListFilter';
import { ApiClientsListFilterChips } from './ApiClientsListFilterChips';
import { ApiClientsListMerchantField } from './ApiClientsListMerchantField/ApiClientsListMerchantField';
import { ApiClientsListSecurityIssuesField } from './ApiClientsListSecurityIssuesField/ApiClientsListSecurityIssuesField';
import { usePermissions } from '../../../hooks/usePermissions';
import { SelectColumnButtonFilter } from '../../../shared/react-admin/Table/SelectColumnButtonFilter/SelectColumnButtonFilter';
import { ApiClient, Permission, SecurityIssuesTypes } from '../../../types';
import { ApiClientsDeleteDialog } from '../components/ApiClientsDeleteDialog/ApiClientsDeleteDialog';

export type ApiClientsListFilterFormValues = {
  name: string | null;
  roles: Role.Merchant | Role.Service | null;
  merchant: string | null;
  securityIssues: SecurityIssuesTypes | null;
};

export const ApiClientsList = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<ApiClient>();

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<ApiClientsListFilterFormValues>({
      mode: 'all',
      defaultValues: {
        name: null,
        roles: null,
        merchant: null,
        securityIssues: null,
      },
    });

  const listFilters = useMemo(() => {
    return cleanEmpty({
      ...appliedFilters,
    });
  }, [appliedFilters]);

  const isListFilterApplied = Object.keys(listFilters).length > 0;

  return (
    <ListPage
      actions={[
        hasResourcePermissions('api_clients', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-button" />
        ),
        <ExportButton key="export-button" />,
      ]}
      empty={false}
      filter={listFilters}
      headerTitle={{
        titleText: 'Клиенты API',
        tooltipText:
          'Приложение, выполняющее запросы к API Kubera. Например, сайт мерчанта или его мобильное приложение.',
      }}
      listBoxProps={{ sx: { maxHeight: '100%' } }}
    >
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <SelectColumnButtonFilter key="select-column" />,
          <FilterClearButton
            key="clear-filter"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterApplied}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <ApiClientsListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <ApiClientsListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <StyledDatagridConfigurableWithStickyActions
        bulkActionButtons={false}
        rowSx={(record: ApiClient) =>
          record?.securityIssues.some((issue) => issue !== SecurityIssuesTypes.NoProblem)
            ? { background: 'rgb(254, 235, 238)' }
            : { background: 'white' }
        }
      >
        <TextField label="ID" sortBy="id" source="originId" />
        <ApiClientsListActiveField label="-" source="active" />
        <TooltipTextLinkField label="Название" resource="api_clients" source="name" type="show" />
        <TooltipTextField label="Алиас" source="alias" />
        <ListField
          array={ROLES_SELECT_ITEMS}
          label="Доступ"
          renderItem={({ id, name }) => <Chip icon={ROLES_ICONS[id]} key={id} label={name} />}
          sortable={false}
          source="roles"
        />
        <ApiClientsLastActivityField
          label="Последняя активность"
          sortBy="lastActivity"
          source="activityUpdatedAt"
        />
        {hasResourceAccess('merchants') && <ApiClientsListMerchantField label="Мерчанты" />}
        {hasResourceAccess('api_keys') && <ApiClientsListApiKeysField label="API ключи" />}
        <ApiClientsListSecurityIssuesField
          label="Уведомления безопасности"
          source="securityIssues"
        />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <DatagridActions
          render={(record: ApiClient) => [
            hasResourceAccess('user_actions') && (
              <DatagridActionsUserActionsButton
                entityId={record?.originId}
                entityName="api_client"
                key="user-action-link"
                target="_blank"
              />
            ),
            <DatagridActionsShowButton key="show-button" resource="api_clients" target="_blank" />,
            hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) && (
              <DatagridActionsEditButton key="edit-button" resource="api_clients" target="_blank" />
            ),
            hasResourcePermissions('api_clients', [Permission.Full, Permission.Delete]) && (
              <DatagridActionsDeleteButton
                key="delete-button"
                onClick={() => handleOpenRecordModalDelete(record)}
              />
            ),
          ]}
        />
      </StyledDatagridConfigurableWithStickyActions>
      {openRecordModalDelete && (
        <ApiClientsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          onSuccess={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
    </ListPage>
  );
};
