import { styled } from '@mui/material/styles';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';
import { ImageField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

export const DatagridConfigurableStyled = styled(DatagridConfigurable)({
  '.RaDatagrid-headerCell': {
    backgroundColor: appColors.tableHead,
    padding: '8px 16px',
    lineHeight: '18px',
  },
  '.MuiTableCell-root': {
    verticalAlign: 'middle',
  },
  '.MuiTableCell-body': {
    padding: '8px 16px',
  },
  '& .column-id': getColumnStyle(168),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-updatedAt': getColumnStyle(120),
  '& .column-status': getColumnStyle(168),
  '& .column-impactToBalance': { ...getColumnStyle(110), textAlign: 'center' },
  '& .column-amount': {
    ...getColumnStyle(168),
    textAlign: 'right',
    flexDirection: 'row',
  },
  '& .column-currency': {
    width: '88px',
    maxWidth: '200px',
    minWidth: '88px',
  },
  '& .column-commissionValueByCurrencyOrder': {
    ...getColumnStyle(168),
    textAlign: 'right',
  },
  '& .column-commissionValueByCurrencyCommission': {
    ...getColumnStyle(168),
    textAlign: 'right',
  },
  '& .column-commission': {
    minWidth: '184px',
  },
  '& .column-totalAmount': {
    ...getColumnStyle(168),
    textAlign: 'right',
  },
  '& .column-direction': getColumnStyle(136),
  '& .column-merchant': {
    ...getColumnStyle(168),
  },
  '& .column-merchantAccount': {
    ...getColumnStyle(184),
    ...getColumnStyle(168),
  },
  '& .column-gateway': {
    ...getColumnStyle(184),
  },
  '& .column-method': {
    ...getColumnStyle(184),
  },
  '& .column-merchantReference': getColumnStyle(168),
  '& .column-providerReferenceId': getColumnStyle(168),
});

export const ImageFieldStyled = styled(ImageField)({
  '& .RaImageField-image': {
    margin: 0,
    marginRight: '2px',
    maxWidth: 24,
    maxHeight: 24,
    minWidth: 24,
    minHeight: 24,
    objectFit: 'contain',
  },
});
