import { useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useRecordModal } from 'hooks/useRecordModal';
import { WrapperField } from 'react-admin';
import { Button, CreateButton } from 'shared/mui/Buttons';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
  DatagridActionsUserActionsButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import { ChipField, FunctionField, SingleFieldList } from 'shared/react-admin/Fields';
import { ListPage } from 'shared/react-admin/Pages';
import { ReferenceArrayField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { getUserName } from 'utils';

import { StyledDatagridWithStickyActions } from './WorkTeamsList.styled';
import { WorkTeamsListFilter } from './WorkTeamsListFilter';
import { WorkTeamsListInfoDialog } from './WorkTeamsListInfoDialog/WorkTeamsListInfoDialog';
import { WorkTeamsListMembersAvatars } from './WorkTeamsListMembersAvatars';
import { WorkTeamsListNameField } from './WorkTeamsListNameField/WorkTeamsListNameField';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Permission, WorkTeam } from '../../../../types';
import { WorkTeamsDeleteDialog } from '../components/WorkTeamsDeleteDialog';

export const WorkTeamsList = () => {
  const { hasResourcePermissions, hasResourceAccess } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<WorkTeam>();
  const [isOpenInfoDialog, setIsOpenInfoDialog] = useState(false);

  return (
    <ListPage
      actions={[
        <Button
          key="help"
          label="справка"
          onClick={() => setIsOpenInfoDialog(true)}
          startIcon={<InfoOutlinedIcon />}
          variant="outlined"
        />,
        hasResourcePermissions('work_teams', [Permission.Full, Permission.Create]) && (
          <CreateButton isLinkToModal key="create-comissions" />
        ),
      ]}
      empty={false}
      headerTitle={{ titleText: 'Рабочие группы' }}
      listBoxProps={{ sx: { marginTop: '16px' } }}
    >
      <WorkTeamsListFilter />
      <StyledDatagridWithStickyActions bulkActionButtons={false}>
        <WorkTeamsListNameField label="Название группы" source="name" />
        <ReferenceArrayField
          label="Страны"
          reference="countries"
          sortable={false}
          source="countries"
        >
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: WorkTeam) => (
                <ChipField color="default" record={record} source="name" />
              )}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceOneField
          label="Руководитель группы"
          reference="users"
          source="manager"
          target="id"
        >
          <FunctionField render={getUserName} />
        </ReferenceOneField>
        <ReferenceArrayField label="Участники" reference="users" sortable={false} source="members">
          <WorkTeamsListMembersAvatars />
        </ReferenceArrayField>
        <WrapperField cellClassName="actions">
          <DatagridActions
            render={(record: WorkTeam) => [
              hasResourceAccess('user_actions') && (
                <DatagridActionsUserActionsButton entityName="work_team" key="user-action-button" />
              ),
              <DatagridActionsShowButton key="show-button" />,

              hasResourcePermissions('work_teams', [Permission.Full, Permission.Update]) && (
                <DatagridActionsEditButton key="edit-button" />
              ),
              hasResourcePermissions('work_teams', [Permission.Full, Permission.Delete]) && (
                <DatagridActionsDeleteButton
                  key="delete-button"
                  onClick={() => handleOpenRecordModalDelete(record)}
                />
              ),
            ]}
          />
        </WrapperField>
      </StyledDatagridWithStickyActions>
      {openRecordModalDelete && (
        <WorkTeamsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {isOpenInfoDialog && (
        <WorkTeamsListInfoDialog
          onClose={() => setIsOpenInfoDialog(false)}
          open={isOpenInfoDialog}
        />
      )}
    </ListPage>
  );
};
