import { OrderStatuses } from 'constants/orderStatuses';

import { ChipFieldProps, useRecordContext } from 'react-admin';
import { StatusIcon } from 'shared/mui/Icons';
import { StatusIconProps } from 'shared/mui/Icons/StatusIcon/StatusIcon';

import { ConfirmationChipField, OrderStatusChipFieldStyled } from './OrderStatusChipField.styled';

export type OrderStatusChipFieldProps = ChipFieldProps & {
  iconProps?: Omit<StatusIconProps, 'color'>;
};

export const OrderStatusChipField = ({
  iconProps,
  ...restProps
}: OrderStatusChipFieldProps): JSX.Element => {
  const record = useRecordContext();

  if (record?.value === OrderStatuses.WaitingConfirmation) {
    return <ConfirmationChipField {...restProps} />;
  }

  return (
    <OrderStatusChipFieldStyled
      icon={<StatusIcon color={record?.value} {...iconProps} />}
      {...restProps}
    />
  );
};
