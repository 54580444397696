import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Form, RecordContextProvider, useNotify, useUpdate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import { MerchantsShowLimitsEditDialogForm } from './MerchantsShowLimitsEditDialogForm/MerchantsShowLimitsEditDialogForm';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { Limit, Permission } from '../../../../../types';
import { LIMITS_SCHEMA } from '../schemas/schema';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
  onDelete: (record: Limit) => void;
  record: Limit | undefined;
};

interface FormValues extends FieldValues {
  currency: string;
  gateway: string;
  paymentMax: number;
  paymentMin: number;
  payoutMax: number;
  payoutMin: number;
}

const transform = (data: FormValues) => {
  return {
    ...data,
    paymentMax: data.paymentMax ? data.paymentMax + '' : null,
    paymentMin: data.paymentMin ? data.paymentMin + '' : null,
    payoutMax: data.payoutMax ? data.payoutMax + '' : null,
    payoutMin: data.payoutMin ? data.payoutMin + '' : null,
  };
};

export const MerchantsShowLimitsEditDialog: React.FC<Props> = ({
  open,
  onClose,
  onDelete,
  record,
  merchantId,
}) => {
  const { hasResourcePermissions } = usePermissions();

  const [update] = useUpdate();
  const notify = useNotify();

  const onSubmit = (formValues: FormValues) => {
    update(
      'limits',
      {
        id: record?.id,
        data: {
          ...transform(formValues),
          merchant: merchantId,
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          notify('Лимит успешно отредактирован!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error) => notify(`Ошибка: ${(error as Error).message}`, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} scroll="body" transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Form onSubmit={onSubmit as any} resolver={yupResolver<FieldValues>(LIMITS_SCHEMA)}>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Редактирование лимита"
          />
          <MerchantsShowLimitsEditDialogForm merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="update" label="Сохранить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('limits', [Permission.Full, Permission.Delete]) && (
                <DeleteButton
                  onClick={() => {
                    onClose();
                    record && onDelete(record);
                  }}
                  variant="outlined"
                />
              )
            }
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
