import * as React from 'react';

import { format } from 'date-fns';
import { useRecordContext } from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { UserBadgeField } from 'shared/react-admin/Fields';
import { appColors } from 'themes/variables';
import { User } from 'types';

import { BoxStyled, ProfileBoxStyled } from './CommissionsListCommentUser.styled';

type Props = {
  createdAt: string;
};

export const CommissionsListCommentUser: React.FC<Props> = ({ createdAt }) => {
  const { firstName, lastName } = useRecordContext<User>();
  return (
    <BoxStyled>
      <UserBadgeField firstName={firstName} lastName={lastName} />
      <ProfileBoxStyled>
        <Typography fontSize={14}>
          {firstName} {lastName}
        </Typography>
        <Typography fontSize={13} sx={{ color: appColors.secondary.main }}>
          <Typography>{format(Date.parse(createdAt), 'dd.MM.yyyy, HH:mm:ss')}</Typography>
        </Typography>
      </ProfileBoxStyled>
    </BoxStyled>
  );
};
