import { BankOperationStatusesNew } from 'constants/bankOperationStatusesNew';

import * as React from 'react';

import { useBankOperationEditable } from 'components/Finance/BankOperations/hooks/useBankOperationEditable';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button, DeleteButton, PageEditButton } from 'shared/mui/Buttons';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { ShowPage } from 'shared/react-admin/Pages';
import { BankOperation, Permission } from 'types';

import { BankOperationsShowAgreeDialog } from './BankOperationsShowAgreeDialog/BankOperationsShowAgreeDialog';
import { BankOperationsShowByAgreementDialog } from './BankOperationsShowByAgreementDialog/BankOperationsShowByAgreementDialog';
import { BankOperationsShowCancelDialog } from './BankOperationsShowCancelDialog/BankOperationsShowCancelDialog';
import { BankOperationsShowRejectDialog } from './BankOperationsShowRejectDialog/BankOperationsShowRejectDialog';
import { BankOperationsShowTableNew } from './BankOperationsShowTableNew';
import { useBankOperationsAvailableActions } from '../hooks/useBankOperationsAvailableActions';

export const BankOperationsShowNew: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useShowController();
  const { data = [] } = useBankOperationsAvailableActions(record?.originId);
  const navigate = useNavigate();

  const { handleCloseRecordModal, openRecordModal, handleOpenRecordModal } = useRecordModal();

  const {
    record: recordModalAgreement,
    handleOpenRecordModal: handleOpenModalAgreement,
    openRecordModal: openModalAgreement,
    handleCloseRecordModal: handleCloseModalAgreement,
  } = useRecordModal<BankOperation>();

  const {
    record: recordModalAgree,
    handleOpenRecordModal: handleOpenModalAgree,
    openRecordModal: openModalAgree,
    handleCloseRecordModal: handleCloseModalAgree,
  } = useRecordModal<BankOperation>();

  const {
    record: recordModalReject,
    handleOpenRecordModal: handleOpenModalReject,
    openRecordModal: openModalReject,
    handleCloseRecordModal: handleCloseModalReject,
  } = useRecordModal<BankOperation>();

  const {
    record: recordModalCancel,
    handleOpenRecordModal: handleOpenModalCancel,
    openRecordModal: openModalCancel,
    handleCloseRecordModal: handleCloseModalCancel,
  } = useRecordModal<BankOperation>();

  const { checkBankOperationEditable } = useBankOperationEditable();

  const renderActions = (): React.ReactElement[] | false => {
    const actions = [];

    if (data.includes(BankOperationStatusesNew.OnApproval)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agreement"
          label="На согласование"
          onClick={() => handleOpenModalAgreement(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(BankOperationStatusesNew.Approved)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agree"
          label="Согласовать"
          onClick={() => handleOpenModalAgree(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(BankOperationStatusesNew.Rejected)) {
      actions.push(
        <Button
          color="error"
          key="btn-reject"
          label="Отклонить"
          onClick={() => handleOpenModalReject(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(BankOperationStatusesNew.Canceled)) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Аннулировать"
          onClick={() => handleOpenModalCancel(record)}
          variant="outlined"
        />,
      );
    }

    return actions.length ? actions : false;
  };

  return (
    <ShowPage
      additionalActions={renderActions()}
      leftActionsSlot={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Update]) &&
          checkBankOperationEditable(record) && <PageEditButton />,
      ]}
      listName="Банковские операции"
      rightActionsSlot={[
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) &&
          checkBankOperationEditable(record) && (
            <DeleteButton
              onClick={() => handleOpenRecordModal(record)}
              title="Удалить"
              variant="outlined"
            />
          ),
      ]}
      title="Просмотр банковской операции"
    >
      <BankOperationsShowTableNew />
      <DeleteDialog
        details="Банковская операция будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={record}
        title="Удаление банковской операции"
      />
      <BankOperationsShowByAgreementDialog
        onClose={handleCloseModalAgreement}
        open={openModalAgreement}
        record={recordModalAgreement}
      />
      <BankOperationsShowAgreeDialog
        onClose={handleCloseModalAgree}
        open={openModalAgree}
        record={recordModalAgree}
      />
      <BankOperationsShowRejectDialog
        onClose={handleCloseModalReject}
        open={openModalReject}
        record={recordModalReject}
      />
      <BankOperationsShowCancelDialog
        onClose={handleCloseModalCancel}
        open={openModalCancel}
        record={recordModalCancel}
      />
    </ShowPage>
  );
};
