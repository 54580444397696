import { styled } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Box } from 'shared/mui/Box';
import { Typography } from 'shared/mui/Typography';
import { Datagrid } from 'shared/react-admin/Datagrids';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

export const StyledCumulativeReportSummaryPeriod = styled('div')(
  ({ isOpen }: { isOpen: boolean }) => ({
    width: '100%',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${appColors.divider}`,
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: isOpen ? 0 : '4px',
    borderBottomLeftRadius: isOpen ? 0 : '4px',
    padding: '0 16px',
    backgroundColor: appColors.background,
  }),
);

export const TypographyStyled = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
  color: blue[700],
  cursor: 'pointer',
  fontSize: '14px',
});

export const DatagridStyled = styled(Datagrid)({
  width: '100%',
  borderBottom: `1px solid ${appColors.divider}`,
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderTop: 'none',
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  '& .MuiTableCell-root': {
    verticalAlign: 'middle!important',
  },
  '& .RaDatagrid-headerCell': {
    padding: '10px 16px',
  },
  '& .MuiTableCell-body': {
    verticalAlign: 'top',
    padding: '16px',
  },
  '& .MuiTableCell-head': {
    backgroundColor: '#FAFAFA',
  },
  '& .column-merchantAccountName': getColumnStyle(200),
  '& .column-merchantAccountCurrencyCode': { maxWidth: '200px', minWidth: '88px', width: '200px' },
  '& .column-originalPaymentAmount, .column-originalPayoutAmount, .column-originalRefundAmount, .column-originalChargebackAmount, .column-originalCommissionValue, .column-originalTotalAmount':
    {
      ...getColumnStyle(168),
      textAlign: 'right',
    },
});

export const BoxStyled = styled(Box)({
  marginBottom: '16px',
});
